<template lang="pug">

.app-card.tw-mb-5.tw-pl-10.tw-pr-20
  .flex(class='justify-end')
    edit-invoiceable-modal(
      :data-icon='"fa fa-plus"' 
      :data-label="$I18n.t('vue.invoiceables_detail_table_panel.create_new_invoice_item')"
      :data-mode='"new"'
      :data-item='JSON.stringify(empty_item)'
      :data-percentage-default='dataPercentageDefault'
      @updated='createNewInvoiceItem'
    )
  table(v-if='!emptyItems' class="table table-hover")
    thead
      th(class="tw-text-left") {{$I18n.t('vue.invoiceables_detail_table_panel.table_header_concept')}}
      th(class="tw-text-left") {{$I18n.t('vue.invoiceables_detail_table_panel.table_header_description')}}
      th(class="tw-text-right") {{$I18n.t('vue.invoiceables_detail_table_panel.table_header_price')}}
      th(class="tw-text-center") {{$I18n.t('vue.invoiceables_detail_table_panel.table_header_quantity')}}
      th(class="tw-text-center") {{$I18n.t('vue.invoiceables_detail_table_panel.table_header_percentage')}}
      th(class="tw-text-right") {{$I18n.t('vue.invoiceables_detail_table_panel.table_header_total')}}
      th
    tbody
      tr(v-for="item in items")
        td {{item.concept}}
        td {{item.description}}
        td(class="tw-text-right") {{item.masked_price}}
        td(class="tw-text-center") {{item.quantity}}
        td(class="tw-text-center") {{item.masked_percentage}}
        td(class="tw-text-right") {{item.masked_total}}
        td
          .app-buttons-row(class='justify-end')
            delete-invoiceable-modal(
              :data-id='item.id'
              @deleted='deleteInvoiceItem'
            )
            edit-invoiceable-modal(
              :data-icon='"fa fa-pencil"'
              :data-label='""'
              :data-mode='"edit"'
              :data-item='JSON.stringify(item)'
              :data-percentage-default='dataPercentageDefault'
              @updated='updateInvoiceItem'
            )
  div(v-if='!emptyItems')
    .row
      .col-9
      .col-3
        .row
          table(class="table table-bordered table-hover")
            tbody
              tr(v-if="calcularTotal()")
                th.flex(class='justify-end')  {{$I18n.t('vue.invoiceables_detail_table_panel.total_invoice_items')}}
                th(class="text-dark") {{calcularTotal()}}

  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.invoiceables_detail_table_panel.empty_items')}}

</template>
<script>

import DeleteInvoiceableModal from './delete-invoiceable-modal.vue'
import EditInvoiceableModal from './edit-invoiceable-modal.vue'
import {BackendService} from '../../services';
import CoreComponents from '../core';

export default {
  components:{
    'delete-invoiceable-modal':DeleteInvoiceableModal,
    'edit-invoiceable-modal':EditInvoiceableModal,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataItems: String,
    dataPercentageDefault: String,
  },
  data: function(){
    return {
      state: {
        confirmed: false,
      },
      empty_item:{
        concept:"",
        description:"",
        price:"",
        quantity:"",
        percentage:"",
        total:"",
      },
      record:{
        id: this.dataId,
      },
      items: JSON.parse(this.dataItems),
    }
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  },
  methods:{
    retrieveInvoiceItems:function(){
      location.reload();
    },
    createNewInvoiceItem:function(item){
      const self = this;
      this.state.sending = true;
      let params = JSON.stringify({
        id: this.dataId,
        invoice_item: JSON.parse(item)
      })
      BackendService
        .create_invoice_item(params)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    deleteInvoiceItem:function(invoiceable_id){
      const self = this;
      this.state.sending = true;
      BackendService
        .delete_invoice_item(invoiceable_id)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    updateInvoiceItem:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .update_invoice_item(params)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    calcularTotal:function(){
      // Usamos reduce para iterar sobre el array y sumar los precios
      let total = this.items.reduce((total, item) => {
        return total + parseFloat(item.total.replace("$", "").replace(",", "").replace(" ", ""));
      }, 0); // Inicializamos el total en 0
      return total.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  }
}
</script>
