<template lang='pug'>
  div
    li(class="list-group-item tw-py-5" :class="classes.li")
      a(:href="href") 
        i(:class="icon" class="mr-2")
        span {{label}}
</template>

<script>

export default {
  props: ["href", "label", "icon", "highlighted"],
  data:function(){
    return {
      classes:{
        li:{'list-group-item-active': this.highlighted === 'true' ? true : false}
      }
    }
  },
  methods:{ }
}
</script>
