<template lang="pug">

.app-card.overflow-x-auto
  .flex(class='justify-end')
    edit-service-modal(
      :data-icon='"fa fa-plus"'
      :data-mode='"new"'
      :data-item='JSON.stringify([])'
      @updated='createNewService'
      :data-label="$I18n.t('vue.services_detail_table_panel.title')"
    )

  table(v-if='!emptyItems' class="table")
    thead
      th {{$I18n.t('vue.services_detail_table_panel.table_header_service')}}
      th {{$I18n.t('vue.services_detail_table_panel.table_header_frequency')}}
      th {{$I18n.t('vue.services_detail_table_panel.table_header_base_price')}}
      th {{$I18n.t('vue.services_detail_table_panel.table_header_breakdown')}}
      th {{$I18n.t('vue.services_detail_table_panel.table_header_total')}}
      th
    tbody
      tr(v-for="item in items")
        td {{item.service}}
        td(class="text-center") {{item.frequency_month=="" || item.frequency_month==null ? item.frequency : $I18n.t('vue.services_detail_table_panel.months')+": "+item.frequency_month}}
        td(class="text-center") {{item.base_price}}
        td {{item.breakdown}}
        td(class="text-center") ${{(item.total*100/100).toFixed(2)}}
        td
          .app-buttons-row(class='justify-end')
            delete-service-modal(
              :data-id='item.id'
              :data-related-orders='item.orders_for_this_service'
              @deleted='deleteService'
            )
            edit-service-modal(
              :data-icon='"fa fa-pencil"'
              :data-label='""'
              :data-mode='"edit"'
              :data-item='JSON.stringify(item)'
              @updated='updateService'
            )
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.services_detail_table_panel.empty_items')}}

</template>
<script>

import DeleteServiceModal from './delete-service-modal.vue'
import EditServiceModal from './edit-service-modal.vue'
import {BackendService} from '../../services';
import CoreComponents from '../core';

export default {
  components:{
    'delete-service-modal':DeleteServiceModal,
    'edit-service-modal':EditServiceModal,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataItems: String,
  },
  data: function(){
    return {
      state: {
        confirmed: false,
      },
      record:{
        id: this.dataId,
      },
      items: JSON.parse(this.dataItems),
    }
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  },
  methods:{
    retrieveServices:function(){
      location.reload();
    },
    createNewService:function(item){
      const self = this;
      this.state.sending = true;
      let params = JSON.stringify({
        id: this.dataId,
        service: JSON.parse(item)
      })
      BackendService
        .create_subsidary_service(params)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    deleteService:function(service_id){
      const self = this;
      this.state.sending = true;
      BackendService
        .service_delete(service_id)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    updateService:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .service_update(params)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
  }
}
</script>
