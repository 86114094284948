<template lang="pug">
  div
    //Notification Alert
    Notificationalert(
      v-if="controls.alert.display" 
      v-bind:alert="controls.alert"
    )

    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

    // FORM
    div(v-else)
      Detailform(v-bind:grid="form_grid")
      div(v-if="!record.present")
          p(class="mt-5 text-danger") {{$I18n.t('vue.new_technician_form.necessary_capture_value')}}
      
      // BUTTONS
      div(v-if="!state.sending" class="tw-text-right tw-mt-7")
        div(class="form-group row")
          div(class="row")
            div(class="col")
              a(@click="clear" class="app-btn-link-primary") 
                i(class="fa fa-eraser mr-2")
                span {{$I18n.t('vue.new_technician_form.button_clear')}}
              a(class="app-btn-link-primary" :href="index_technicians_path") 
                i(class="fa fa-times mr-2")
                span {{$I18n.t('vue.new_technician_form.button_cancel')}}
              button(@click="send" class="app-btn-primary") 
                i(class="fa fa-save mr-2")
                span {{$I18n.t('vue.new_technician_form.button_create')}}

</template>
<script>

import CoreComponents from '../core';
const {ActiveValue}=require('../../core/active_support');
const {BackendService,BackendRoutes} = require('app/services');

export default {
  props:{},
  data: function(){
    return {
      record:{
        id: "",
        model: "technicians",
        present: true
      },
      controls:{
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      inputs:{
        role: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.new_technician_form.role'));
          i.setValue("");
          i.setInputKind('text');
        }),
        first_name: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.new_technician_form.first_name'));
          i.setValue("");
          i.setInputKind('text');
        }),
        middle_name: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.new_technician_form.middle_name'));
          i.setValue("");
          i.setInputKind('text');
        }),
        last_name: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.new_technician_form.last_name'));
          i.setValue("");
          i.setInputKind('text');
        }),
        email: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.new_technician_form.email'));
          i.setValue("");
          i.setKind('email');
          i.setInputKind('text');
        }),
        phone_kind: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.new_technician_form.phone_kind'));
          i.setValue("");
          i.setInputKind('text');
        }),
        phone_number: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.new_technician_form.phone_number'));
          i.setValue("");
          i.setInputKind('text');
        })
      },
      state:{
        compleated: true,
        sending: false
      },
      result:{
        isError: undefined,
        isOk: undefined,
        key: undefined,
        notice: undefined,
        technician: {
          id: undefined
        }
      }
    }
  },
  computed:{
    index_technicians_path:function(){
      return  BackendRoutes.index_technicians_path();
    },
    technician_path:function(){
      return  BackendRoutes.technician_path(this.result.technician.id);
    },
    form_grid:function(){
      return [
        [this.inputs.role],
        [this.inputs.email],
        [this.inputs.first_name, this.inputs.middle_name,  this.inputs.last_name],
        [this.inputs.phone_kind, this.inputs.phone_number]
      ]
    }
  },
  methods:{
    valid: function () {
      let ins = this.inputs;
      //If some technician values are present all are required otherwise all are optional
      let technician_keys = [
        "role",
        "first_name",
        "middle_name",
        "last_name",
        "email",
        "phone_kind",
        "phone_number"
      ];
      let technicianIsPresent = technician_keys
        .map((k) => ins[k].isPresent())
        .some((v) => v === true);
      if (technicianIsPresent) {
        this.record.present = true
      } else {
        this.record.present = false
      }
      return this.record.present
    },
    stringify:function(){
      return JSON.stringify({
        role_name: this.inputs.role.input.toString(),
        first_name: this.inputs.first_name.input,
        middle_name:this.inputs.middle_name.input,
        last_name:this.inputs.last_name.input,
        email:this.inputs.email.input,
        state:"ACTIVE",
        phone: {
          kind: this.inputs.phone_kind.input.toString(),
          number:this.inputs.phone_number.input
        }
      })
    },
    send:function(){
      let self = this; 
      if(self.valid()){
        self.state.sending=true;
        BackendService.create_technician(self.stringify())
          .then((response)=>{
            self.result = response;
            self.controls.alert.notice = 'vue.new_technician_form.result.'+response.notice
            if (response.isOk){
              self.controls.alert.type="success"
              self.controls.alert.message = 'vue.new_technician_form.view_detail_text'
              self.controls.alert.link.text = 'vue.new_technician_form.show_link'
              self.controls.alert.link.href=this.technician_path
              self.clear();
            }else{
              self.controls.alert.type="danger"
            }
          })
          .finally(()=>{
            self.state.sending=false;
            self.controls.alert.display=true
          })
      }
    },
    clear:function(){
      Object
        .values(this.inputs)
        .forEach(v=>v.resetValue());
    }
  },
  components:{...CoreComponents}
}
</script>
