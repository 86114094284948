<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display",
    v-bind:alert="controls.alert"
  )

  // Spinner Loading
  .tw-text-center.tw-my-20(v-if="state.sending")
    .spinner-border.spinner-border-sm(role="status")

  // FORM
  div(v-else)

    //Data Payment
    .flex(class='justify-between items-center')
      h5(class="h5 pt-4 tw-text-gray-500") {{$I18n.t('vue.new_payment_form.subtitle_invoice_detail')}}
    hr
    app-form.tw-py-4(v-bind:grid="formGrid")

    p(style="display: none;") {{set_values_amount}}

    //List Invoices
    .flex(class='justify-between items-center')
      h5(class="h5 pt-4 tw-text-gray-500") {{$I18n.t('vue.new_payment_form.list_invoices_detail')}}
    hr
    div(v-if='emptyItems')
      p(class='message-search') {{$I18n.t('vue.new_payment_form.no_invoices')}}
    div(v-else)
      div(class="app-table-scrollable-500")
        table.table
          thead
            tr
              th
                input(
                  class="form-check-input"
                  type="checkbox"
                  :checked='controls.checkbox.checked_masive.checked'
                  @click="checked_masive()"
                )
              th {{$I18n.t('vue.new_payment_form.table_header_number')}}
              th {{$I18n.t('vue.new_payment_form.table_header_status')}}
              th {{$I18n.t('vue.new_payment_form.table_header_vendor')}}
              th {{$I18n.t('vue.new_payment_form.table_header_customer')}}
              th {{$I18n.t('vue.new_payment_form.table_header_subsidary')}}
              th {{$I18n.t('vue.new_payment_form.table_header_date')}}
              th {{$I18n.t('vue.new_payment_form.table_header_amount')}}
              th {{$I18n.t('vue.new_payment_form.table_header_percentage')}}
              th {{$I18n.t('vue.new_payment_form.table_header_amount_cr')}}
              th {{$I18n.t('vue.new_payment_form.table_header_amount_pay')}}
              th {{$I18n.t('vue.new_payment_form.table_header_outstanding_balance')}}
          tbody
            tr(v-for="(invoice, index) in items")
              td 
                input(
                  class="form-check-input"
                  type="checkbox"
                  v-model='invoice.checked'
                  @change="update_amount()"
                ) 
              td {{ invoice.document_number==null ? invoice.id : invoice.document_number}}
              td {{ $I18n.t('vue.states.'+invoice.status.toLowerCase()) }}
              td(v-html="invoice.list_vendors_in_links")
              td(v-html="invoice.list_customers_in_links")
              td(v-html="invoice.list_subsidaries_in_links")
              td {{ invoice.locale_date }}
              td {{ invoice.masked_amount }}
              td {{ invoice.masked_porcent }}
              td {{ invoice.masked_amount_with_credit_card }}
              td {{ invoice.masked_total_paid_amount }}
              td {{ invoice.masked_total_unpaid_amount }}
              td 
                app-icon-link(
                  data-kind="link",
                  :href="invoice.paths.show"
                  data-icon="fa fa-eye",
                  data-label="",
                  data-target="_blank",
                  :data-title="$I18n.t('vue.new_payment_form.view_invoice')"
                )

    app-form.tw-py-4(v-bind:grid="formGrid_amount")

    div(v-if="validations!=''")  
      p(class="text-danger tw-text-right") {{validations}}

    //BUTTONS
    .tw-text-right.tw-mt-7(v-if="!state.sending")
      .form-group.row
        .row
          .col
            a.app-btn-link-primary(@click="clear") 
              i.fa.fa-eraser.mr-2
              span {{ $I18n.t('vue.new_payment_form.button_clear') }}
            a.app-btn-link-primary(:href="index_payments_path") 
              i.fa.fa-times.mr-2
              span {{ $I18n.t('vue.new_payment_form.button_cancel') }}
            button.app-btn-primary(@click="send" :disabled='count_invoice_ids == 0 || validations != "" ') 
              i.fa.fa-save.mr-2
              span {{ $I18n.t('vue.new_payment_form.button_create') }}
</template>
<script>
import { ActiveValue } from "../../core/active_support";
import CoreComponents from "../core";
const {BackendService,BackendRoutes} = require('app/services');
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  props: {
    dataItems: String,
    dataWayToPay: String,
  },
  data: function () {
    this.invoices = JSON.parse(this.dataItems);
    return {
      payment: {
          amount_invoice_paid: 0,
          amount_admin_fee_paid: 0,
          amount_total_paid: 0,
      },
      controls: {
        checkbox: {
          checked_masive: {
            checked: false
          }
        },
        alert: {
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: "",
          },
          options: {
            update_page: true,
          }
        },
      },
      items: [],
      values: {
        date_payment: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_payment_form.date'));
          i.setValue("");
          i.setInputKind("date");
          i.required();
        }),
        amount: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_payment_form.amount'));
          i.setValue("0.00");
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        way_to_pay: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_payment_form.way_to_pay'));
          i.setValue('');
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataWayToPay));
          i.required();
        }),
        num_check: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_payment_form.num_check'));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        credit_card_number: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_payment_form.credit_card_number'));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        reference: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_payment_form.reference'));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
      },
      state: {
        compleated: true,
        sending: false,
      },
      result: {
        isError: undefined,
        isOk: undefined,
        key: undefined,
        notice: undefined,
        vendor: {
          id: undefined,
        },
      },
    };
  },
  computed: {
    set_values_amount: function(){
      let self = this
      self.payment.amount_invoice_paid    = 0
      self.payment.amount_admin_fee_paid  = 0
      self.payment.amount_total_paid      = 0
      let actual_value = this.values.amount.input
      let amount_admin_fee_paid = 0
      let amount_invoice_paid   = 0
      let amount_total_paid     = 0
      console.log(" ")
      console.log("set_values_amount:")
      console.log("Actual_Value                  :",actual_value)
      console.log("this.payment.amount_total_paid:",this.payment.amount_total_paid)
      //Realiza calculos
      let total_unpaid_amount   = 0
      let porcent               = 0
      let amount_admin_fee      = 0
      let amount_with_admin_fee = 0
      let amount_100 = 0
      let amount_102 = 0
      let amount_2   = 0
      this.items.forEach( function(item, indice, array) {
        if (item.checked==true){
          total_unpaid_amount   = Number(item.total_unpaid_amount)
          porcent               = Number(item.porcent)
          amount_admin_fee      = Number(Number(total_unpaid_amount * porcent / 100).toFixed(2))
          amount_with_admin_fee = total_unpaid_amount + amount_admin_fee
          amount_100 = 0
          amount_102 = 0
          amount_2   = 0

          if (self.values.way_to_pay.input == "Credit Card"){
            if(actual_value < amount_with_admin_fee){
              amount_102 = actual_value
              amount_100 = Number(Number(actual_value * 100 / (100 + porcent)).toFixed(2))
              amount_2   = amount_102 - amount_100
            }else{
              amount_102 = amount_with_admin_fee
              amount_100 = total_unpaid_amount
              amount_2   = amount_102 - amount_100
            }
          }else{
            if(actual_value < amount_with_admin_fee){
              amount_102 = actual_value
              amount_100 = actual_value
              amount_2   = 0
            }else{
              amount_102 = amount_with_admin_fee
              amount_100 = amount_with_admin_fee
              amount_2   = 0
            }
          }
          self.payment.amount_invoice_paid    += Number(Number(amount_100).toFixed(2))
          self.payment.amount_admin_fee_paid  += Number(Number(amount_2).toFixed(2))
          self.payment.amount_total_paid      += Number(Number(amount_102).toFixed(2))
          actual_value -= amount_102
        }
      });
      return actual_value
    },
    count_invoice_ids: function(){
      console.log("count_invoice_ids")
      let num_ids = 0
      this.items.forEach( function(item, indice, array) {
        if (item.checked==true){
          num_ids = num_ids + 1
        }
      });
      console.log("num_ids:",num_ids)
      return num_ids;
    },
    emptyItems: function(){
      return this.invoices.length === 0;
    },
    index_payments_path: function () {
      return BackendRoutes.index_payments_path();
    },
    payment_path: function () {
      return BackendRoutes.payment_path(this.result.payment.id);
    },
    formGrid: function () {
      //Input num check, num card or reference
      let input_number = this.values.reference
      if (this.values.way_to_pay.input == "Check"){
        input_number = this.values.num_check
      }
      if (this.values.way_to_pay.input == "Credit Card"){
        input_number = this.values.credit_card_number
      }
      return [
        [this.values.date_payment,  this.values.way_to_pay],
        [input_number,              ''],
      ];
    },
    formGrid_amount: function () {
      return [
        ['',   '',   '', this.values.amount],
      ];
    },
    validations: function(){
      console.log(" ")
      let message = ""
      let amount_payment = Number(Number(this.values.amount.input).toFixed(2))
      let amount_unpaid = Number(Number(this.payment.amount_total_paid).toFixed(2))
      //Importe capturado NO mayor al saldo de las facturas
      console.log(amount_payment)
      console.log(amount_unpaid)
      if ( amount_payment > amount_unpaid ) {
        message = this.$I18n.t('vue.apply_payment_modal.messages.amount_greater_than_the_invoice')
      }
      return message;
    },
  },
  methods: {
    update_amount: function(){
      console.log("update_amount")
      console.log("Antes:",this.values.amount.input)
      let self = this
      let new_amount = 0
      let amount_total = 0
      let percentage = 0
      let amount_cr_total = 0
      self.payment.amount_invoice_paid    = 0
      self.payment.amount_admin_fee_paid  = 0
      self.payment.amount_total_paid      = 0 
      this.items.forEach( function(item, indice, array) {
        if (item.checked==true){
          amount_total = amount_total + Number(item.total_unpaid_amount)
          percentage = Number(item.porcent)/100
          amount_cr_total = amount_cr_total + (Number(item.total_unpaid_amount) * (1 + percentage))
          self.payment.amount_invoice_paid    += Number(Number(item.total_unpaid_amount).toFixed(2))
          self.payment.amount_admin_fee_paid  += Number((amount_cr_total - amount_total).toFixed(2))
        }
      });
      if (this.values.way_to_pay.input == "Credit Card"){
        this.values.amount.setValue(amount_cr_total.toFixed(2));
        self.payment.amount_total_paid      += Number(amount_cr_total.toFixed(2))
        new_amount = Number(amount_cr_total.toFixed(2))
      }else{
        this.values.amount.setValue(amount_total.toFixed(2));
        self.payment.amount_invoice_paid    = Number(amount_total.toFixed(2))
        self.payment.amount_admin_fee_paid  = Number((0).toFixed(2))
        self.payment.amount_total_paid      = Number(amount_total.toFixed(2))
        new_amount = Number(amount_total.toFixed(2))
      }
      console.log("new_amount:",new_amount)
      return new_amount;
    },
    vendor_path: function (vendor_id) {
      return BackendRoutes.vendor_path(vendor_id);
    },
    checked_masive: function (){
      let self = this; 
      self.controls.checkbox.checked_masive.checked = !self.controls.checkbox.checked_masive.checked
      self.items.forEach( function(item, indice, array) {
        item.checked = self.controls.checkbox.checked_masive.checked
      });
      this.update_amount()
    },
    invoices_to_table: function(){
      let self = this; 
      self.invoices.forEach( function(invoice, indice, array) {
        if (invoice.status != "CANCELED" && invoice.status != "PAID"){
          self.items.push({
            checked: false,
            id: invoice.id,
            status: invoice.status,
            list_vendors_in_links: invoice.list_vendors_in_links,
            list_customers_in_links: invoice.list_customers_in_links,
            list_subsidaries_in_links: invoice.list_subsidaries_in_links,
            locale_date: invoice.locale_date,
            amount: invoice.amount,
            amount_with_credit_card: invoice.amount_with_credit_card,
            masked_amount: invoice.masked_amount,
            porcent: invoice.porcent,
            masked_porcent: invoice.masked_porcent,
            masked_amount_with_credit_card: invoice.masked_amount_with_credit_card,
            way_to_pay: invoice.way_to_pay,
            total_unpaid_amount: invoice.total_unpaid_amount,
            masked_total_paid_amount: invoice.masked_total_paid_amount,
            masked_total_unpaid_amount: invoice.masked_total_unpaid_amount,
            paths: {
              show: invoice.paths.show
            },
          })
        }
      });
    },
    valid: function () {
      return Object.values(this.values)
        .map((i) => {
          i.validate();
          return i.hasError();
        })
        .every((e) => e === false);
    },
    stringify: function () {
      let self = this;
      let array_invoice_ids = [];
      this.items.forEach( function(item, indice, array) {
        if (item.checked==true){
          array_invoice_ids.push(item.id)
        }
      });
      let params = JSON.stringify({
        invoice_ids: array_invoice_ids,
        date: this.values.date_payment.input,
        amount_payment: this.payment.amount_invoice_paid,
        amount_admin_fee: this.payment.amount_admin_fee_paid,
        way_to_paid: this.values.way_to_pay.input+"",
        num_check: this.values.num_check.input,
        credit_card_number: this.values.credit_card_number.input,
        reference: this.values.reference.input,
      });
      return params;
    },
    send: function () {
      let self = this;
      if (self.valid()) {
        self.state.sending = true;
        //console.log("params:",self.stringify())
        BackendService
          .create_payment(self.stringify())
            .then((response) => {
              self.result = response;
              self.controls.alert.notice =
                "vue.new_payment_form.result." + response.notice;
              if (response.isOk) {
                self.controls.alert.type = "success";
                self.controls.alert.message =
                  "vue.new_payment_form.view_detail_text";
                self.controls.alert.link.text = "vue.new_payment_form.show_link";
                self.controls.alert.link.href = this.payment_path;
                self.clear();
              } else {
                self.controls.alert.type = "danger";
              }
            })
            .finally(() => {
              self.state.sending = false;
              self.controls.alert.display = true;
            });
      }
    },
    clear: function () {
      Object.values(this.values).forEach((v) => v.resetValue());
      this.items.forEach((item) => item.checked=false);
      this.controls.checkbox.checked_masive.checked = false;
    },
  },
  mounted: function(){
    this.invoices_to_table();
  },
  components: { 
    "app-modal": CoreComponents.Modal,
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-form": CoreComponents.Detailform,
    ...CoreComponents,
  },
};
</script>
