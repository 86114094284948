<template lang="pug">
  div
    // Main Button
    button(@click="showModal" class="app-btn-link-primary" :disabled="!enable") 
      i(class="fa fa-ban mr-2")
      span {{$I18n.t('vue.maintenance_cancel.button')}}
    
    // Modal 
    div(v-if="controls.modal.showing") 
      .app-modal( @click.self="reject()" )
        .dialog
          .header
            p {{ labels.modal_title }}

          .body( v-if='!state.sending' )
            div(v-if="state.confirmated")
              .tw-mt-3( class='tw-text-center tw-mb-8' )
                p(class="tw-text-xl") {{ labels.reason_question }}
              .tw-mb-8
                textarea(class='form-control' v-model='values.cancelation_reason.input')
                .text-left( v-if='values.cancelation_reason.error' )
                  .app-text-error {{ $I18n.t('vue.general_messages.'+values.cancelation_reason.error)}}

            div(v-else)
              .tw-mt-3( class='tw-text-center  tw-mb-8' )
                p(class="tw-text-xl") {{ labels.confirmation_question }}
              .app-buttons-row( class='justify-center')
                button( @click="reject" class="app-btn-link-primary" ) 
                  i( class="fa fa-times mr-2" )
                  span {{ labels.confirmation_reject }}
                button( @click="accept" class="app-btn-primary" ) 
                  i(class="fa fa-check mr-2")
                  span {{ labels.confirmation_accept }}

          .body( v-else )
            .tw-my-20( class='tw-text-center' ) 
              .spinner-border( class=" spinner-border-sm" role="status" )

          .footer
            .app-buttons-row( 
              v-if="state.confirmated && !state.sending"
              class='justify-end' 
            )
              button(@click="reject" class="app-btn-link-primary") 
                i(class="fa fa-times mr-2")
                span {{$I18n.t('vue.maintenance_cancel.button_close')}}
              button(@click="send" class="app-btn-primary") 
                i(class="fa fa-save mr-2")
                span {{$I18n.t('vue.maintenance_cancel.send')}}                   
</template>

<script>
  import { ActiveValue } from '../../core/active_support';
  import CoreComponents from '../core';
  import {BackendService} from '../../services';

export default{
    props:['dataMaintenanceRecord'],
    components:{...CoreComponents},
    data:function(){
      return {
        record: this.dataMaintenanceRecord,
        state:{
          sending: false,
          confirmated: undefined
        },
        controls:{
          modal:{
            showing: false,
          }
        },
        result:{
          isError: undefined,
          isOk: undefined,
          key: undefined,
          notice: undefined,
        },
        values:{
          cancelation_reason: ActiveValue().setup(i=>{
            i.setLabel(this.$I18n.t('vue.maintenance_calcel.cancellation_reason'));
            i.setValue('');
            i.setInputKind('textArea');
            i.required();
          })
        },
        labels:{
          modal_title:           this.$I18n.t('vue.maintenance_cancel.label_cancel_maintenance'),
          confirmation_question: this.$I18n.t('vue.maintenance_cancel.confimation_question'),
          confirmation_reject:   this.$I18n.t('vue.maintenance_cancel.confirmation_reject'),
          confirmation_accept:   this.$I18n.t('vue.maintenance_cancel.confirmation_accept'),
          reason_question:       this.$I18n.t('vue.maintenance_cancel.cancellation_reason_question')
        }
      }
    },
    computed:{
      formValues:function(){
        return [
          [this.values.cancellation_reason]
        ]
      },
      enable: function(){
        return this.record.state.name === 'REGISTERED'
      }
    },
    methods:{
      showModal:function(){
        this.controls.modal.showing=true;
      },
      hideModal:function(){
        this.controls.modal.showing=false;
      },
      accept:function(){
        this.state.confirmated=true;
      },
      reject:function(){
        this.state.confirmated=false;
        this.hideModal();
        this.clear();
      },
      valid:function(){
        return Object
          .values(this.values)
          .map(v=>v.validate())
          .map(v=>v.hasError())
          .every(e=>e===false)
      },
      clear:function(){
        Object
          .values(this.values)
          .forEach(v=>v.resetValue());
      },
      stringify:function(){
        return JSON.stringify({
          reason: this.values.cancelation_reason.input,
        })
      },
      send:function(){
        const self = this; 
        if(self.valid()){
          this.state.sending=true;
          BackendService
            .cancel_maintenance(this.record.id, this.stringify())
            .then((response)=>{
              if (response.isOk){
                location.reload();
              }
            })
            .catch(()=>{
              self.result.isError=true;
              self.result.notice='error_promise';
              self.state.sending=false;
            })
        }
      },
    }
  }

</script>

