<template lang="pug">

.app-card.overflow-x-auto
  .flex(class='justify-end')
    edit-contact-modal(
      :data-icon='"fa fa-plus"'
      :data-label="$I18n.t('vue.contacts_detail_panel.title')"
      :data-title="$I18n.t('vue.contacts_detail_panel.new_contact')"
      :data-mode='"new"'
      :data-item='JSON.stringify(empty_item)'
      :data-id='record.id' 
      :data-model='record.model'
      :data-send-invoice-option='send_invoice_option'
      @updated='createNewContact'
    )
  table(v-if='!emptyItems' class="table")
    thead
      th {{$I18n.t('vue.contacts_detail_panel.table_header_role_name')}}
      th {{$I18n.t('vue.contacts_detail_panel.table_header_name')}}
      th {{$I18n.t('vue.contacts_detail_panel.table_header_email')}}
      th {{$I18n.t('vue.contacts_detail_panel.table_header_send_invoice')}}
      th
      th
      th {{$I18n.t('vue.contacts_detail_panel.table_header_phone_kind')}}
      th {{$I18n.t('vue.contacts_detail_panel.table_header_phone_number')}}
    tbody
      tr(v-for="item in items")
        td {{item.role_name}}
        td {{item.person.first_name}} {{item.person.middle_name}} {{item.person.last_name}}
        td(class="text-center") {{item.email}}
        td(class="text-center") {{item.send_invoice? "Yes" : "No"}}
        td
          .app-buttons-row(class='justify-end')
            delete-contact-modal(
              :data-id='item.id'
              @deleted='retrieveContacts'
            )
            edit-contact-modal(
              :data-icon='"fa fa-pencil"'
              :data-label='""'
              :data-title="$I18n.t('vue.contacts_detail_panel.edit_contact')"
              :data-mode='"edit"'
              :data-item='JSON.stringify(item)'
              :data-send-invoice-option='send_invoice_option'
              @updated='updateContact'
            )
        td
        td(colspan="2")
          div(v-for="phone in item.phones" class="")
            div(class="card")
              div(class="card-body tw-py-0")
                div(class="row text-center")
                  div(class="col-4")
                    span {{phone.kind}}
                  div(class="col-2")
                    span 
                  div(class="col-4")
                    span {{phone.number}}
                  div(class="col-1")
                    edit-phone-contact-modal(
                      :data-id='String(phone.id)'
                      :data-contact-id='String(item.id)'
                      :data-model='"Contact"'
                      :data-item='JSON.stringify(phone)'
                      :data-icon='"fa fa-pencil"'
                      :data-label='""'
                      :data-mode='"update"'
                      :data-title="$I18n.t('vue.contacts_detail_panel.edit_phone_contact')"
                      @updated='updatePhoneContact'
                    )
                  div(class="col-1")
                    delete-phone-contact-modal(
                      :data-id='phone.id'
                      @deleted='retrieveContacts'
                    )
          div(class="card")
            div(class="text-end card-body tw-py-0")
              edit-phone-contact-modal(
                :data-id='""'
                :data-contact-id='String(item.id)'
                :data-model='"Contact"'
                :data-item='JSON.stringify(empty_phone_item)'
                :data-icon='"fa fa-plus-circle"'
                :data-label="$I18n.t('vue.contacts_detail_panel.phone_add')"
                :data-title="$I18n.t('vue.contacts_detail_panel.new_phone_contact')"
                :data-mode='"new"'
                @updated='createNewPhoneContact'
              )
        
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.contacts_detail_panel.empty_items')}}

</template>
<script>

import DeleteContactModal from './delete-contact-modal.vue'
import EditContactModal from './edit-contact-modal.vue'
import {BackendService} from '../../services';
import EditPhoneContactModal from './edit-phone-contact-modal.vue';
import DeletePhoneContactModal from './delete-phone-contact-modal.vue';
export default {
  components:{
    'delete-contact-modal':DeleteContactModal,
    'edit-contact-modal':EditContactModal,
    'edit-phone-contact-modal': EditPhoneContactModal,
    'delete-phone-contact-modal': DeletePhoneContactModal,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataItems: String,
    dataSendInvoiceOptions: String,
  },
  data: function(){
    return {
      state: {
        confirmed: false,
      },
      record:{
        id: this.dataId,
        model: this.dataModel
      },
      send_invoice_option: this.dataSendInvoiceOptions,
      items: JSON.parse(this.dataItems),
      empty_item: {
        person:{},
        phones:[
          {kind:"",number:""}
        ]
      },
      empty_phone_item: {
        kind:"",
        number:""
      },
    }
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  },
  methods:{
    showContact:function(path){
    },
    retrieveContacts:function(){
      let self = this; 
      BackendService
        .contacts(JSON.stringify({
          query:{
            contactable_type: self.record.model,
            contactable_id: self.record.id
          }
        }))
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            self.items = response.contacts;
          }
        })
        .finally(()=>{
        })
    },
    updateContact:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .contact_update(params)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    createNewContact:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .create_contact(params)
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
          self.state.sending = false;
        })
        .finally(()=>{
        })
    },
    createNewPhoneContact:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .create_phone_contact(params)
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
          self.state.sending = false;
        })
        .finally(()=>{
        })
    },
    updatePhoneContact:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .contact_phone_update(params)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
  }
}
</script>
