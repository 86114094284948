<template lang="pug">

.app-card.overflow-x-auto
  .flex(class='justify-end')
    add-technician-to-order-modal(
      :data-icon='"fa fa-plus"'
      :data-label="$I18n.t('vue.technicians_detail_panel.add_technician')"
      :data-mode='"new"'
      :data-item='JSON.stringify(empty_item)'
      :data-id='record.id' 
      :data-model='record.model'
      :data-technicians-active='technicians_to_options()'
      @add='addTechnician'
    )
  table(v-if='!emptyItems' class="table")
    thead
      th {{$I18n.t('vue.technicians_detail_panel.table_header_name')}}
      th {{$I18n.t('vue.technicians_detail_panel.table_header_role_name')}}
      th {{$I18n.t('vue.technicians_detail_panel.table_header_email')}}
      th {{$I18n.t('vue.technicians_detail_panel.table_header_phone')}}
      th
    tbody
      tr(v-for="item in all_technicians")
        td {{item.technician.table.full_name}}
        td {{item.technician.table.role_name}}
        td {{item.technician.table.email}}
        td {{item.technician.table.phones[0].table.kind}} {{item.technician.table.phones[0].table.kind=='' ? '' : ':'}} {{item.technician.table.phones[0].table.number}}
        td
          .app-buttons-row(class='justify-end')
            remove-technician-to-order-modal(
              :data-id='item.id'
              @deleted='retrieveTechnicians'
            )
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.technicians_detail_panel.empty_items')}}

</template>
<script>

import RemoveTechnicianToOrderModal from './remove-technician-to-order-modal.vue'
import AddTechnicianToOrderModal from './add-technician-to-order-modal.vue'
import {BackendService} from '../../services';

export default {
  components:{
    'remove-technician-to-order-modal':RemoveTechnicianToOrderModal,
    'add-technician-to-order-modal':AddTechnicianToOrderModal
  },
  props:{
    dataId: String,
    dataModel: String,
    dataOrder: String,
    dataItems: String,
    dataTechniciansActive: String,
    dataOtherOrders: String,
  },
  data: function(){
    return {
      state: {
        confirmed: false,
      },
      record:{
        id: this.dataId,
        model: this.dataModel
      },
      items: JSON.parse(this.dataItems),
      other_orders: JSON.parse(this.dataOtherOrders),
      data_order: JSON.parse(this.dataOrder),
      empty_item: {
        person:{
          first_name:"",
          middle_name:"",
          last_name:"",
        },
        role_name:"",
        email:"",
        state:"",
      }
    }
  },
  computed:{
    all_technicians: function(){
      var items = []
      if (this.other_orders.length != 0 && this.data_order.state.name != 'CANCELED'){
        this.other_orders.forEach(order => {
          if(order.state.name != "CANCELED"){
            order.technicians.forEach(t => {
              if(t.deleted_at === null){
                items.push(t)
              }
            })
          }
        })
      }else{
        items = this.items
      }
      return items
    },
    emptyItems: function(){
      return this.all_technicians.length === 0;
    }
  },
  methods:{
    showTechnician:function(path){
    },
    retrieveTechnicians:function(){
      let self = this; 
      BackendService
        .technicians(JSON.stringify({
          query:{
            technicianable_type: self.record.model,
            technicianable_id: self.record.id
          }
        }))
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            self.items = response.technicians;
          }
        })
        .finally(()=>{
        })
    },
    addTechnician:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .add_technician_to_order(params)
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
          self.state.sending = false;
        })
        .finally(()=>{
        })
      
    },
    technicians_to_options:function(){
      let technicians = JSON.parse(this.dataTechniciansActive)
      let items = this.all_technicians
      var array_technicians_for_options = []
      technicians.forEach(function(technician){
        let technician_exist = false
        items.forEach(function(item){
          let item_name = item.technician.table.full_name
          let technician_name = technician.label
          if (item_name == technician_name){
            technician_exist = true
          }
        })
        if (technician_exist==false){
          array_technicians_for_options.push(technician)
        }
      })
      return JSON.stringify(array_technicians_for_options)
    }
  }
}
</script>
