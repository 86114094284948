<template lang="pug">
div
  app-icon-btn(
    :disabled="this.disabled"
    data-kind="link",
    @click.native="showModal",
    data-icon="fa fa-times",
    :data-label="$I18n.t('vue.cancel_payment_modal.title')"
    :data-title="$I18n.t('vue.cancel_payment_modal.title')"
  )

  // Modal
  app-modal(
    :showing="controls.modal.showing",
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}

    // Body
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-10")
        div(class="spinner-border spinner-border-sm" role="status")
      // FORM
      div(v-else)
        .tw-mt-3.tw-text-center.tw-mb-8
          p.tw-text-xl {{ labels.confirmation_question }}
          app-form.tw-py-4(v-bind:grid="formGrid")
        .app-buttons-row.justify-center
          button.app-btn-link-primary(@click="reject") 
            i.fa.fa-times.mr-2
            span {{ labels.confirmation_reject }}
          button.app-btn-primary(@click="send") 
            i.fa.fa-check.mr-2
            span {{ labels.confirmation_accept }}
</template>

<script>
import { ActiveValue } from "../../core/active_support";
import CoreComponents from "../core";
import {BackendService} from '../../services';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components: {
    "app-modal": CoreComponents.Modal,
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-form": CoreComponents.Detailform,
  },
  props:['dataId','disabled'],
  data: function () {
    return {
      state: {
        sending: false,
      },
      controls: {
        modal: {
          showing: false,
        },
      },
      labels: {
        confirmation_question: this.$I18n.t('vue.cancel_payment_modal.question'),
        confirmation_reject: this.$I18n.t('vue.cancel_payment_modal.reject'),
        confirmation_accept: this.$I18n.t('vue.cancel_payment_modal.accept'),
        modal_title: this.$I18n.t('vue.cancel_payment_modal.title'),
      },
      values: {
        cancelation_reason: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.cancel_payment_modal.label_reason_cancellation'));
          i.setValue("");
          i.setInputKind("textArea");
          i.required();
        })
      },
    };
  },
  computed: {
    formGrid: function () {
      return [
        [this.values.cancelation_reason]
      ];
    },
  },
  methods: {
    valid: function () {
      return Object.values(this.values)
        .map((i) => {
          i.validate();
          return i.hasError();
        })
        .every((e) => e === false);
    },
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    reject: function () {
      this.state.sending = false;
      this.hideModal();
    },
    stringify: function () {
      return JSON.stringify({
        id: this.dataId,
        cancelation_reason: this.values.cancelation_reason.input,
      });
    },
    send: function () {
      let self = this;
      if (self.valid()) {
        this.state.sending = true;
        BackendService
          .cancel_payment(self.stringify())
          .then((response) => {
            if (response.isOk) {
              location.reload();
            }
          })
          .catch(() => {
            self.result.isError = true;
            self.result.notice = "error_promise";
            self.state.sending = false;
          });
      }
    },
  },
};
</script>
