<template lang="pug">

  .app-modal(v-if='localShowing' @click.self='hide()')
    .dialog
      .header
        slot(name='header')
      .body
        slot(name='body')
      .footer
        slot(name='footer')

  
</template>

<script>
export default {
  name: 'app-modal',
  props: ['showing'],
  data: function(){
    return {
      localShowing: this.showing
    };
  },
  watch:{
    localShowing: function(newV){
      this.$emit('showing', newV);
    },
    showing: function(newV){
      this.localShowing = newV;
    }
  },
  methods:{
    show:function(){this.localShowing = true;},
    hide:function(){this.localShowing = false;}
  }
}
</script>
