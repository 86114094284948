<template lang="pug">

.app-card.overflow-x-auto
  .flex(class='justify-end')
    create-customer-modal(
      :data-id='record.id' 
      :data-model='record.model'
    )
  table.table(v-if="!emptyItems")
    thead
      th {{ $I18n.t('vue.customers_detail_table_panel.table_header_name') }}
      th {{ $I18n.t('vue.customers_detail_table_panel.table_header_address') }}
      th
    tbody
      tr(v-for="item in items")
        td
          .tw-flex.tw-flex-col
            div {{ item.aka }} 
            div {{ item.name }}
        td {{ item.address.full }}
        td
          .app-buttons-row.justify-end
            delete-customer-modal(
              :data-id='item.id'
              @deleted='retrieveCustomers'
            )
            app-icon-link(
              data-kind="link",
              :href="item.paths.show"
              data-icon="fa fa-eye",
              data-label="",
              :data-title="$I18n.t('vue.customers_detail_table_panel.view_customer')"
            )
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.customers_detail_table_panel.empty_items')}}
</template>

<script>
import CreateCustomerModal from './create-customer-modal.vue';
import DeleteCustomerModal from './delete-customer-modal.vue'
import CoreComponents from "../core";
import { BackendService } from "../../services";

export default {
  props: {
    dataId: String,
    dataModel: String,
    dataItems: String,
  },
  components:{
    'create-customer-modal': CreateCustomerModal,
    'delete-customer-modal': DeleteCustomerModal,
    "app-icon-link": CoreComponents.AppIconLink,
  },
  data: function () {
    return {
      record: {
        id: this.dataId,
        model: this.dataModel,
      },
      items: JSON.parse(this.dataItems),
    };
  },
  computed: {
    emptyItems: function () {
      return this.items.length === 0;
    }
  },
  methods: {
    retrieveCustomers: function () {
    }
  }
};
</script>