<template lang="pug">
div
  p {{cable_path}}
  p {{time}}
</template>
<script>

import {BackendRoutes} from '../services'
import { createConsumer } from "@rails/actioncable"

export default {
  data(){
    const self = this;
    const consumer = createConsumer(BackendRoutes.cable_path());
    return {
      time: null,
      cable_path: BackendRoutes.cable_path(),
      subscription: consumer
        .subscriptions
        .create(
          { channel: "TimeChannel" },
          {
            received: self.updateTime
          }
        )
    }
  },
  methods:{
    updateTime(data){
      this.time = data.time;
    }
  }
}
</script>
