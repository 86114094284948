import ContactsDetailTablePanel from './contacts-detail-table-panel.vue';
import EditContactModal  from './edit-contact-modal.vue';
import DeleteContactModal  from './delete-contact-modal.vue';
import EditPhoneContactModal from './edit-phone-contact-modal.vue';
import DeletePhoneContactModal from './delete-phone-contact-modal.vue';
export default{
  'contacts-detail-table-panel': ContactsDetailTablePanel,
  'edit-contact-modal': EditContactModal,
  'delete-contact-modal': DeleteContactModal,
  'edit-phone-contact-modal': EditPhoneContactModal,
  'delete-phone-contact-modal': DeletePhoneContactModal,
}