<template lang="pug">

div
  app-icon-btn(
    @click.native="showModal"
    data-kind="link",
    :data-icon="dataIcon",
    :data-label="dataLabel",
  )
  // Modal
  app-modal(
    :showing='controls.modal.showing'
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}
    
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")
      // FORM
      div(v-else)
        app-form(v-bind:grid="form_grid"  class='tw-py-4')
          
    template(v-slot:footer)
      .app-buttons-row( v-if="!state.sending" class='justify-end' )
        app-icon-btn(
          v-if="dataMode=='new'"
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="update",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>
<script>

import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default{
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components:{
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataItem: String,
    dataIcon: String,
    dataLabel: String,
    dataMode: String,
  },
  data:function(){
    return {
      record:{
        id: this.dataId,
        model: this.dataModel,
        present: true
      },
      controls:{
        modal:{
          showing: false
        }
      },
      errors:{
        link_valid: true
      },
      state:{
        sending: false
      },
      values:{
        link: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.edit_link_modal.link'));
          i.setValue(JSON.parse(this.dataItem).link==undefined ? "" : JSON.parse(this.dataItem).link);
          i.setInputKind('text');
          i.required();
        }),
        description: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.edit_link_modal.description'));
          i.setValue(JSON.parse(this.dataItem).description==undefined ? "" : JSON.parse(this.dataItem).description);
          i.setInputKind('textArea');
        })
      },
      labels:{
        modal_title: this.$I18n.t('vue.edit_link_modal.label_link'),
        clear_btn: this.$I18n.t('vue.edit_link_modal.button_clear'),
        cancel_btn: this.$I18n.t('vue.edit_link_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.edit_link_modal.button_create')
      }
    }
  },
  computed:{
    form_grid:function(){
      return [
        [this.values.link],
        [this.values.description]
      ]
    }
  },
  methods:{
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function(){
      this.controls.modal.showing = false;
    },
    clear: function(){
      this.resetActiveValues(this.values);
      this.errors.link_valid = false;
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.hideModal();
      this.resetActiveValues(this.values);
      if (this.dataMode=='new') {
      }else{
        this.values.link.setValue(JSON.parse(this.dataItem).link);
        this.values.description.setValue(JSON.parse(this.dataItem).description);
      }
    },
    linkValid:function(){
      let valid = /^(ftp|http|https):\/\/[^ "]+$/.test(this.values.link.input);
      this.errors.link_valid = valid;
      if (valid){
        this.values.link.error = null
      }else{
        this.values.link.error = "InvalidLinkFormatError"
      }
      return valid
    },
    params:function(){
      return JSON.stringify({
        id: JSON.parse(this.dataItem).id,
        linkable: this.record.model+"#"+this.record.id,
        link: this.values.link.input,
        description: this.values.description.input
      })
    },
    update:function(){
      let self = this; 
      if(self.areValidActiveValues(this.values)){
        if(self.linkValid()){
          this.state.sending = true;
          let params = this.params()
          this.clear();
          //this.hideModal();
          this.$emit("updated", params);        
        }
      }
    }
  }
}
</script>
