<template lang="pug">

div
  app-icon-btn(
    @click.native="showModal"
    data-kind="link",
    :data-icon="dataIcon",
    :data-label="dataLabel",
  )
  // Modal
  app-modal(
    :showing='controls.modal.showing'
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}
    
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")
      // FORM
      div(v-else)
        app-form(v-bind:grid="form_grid" class='tw-py-4')
      
    template(v-slot:footer)
      .app-buttons-row( v-if="!state.sending" class='justify-end' )
        app-icon-btn(
          v-if="dataMode=='new'"
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="update",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>
<script>

import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default{
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components:{
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataItem: String,
    dataIcon: String,
    dataLabel: String,
    dataMode: String,
    dataTechniciansActive: String,
  },
  data:function(){
    return {
      record:{
        id: this.dataId,
        model: this.dataModel,
        present: true
      },
      controls:{
        modal:{
          showing: false
        }
      },
      state:{
        sending: false
      },
      values:{
        technician: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.add_technician_to_order_modal.technician'));
          i.setValue("");
          i.setInputKind('select');
          i.setInputOption("options", JSON.parse(this.dataTechniciansActive));
          i.required();
        })        
      },
      labels:{
        modal_title: this.$I18n.t('vue.add_technician_to_order_modal.label_technician'),
        clear_btn: this.$I18n.t('vue.add_technician_to_order_modal.button_clear'),
        cancel_btn: this.$I18n.t('vue.add_technician_to_order_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.add_technician_to_order_modal.button_create')
      }
    }
  },
  computed:{
    form_grid:function(){
      return [
        [this.values.technician]
      ]
    }
  },
  methods:{
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function(){
      this.controls.modal.showing = false;
    },
    clear: function(){
      this.resetActiveValues(this.values);
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.hideModal();
      this.resetActiveValues(this.values);
      if (this.dataMode=='new') {
      }else{
        this.values.technician.setValue();
      }
    },
    params:function(){
      return JSON.stringify({
        id: this.record.id,
        technician_id: this.values.technician.input,
      })
    },
    update:function(){
      let self = this; 
      if(self.areValidActiveValues(this.values)){
        this.state.sending = true;
        let params = this.params()
        this.clear();
        //this.hideModal();
        this.$emit("add", params);        
      }
    }
  }
}
</script>
