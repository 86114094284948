<template lang="pug">
div
  app-icon-btn(
    data-kind="link",
    @click.native="showModal",
    data-icon="fa fa-window-close",
    data-label="",
    :data-title="$I18n.t('vue.delete_orderable_modal.title')"
  )

  // Modal
  app-modal(
    :showing="controls.modal.showing",
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}

    // Body
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center")
        div(class="spinner-border spinner-border-sm tw-my-5" role="status")
      div(v-else)
        .tw-mt-3.tw-text-center.tw-mb-8
          p.tw-text-xl {{ labels.confirmation_question }}
        .app-buttons-row.justify-center
          button.app-btn-link-primary(@click="reject") 
            i.fa.fa-times.mr-2
            span {{ labels.confirmation_reject }}
          button.app-btn-primary(@click="send") 
            i.fa.fa-check.mr-2
            span {{ labels.confirmation_accept }}
</template>

<script>
import CoreComponents from "../core";
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components: {
    "app-modal": CoreComponents.Modal,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:[
    'dataId',
    'dataItem'
  ],
  data: function () {
    return {
      state: {
        sending: false,
      },
      controls: {
        modal: {
          showing: false,
        },
      },
      labels: {
        confirmation_question: this.$I18n.t('vue.delete_orderable_modal.confirmation_question'),
        confirmation_reject: this.$I18n.t('vue.delete_orderable_modal.reject'),
        confirmation_accept: this.$I18n.t('vue.delete_orderable_modal.accept'),
        modal_title: this.$I18n.t('vue.delete_orderable_modal.title'),
      },
    };
  },
  methods: {
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    reject: function () {
      this.state.sending = false;
      this.hideModal();
    },
    send: function () {
      const self = this;
      this.state.sending = true;
      this.$emit("deleted", this.dataItem);
    },
  },
};
</script>
