import invoiceDetail from './invoice-detail.vue'
import invoiceActions from './invoice_actions.vue'
import NewInvoice from './new_invoice.vue'
import NewInvoiceForm from './new_invoice_form.vue'
import InvoicesDetailTablePanel from './invoices-detail-table-panel.vue';
import InvoiceOrdersTablePanel from './invoice-orders-table-panel.vue';
import InvoicePaymentsTablePanel from './invoice-payments-table-panel.vue';
import CancelInvoiceModal from './cancel-invoice-modal.vue';
import ApplyPaymentModal from './apply-payment-modal.vue';
import SendEmailInvoiceModal from './send-email-invoice-modal.vue';
export default{
  'invoice-detail': invoiceDetail,
  'invoice-actions': invoiceActions,
  'invoices-detail-table-panel': InvoicesDetailTablePanel,
  'invoice-orders-table-panel': InvoiceOrdersTablePanel,
  'invoice-payments-table-panel': InvoicePaymentsTablePanel,
  'cancel-invoice-modal': CancelInvoiceModal,
  'apply-payment-modal': ApplyPaymentModal,
  NewInvoice,
  'new-invoice-form': NewInvoiceForm,
  'send-email-invoice-modal': SendEmailInvoiceModal,
}