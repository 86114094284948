<template lang='pug'>

  .tw-inline-flex
    //Cambiar fecha de Vencimiento
    Orderdueat(
      :disabled= 'order.state.name == "COMPLETED" || order.state.name == "CANCELED"' 
      :data-order='order' 
    )

    //Re-programar fecha de la orden
    Orderschedule(
      :disabled= 'order.state.name == "COMPLETED" || order.state.name == "CANCELED"' 
      :data-order='order' 
    ) 

    //Completar la orden
    Ordercomplete(
      :disabled= 'order.state.name !== "SCHEDULED"' 
      :data-order='order' 
      :other-orders='other_orders'
    )

    //Cancelar la Order
    Ordercancel(
      :disabled= 'order.state.name == "COMPLETED" || order.state.name == "CANCELED"' 
      :data-order='order' 
    )

    //Facturar Orden
    Newinvoice(
      :disabled= 'order.state.name !== "COMPLETED" && order.state.name !== "SCHEDULED"' 
      :data-order='order' 
      :data-way-to-pay='dataWayToPay'
      :data-credit-card-payment-percentage-value = 'dataCreditCardPaymentPercentageValue'
      :other-orders='other_orders'
    )

    //Generar Paper Work (empleado/cliente)
    a(class="app-btn-link-primary" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false")
      i(class="fa fa-file mr-2")
      span {{$I18n.t('vue.order_actions.paper_work')}}
      
    ul(class="dropdown-menu" aria-labelledby="dropdownMenuLink")
      li
        a( 
          class="dropdown-item app-btn-link" 
          :href='order.paths.paper_work+"?type=E"'
          target='_blank'
        )
          span {{$I18n.t('vue.order_actions.for_technician')}}
      li
        a( 
          class="dropdown-item app-btn-link" 
          :href='order.paths.paper_work+"?type=C"'
          target='_blank'
        )
          span {{$I18n.t('vue.order_actions.for_customer')}}
</template>

<script>

import Ordercomplete from './order_complete.vue'
import Orderschedule from './order_schedule.vue'
import Orderdueat from './order_due_at.vue'
import Ordercancel from './order_cancel.vue'
import Newinvoice from '../invoice/new_invoice.vue'

export default {
  components:{Ordercomplete, Orderschedule, Orderdueat, Ordercancel, Newinvoice},
  props:['dataRecord', 'dataWayToPay','dataOtherOrders', 'dataCreditCardPaymentPercentageValue'],
  data: function(){
    return {
      order: JSON.parse(this.dataRecord),
      other_orders: JSON.parse(this.dataOtherOrders),
    }
  }
}
</script>
