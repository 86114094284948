<template lang="pug">
  div(v-if="state.showing")
    .app-modal(@click.self='reject()')
      .dialog
        .header
          p {{$I18n.t('vue.hood_add_maintenance.label_add_maintenance')}}
        .body( v-if='!state.sending' )
          //FORM
          Detailform(class="tw-py-4" v-bind:grid="formValues")
        .body( v-else )
          .tw-my-20( class='tw-text-center' ) 
            .spinner-border( class=" spinner-border-sm" role="status" )
        .footer
          //Error
          p(v-if="result.isError" class="app-text-error tw-my-3") {{$I18n.t('vue.hood_add_maintenance.result.'+result.notice)}}
          p(v-if="result.isOk" class="app-text-ok") {{$I18n.t('vue.hood_add_maintenance.result.'+result.notice)}}
          //BUTTONS
          a(@click="clear" class="app-btn-link-primary") 
            i(class="fa fa-eraser mr-2")
            span {{$I18n.t('vue.hood_add_maintenance.button_clear')}}
          a(@click="cancel" class="app-btn-link-primary") 
            i(class="fa fa-times mr-2")
            span {{$I18n.t('vue.hood_add_maintenance.button_cancel')}}
          button(@click="create" class="app-btn-primary") 
            i(class="fa fa-save mr-2")
            span {{$I18n.t('vue.hood_add_maintenance.button_create')}}
</template>
<script>
import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
import {BackendService} from '../../services';

export default{
  props:{dataTriggerId: String,
         dataKinds: String,
         dataHoodId: String},
  components:{...CoreComponents},
  data:function(){
    return {
      hood_id: this.dataHoodId,
      state:{
        showing:false,
        sending:false,
        compleated: true
      },
      result:{
        isError: undefined,
        isOk: undefined,
        key: undefined,
        notice: undefined,
      },
      values:{
        kind: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.hood_add_maintenance.maintenance_kind'));
          i.setValue('');
          i.setKind('text');
          i.setInputKind('select');
          i.setInputOption('options',JSON.parse(this.dataKinds));
          i.required();
        }),
        schedule_for: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.hood_add_maintenance.due_at'));
          i.setInputKind('date');
          i.required();
        }),
      }
    }
  },
  computed:{
    formValues:function(){
      return [
        [this.values.kind,       this.values.schedule_for ]
      ]
    }
  },
  methods:{
    valid:function(){
      return Object
        .values(this.values)
        .map(v=>v.validate())
        .map(v=>v.hasError())
        .every(e=>e===false)
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.state.sending=false;
      this.hide();
      this.clear();
      this.result.isError=undefined
      this.result.isOk=undefined
      this.result.key=undefined
      this.result.notice=undefined
    },
    clear:function(){
      Object
        .values(this.values)
        .forEach(v=>v.resetValue());
    },
    hide:function(){
      this.state.showing=false;
    },
    stringify:function(){
      return JSON.stringify({
        id: this.hood_id,
        maintenance:{
          kind_id: this.values.kind.input,
          due_at:this.values.schedule_for.input
        }
      })
    },
    create:function(){
      let self = this; 
      if(self.valid()){
        this.state.sending=true;
        BackendService.create_hood_maintenance(self.stringify())
          .then((response)=>{
            self.result = response;
            if (response.isOk){
              self.hide();
              self.clear(); 
              //location.reload();
              this.$emit("update", "ok");
            }
          })
          .catch(()=>{
            self.result.isError=true;
            self.result.notice='error_promise';
          })
          .finally(()=>{
            self.state.sending=false;
          })
      }
    }
  },
  mounted:function(){
    let _self=this;
    let _btn=document.getElementById(this.dataTriggerId);
    _btn.onclick=function(){
      console.log(_self.show());
    };
  }
}
</script>