<template lang="pug">
div
  // Main Button
  app-icon-btn(
    :disabled="!enable",
    @click.native="showModal",
    data-kind="link",
    data-icon="fa fa-calendar",
    :data-label="labels.reschedule_btn"
  )

  // Modal
  app-modal(
    :showing="controls.modal.showing",
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}

    // Body
    template(v-slot:body)
      app-form(v-if="!state.processing", v-bind:grid="form_grid" class='tw-py-4')
      .tw-my-20.tw-text-center(v-if="state.processing") 
        .spinner-border.spinner-border-sm(role="status")

    // Footer
    template(v-slot:footer)
      .app-buttons-row.justify-end(v-if="!state.processing")
        app-icon-btn(
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="send",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>
<script>
import { ActiveValue } from "../../core/active_support";
import CoreComponents from "../core";
import { BackendService } from "../../services";
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");
export default {
  props: ["dataMaintenanceRecord"],
  components: {
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  mixins: [ActiveValuesMixin, ProcessingMixin],
  data: function () {
    return {
      record: this.dataMaintenanceRecord,
      controls: {
        modal: {
          showing: false,
        },
      },
      result: {
        isError: undefined,
        isOk: undefined,
        key: undefined,
        notice: undefined,
      },
      values: {
        due_date: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.maintenance_reschedule_due_date.due_date")
          );
          i.setValue("");
          i.setInputKind("date");
          i.required();
        }),
      },
      labels: {
        modal_title: this.$I18n.t(
          "vue.maintenance_reschedule_due_date.label_reschedul_due_date"
        ),
        reschedule_btn: this.$I18n.t(
          "vue.maintenance_reschedule_due_date.button_reschedul_due_date"
        ),
        clear_btn: this.$I18n.t(
          "vue.maintenance_reschedule_due_date.button_clear"
        ),
        cancel_btn: this.$I18n.t(
          "vue.maintenance_reschedule_due_date.button_cancel"
        ),
        send_btn: this.$I18n.t(
          "vue.maintenance_reschedule_due_date.button_reschedule"
        ),
      },
    };
  },
  computed: {
    form_grid: function () {
      return [[this.values.due_date]];
    },
    enable: function () {
      return this.record.state.name === "REGISTERED";
    },
  },
  methods: {
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    cancel: function () {
      this.state.sending = false;
      this.hideModal();
      this.resetActiveValues();
      this.result.isError = undefined;
      this.result.isOk = undefined;
      this.result.key = undefined;
      this.result.notice = undefined;
    },
    params: function () {
      return JSON.stringify({
        due_date: this.values.due_date.input,
      });
    },
    send: function () {
      let self = this;
      if (this.areValidActiveValues(this.values)) {
        this.process();
        BackendService
          .reset_maintenance_due_date(
            this.record.id,
            self.params()
          )
          .then((response) => {
            if (response.isOk) {
              location.reload();
            }
          })
          .catch((_) => {
            self.result.isError = true;
            self.result.notice = "error_promise";
            self.state.sending = false;
            self.processed()
          })
      }
    },
  },
};
</script>

