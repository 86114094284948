import Maintenancecancel from './maintenance_cancel.vue'
import Maintenanceactions from './maintenance_actions.vue'
import MaintenancePriceValue from './maintenance-price-value.vue';
import Maintenancesearchform from './maintenance_search_form.vue'
import Maintenancerescheduleduedate from './maintenance_reschedule_due_date.vue'
import MaintenancesDetailTablePanel from './maintenances-detail-table-panel.vue';


export default{
  Maintenancecancel,
  Maintenanceactions,
  Maintenancesearchform,
  Maintenancerescheduleduedate,
  'maintenances-detail-table-panel': MaintenancesDetailTablePanel,
  'maintenance-price-value': MaintenancePriceValue
}