const { save } = require("autoprefixer/lib/value");

module.exports={
  vue:{
    empty: '',
    states: {
      active: 'ACTIVE',
      inactive: 'INACTIVE',
      registered: 'REGISTERED',
      scheduled: 'SCHEDULED',
      completed: 'COMPLETED',
      canceled: 'CANCELED',
      unpaid: "UNPAID",
      semi_paid: "SEMI PAID",
      paid: "PAID",
      applied: "APPLIED"
    },
    payment_types: {
      check: "CHECK",
      credit_card: "CREDIT CARD",
      direct_deposit: "DIRECT DEPOSIT",
      money_order: "MONEY ORDER",
      zelle: "ZELLE",
    },
    invoice_target: {
      restaurant: "RESTAURANT",
      customer: "CUSTOMER",
      vendor: "VENDOR",
    },
    forgot_password_form:{
      send_button: 'Send',
      cancel_button: 'Cancel',
      login_link: 'Return to Login Page',
      sending: 'Sending...',
      follow_instructions: 'Follow the email instructions to change your password',
      forget_password: 'Did you forget your password?',
      instructions_reset: `Don't worry, changing your password is easy, you just need to tell us your email address.`,
      error_promise: 'An error occurred, please try again',
      results:{
        reset_password_sended_if_account_exist_ok: 'An email has been sent if your account exists',
        reset_password_email_sended_if_account_exist_ok: 'An email has been sent if your account exists',
        invalid_email: 'Invalid Email',
        invalid_account_error: 'Invalid Account'
      },
    },
    change_password_edit_form:{
      change_button: 'Change',
      cancel_button: 'Cancel',
      login_link: 'Return to Login Page',
      sending: 'Sending...',
      password_placeholder: 'Password',
      confirm_password_placeholder: 'Confirm Password',
      change_password: 'Change Password',
      different_passwords: 'Password and Confirm Password must match',
      password_empty: 'Empty password not allowed',
      error_promise: 'An error occurred, please try again',
      password_strenght: 'Password strenght:',
      weak: 'WEAK',
      medium: 'MEDIUM',
      strong: 'STRONG',
      password_need_to: 'Your password need to:',
      number_characters: 'Be at least 8 characters long.',
      one_number: 'Include at least one number.',
      lowercase_letter: 'Include at least one lowercase letter.',
      uppercase_letter: 'Include at least one uppercase letter.',
      special_character: 'Include at least one special character.',
      passwords_match: 'The password and password confirmation must be the same.',
      results:{
        invalid_token_error: 'Invalid Token',
        InvaliPasswordError: 'Invalid Password',
        InvaliPasswordConfirmationError: 'Invalid password confirmation',
        UserNotFoundError: 'User not found',
        user_update_error: 'An error occurred while updating',
        user_updated_ok: 'The update was successful'
      },
    },
    new_account_form:{
      label_contact_role:'Role:',
      label_contact_fistName:'First Name:',
      label_contact_middleName:'Middle Name:',
      label_contact_lastName:'Last Name:',
      label_contact_phoneKind:'Phone Kind:',
      label_contact_phoneNumber:'Phone Number:',
      label_contact_email:'Email:',
      show_link: 'View Account',
      button_create:'Create',
      button_clear:'Clear',
      button_cancel:'Cancel',
      view_detail_text:'To see the new account created, click on the following link:',
      RequiredInputError:'This field is required',
      InvalidEmailError: 'Invalid Email',
      error_promise: 'An error occurred, please try again',
      label_phone_kind: 'Phone Kind',
      phone_number: 'Phone Number',
      result:{
        account_update_error: 'An error occurred while updating the account',
        account_updated_ok: 'Successfully updated account',
        account_created_ok: 'Successfully created account',
        create_account_ok: 'Successfully created account',
        create_account_user_exist: 'An account with this Email already exist',
        account_not_found_error: 'account not found',
        account_create_error: 'An error occurred while creating the account',
        address_create_error: 'An error occurred while saving the address',
        person_create_error: 'An error occurred while saving the contact (person)',
        contact_create_error: 'An error occurred while saving the contact',
        phone_create_error: 'An error occurred while saving the contact (phone number)',
        error_promise: 'An error occurred, please try again',
      }      
    },
    new_customer_form:{
      subtitle_address:'Address',
      subtitle_contact:'Contact',
      label_name:'Name:',
      label_aka: 'A.K.A.:',
      label_vendor:'Vendor:',
      label_address_street:'Street:',
      label_address_apartament:'Apt / Suite / Other:',
      label_address_city:'City:',
      label_address_state:'State:',
      label_address_zipCode:'Zip Code:',
      label_contact_role:'Role:',
      label_contact_fistName:'First Name:',
      label_contact_middleName:'Middle Name:',
      label_contact_lastName:'Last Name:',
      label_contact_phoneKind:'Phone Kind:',
      label_contact_phoneNumber:'Phone Number:',
      label_contact_email:'Email:',
      label_phone_number: 'Phone Number:',
      show_link: 'View customer',
      button_create:'Create',
      button_clear:'Clear',
      button_cancel:'Cancel',
      view_detail_text:'To see the new customer created, click on the following link:',
      RequiredInputError:'This field is required',
      InvalidEmailError: 'Invalid Email',
      error_promise: 'An error occurred, please try again',
      result:{
        customer_update_error: 'An error occurred while updating the customer',
        customer_updated_ok: 'Successfully updated customer',
        customer_created_ok: 'Successfully created customer',
        vendor_not_found_error: 'Vendor not found',
        customer_create_error: 'An error occurred while creating the customer',
        address_create_error: 'An error occurred while saving the address',
        person_create_error: 'An error occurred while saving the contact (person)',
        contact_create_error: 'An error occurred while saving the contact',
        phone_create_error: 'An error occurred while saving the contact (phone number)',
        error_promise: 'An error occurred, please try again',
      }      
    },
    new_vendor_form:{
      subtitle_address:'Address',
      subtitle_contact:'Contact',
      label_name:'Name:',
      label_aka: 'A.K.A.:',
      label_invoice_target: 'Invoice Target:', 
      label_address_street:'Street Address:',
      label_address_apartament:'Apt / Suite / Other:',
      label_address_city:'City:',
      label_address_state:'State:',
      label_address_zipCode:'Zip Code:',
      label_contact_role:'Role:',
      label_contact_fistName:'First Name:',
      label_contact_middleName:'Middle Name:',
      label_contact_lastName:'Last Name:',
      label_contact_phoneKind:'Phone Kind:',
      label_contact_phoneNumber:'Phone Number:',
      label_contact_email:'Email:',
      show_link: 'View vendor',
      button_create:'Create',
      button_clear:'Clear',
      button_cancel:'Cancel',
      view_detail_text:'To see the new vendor created, click on the following link:',
      RequiredInputError:'This field is required',
      InvalidEmailError: 'Invalid Email',
      error_promise: 'An error occurred, please try again',
      label_phone_kind: 'Phone Kind',
      phone_number: 'Phone Number',
      result:{
        vendor_update_error: 'An error occurred while updating the vendor',
        vendor_updated_ok: 'Successfully updated vendor',
        vendor_created_ok: 'Successfully created vendor',
        vendor_not_found_error: 'Vendor not found',
        vendor_create_error: 'An error occurred while creating the vendor',
        address_create_error: 'An error occurred while saving the address',
        person_create_error: 'An error occurred while saving the contact (person)',
        contact_create_error: 'An error occurred while saving the contact',
        phone_create_error: 'An error occurred while saving the contact (phone number)',
        error_promise: 'An error occurred, please try again',
      }      
    },
    vendor_detail:{
      title: 'Vendor',
      inactive_vendor: 'Inactive Vendor',
      active_vendor: 'Active Vendor',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      id: 'ID',
      name: 'Name',
      aka: 'A.K.A.',
      state: 'State',
      vendor: 'Vendor',
      customer: 'Customer',
      phone_number: 'Phone Number',
      invoice_target: 'Invoice Target',
      result: {
        vendor_updated_ok: 'Successfully updated vendor',
      }
    },
    account_detail:{
      title: 'Account',
      id: 'ID',
      status: 'Status',
      first_name: 'First Name',
      middle_name: 'Middle Name',
      last_name: 'Last Name',
      email: 'Email',
      role: 'Role',
      inactive_account: 'Inactive Account',
      active_account: 'Active Account',
      sending_email: 'Sending email ...',
      send_email_recover_password: 'Send email to recover password',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      result: {
        reset_password_email_sended_if_account_exist_ok: 'Email sent successfully',
        account_updated_ok: 'Successfully updated account',
        email_not_sent: 'It was not possible to send the Email',
      }
    },
    subsidaries_detail_table_panel:{
      empty_items: "No Restaurants yet",
      table_name: 'Name',
      table_address: 'Address',
      view_restaurant: 'View Restaurant',
    },
    create_subsidary_modal:{
      label_add_subsidary:'Add Restaurant',
      subtitle_address:'Address',
      subtitle_contact:'Contact',
      subtitle_notes:'Notes',
      label_name:'Name',
      label_aka: 'A.K.A.',
      label_phone: 'Phone Number',
      label_address_street:'Street:',
      label_address_apartament:'Apt / Suite / Other:',
      label_address_city:'City:',
      label_address_state:'State:',
      label_address_zipCode:'Zip Code:',
      label_contact_role:'Role:',
      label_contact_first_name:'First Name:',
      label_contact_middle_name:'Middle Name:',
      label_contact_last_name:'Last Name:',
      label_contact_phone_kind:'Phone Kind:',
      label_contact_phone_number:'Phone Number:',
      label_contact_email:'Email:',
      label_service_note_description:'Service Note',
      label_service_note_visibility:'Visible to',
      label_billing_note_description:'Billing Note',
      label_billing_note_visibility:'Visible to',
      label_work_note_description:'Work Note',
      label_work_note_visibility:'Visible to',
      contacts_from_vendor:"Copy the customer's contacts to this new restaurant?",
      data_from_customer:"Copy the customer's data to this new restaurant?",
      button_create:'Create',
      button_clear:'Clear',
      button_cancel:'Cancel',
      RequiredInputError:'This field is required',
      InvalidEmailError: 'Invalid Email',
      error_promise: 'An error occurred, please try again',
      result:{
        record_created_ok: 'Successfully created restaurant',
        error_promise: 'An error occurred, please try again',
      }      
    },
    customer_address_detail:{
      label_address_street:'Street:',
      label_address_apartament:'Apt / Suite / Other:',
      label_address_city:'City:',
      label_address_state:'State:',
      label_address_zipCode:'Zip Code:',
    },
    address_detail:{
      title: 'Address',
      label_address_street:'Street:',
      label_address_apartament:'Apt / Suite / Other:',
      label_address_city:'City:',
      label_address_state:'State:',
      label_address_zipCode:'Zip Code:',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      result: {
        address_updated_ok: 'Successfully updated address'
      }
    },
    maintenance_kind_detail:{
      title: 'Service Kind',
      inactive_service_kind: 'Inactive Service Kind',
      active_service_kind: 'Active Service Kind',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      id: 'ID',
      periodicity: 'Frequency (days)',
      name: 'Name',
      state: 'State',
      abbreviation: 'Abbreviation',
      description: 'Notes'
    },
    order_detail:{
      id: 'ID',
      job_number: 'WO-PO',
      id_kanela: 'ID Schedule',
      customer: 'Customer',
      state: 'Status',
      due_on: 'Due on',
      vendor: 'Vendor',
      subsidary: 'Restaurant',
      frequency: 'Frequency (Days)',
      schedule_for: 'Schedule for',
      price: 'Price',
      billed_amount: 'Billed Amount',
      unbilled_amount: 'Unbilled Amount',
      cancelation_reason: 'Cancelation Reason',
      title: 'Work Order',
      btn_edit: 'Edit',
      btn_cancel: 'Cancel',
      btn_save: 'Save',
      months: 'Months',
    },
    contacts_detail_panel:{
      title: 'Add Contact',
      new_contact: 'New Contact',
      edit_contact: 'Edit Contact',
      table_header_id: "ID",
      table_header_role_name: "Role",
      table_header_name: "Name",
      table_header_phone_numbers: "Phone Numbers",
      table_header_email: "Email",
      table_header_send_invoice: "Send Invoice",
      table_header_phone_kind: "Phone Kind",
      table_header_phone_number: "Phone Number",
      empty_items: 'No contacts yet',
      phone_add: 'Add Phone',
      new_phone_contact: 'New Phone',
      edit_phone_contact: 'Edit Phone',
    },
    edit_contact_modal:{
      btn_add: 'Add Contact',
      label_contact: 'Contact',
      role:'Role:',
      first_name:'Fisrt Name:',
      middle_name:'Middle Name:',
      last_name:'Last Name:',
      email:'Email',
      send_invoice: 'Send Invoice',
      phone_kind:'Phone Kind',
      phone_number:'Phone Number',
      button_create:'Save',
      button_clear:'Clear',
      button_cancel:'Cancel',
      necessary_capture_value: 'It is necessary to capture at least 1 value',
      result:{
        contact_created_ok: 'Successfully created contact',
        error_promise: 'An error occurred, please try again',
      }
    },
    new_subsidary_form:{
      label_add_subsidary:'Add Restaurant',
      subtitle_address:'Address',
      subtitle_contact:'Contact',
      subtitle_notes:'Notes',
      subtitle_services:'Services',
      no_services: 'No services yet',
      add_service: 'Add Service',
      table_services_service: 'Service',
      table_services_frecuency: 'Frecuency',
      table_services_base_price: 'Base Price',
      table_services_breakdown: 'Breakdown',
      table_services_total: 'Total',
      table_services_delete_service: 'Delete Service',
      customer:'Customer',
      label_name:'Name',
      label_aka: 'A.K.A.',
      label_phone: 'Phone Number',
      label_fax: 'Fax Number',
      label_turn_around_time: 'Turn Around Time (days)',
      label_keys: 'Keys',
      label_alarm: 'Alarm',
      label_hours_of_operation: 'Hours of Operation',
      label_invoice_tarjet: 'Invoice Target',
      label_service_periodicity: 'Service',
      label_service_base_price: 'Base Price',
      label_service_breakdown: 'Breakdown',
      label_service_total: 'Total',
      label_address_street:'Street:',
      label_service_months: 'Months:',
      label_address_apartament:'Apt / Suite / Other:',
      label_address_city:'City:',
      label_address_state:'State:',
      label_address_zipCode:'Zip Code:',
      label_contact_role:'Role:',
      label_contact_first_name:'First Name:',
      label_contact_middle_name:'Middle Name:',
      label_contact_last_name:'Last Name:',
      label_contact_phone_kind:'Phone Kind:',
      label_contact_phone_number:'Phone Number:',
      label_contact_email:'Email:',
      label_service_note_description:'Service Note',
      label_service_note_visibility:'Visible to',
      label_billing_note_description:'Billing Note',
      label_billing_note_visibility:'Visible to',
      label_work_note_description:'Work Note',
      label_work_note_visibility:'Visible to',
      button_create:'Create',
      button_clear:'Clear',
      button_cancel:'Cancel',
      view_detail_text:'Show restaurant:',
      show_link: 'View Restaurant',
      RequiredInputError:'This field is required',
      InvalidEmailError: 'Invalid Email',
      error_promise: 'An error occurred, please try again',
      result:{
        record_created_ok: 'Successfully created restaurant',
        subsidary_created_ok: 'Successfully created restaurant',
        error_promise: 'An error occurred, please try again',
        invalid_params_error: 'Invalid Params',
      }      
    },
    subsidary_add_hood:{
      label_add_hood: 'Add Hood',
      label_kind: 'Fan type',
      label_equipment_under: 'Equipment Under',
      label_maintenance_kind: 'Service Kind',
      label_description: 'Description',
      button_create:'Create',
      button_clear:'Clear',
      button_cancel:'Cancel',
      result:{
        hood_created_ok: 'Successfully created hood',
        error_promise: 'An error occurred, please try again',
      }
    },
    new_maintenance_kind_form:{
      label_name:'Name:',
      label_abbreviation:'Abbreviation:',
      label_description:'Notes:',
      label_price: 'Price (USD):',
      label_periodicity: 'Frecuency (days):',
      show_link: 'View Service Kind',
      button_create:'Create',
      button_clear:'Clear',
      button_cancel:'Cancel',
      view_detail_text:'Show service kind:',
      RequiredInputError:'This field is required',
      error_promise: 'An error occurred, please try again',
      result:{
        error_promise: 'An error occurred, please try again',
        maintenance_kind_created_ok: 'Service Kind successfully created'
      }      
    },
    hood_maintenances: {
      label_add_maintenance: 'Add Maintenance',
      table_header_id: 'ID',
      table_header_restaurant: 'Restaurant',
      table_header_maintenance_kind: 'Service Kind',
      table_header_hood_id: 'Hood ID',
      table_header_due_at: 'Due on',
      table_header_completed_at: 'Completed on',
      table_header_status: 'Status',
      table_header_maintenance_frequency: 'Frequency (Days)',
      table_header_maintenance_price: 'Price (USD)'
    },
    hood_add_maintenance: {
      label_add_maintenance: 'Add Maintenance',
      maintenance_kind: 'Service Kind',
      due_at: 'Due Date',
      button_create:'Create',
      button_clear:'Clear',
      button_cancel:'Cancel',
    },
    create_order_modal: {
      btn_add_order:'Add Work Order',
      label_add_note_undefined:'Add Work Order',
    },
    new_order:{
      label_subsidary:'Restaurant',
      label_service:'Service',
      frequency: 'Frequency (Days)',
      due_date: 'Due Date',
      schedule_date: 'Schedule Date',
      price: 'Total Order Price',
      subtitle_maintenances: 'Registered Maintenances',
      restaurant_no_hoods: 'This restaurant has no Hoods.',
      restaurant_register_hood: 'Register new hoods from here',
      restaurant_no_services: 'This restaurant has no services.',
      restaurant_register_service: 'Register new services from here',
      subtitle_order_items: 'Order Items',
      no_order_items: 'No order items yet',
      table_order_items_header_kind: 'Order Item Kind',
      table_order_items_header_service_kind: 'Service Kind',
      table_order_items_header_under_hood: 'Equipment Under Hood',
      table_order_items_header_quantity: 'Quantity',
      table_order_items_header_price: 'Price',
      table_order_items_header_total: 'Total',
      total_order_price: 'Total Order Price:',
      create: 'Create',
      table_header_id:'ID',
      table_header_customer:'Customer',
      table_header_subsidary:'Restaurant',
      table_header_due_date:'Due Date',
      table_header_status:'Status',
      button_create:'Create New Work Order',
      view_detail_text:'To see the new order created, click on the following link:',
      show_link: 'View order',
      result:{
        order_created_ok: 'Successfully created order',
        error_promise: 'An error occurred, please try again',
      },
      messages:{
        error_dates: 'It is essential to enter the "Due Date" or the "Scheduled Date"'
      },
      data_title_delete_item: 'Delete Order Item'
    },
    maintenance_actions: {
      button_cancel: "Cancel Maintenance",
      button_reschedul_due_date: "Reschedule Due Date"
    },
    maintenance_reschedule_due_date: {
      label_reschedul_due_date: "Reschedule Due Date",
      button_reschedul_due_date: "Reschedule Due Date",
      due_date: "Due Date:",
      button_clear: "Clear",
      button_cancel: "Cancel",
      button_reschedule: "Reschedule Due Date",
      result:{
        error_promise: 'An error occurred, please try again',
      }
    },
    maintenance_cancel: {
      label_cancel_maintenance: "Cancel Maintenance",
      button_clear: "Clear",
      button_close: "Close",
      cancellation_reason: "Cancellation Reason",
      button: "Cancel Maintenance",
      send: 'Cancel Maintenance',
      cancellation_reason_question: 'What is the cancelation reason?',
      confimation_question: "Are you sure you want to cancel this maintenance?",
      confirmation_reject: "No",
      confirmation_accept: "Yes",
      result:{
        error_promise: 'An error occurred, please try again',
      }
    },
    general_messages: {
      RequiredValueError: 'This value is required',
      InvalidEmailError: 'Invalid Email',
      choose_option: 'Please choose an option',
      InvalidLinkFormatError: 'Invalid link format'
    },
    customers_detail_table_panel:{
      table_header_id: 'ID',
      table_header_name: 'Name',
      table_header_address: 'Address',
      empty_items: 'No customers yet',
      view_customer: 'View Customer',
    },
    create_customer_modal:{
      modal_title: 'Add Customer',
      button_clear: 'Clear',
      button_cancel: 'Cancel',
      button_create: 'Create'
    },
    customer_detail:{
      title: 'Customer',
      id: 'ID',
      name: 'Name',
      aka: 'A.K.A.',
      vendor: 'Vendor',
      phone_number: 'Phone Number',
      active_customer: 'Active Customer',
      inactive_customer: 'Inactive Customer',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
    },
    subsidary_detail:{
      title: 'Restaurant',
      active_restaurant: 'Active Restaurant',
      inactive_restaurant: 'Inactive Restaurant',
      bt_edit: 'Edit',
      bt_cancel: 'Cancel',
      bt_save: 'Save',
      id: 'ID',
      name: 'Name',
      aka: 'A.K.A.',
      state: 'State',
      vendor: 'Vendor',
      customer: 'Customer',
      turn_around_time: 'Turn Around Time (days)',
      invoice_target: 'Invoice Target',
      phone_number: 'Phone Number',
      fax_number: 'Fax Number',
      keys: 'Keys',
      alarm: 'Alarm',
      hours_of_operation: 'Hours of Operation',
      service_periodicity: 'Service',
      service_base_price: 'Base Price',
      service_breakdown: 'Breakdown',
      service_total: 'Total',
    },
    notes_detail_table_panel:{
      subtitle_service: 'Service Notes',
      subtitle_billing: 'Billing Notes',
      subtitle_work: 'Work Notes',
      empty_items: 'No notes yet',
      visible: 'Visible',
      visibility: 'Visibility',
      visible_system: 'System',
      visible_employee: 'Employee',
      visible_customer: 'Customer',
      visible_everyone: 'Everyone',
    },
    create_note_modal:{
      btn_add_note_service: 'Add Service Note',
      btn_add_note_billing: 'Add Billing Note',
      btn_add_note_work: 'Add Work Note',
      btn_add_note_invoice: 'Add Invoice Note',
      label_add_note_service: 'New Service Note',
      label_add_note_billing: 'New Billing Note',
      label_add_note_work: 'New Work Note',
      label_add_note_invoice: 'New Invoice Note',
      description: 'Description',
      visibility: 'Visible for',
      button_clear: 'Clear',
      button_cancel: 'Cancel',
      button_create: 'Create'
    },
    edit_note_modal:{
      label_edit_note_service: 'Edit Service Note',
      label_edit_note_billing: 'Edit Billing Note',
      label_edit_note_work: 'Edit Work Note',
      label_edit_note_invoice: 'Edit Invoice Note',
      description: 'Description',
      visibility: 'Visible for',
      button_clear: 'Clear',
      button_cancel: 'Cancel',
      button_save: 'Save'
    },
    services_detail_table_panel:{
      title: 'Add Service',
      months: 'Months',
      empty_items: 'No services yet',
      table_header_service: 'Type',
      table_header_frequency: 'Frecuency (Days)',
      table_header_base_price: 'Base Price',
      table_header_breakdown: 'Breakdown',
      table_header_total: 'Total',
    },
    hoods_detail_table_panel:{
      title: 'Add Hood',
      empty_items: 'No hoods yet',
      table_header_hood: 'Hood',
      table_header_equipment_under: 'Equipment Under',
      table_header_description: 'Description',
    },
    edit_subsidary_hood:{
      modal_title: 'Hood',
      label_hood_equipment_under: 'Select a Equipment Under',
      label_or: 'Or',
      label_hood_equipment_under_write: 'Write a Equipment Under',
      label_hood_description: 'Description (Optional)',
      button_clear: 'Clear',
      button_cancel: 'Cancel',
      button_save: 'Save'
    },
    links_detail_panel:{
      table_header_link: 'Link',
      table_header_description: 'Description',
      empty_items: "No Links yet",
      add_link: 'Add Link',
    },
    edit_link_modal: {
      link: 'Link',
      description: 'Description',
      label_link: 'Link',
      button_create:'Save',
      button_clear:'Clear',
      button_cancel:'Cancel',
    },
    new_technician_form: {
      role: 'Job',
      email: 'Email',
      first_name: 'First Name',
      middle_name: 'Middle Name',
      last_name: 'Last Name',
      phone_kind: 'Phone Kind',
      phone_number: 'Phone Number',
      button_create:'Create',
      button_clear:'Clear',
      button_cancel:'Cancel',
      view_detail_text: 'To see the new technician created, click on the following link:',
      necessary_capture_value: 'It is necessary to capture at least 1 value',
      show_link: 'View Technician',
      result: {
        technician_created_ok: 'Successfully created technician',
      }
    },
    technician_detail:{
      title: 'Technician',
      inactive_technician: 'Inactive Technician',
      active_tecnician: 'Active Technician',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      id: 'ID',
      state: 'State',
      first_name: 'First Name',
      middle_name: 'Middle Name',
      last_name: 'Last Name',
      email: 'Email',
      role: 'Job',
      phone_kind: 'Phone Kind',
      phone_number: 'Phone Number',
    },
    technicians_detail_panel:{
      add_technician: 'Add Technician',
      table_header_name: 'Name',
      table_header_role_name: 'Job',
      table_header_email: 'Email',
      table_header_phone: 'Phone',
      empty_items: 'No Technicians yet'
    },
    add_technician_to_order_modal:{
      label_technician: 'Add Technician',
      technician: 'Technician',
      button_create:'Add',
      button_clear:'Clear',
      button_cancel:'Cancel',
    },
    orders_detail_table_panel:{
      table_header_id: 'ID',
      table_header_job_number: 'WO-PO',
      table_header_id_kanela: 'ID Schedule',
      table_header_status: 'Status',
      table_header_service: 'Service',
      table_header_frequency: 'Frecuency(days)',
      table_header_price: 'Price',
      table_header_due_on: 'Due On',
      table_header_schedule_for: 'Schedule For',
      table_header_compleated_on: 'Compleated On',
      table_header_preview_cleaning: 'Previous Cleaning',
      table_header_next_cleaning: 'Next Cleaning',
      table_header_invoice_ids: 'Invoice',
      table_header_payment_status: 'Payment Status',
      total_orders: 'Total Orders',
      total_billed: 'Total Billed',
      total_unbilled: 'Total Unbilled',
      months: 'Months',
      view_order: 'View Order',
      view_invoice: 'View Invoice',
      empty_items: 'No Orders yet'
    },
    delete_contact_modal:{
      delete_contact: "Delete Contact",
      question: "Are you sure to delete this contact?",
      reject: 'No',
      accept: 'Yes',
      title: 'Delete Contact',
      delete_phone_contact: 'Delete Phone',
    },
    delete_customer_modal:{
      inactive_customer: 'Inactive Customer',
      comments: 'Disabling the customer will also inactive its restaurants.',
      question: "Are you sure to inactive this customer?",
      reject: 'No',
      accept: 'Yes',
      title: 'Inactive Customer',
    },
    delete_hood_modal: {
      title: 'Delete Hood',
      question: "Are you sure to delete this hood?",
      reject: 'No',
      accept: 'Yes',
    },
    delete_link_modal:{
      title: 'Delete Link',
      question: 'Are you sure to delete this link?',
      reject: 'No',
      accept: 'Yes',
    },
    delete_vendor_modal:{
      inactive_vendor: "Inactive Vendor",
      question: "Are you sure to inactive this vendor?",
      comments: 'Disabling the vendor will also inactive its customers.',
      reject: 'No',
      accept: 'Yes',
      title: 'Inactive Vendor',
    },
    maintenance_price_value: {
      cancel: 'Cancel',
      save: 'Save',
    },
    delete_note_modal: {
      title: "Delete Note",
      question: 'Are you sure to delete this note?',
      reject: 'No',
      accept: 'Yes',
    },
    order_actions: {
      invoice_order: 'Invoice Order',
      paper_work: 'Paper Work',
      for_technician: 'For Technician',
      for_customer: 'For Customer',
    },
    order_cancel: {
      title: 'Cancel Work Order',
      btn_clear: 'Clear',
      btn_cancel: 'Cancel',
      btn_send: 'Save',
      label_reason_cancellation: 'Reason for cancellation',
      warning_order_cancel: '¡Warning! This work order has associated invoices. Please note that canceling this order will also cancel all related invoices. We strongly advise you to review carefully before proceeding with this action.',
    },
    order_complete: {
      title: 'Complete Work Order',
      confirmation_question: 'Are you sure to complete this work order?',
      cancel: 'Cancel',
      send: 'Complete',
    },
    order_due_at: {
      title: 'Due On',
      date: 'Date',
      clear: 'Clear',
      cancel: 'Cancel',
      send: 'Save',
    },
    order_schedule: {
      title: 'Schedule For',
      date: 'Date',
      clear: 'Clear',
      cancel: 'Cancel',
      send: 'Save',
    },
    create_orderable_modal: {
      title: 'Add Order Item',
      clear: 'Clear',
      cancel: 'Cancel',
      send: 'Save',
      orderable_kind: 'Order Item Kind',
      service_kind: 'Service Kind',
      hood: 'Hood',
      quantity: 'Quantity',
      price: 'Price',
    },
    delete_orderable_modal: {
      title: 'Delete Order Item',
      confirmation_question: 'Are you sure to delete this order item?',
      reject: 'No',
      accept: 'Yes',
    },
    orderables_list_panel: {
      no_order_items: 'No order items yet',
      table_header_item_kind: 'Order Item Kind',
      table_header_kind: 'Service Kind',
      table_header_under_hood: 'Equipment Under Hood',
      table_header_quantity: 'Quantity',
      table_header_price: 'Price',
      table_header_total: 'Total',
      extra: 'Extra',
      service: 'Service',
      update_order_item: 'Update Order Item',
      total_order_price: 'Total Order Price:',
      update_total_order_price: 'Update Total Order Price',
    },
    delete_service_modal: {
      title: 'Delete Service',
      confirmation_question: 'Are you sure to delete this service?',
      message1: 'If you delete this service, the frequency associated with the following orders (IDs: ',
      message2: ' ) will also be removed. Are you sure you want to proceed?',
      reject: 'No',
      accept: 'Yes',
    },
    edit_service_modal: {
      modal_title: 'Service',
      label_service_periodicity: 'Type',
      label_service_frequency: 'Frecuency (Days)',
      label_service_base_price: 'Base Price',
      label_service_breakdown: 'Breakdown',
      label_service_total: 'Total',
      button_clear: 'Clear',
      button_cancel: 'Cancel',
      button_save: 'Save',
      other_frecuency: 'Other Frecuency',
      select_months: 'Please select the months:',
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
    },
    delete_subsidary_modal: {
      title: 'Inactive Restaurant',
      comments: 'Disabling the restaurant will also cancel uncompleted work orders.',
      confirmation_question: 'Are you sure to Inactive this restaurant?',
      reject: 'No',
      accept: 'Yes',
    },
    remove_technician_to_order_modal: {
      title: 'Remove Technician',
      confirmation_question: 'Are you sure to remove this technician?',
      reject: 'No',
      accept: 'Yes',
    },
    new_invoice: {
      title: 'New Invoice',
      clear: 'Clear',
      cancel: 'Cancel',
      send: 'Save',
      document_number: 'PO# (Optional)',
      date: 'Date',
      porcent_credit_card: 'Credit Card Admin Fee',
      price: 'Price',
      price_with_credit_card: 'Price With Credit Card',
      way_to_pay: 'Payment Type',
      cod: 'Payment Term (days)',
      view_detail_text:'To see the new invoice created, click on the following link:',
      show_link: 'View Invoice',
      combine_orders_in_invoice: 'Generate 1 invoice including all orders?',
      messages: {
        amount_greater_than_the_order: 'Amount greater than the order',
        total_charged_greater_than_the_order: 'Amount greater than what was already invoiced',
        unbilled_total_zero: 'Unbilled amount is zero',
      },
      result: {
        invoice_created_ok: 'Successfully created invoice',
      }
    },
    new_invoice_form: {
      subtitle_invoice_detail: 'Detail of the new invoice',
      list_orders_detail: 'Order List',
      table_header_number: 'Num.',
      table_header_status: 'Status',
      table_header_vendor: 'Vendor',
      table_header_customer: 'Customer',
      table_header_subsidary: 'Restaurant',
      table_header_frecuency: 'Frecuency',
      table_header_schedule_for: 'Schedule For',
      table_header_price: 'Price',
      table_header_billed: 'Billed',
      table_header_unbilled: 'Unbilled',
      button_clear: 'Clear',
      button_cancel: 'Cancel',
      button_create: 'Create',
      no_orders: 'No Orders yet',
      view_detail_text:'To see the new invoice created, click on the following link:',
      show_link: 'View Invoice',
      result: {
        invoice_created_ok: 'Successfully created invoice',
      }
    },
    invoice_detail: {
      title: 'Invoice',
      id: 'ID',
      document_number: 'PO#',
      date_created: 'Date Created',
      date_invoice: 'Invoice Date',
      way_to_pay: 'Payment Type',
      cod: 'Payment Term (days)',
      cancelation_reason: 'Cancelation Reason',
      status: 'Status',
      amount: 'Amount',
      porcent: 'Credit Card Admin Fee',
      amount_with_credit_card: 'Amount With Credit Card',
      total_paid_amount: 'Paid',
      masked_total_unpaid_amount: 'Unpaid',
      cancel_invoice: 'Cancel Invoice',
      pay_invoice: 'Pay Invoice',
      edit: 'Edit',
      cancel: 'Cancel',
      save: 'Save',
      actions: {
        edit: "Edit",
        cancel: "Cancel",
        save: "Save",
      },
    },
    invoices_detail_panel: {
      empty_items: 'No Invoices yet',
      table_header_document_number: 'Number',
      table_header_date_invoice: 'Date',
      table_header_price: 'Price',
      table_header_porcent_credit_card: 'Credit Card Admin Fee',
      table_header_price_with_credit_card: 'Price Credit Card',
      table_header_cod: 'Payment Term (days)',
      table_header_way_to_pay: 'Payment Type',
      table_header_amount_paid: 'Paid',
      table_header_outstanding_balance: 'Unpaid',
      table_header_status: 'Status',
      total_invoices: 'Total Invoices',
      total_paid: 'Total Paid',
      total_unpaid: 'Total Unpaid',
    },
    invoice_orders_table_panel: {
      view_order: 'View Order',
      table_header_id: 'ID',
      table_header_job_number: 'WO-PO',
      table_header_id_kanela: 'ID Schedule',
      table_header_status: 'Status',
      table_header_frequency: '	Frecuency(days)',
      table_header_price: 'Price',
      table_header_due_on: 'Due On',
      table_header_schedule_for: 'Schedule For',
      table_header_compleated_on: 'Compleated On',
      table_header_restaurant: 'Restaurant',
      table_header_invoice_target: 'Invoice Target',
      months: 'Months',
      total_orders: 'Total Orders',
    },
    remove_order_from_invoice_modal: {
      title: 'Remove order from invoice',
      question: 'Are you sure you want to remove order from invoice?',
      reject: 'No',
      accept: 'Yes',
    },
    invoice_payments_table_panel: {
      empty_items: "No Payments yet",
      view_payment: 'View Payment',
      table_header_id: 'ID',
      table_header_status: 'Status',
      table_header_user_create: 'User',
      table_header_date_payment: 'Payment Date',
      table_header_amount_paid: 'Amount',
      table_header_way_to_pay: 'Payment Type',
      table_header_num_check: 'Check Num.',
      table_header_credit_card_number: 'Credit Card Number',
      table_header_reference: 'Reference',
      total_payments: 'Total Payments',
    },
    cancel_invoice_modal: {
      title: 'Cancel Invoice',
      question: 'Are you sure you want to cancel the invoice?',
      label_reason_cancellation: 'Reason for cancellation',
      label_exist_payments_applied: 'It cannot be canceled because there are payments applied. Cancel payments first.',
      reject: 'No',
      accept: 'Yes',
    },
    invoice_actions: {
      paper_invoice: 'Print Invoice',
      download_invoice: 'Download Invoice',
      for_technician: 'Technician',
      for_customer: 'Customer',
    },
    apply_payment_modal: {
      title: 'Apply Payment',
      label_date: 'Date',
      label_amount: 'Amount',
      label_way_to_pay: 'Payment Type',
      label_num_check: 'Check Num.',
      label_credit_card_number: 'Credit Card Number',
      label_reference: 'Reference',
      label_num_check: 'Check Num.',
      button_create:'Apply',
      button_clear:'Clear',
      button_cancel:'Cancel',
      messages: {
        amount_greater_than_the_invoice:'Amount greater than the balance of the invoice',
      },
    },
    payment_detail: {
      title: 'Payment',
      id: "ID",
      status: "Status",
      date_payment: "Payment Date",
      amount_paid: "Invoice Amount",
      amount_admin_fee: "Admin Fee Amount",
      amount_total: "Total Amount",
      way_to_pay: "Payment Type",
      num_check: "Check Num",
      credit_card_number: "Credit Card Number",
      reference: "Reference",
      cancelation_reason: 'Cancelation Reason',
      edit: "Edit",
      cancel: "Cancel",
      save: "Save",
    },
    payment_invoices_table_panel: {
      table_header_id: 'ID',
      table_header_number: 'PO#',
      table_header_status: 'Status',
      table_header_vendor: 'Vendor',
      table_header_customer: 'Customer',
      table_header_subsidary: 'Restaurant',
      table_header_date_invoice: 'Invoice Date',
      table_header_amount: 'Amount',
      table_header_porcent: 'Credit Card Admin Fee',
      table_header_amount_with_credit_card: 'Amount With Credit Card',
      table_header_way_to_pay: 'Payment Type',
      table_header_cod: 'Payment Term (days)',
    },
    cancel_payment_modal: {
      title: "Cancel Payment",
      question: 'Are you sure you want to cancel the payment?',
      label_reason_cancellation: 'Reason for cancellation',
      reject: 'No',
      accept: 'Yes',
    },
    new_payment_form: {
      subtitle_invoice_detail: 'Detail of the new payment',
      date: 'Payment Date',
      amount: 'Amount',
      way_to_pay: 'Payment Type',
      num_check: 'Check Num.',
      credit_card_number: 'Credit Card Number',
      reference: 'Reference',
      list_invoices_detail: 'Select invoices for payment',
      table_header_number: 'Number',
      table_header_status: 'Status',
      table_header_vendor: 'Vendor',
      table_header_customer: 'Customer',
      table_header_subsidary: 'Restaurant',
      table_header_invoice_target: 'Invoice Target',
      table_header_date: 'Date',
      table_header_amount: 'Amount',
      table_header_percentage: 'Credit Card Admin Fee',
      table_header_amount_cr: 'Amount Credit Card',
      table_header_way_to_pay: 'Payment Type',
      table_header_amount_pay: 'Paid',
      table_header_outstanding_balance: 'Unpaid',
      button_clear: 'Clear',
      button_cancel: 'Cancel',
      button_create: 'Create',
      no_invoices: 'No Invoices yet',
      view_detail_text:'To see the new payment created, click on the following link:',
      show_link: 'View Payment',
      result: {
        payment_created_ok: 'Successfully created payment',
      }
    },
    send_email_invoice_modal:{
      title: 'Send Invoice',
      label_to: 'To',
      label_cc: 'CC',
      label_cco: 'BCC',
      label_subject: 'Subject',
      label_message: 'Message',
      title_to: "Enter the email addresses of the people you want to send the email to. If you're sending the email to more than one person, separate the addresses using commas (,) or semicolons (;).",
      title_cc: "Enter the email addresses of people who will also receive a copy of the email. This is useful for keeping others informed or for sharing information with multiple recipients. Separate the addresses with commas (,) or semicolons (;).",
      title_cco: "Similar to the 'CC' field, but the addresses entered here will not be visible to the other recipients of the email. This allows you to send copies of the email without revealing the recipients' addresses. Separate the addresses with commas (,) or semicolons (;).",
      title_subject: "Write a brief summary or title that describes the content of the email. This helps recipients understand what the email is about and may increase the likelihood of them opening it.",
      button_clear: 'Clear',
      button_cancel: 'Cancel',
      button_create: 'Send',
      result: {
        email_not_sent: 'It was not possible to send the Email',
        invoice_email_sended_ok: 'Email sent successfully',
      }
    },
    edit_phone_contact_modal:{
      necessary_capture_value: 'It is necessary to capture at least 1 value',
      phone_kind: 'Phone Kind',
      phone_number: 'Phone Number',
      label_phone: 'Phone',
      button_clear: 'Clear',
      button_cancel: 'Cancel',
      button_create: 'Save',
    },
    delete_phone_contact_modal:{
      delete_phone: 'Delete Phone',
      question: 'Are you sure to delete this phone?',
      reject: 'No',
      accept: 'Yes',
      title: 'Delete Phone',
    },
    vendors_active:{
      subtitle_vendors_active: 'Active Vendors',
      view_vendors: 'View Vendors ',
    },
    customers_active:{
      subtitle_customers_active: 'Active Customers',
      view_customers: 'View Customers ',
    },
    subsidaries_active:{
      subtitle_subsidaries_active: 'Active Subsidaries',
      view_subsidaries: 'View Subsidaries ',
    },
    invoices_by_state: {
      title: 'Send Email',
      subtitle_invoices_by_state: 'Summary of Invoices by State',
      invoice_UNPAID: 'Unpaid Invoices',
      invoice_SEMI_PAID: 'Partially Paid Invoices',
      invoice_PAID: 'Paid Invoices',
      invoice_CANCELED: 'Cancelled Invoices',
      view_invoices: 'View Invoices ',
    },
    invoices_unbilled: {
      subtitle_invoices_unbilled: 'Overdue Invoices',
      view_invoices: 'View Invoices ',
    },
    unbilled_invoices_list: {
      subtitle_invoices_unbilled: 'Overdue Invoices',
      restaurant_header: 'Restaurant',
      date_header: 'Date',
      outstanding_balance_header: 'Unpaid',
      emails_header: 'Email',
      view_header: '',
      number_header: 'Number',
      cod_header: 'Payment Term (days)',
      send_all_emails: 'Send All Emails',
      send_email: 'Send Email',
      view_invoices: 'View Invoices ',
      result: {
        error_send: 'Error sending email, please try again',
        error_promise: 'An error occurred, please try again',
        invalid_params_error: 'Invalid Params',
        invoice_not_found: 'Invoice Not Found',
        invoice_email_sended_ok: 'Email sent successfully',
      },
      email_invoice_not_present: 'No email found to send the invoice',
      email_sended_ok_to: 'The email was sent to',
      email_sended_ok: 'Email sent successfully',
      email_not_sended: 'Could not send mail',
    },
    jobs_list: {
      subtitle_jobs_list: 'Jobs List',
      headers: {
        restaurant: 'Restaurant',
        word_order_id: 'ID Work Order',
        num_service: 'WO-PO',
        id_kanela: 'ID Schedule',
        job_status: 'Status',
        previous_cleaning: 'Previous Cleaning',
        schedule_date: 'Schedule Date',
        next_cleaning: 'Next Cleaning',
        service: 'Service',
        frequency: 'Frequency (Days)',
        invoice_id: 'Invoice ID',
        invoice_document_number: 'Invoice PO#',
        total_amount: 'Total Amount',
        percentage: '%',
        credit_card: 'Credit Card',
        date_paid: 'Date Paid',
        check: 'Check',
        way_to_pay: 'Payment Type',
      },
      months_label: 'Months',
      months: {
        1: 'January',
        2: 'February',
        3: 'March',
        4: 'April',
        5: 'May',
        6: 'June',
        7: 'July',
        8: 'August',
        9: 'September',
        10: 'October',
        11: 'November',
        12: 'December',
      },
      total_jobs: 'Total Jobs',
      view_jobs: 'Complete List of Jobs ',
    },
    invoiceables_detail_table_panel: {
      create_new_invoice_item: 'Add Invoice Item',
      empty_items: 'No Invoice Items yet',
      table_header_concept: 'Concept',
      table_header_description: 'Description ',
      table_header_price: 'Price',
      table_header_quantity: 'Quantity',
      table_header_percentage: 'Percentage ',
      table_header_total: 'Total',
      total_invoice_items: 'Total Invoice Items',
    },
    edit_invoice_item: {
      modal_title: 'Invoice Item',
      label_invoice_item_concept: 'Concept',
      label_invoice_item_description: 'Description (optional)',
      label_invoice_item_price: 'Price',
      label_invoice_item_quantity: 'Quantity',
      label_invoice_item_percentage: 'Percentage (%)',
      label_invoice_item_total: 'Total',
      button_save:'Save',
      button_clear:'Clear',
      button_cancel: 'Cancel',
    },
    delete_invoiceable_modal: {
      title: 'Delete Invoice Item',
      question: 'Are you sure to delete this Invoice Item?',
      reject: 'No',
      accept: 'Yes',
    },
    service_detail:{
      title: 'Restaurant Service Assigned to this Order',
      title_list_services: 'List of Available Services in the Restaurant',
      label_delete_service: 'Delete Service From Order',
      label_assign_service: 'Assign another service to the order',
      label_cancel_assignment: 'Cancel assignment',
      label_create_service: 'Create a new service for the restaurant',
      table_services_header_service: 'Service',
      table_services_header_frequency: 'Frecuency (Days)',
      table_services_header_frequency_month: 'Frecuency (Months)',
      table_services_header_base_price: 'Base Price',
      table_services_header_breakdown: 'Breakdown',
      table_services_header_total: 'Total',
      edit: 'Edit',
      assign_service: 'Assign Service',
      select: 'Select',
      messages:{
        no_services_yet: 'Currently, the restaurant does not have any active registered services.',
        no_service_assigned: 'No service has been assigned. It is essential to allocate a service to ensure the proper scheduling of the following orders.',
        this_service_is_selected: 'This service is currently selected.',
        modifying_affect_restaurant: 'Attention! Modifying this data will affect the configuration of the service registered in the restaurant.',
        remove_affect_order: 'Caution! If you remove the service from the order, it will not be possible to automatically assign subsequent orders. It is crucial that all orders have a service assigned.',
      }
    },
    units_detail_table_panel: {
      total_units: 'Total Units',
      total_units_price: 'Total Price',
      save_units: 'Save'
    }
  }
}