<template lang='pug'>
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display" 
    v-bind:alert="controls.alert"
  )

  .tw-flex.tw-flex-row.tw-justify-between
    div
      h5.h5.tw-text-gray-500 {{$I18n.t('vue.account_detail.title')}}
    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center")
      div(class="spinner-border spinner-border-sm" role="status")

    //Barra de botones  
    div(v-else)
      .tw-flex.tw-flex-row
        button.app-btn-link-primary(v-if="record.state!='INACTIVE' && state.show", @click="disable_account()") 
          i.fa.fa-window-close.tw-mx-1 
          span {{$I18n.t('vue.account_detail.inactive_account')}}
        button.app-btn-link-primary(v-if="record.state=='INACTIVE' && state.show", @click="active_account()") 
          i.fa.fa-check-circle.tw-mx-1 
          span {{$I18n.t('vue.account_detail.active_account')}}
        div(v-if="state.sending_email_recovery_password")
          span {{$I18n.t('vue.account_detail.sending_email')}}
        div(v-else)
          button.app-btn-link-primary(v-if="record.state=='ACTIVE' && state.show", @click="send_email_recovery_password()") 
            i.fa.fa-envelope.tw-mx-1 
            span {{$I18n.t('vue.account_detail.send_email_recover_password')}}
        button.app-btn-link-primary(v-if="state.show", @click="edit()") 
          i.fa.fa-pencil.tw-mx-1 
          span {{$I18n.t('vue.account_detail.edit')}}
        button.app-btn-link-primary(v-if="state.edit", @click="cancel()")
          i.fa.fa-times.tw-mx-2
          span {{$I18n.t('vue.account_detail.cancel')}}
        button.app-btn-link-primary(v-if="state.edit", @click="update()")
          i.fa.fa-save.tw-mx-2
          span {{$I18n.t('vue.account_detail.save')}}
  hr
  Detailgrid(:data-grid="grid" :data-editing='state.edit')

</template>

<script>
import CoreComponents from "../core";
import { BackendService } from "../../services";
const { ActiveValue } = require("../../core/active_support");

export default {
  props: ["dataRecord", "dataRoles"],
  components: { ...CoreComponents },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    let self = this;
    return {
      state: {
        show: true,
        edit: false,
        sending: false,
        sending_email_recovery_password: false
      },
      controls:{
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      values: {
        id: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.account_detail.id"));
          i.setValue(self.record.id);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        status: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.account_detail.status"));
          i.setValue(self.$I18n.t("vue.states."+self.record.state.toLowerCase()));
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        first_name: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.account_detail.first_name"));
          i.setValue(self.record.person.first_name);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        middle_name: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.account_detail.middle_name"));
          i.setValue(self.record.person.middle_name);
          i.setKind("string");
          i.setInputKind("text");
        }),
        last_name: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.account_detail.last_name"));
          i.setValue(self.record.person.last_name);
          i.setKind("string");
          i.setInputKind("text");
        }),
        email: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.account_detail.email"));
          i.setValue(self.record.user.email);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        role: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.account_detail.role"));
          i.setValue(self.record.role.name);
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataRoles));
          i.required();
        })
      },
    };
  },
  methods: {
    disable_account: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .disable_account(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    active_account: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .active_account(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    send_email_recovery_password: function () {
      const self = this;
      self.state.sending_email_recovery_password=true
      if (this.valid()) {
        self.sending();
        BackendService
          .public_send_reset_password_email(
            self.record.id,
            JSON.stringify({
              email: self.record.user.email,
              person_full_name: self.record.person_full_name
            })
          )
          .then((r) => {
            self.controls.alert.notice = 'vue.account_detail.result.'+r.notice
            if (r.isOk){
              self.controls.alert.type="success"
              self.controls.alert.message = 'vue.empty'
              self.controls.alert.link.text = 'vue.empty'
              self.controls.alert.link.href=""
            }else{
              self.controls.alert.type="danger"
              self.controls.alert.notice = 'vue.account_detail.result.email_not_sent'
              self.controls.alert.message = 'vue.empty'
              self.controls.alert.link.text = 'vue.empty'
              self.controls.alert.link.href=""
            }
            self.state.sending_email_recovery_password=false
            this.state.edit = false;
            this.state.show = true;
            this.state.sending = false;
          }).catch(()=>{
            self.state.sending_email_recovery_password=false
            this.state.edit = false;
            this.state.show = true;
            this.state.sending = false;
            self.controls.alert.type="danger"
            self.controls.alert.notice = 'vue.account_detail.result.email_not_sent'
            self.controls.alert.message = 'vue.empty'
            self.controls.alert.link.text = 'vue.empty'
            self.controls.alert.link.href=""
          })
          .finally(()=>{
            self.state.sending=false;
            self.controls.alert.display=true
          });
      }
    },
    valid: function () {
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    edit: function () {
      this.values.role.setValue(this.record.role.id);
      this.state.edit = true;
      this.state.show = false;
      this.state.sending = false;
    },
    cancel: function () {
      this.values.role.setValue(this.record.role.name);
      this.state.show = true;
      this.state.edit = false;
      this.state.sending = false;
      Object.values(this.values).map((v) => v.valueToInput());
    },
    sending: function () {
      this.state.show = false;
      this.state.edit = false;
      this.state.sending = true;
    },
    stringify: function () {
      return JSON.stringify({
        atts: {
          first_name: this.values.first_name.input,
          middle_name: this.values.middle_name.input,
          last_name: this.values.last_name.input,
          email: this.values.email.input,
          role: this.values.role.input,
        },
      });
    },
    update: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .update_account(
            self.record.id,
            this.stringify()
          )
          .then((r) => {
            if (r.isOk){
              //self.controls.alert.type="success"
              //self.controls.alert.message = 'vue.empty'
              //self.controls.alert.link.text = 'vue.empty'
              //self.controls.alert.link.href=''
            }else{
              self.controls.alert.notice = 'vue.account_detail.result.'+r.notice
              self.controls.alert.type="danger"
              self.controls.alert.display=true
            }
            self.cancel();
            self.values.first_name.setValue(r.account.person.first_name);
            self.values.middle_name.setValue(r.account.person.middle_name);
            self.values.last_name.setValue(r.account.person.last_name);
            self.values.email.setValue(r.account.user.email);
            self.values.role.setValue(r.account.role.name);
          })
          .finally(()=>{
            self.state.sending=false;
          });
      }
    },
  },
  computed: {
    grid: function () {
      return [
        [this.values.id,        this.values.status     ,  ""                    ],
        [this.values.first_name,this.values.middle_name,  this.values.last_name ],
        [this.values.email,     this.values.role,         ""                    ]
      ];
    }
  },
};
</script>
