<template lang="pug">

.app-card.overflow-x-auto
  .flex(class='justify-end')
    create-orderable-modal(
      v-if="record.state.name != 'COMPLETED' && record.state.name != 'CANCELED'"
      :data-id='order.id'
      :data-model='order.model'
      :data-maintenance-kinds='dataMaintenanceKinds'
      :data-maintenance-complement-kinds='dataMaintenanceComplementKinds'
      :data-subsidary-hoods='dataSubsidaryHoods'
      :data-orderable-kinds='dataOrderableKinds'
      @created='CreateItem'
    )

  

  div(v-if='emptyItems')
    p(class='message-search') {{$I18n.t('vue.orderables_list_panel.no_order_items')}}
  div(v-else)
    table.table
      thead
        tr
          th {{$I18n.t('vue.orderables_list_panel.table_header_item_kind')}}
          th {{$I18n.t('vue.orderables_list_panel.table_header_kind')}}
          th {{$I18n.t('vue.orderables_list_panel.table_header_under_hood')}}
          th {{$I18n.t('vue.orderables_list_panel.table_header_quantity')}}
          th {{$I18n.t('vue.orderables_list_panel.table_header_price')}}
          th {{$I18n.t('vue.orderables_list_panel.table_header_total')}}
      tbody
        tr(v-for="(item, index) in items")
          td(:class="!item.counts ? 'line-through' : ''") {{ item.hood==null ? $I18n.t('vue.orderables_list_panel.extra') : $I18n.t('vue.orderables_list_panel.service') }}
          td(:class="!item.counts ? 'line-through' : ''") {{ item.name }}
          td(:class="!item.counts ? 'line-through' : ''") {{ item.hood==null ? "" : item.hood.equipment_under }}
          
          td(
            v-if="record.state.name != 'COMPLETED' && record.state.name != 'CANCELED'"
            :class="!item.counts ? 'line-through' : ''"
          )
            input(type="number", v-model="items[index].quantity", class="appearance-none border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline")
          td(
            v-else
            :class="!item.counts ? 'line-through' : ''"
          ) {{ item.quantity }}
          
          td(
            v-if="record.state.name != 'COMPLETED' && record.state.name != 'CANCELED'"
            :class="!item.counts ? 'line-through' : ''"
          ) $
            input(type="number", v-model="items[index].price", step="0.01", class="appearance-none border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline")
          td(
            v-else
            :class="!item.counts ? 'line-through' : ''"
          ) ${{ item.price }}
          
          td(:class="!item.counts ? 'line-through' : ''") {{ item.amount != '0.00' ? '$'+`${item.amount}` : '-' }}
          td
            .app-buttons-row(class='justify-end')
              app-icon-btn(
                v-if="record.state.name != 'COMPLETED' && record.state.name != 'CANCELED'"
                v-show="enable_button_order_item_save(index)"
                data-kind="link_zoom",
                @click.native="updateItem(items[index], inputs[index])",
                data-icon="fa fa-floppy-o",
                data-label="Save",
                :data-title="$I18n.t('vue.orderables_list_panel.update_order_item')"
              )
              delete-orderable-modal(
                v-if="record.state.name != 'COMPLETED' && record.state.name != 'CANCELED'"
                :data-id='item.id'
                :data-item='item'
                @deleted='deleteItem'
              )
        tr
          td(colspan="7")
        tr
          td(colspan="4")
          th {{$I18n.t('vue.orderables_list_panel.total_order_price')}}
          
          td(
            v-if="record.state.name != 'COMPLETED' && record.state.name != 'CANCELED'"
          ) $
            input(type="number", v-model="order.amount_edited", step="0.01", class="appearance-none border border-gray-300 rounded-md py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-indigo-500 focus:shadow-outline")
          td(
            v-else
          ) ${{ order.amount_edited }}

          
          td
            app-icon-btn(
              v-if="record.state.name != 'COMPLETED' && record.state.name != 'CANCELED'"
              v-show="enable_button_total_order_price_save()"
              data-kind="link_zoom",
              @click.native="updateTotalOrderPrice()",
              data-icon="fa fa-floppy-o",
              data-label="Save",
              :data-title="$I18n.t('vue.orderables_list_panel.update_total_order_price')"
            )
</template>

<script>
import CoreComponents from "../core";
import { BackendService } from "../../services";
import CreateOrderableModal from './create-orderable-modal.vue';
import DeleteOrderableModal from './delete-orderable-modal.vue'
const { ActiveValue } = require("../../core/active_support");
export default {
  props: {
    dataId: String,
    dataModel: String,
    dataRecord: String,
    dataItems: String,
    dataAmount: String,
    dataItemKinds: String,
    dataMaintenanceKinds: String,
    dataMaintenanceComplementKinds: String,
    dataOrderableKinds: String,
    dataSubsidaryHoods: String,
  },
  components:{
    'create-orderable-modal': CreateOrderableModal,
    'delete-orderable-modal': DeleteOrderableModal,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    return {
      order: {
        id: this.dataId,
        model: this.dataModel,
        amount: (this.dataAmount*100/100).toFixed(2),
        amount_edited: (this.dataAmount*100/100).toFixed(2),
      },
      items: JSON.parse(this.dataItems),
      inputs: JSON.parse(this.dataItems),
      values: {
        item: {
          kind: "",
          price: null,
          quantity: null,
          orderable: {},
          hood: null,
        },
        selected: ActiveValue().setup((i)=>{
        })
      },
      options: {
        orderables: [
          { label: this.$I18n.t('vue.orderables_list_panel.service'), value: "maintenances" },
          { label: this.$I18n.t('vue.orderables_list_panel.extra'),   value: "maintenance_complements" },
        ],
        itemKinds: JSON.parse(this.dataItemKinds)
      },
    };
  },
  methods: {
    enable_button_total_order_price_save: function(){
      let result = 0
      let mount_orig = (this.order.amount*100/100).toFixed(2)
      let mount_edit = (this.order.amount_edited*100/100).toFixed(2)
      if ( mount_orig != mount_edit ){
        result = 1
      }else{
        result = 0
      }
      return result
    },
    updateTotalOrderPrice: function(){
      return BackendService.order_update_total_price(
        JSON.stringify({
          id: this.order.id,
          price: this.order.amount_edited,
        })
      ).then((response) => {
        if (response.isError){
          //Error
        }
        if (response.isOk){
          this.order.amount=response.order.table.price
          this.order.amount_edited=this.order.amount
          location.reload();
        }
      });
    },
    enable_button_order_item_save: function(index){
      let result = 0
      if (this.items[index].price != this.inputs[index].price || this.items[index].quantity != this.inputs[index].quantity){
        result = 1
      }else{
        result = 0
      }
      return result
    },
    updateItem: function (item, input) {
      BackendService.order_update_item(
        JSON.stringify({
          id: item.id,
          counts: item.counts,
          quantity: item.quantity,
          price: item.price,
        })
      ).then((response) => {
        if (response.isError){
          //Error
        }
        if (response.isOk){
          item.price=response.item.table.price
          input.price=item.price
          item.quantity=response.item.table.quantity
          input.quantity=item.quantity
          item.amount=response.item.table.amount
        }
      });
    },
    deleteItem: function (orderable_item) {
      BackendService
        .delete_item_order(orderable_item.id)
        .then((response) => {
          if (response.isOk) {
            let price_item = orderable_item.amount
            this.order.amount_edited = Number(this.order.amount_edited) - Number(price_item)
            this.updateTotalOrderPrice()
            .then((response) => {
              location.reload();
            })
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    CreateItem: function (params) {
      BackendService.create_item_order(params).then(
        (response) => {
          if (response.isOk) {
            let price_new_item=0;
            let quantity_new_item = 0;
            let total_new_item = Number(this.order.amount_edited);
            response.array_created.forEach(element => {
              price_new_item = Number(element.order_item.price)
              quantity_new_item = Number(element.order_item.quantity)
              total_new_item += price_new_item * quantity_new_item
            });
            this.order.amount_edited = total_new_item
            this.updateTotalOrderPrice()
            .then((response) => {
              location.reload();
            })
          }
        }
      );
    },
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  }
};
</script>