<template lang="pug">
  button(:class="kind" :target='dataTarget' :title='dataTitle')
    i(:class='icon')
    span(class='ml-2') {{label}}
</template>

<script>
const kinds = {
  link: 'app-btn-link-primary',
  link_zoom: 'app-btn-link-primary custom-zoom-in',
  btn:  'app-btn-primary'
}
export default {
  props: ['dataKind', 'dataIcon', 'dataLabel', 'dataTitle', 'dataTarget'],
  data: function(){
    return {
      icon: this.dataIcon,
      label: this.dataLabel,
      target: this.dataTarget,
      kind: kinds[this.dataKind]
    }
  }
}
</script>
