<template lang="pug">
div
  app-icon-btn(
    @click.native="showModal",
    data-kind="link",
    data-icon="fa fa-plus",
    :data-label="$I18n.t('vue.create_subsidary_modal.label_add_subsidary')"
  )
  app-modal(
    :showing="controls.modal.showing",
    @showing="controls.modal.showing = $event"
  )
    template(#header)
      p {{ labels.modal_title }}

    template(#body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        div(class="text-right")
          input(
            class="form-check-input"
            type="checkbox"
            id="checkbox_ccd"
            @click="check_copy_customer_data_from_customer()"
          )
          label(
            class="form-check-label tw-ml-2"
            for="checkbox_ccd"
          ) {{$I18n.t('vue.create_subsidary_modal.data_from_customer')}}
        app-form.tw-py-4(v-if="!state.sending", v-bind:grid="grid_subsidary")
        .h5.pt-4.tw-text-gray-500.tw-text-left
          h5.h5.pt-4 {{ $I18n.t('vue.create_subsidary_modal.subtitle_address') }}
          hr
        app-form.tw-py-4(v-if="!state.sending", v-bind:grid="grid_address")
        .h5.pt-4.tw-text-gray-500.tw-text-left
            h5.h5.pt-4 {{ $I18n.t('vue.create_subsidary_modal.subtitle_contact') }}
            hr
        div(class="text-right")
          input(
            class="form-check-input"
            type="checkbox"
            id="checkbox_ccv"
            @click="check_copy_contacts_from_vendor()"
          )
          label(
            class="form-check-label tw-ml-2"
            for="checkbox_ccv"
          ) {{$I18n.t('vue.create_subsidary_modal.contacts_from_vendor')}}
        div(v-if="!controls.checkbox.copy_contacts_from_vendor.checked")
          app-form.tw-py-4(v-if="!state.sending", v-bind:grid="grid_contact")
        .h5.pt-4.tw-text-gray-500.tw-text-left
            h5.h5.pt-4 {{ $I18n.t('vue.create_subsidary_modal.subtitle_notes') }}
            hr
        app-form.tw-py-4(v-if="!state.sending", v-bind:grid="grid_notes")
    template(#footer)
      .app-buttons-row.justify-end(v-if="!state.sending")
        app-icon-btn(
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="create",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>

<script>
import { ActiveValue } from "../../core/active_support";
import { BackendService } from "../../services";
import CoreComponents from "../core";
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components: {
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
  },
  props: {
    dataId: String,
    dataCustomer: String,
    dataModel: String,
    dataNoteKindVisible: String,
    dataInvoiceTarget: String,
  },
  data: function () {
    return {
      record: {
        id: this.dataId,
        customer: JSON.parse(this.dataCustomer),
        model: this.dataModel,
      },
      controls: {
        modal: {
          showing: false,
        },
        checkbox: {
          copy_contacts_from_vendor: {
            checked: false
          },
          copy_customer_data_from_customer: {
            checked: false
          },
        },
      },
      state:{
        sending: false
      },
      labels: {
        modal_title: this.$I18n.t("vue.create_subsidary_modal.label_add_subsidary"),
        clear_btn: this.$I18n.t("vue.create_subsidary_modal.button_clear"),
        cancel_btn: this.$I18n.t("vue.create_subsidary_modal.button_cancel"),
        send_btn: this.$I18n.t("vue.create_subsidary_modal.button_create"),
      },
      values: {
        //Restaurant
        name: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.create_subsidary_modal.label_name"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        aka: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.create_subsidary_modal.label_aka"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        subsidary_phone: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.create_subsidary_modal.label_phone"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        turn_around_time: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_turn_around_time"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("number");
        }),
        fax_number: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_fax"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        keys: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_keys"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        alarm: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_alarm"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        hours_of_operation: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_hours_of_operation"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        invoice_target: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_invoice_tarjet"));
          i.setValue("Restaurant");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataInvoiceTarget));
          i.required();
        }),
        //notes
        service_note_description: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.create_subsidary_modal.label_service_note_description"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("textArea");
        }),
        service_note_visibility: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.create_subsidary_modal.label_service_note_visibility"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataNoteKindVisible));
        }),
        billing_note_description: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.create_subsidary_modal.label_billing_note_description"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("textArea");
        }),
        billing_note_visibility: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.create_subsidary_modal.label_billing_note_visibility"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataNoteKindVisible));
        }),
        work_note_description: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.create_subsidary_modal.label_work_note_description"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("textArea");
        }),
        work_note_visibility: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.create_subsidary_modal.label_work_note_visibility"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataNoteKindVisible));
        }),
        //address
        street_address: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_address_street")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        secundary_address: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_address_apartament")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.optional();
        }),
        city: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_address_city")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        state_code: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_address_state")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        zip_code: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_address_zipCode")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        //contact
        role: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_contact_role")
          );
          i.setValue("");
          i.setInputKind("text");
        }),
        first_name: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_contact_first_name")
          );
          i.setInputKind("text");
        }),
        middle_name: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_contact_middle_name")
          );
          i.setInputKind("text");
        }),
        last_name: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_contact_last_name")
          );
          i.setInputKind("text");
        }),
        email: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_contact_email")
          );
          i.setKind("email");
          i.setInputKind("text");
        }),
        phone_kind: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.create_subsidary_modal.label_contact_phone_kind")
          );
          i.setValue("");
          i.setInputKind("text");
        }),
        phone_number: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t(
              "vue.create_subsidary_modal.label_contact_phone_number"
            )
          );
          i.setInputKind("text");
        }),
      },
    };
  },
  computed: {
    grid_subsidary: function () {
      return [
        [this.values.name,                this.values.aka,                ""],
        [this.values.subsidary_phone,     this.values.fax_number,         this.values.turn_around_time],
        [this.values.keys,                this.values.alarm,              this.values.hours_of_operation],
        [this.values.invoice_target, "", ""],
      ];
    },
    grid_address: function () {
      return [
        [this.values.street_address,  this.values.secundary_address,   this.values.city],
        [this.values.state_code,      this.values.zip_code],
      ];
    },
    grid_contact: function () {
      return [
        [this.values.role, "", ""],
        [this.values.first_name,  this.values.middle_name,    this.values.last_name],
        [this.values.phone_kind,  this.values.phone_number,   this.values.email],
      ];
    },
    grid_notes: function () {
      return [
        [this.values.service_note_description,  this.values.service_note_visibility],
        [this.values.billing_note_description,  this.values.billing_note_visibility],
        [this.values.work_note_description,     this.values.work_note_visibility],
      ];
    },
  },
  methods: {
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    clear: function () {
      this.resetActiveValues(this.values);
      this.controls.checkbox.copy_customer_data_from_customer.checked = false;
      this.controls.checkbox.copy_contacts_from_vendor.checked = false;
      document.getElementById("checkbox_ccd").checked = this.controls.checkbox.copy_customer_data_from_customer.checked;
      document.getElementById("checkbox_ccv").checked = this.controls.checkbox.copy_contacts_from_vendor.checked;
    },
    cancel: function () {
      this.hideModal();
      this.clear(this.values);
    },
    valid: function () {
      let ins = this.values;
      //If notes is blank, option is required
      if (ins['service_note_description'].isPresent()){
        ins['service_note_visibility'].required();
      }else{
        ins['service_note_visibility'].optional();
      }
      if (ins['billing_note_description'].isPresent()){
        ins['billing_note_visibility'].required();
      }else{
        ins['billing_note_visibility'].optional();
      }
      if (ins['work_note_description'].isPresent()){
        ins['work_note_visibility'].required();
      }else{
        ins['work_note_visibility'].optional();
      }
      //Return inputs updated
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    get_notes: function () {
      let array_notes = []
      if (this.values.service_note_description.input != ''){
        array_notes.push({
          kind: 'service',
          description: this.values.service_note_description.input,
          visibility: this.values.service_note_visibility.input,
        })
      }
      if (this.values.billing_note_description.input != ''){
        array_notes.push({
          kind: 'billing',
          description: this.values.billing_note_description.input,
          visibility: this.values.billing_note_visibility.input,
        })
      }if (this.values.work_note_description.input != ''){
        array_notes.push({
          kind: 'work',
          description: this.values.work_note_description.input,
          visibility: this.values.work_note_visibility.input,
        })
      }
      return array_notes;
    },
    stringify: function () {
      const params = {
        id: this.record.id.toString(),
        subsidary: {
          name: this.values.name.input,
          aka: this.values.aka.input,
          state: "ACTIVE",
          phone_number: this.values.subsidary_phone.input,
          turn_around_time: this.values.turn_around_time.input,
          fax_number: this.values.fax_number.input,
          keys: this.values.keys.input,
          alarm: this.values.alarm.input,
          hours_of_operation: this.values.hours_of_operation.input,
          invoice_target: this.values.invoice_target.input,
          copy_contacts_from_vendor: this.controls.checkbox.copy_contacts_from_vendor.checked+"",
          address: {
            street_address: this.values.street_address.input,
            secundary_address: this.values.secundary_address.input,
            city: this.values.city.input,
            state_code: this.values.state_code.input,
            zip_code: this.values.zip_code.input,
          },
          notes: this.get_notes()
        },
      };
      //Agrega contacto, solo si existe algun dato
      let ins = this.values;
      let contact_keys = [
        "role",
        "first_name",
        "middle_name",
        "last_name",
        "phone_kind",
        "phone_number",
        "email",
      ];
      let some_contact_inputs_present = contact_keys
        .map((k) => ins[k].isPresent())
        .some((v) => v === true);
      if (some_contact_inputs_present) {
        params.subsidary.contact = {
          role_name: this.values.role.input.toString(),
          first_name: this.values.first_name.input,
          middle_name: this.values.middle_name.input,
          last_name: this.values.last_name.input,
          email: this.values.email.input,
          phone_kind: this.values.phone_kind.input.toString(),
          phone_number: this.values.phone_number.input,
        };
      }
      


      return JSON.stringify(params);
    },
    create: function () {
      let self = this;
      if (self.valid()) {
        this.state.sending = true;
        BackendService.create_customer_subsidary(self.stringify())
          .then((response) => {
            self.result = response;
            if (response.isOk) {
              location.reload();
            }
          })
          .catch(() => {
            self.result.isError = true;
            self.result.notice = "error_promise";
          })
          .finally(() => {
            //self.state.sending = false;
          });
      }
    },
    check_copy_contacts_from_vendor:function (){
      this.controls.checkbox.copy_contacts_from_vendor.checked = !this.controls.checkbox.copy_contacts_from_vendor.checked
      if (this.controls.checkbox.copy_contacts_from_vendor.checked){
        let ins = this.values;
        let contact_keys = [
          "role",
          "first_name",
          "middle_name",
          "last_name",
          "phone_kind",
          "phone_number",
          "email",
        ];
        let some_contact_inputs_present = contact_keys
          .map((k) => ins[k].setValue(""))
      }
      
    },
    check_copy_customer_data_from_customer:function (){
      this.controls.checkbox.copy_customer_data_from_customer.checked = !this.controls.checkbox.copy_customer_data_from_customer.checked
      let ins = this.values;
      let subsidary_keys = [
        "name",
        "aka",
      ];
      let address_keys = [
        "street_address",
        "secundary_address",
        "city",
        "state_code",
        "zip_code",
      ]
      let phone_keys = [
        "subsidary_phone"
      ]
      if (this.controls.checkbox.copy_customer_data_from_customer.checked){
        let some_customer_inputs_present = subsidary_keys
          .map((k) => ins[k]
            .setValue(this.record.customer[k]));
        let some_address_inputs_present = address_keys
          .map((k) => ins[k]
            .setValue(this.record.customer.address[k]))
        let phone_inputs_present = phone_keys
          .map((k) => ins[k]
            .setValue(this.record.customer.phone.number))
        this.controls.checkbox.copy_contacts_from_vendor.checked = false;
      }else{
        let some_customer_inputs_present = subsidary_keys
          .map((k) => ins[k].setValue(""))
        let some_address_inputs_present = address_keys
          .map((k) => ins[k].setValue(""))
        let some_phone_inputs_present = phone_keys
          .map((k) => ins[k].setValue(""))
        this.controls.checkbox.copy_contacts_from_vendor.checked = true;
      }
      document.getElementById("checkbox_ccv").checked = !this.controls.checkbox.copy_contacts_from_vendor.checked;
      this.check_copy_contacts_from_vendor();
    },
  },
};
</script>
