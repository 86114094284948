function Result(data,error){
  this.data=data;
  this.error=error;
}
Result.prototype.isError=function(){
  return (this.error !== undefined && this.data === undefined);
}
Result.prototype.isOk=function(){
  return (this.error === undefined && this.data !== undefined);
}
module.exports=Result;