<template lang='pug'>
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display" 
    v-bind:alert="controls.alert"
  )

  div(class="tw-flex tw-flex-row tw-justify-between")
    div
      h5(class="h5 tw-text-gray-500") {{$I18n.t('vue.service_detail.title')}}
    
    //Barra de botones  
    div(v-if="order.state.name!='COMPLETED' && order.state.name!='CANCELED'" class="tw-flex tw-flex-row")
      div(v-if="record.id != undefined" class="tw-flex tw-flex-row justify-end")
        div( @mouseover="showWarningDelete = true" @mouseout="showWarningDelete = false")
          app-icon-btn(
            data-kind="link",
            @click.native="removeServiceFromOrder(record.id)",
            data-icon="fa fa-window-close",
            :data-label="$I18n.t('vue.service_detail.label_delete_service')",
            data-title=""
          )
        div( )
          app-icon-btn(
            data-kind="link",
            @click.native="reasignar_service()",
            data-icon="fa fa-exchange",
            :data-label="$I18n.t('vue.service_detail.label_assign_service')",
            data-title=""
          )
      div(v-if="showTableServices == true")
        a(class="app-btn-link-primary" @click="cancel_assignment()")
          i(class="fa fa-window-close tw-mx-1")
          span {{$I18n.t('vue.service_detail.label_cancel_assignment')}}
  hr
  div(class="container-fluid")
    div(v-if="record.id != undefined")
      table(class="table table-bordered")
        thead
          tr
            th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_service')}}
            th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_frequency')}}
            th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_frequency_month')}}
            th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_base_price')}}
            th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_breakdown')}}
            th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_total')}}
            th
        tbody
          tr
            td {{ record.service }}
            td(class="text-center") {{ record.frequency }}
            td(class="text-center") {{ record.frequency_month }}
            td(class="text-center") {{ record.base_price }}
            td {{ record.breakdown }}
            td(class="text-center") {{ parseFloat(record.total.replace("$", "").replace(",", "").replace(" ", "")).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) }}
            td
              div( @mouseover="showWarningEdit = true" @mouseout="showWarningEdit = false")
                edit-service-modal(
                  v-if="order.state.name!='COMPLETED' && order.state.name!='CANCELED'"
                  :data-icon='"fa fa-pencil"'
                  :data-label="$I18n.t('vue.service_detail.edit')"
                  :data-mode='"edit"'
                  :data-item='JSON.stringify(record)'
                  @updated='updateService'
                )
    div(v-else)
      div(v-if="showTableServices == false")
        div(class="container mt-4 d-flex justify-content-center align-items-center flex-column")
          div(role="alert" class="alert alert-warning text-center" style="width: 80%;")
            span {{$I18n.t('vue.service_detail.messages.no_service_assigned')}}
          div(v-if="order.state.name!='COMPLETED' && order.state.name!='CANCELED'")
            button(class="btn btn-primary" @click="showTableServices = true")
              span {{$I18n.t('vue.service_detail.assign_service')}}
      div(v-else)
        div(class="container mt-4")
            h2(class="mb-4") {{$I18n.t('vue.service_detail.title_list_services')}}
            div(class="tw-flex tw-flex-row justify-end")
              edit-service-modal(
                :data-icon='"fa fa-plus"'
                :data-mode='"new"'
                :data-item='JSON.stringify([])'
                @updated='createNewService'
                :data-label="$I18n.t('vue.service_detail.label_create_service')"
              )
            div(v-if="other_services.length==0" class="container-fluid")
              p(class="message-search border rounded p-3") {{$I18n.t('vue.service_detail.messages.no_services_yet')}}
            div(v-else)
              table(class="table table-bordered")
                thead
                  tr
                    th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_service')}}
                    th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_frequency')}}
                    th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_frequency_month')}}
                    th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_base_price')}}
                    th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_breakdown')}}
                    th(scope="col") {{$I18n.t('vue.service_detail.table_services_header_total')}}
                    th(scope="col") 
                tbody
                  tr(v-for="(servicio, index) in other_services" :key="index")
                    td {{ servicio.table.service }}
                    td(class="text-center") {{ servicio.table.frequency }}
                    td(class="text-center") {{ servicio.table.frequency_month }}
                    td(class="text-center") {{ servicio.table.base_price }}
                    td {{ servicio.table.breakdown }}
                    td(class="text-center") {{ servicio.table.total }}
                    td(class="text-center")
                      div(v-if="servicio.table.id == service_init_id")
                        p(class="text-success") {{$I18n.t('vue.service_detail.messages.this_service_is_selected')}}
                      div(v-else)
                        button(class="btn btn-primary" @click="assignServiceToOrder(servicio)")
                          span {{$I18n.t('vue.service_detail.select')}}
            // Spinner Loading
            div(v-if="state.sending" class="tw-flex tw-flex-row justify-end")
              div(class="spinner-border spinner-border-sm" role="status")
  div(class="tw-flex tw-flex-row justify-end")
    div(v-show="showWarningEdit")
      div(class="alert alert-warning" role="alert")
        span {{$I18n.t('vue.service_detail.messages.modifying_affect_restaurant')}}
    div(v-show="showWarningDelete")
      div(class="alert alert-danger" role="alert")
        span {{$I18n.t('vue.service_detail.messages.remove_affect_order')}}
      

</template>

<script>
import CoreComponents from '../core';
const {ActiveValue}=require('../../core/active_support');
import {BackendService} from '../../services';
import EditServiceModal from './edit-service-modal.vue'

export default {
  props:['dataRecord', 
         'dataOtherServices',
         'dataOrder'
  ],
  components:{
    'edit-service-modal':EditServiceModal,
    ...CoreComponents
  },
  data:function(){
    this.order = JSON.parse(this.dataOrder)
    this.record = this.dataRecord != undefined ? JSON.parse(this.dataRecord) : {      id:undefined,      service:undefined,      frequency:undefined,      frequency_month:undefined,      base_price:undefined,      breakdown:undefined,      total:undefined,   deleted_at:undefined    };
    let self=this;
    return {
      service_init_id: this.record.id,
      other_services: JSON.parse(this.dataOtherServices),
      servicioSeleccionado: null,
      showWarningEdit: false,
      showWarningDelete: false,
      showTableServices: false,
      state:{
        show:true,
        edit:false,
        sending:false,
      },
      controls:{
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      values:{
        service: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.service_detail.label_service_name'));
          i.setValue(self.record.service);
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        }),
        frequency: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.service_detail.label_service_frequency'));
          i.setValue(self.record.frequency);
          i.setKind('string');
          i.setInputKind('text');
          i.optional();
        }),
        frequency_month: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.service_detail.label_service_frequency_month'));
          i.setValue(self.record.frequency_month);
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        }),
        base_price: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.service_detail.label_service_base_price'));
          i.setValue(self.record.base_price);
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        }),
        breakdown: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.service_detail.label_service_breakdown'));
          i.setValue(self.record.breakdown);
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        }),
        total: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.service_detail.label_service_total'));
          i.setValue(self.record.total);
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        })
      }
    }
  },
  computed:{
    grid:function(){
      return [
        [this.values.service,     this.values.frequency, this.values.frequency_month],
        [this.values.base_price,  this.values.breakdown, this.values.total]
      ]
    }
  },
  methods:{
    removeServiceFromOrder() {
      let service = {
        table: {
          id: ""
        }
      }
      this.assignServiceToOrder(service)
    },
    assignServiceToOrder(service) {
      const self = this;
      this.state.sending = true;
      let params = {
        order_id: this.order.id,
        service_id: service.table.id
      }
      BackendService
        .assign_service_to_order(JSON.stringify(params))
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    createNewService:function(item){
      const self = this;
      this.state.sending = true;
      let params = JSON.stringify({
        id: this.order.subsidary.id,
        service: JSON.parse(item)
      })
      BackendService
        .create_subsidary_service(params)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    updateService:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .service_update(params)
        .then((response) => {
          if (response.isOk) {
            let param = JSON.parse(params)
            console.log(param)
            let service = {
              table: {
                id: param.id
              }
            }
            this.assignServiceToOrder(service)
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    reasignar_service:function(params){
      const self = this;
      self.showTableServices = true;
      self.record.id = undefined;
    },
    cancel_assignment:function(params){
      const self = this;
      self.showTableServices = false; 
      self.record.id = self.service_init_id;
    },
  }
}
</script>
