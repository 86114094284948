<template lang="pug">

div
  app-icon-btn(
    @click.native="showModal"
    data-kind="link",
    :data-icon="dataIcon",
    :data-label="dataLabel",
  )
  // Modal
  app-modal(
    :showing='controls.modal.showing'
    @showing="controls.modal.showing = $event"
  )

    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}
    
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        app-form(
          v-bind:grid="form_grid"
          class='tw-py-4'
        )
        input(
          class="form-check-input"
          type="checkbox"
          id="checkbox"
          :checked='controls.checkbox.other_periodicity.checked'
          @click="check_other_periodicity()"
        ) 
        label(
          class="form-check-label tw-ml-2"
          for="checkbox"
        ) {{$I18n.t('vue.edit_service_modal.other_frecuency')}}
        div.row.col-6.tw-ml-2.app-card(
          v-if="controls.checkbox.other_periodicity.checked"
        ) {{$I18n.t('vue.edit_service_modal.select_months')}}
          div(v-for="(month, key) in controls.checkbox.months")
            input(
              class="form-check-input"
              type="checkbox"
              v-model='month.checked'
            ) 
            span {{ month.label }}
      
    template(v-slot:footer)
      .app-buttons-row( v-if="!state.sending" class='justify-end' )
        app-icon-btn(
          v-if="dataMode=='new'"
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="update",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>
<script>

import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default{
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components:{
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataItem: String,
    dataIcon: String,
    dataLabel: String,
    dataMode: String,
    dataHideModal: String,
  },
  data:function(){
    return {
      state:{
        sending: false
      },
      controls:{
        checkbox:{
          other_periodicity:{
            checked: false
          },
          months: [
            {value:"01",   label:this.$I18n.t('vue.edit_service_modal.january'),   checked:false},
            {value:"02",   label:this.$I18n.t('vue.edit_service_modal.february'),  checked:false},
            {value:"03",   label:this.$I18n.t('vue.edit_service_modal.march'),     checked:false},
            {value:"04",   label:this.$I18n.t('vue.edit_service_modal.april'),     checked:false},
            {value:"05",   label:this.$I18n.t('vue.edit_service_modal.may'),       checked:false},
            {value:"06",   label:this.$I18n.t('vue.edit_service_modal.june'),      checked:false},
            {value:"07",   label:this.$I18n.t('vue.edit_service_modal.july'),      checked:false},
            {value:"08",   label:this.$I18n.t('vue.edit_service_modal.august'),    checked:false},
            {value:"09",   label:this.$I18n.t('vue.edit_service_modal.september'), checked:false},
            {value:"10",   label:this.$I18n.t('vue.edit_service_modal.october'),   checked:false},
            {value:"11",   label:this.$I18n.t('vue.edit_service_modal.november'),  checked:false},
            {value:"12",   label:this.$I18n.t('vue.edit_service_modal.december'),  checked:false},
          ]
        },
        modal:{
          showing: false
        }
      },
      values:{
        service: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_service_modal.label_service_periodicity"));
          i.setValue(JSON.parse(this.dataItem).service);
          i.setKind("string");
          i.setInputKind("textArea");
          i.required();
        }),
        frequency: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_service_modal.label_service_frequency"));
          i.setValue(JSON.parse(this.dataItem).frequency);
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        base_price: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_service_modal.label_service_base_price"));
          i.setValue(JSON.parse(this.dataItem).base_price);
          i.setKind("string");
          i.setInputKind("textArea");
          i.required();
        }),
        breakdown: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_service_modal.label_service_breakdown"));
          i.setValue(JSON.parse(this.dataItem).breakdown);
          i.setKind("string");
          i.setInputKind("textArea");
          i.required();
        }),
        total: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_service_modal.label_service_total"));
          i.setValue(JSON.parse(this.dataItem).total);
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
      },
      labels:{
        modal_title: this.$I18n.t('vue.edit_service_modal.modal_title'),
        clear_btn: this.$I18n.t('vue.edit_service_modal.button_clear'),
        cancel_btn: this.$I18n.t('vue.edit_service_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.edit_service_modal.button_save')
      }
    }
  },
  computed:{
    form_grid:function(){
      return [
        [this.values.service],
        [this.values.base_price],
        [this.values.breakdown],
        [this.values.frequency, this.values.total]
      ]
    }
  },
  mounted: function(){
    this.load_checkbox_months()
  },
  methods:{
    check_other_periodicity: function () {
      this.controls.checkbox.other_periodicity.checked = !this.controls.checkbox.other_periodicity.checked
      this.controls.checkbox.months.forEach(element => {element.checked=false});
      if (this.controls.checkbox.other_periodicity.checked){
        this.values.frequency.optional();
        this.values.frequency.notEditable();
        this.values.frequency.setValue("");
      }else{
        this.values.frequency.required();
        this.values.frequency.isEditable();
        this.values.frequency.setValue(JSON.parse(this.dataItem).frequency);
      }
    },
    load_checkbox_months: function () {
      let x = ""
      let values = JSON.parse(this.dataItem).frequency_month
      if(values != "" && values != null && values != undefined){
        this.controls.checkbox.other_periodicity.checked = false
        this.check_other_periodicity()
      }
      if (this.controls.checkbox.other_periodicity.checked){
        var months = values.split(',');
        months.forEach( month_saved => {
          this.controls.checkbox.months.forEach( month => {
            if(month_saved==month.value){
              month.checked = true
            }
          })
        })

      }
    },
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function(){
      this.controls.modal.showing = false;
    },
    clear: function(){
      this.resetActiveValues(this.values);
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.hideModal();
      this.resetActiveValues(this.values);
      if (this.dataMode=='new') {
      }else{
        this.values.service.setValue(JSON.parse(this.dataItem).service);
        this.values.frequency.setValue(JSON.parse(this.dataItem).frequency);
        this.values.base_price.setValue(JSON.parse(this.dataItem).base_price);
        this.values.breakdown.setValue(JSON.parse(this.dataItem).breakdown);
        this.values.total.setValue(JSON.parse(this.dataItem).total);
        this.controls.checkbox.months.forEach(element => {element.checked=false});
        this.load_checkbox_months();
      }
    },
    params:function(){
      let string_frequency_month=[]
      if (this.controls.checkbox.other_periodicity.checked){
        this.controls.checkbox.months.forEach( month => {
          if (month.checked){
            string_frequency_month.push(month.value)
          }
        })
      }
      string_frequency_month = string_frequency_month.join(',')
      return JSON.stringify({
        id: JSON.parse(this.dataItem).id,
        service: this.values.service.input.toString(),
        frequency: this.values.frequency.input.toString(),
        frequency_month: string_frequency_month,
        base_price: this.values.base_price.input,
        breakdown: this.values.breakdown.input,
        total: this.values.total.input,
      })
    },
    update:function(){
      let self = this; 
      if(self.areValidActiveValues(this.values)){
        this.state.sending = true;
        let params = this.params()
        this.clear();
        if (this.dataHideModal){
          this.hideModal();
          this.state.sending = false;
        }
        this.$emit("updated", params);        
      }
    }
  }
}
</script>
