<template lang="pug">

.app-card.overflow-x-auto
  div(class='text-center')
    h5(class="h5 py-4 tw-text-gray-500") 
      i(class="app-title-ico-xl fa fa-building-o")
      span {{$I18n.t('vue.customers_active.subtitle_customers_active')}}
  
  // Spinner Loading
  div(v-if="state.loading" class="tw-text-center tw-my-20")
      div(class="spinner-border spinner-border-sm" role="status")
  div(v-else class="text-center")
    p(class='h2') {{items}}

    div
      .flex(class='justify-content-end')
        a(class="app-btn-link-primary" :href="index_customers_path") 
          span {{$I18n.t('vue.customers_active.view_customers')}}
          i(class="fa fa-arrow-right")

</template>
<script>

import CoreComponents from '../core';
const {ActiveValue}=require('../../core/active_support');
const {BackendService,BackendRoutes} = require('app/services');

export default {
  components:{    
  },
  props:{
  },
  data: function(){
    return {
      state: {
        loading: true,
      },
      items: 0,
    }
  },
  computed:{
    emptyItems: function(){
      return this.items === 0;
    },
    index_customers_path:function(){
      return  BackendRoutes.index_customers_path();
    },
  },
  methods:{
    getNumCustomers:function(){
      let params="";
      BackendService
        .get_num_customers(params)
        .then((response) => {
          if (response.isOk) {
            this.state.loading = false;
            this.items = response.num_customers;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
  },
  beforeMount() {
    this.getNumCustomers();
  },
}
</script>
