<template lang="pug">

.app-card.overflow-x-auto
  table(v-if='!emptyItems' class="table table-hover")
    thead
      th {{$I18n.t('vue.invoices_detail_panel.table_header_document_number')}}
      th {{$I18n.t('vue.invoices_detail_panel.table_header_date_invoice')}}
      th {{$I18n.t('vue.invoices_detail_panel.table_header_price')}}
      th {{$I18n.t('vue.invoices_detail_panel.table_header_porcent_credit_card')}}
      th {{$I18n.t('vue.invoices_detail_panel.table_header_price_with_credit_card')}}
      th {{$I18n.t('vue.invoices_detail_panel.table_header_cod')}}
      th {{$I18n.t('vue.invoices_detail_panel.table_header_amount_paid')}}
      th {{$I18n.t('vue.invoices_detail_panel.table_header_outstanding_balance')}}
      th {{$I18n.t('vue.invoices_detail_panel.table_header_status')}}
      th
    tbody
      tr(v-for="item in all_links")
        td {{item.document_number==''? item.id : item.document_number}}
        td {{item.date_invoice}}
        td {{item.price}}
        td {{item.porcent_credit_card}}
        td {{item.price_with_credit_card}}
        td {{item.cod==0 ? "COD" : item.cod}}
        td {{item.amount_paid}}
        td {{item.outstanding_balance}}
        td {{$I18n.t('vue.states.'+item.status.toLowerCase())}}
        td
          .app-buttons-row.justify-end
            app-icon-link(
              data-kind="link",
              :href="item.paths.show"
              data-icon="fa fa-eye",
              data-label="",
              :data-title="$I18n.t('vue.invoices_detail_panel.view_invoice')"
            )
  div(v-if='!emptyItems')
    .row
      .col-9
      .col-3
        .row
          table(class="table table-bordered table-hover")
            tbody
              tr
                th.flex(class='justify-end')  {{$I18n.t('vue.invoices_detail_panel.total_invoices')}}
                th(class="text-dark") {{calcularTotal()}}
              tr(v-if="total_amount_paid")
                th.flex(class='justify-end')  {{$I18n.t('vue.invoices_detail_panel.total_paid')}}
                th(class="text-success") {{total_amount_paid}}
              tr(v-if="total_amount_paid")
                th.flex(class='justify-end')  {{$I18n.t('vue.invoices_detail_panel.total_unpaid')}}
                th(class="text-danger")  {{total_amount_unpaid}}


  
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.invoices_detail_panel.empty_items')}}

</template>
<script>

import {BackendService} from '../../services';
import CoreComponents from "../core";

export default {
  components:{
    "app-icon-link": CoreComponents.AppIconLink,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataOrder: String,
    dataItems: String,
    dataTotales: String,
  },
  data: function(){
    return {
      state: {
        confirmed: false,
      },
      record:{
        id: this.dataId,
        model: this.dataModel
      },
      items: JSON.parse(this.dataItems),
      total_amount_paid: (!this.dataTotales || /^\s*$/.test(this.dataTotales)) ? "" : JSON.parse(this.dataTotales).paid,
      total_amount_unpaid: (!this.dataTotales || /^\s*$/.test(this.dataTotales)) ? "" : JSON.parse(this.dataTotales).unpaid,
      data_order: JSON.parse(this.dataOrder),
      empty_item: {
        link:"",
        description:""
      }
    }
  },
  computed:{
    all_links: function(){
      var items = []
      items = this.items
      return items
    },
    emptyItems: function(){
      return this.all_links.length === 0;
    },
  },
  methods:{
    showLink:function(path){
    },
    retrieveLinks:function(){
      let self = this; 
      BackendService
        .links(JSON.stringify({
          query:{
            linkable_type: self.record.model,
            linkable_id: self.record.id
          }
        }))
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            self.items = response.links;
          }
        })
        .finally(()=>{
        })
    },
    calcularTotal:function(){
      // Usamos reduce para iterar sobre el array y sumar los precios
      let total_invoices = this.all_links.reduce((total, item) => {
        // Verificamos si el estado del elemento es diferente de "CANCELED"
        if (item.status !== "CANCELED") {
          // Si es diferente, sumamos su precio al total
          return total + parseFloat(item.price.replace("$", "").replace(",", "").replace(" ", ""));
        } else {
          // Si es "CANCELED", simplemente retornamos el total sin modificarlo
          return total;
        }
      }, 0); // Inicializamos el total en 0
      return total_invoices.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  }
}
</script>
