<template lang="pug">
  div
    //Notification Alert
    Notificationalert(
      v-if="controls.alert.display" 
      v-bind:alert="controls.alert"
    )

    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

    // FORM
    div(v-else)
      Detailform(v-bind:grid="grid_account")

      // BUTTONS
      div(v-if="!state.sending" class="tw-text-right tw-mt-7")
        div(class="form-group row")
          div(class="row")
            div(class="col")
              a(@click="clear" class="app-btn-link-primary") 
                i(class="fa fa-eraser mr-2")
                span {{$I18n.t('vue.new_account_form.button_clear')}}
              a(class="app-btn-link-primary" :href="index_accounts_path") 
                i(class="fa fa-times mr-2")
                span {{$I18n.t('vue.new_account_form.button_cancel')}}
              button(@click="send" class="app-btn-primary") 
                i(class="fa fa-save mr-2")
                span {{$I18n.t('vue.new_account_form.button_create')}}

</template>
<script>

import CoreComponents from '../core';
const {ActiveValue}=require('../../core/active_support');
const {BackendService,BackendRoutes} = require('app/services');

export default {
  props:{
    dataRoles: String
  },
  data: function(){
    return {
      controls:{
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      inputs:{
        first_name: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.new_account_form.label_contact_fistName'));
          i.setValue('');
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        }),
        middle_name: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.new_account_form.label_contact_middleName'));
          i.setValue('');
          i.setKind('string');
          i.setInputKind('text');
        }),
        last_name: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.new_account_form.label_contact_lastName'));
          i.setValue('');
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        }),
        email: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.new_account_form.label_contact_email'));
          i.setValue('');
          i.setKind('email');
          i.setInputKind('text');
          i.required();
        }),
        role: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.new_account_form.label_contact_role'));
          i.setValue('');
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataRoles));
          i.required();
        }),
      },
      state:{
        compleated: true,
        sending: false
      },
      result:{
        isError: undefined,
        isOk: undefined,
        key: undefined,
        notice: undefined,
        account: {
          id: undefined
        }
      }
    }
  },
  computed:{
    index_accounts_path:function(){
      return  BackendRoutes.index_accounts_path();
    },
    account_path:function(){
      return  BackendRoutes.account_path(this.result.account.id);
    },
    grid_account:function(){
      return [
        [this.inputs.role,              this.inputs.email,            ''                    ],
        [this.inputs.first_name,        this.inputs.middle_name,      this.inputs.last_name ]
      ]
    }
  },
  methods:{
    valid:function(){
      let ins=this.inputs;
    },  
    stringify:function(){
      const params = {
        person: {
          first_name: this.inputs.first_name.input,
          middle_name: this.inputs.middle_name.input,
          last_name: this.inputs.last_name.input,
        },
        user: {
          role_id: this.inputs.role.input,
          email: this.inputs.email.input,
          password: "",
          password_confirmation: ""
        }
      }
      return JSON.stringify(params);
    },
    valid:function(){
      return Object.values(this.inputs)
        .map((i) => {
          i.validate();
          return i.hasError();
        })
        .every((e) => e === false);
    },
    send:function(){
      let self = this; 
      if (self.valid()){
        self.state.sending=true;
        BackendService.create_account(self.stringify())
          .then((response)=>{
            self.result = response;
            self.controls.alert.notice = 'vue.new_account_form.result.'+response.notice
            if (response.isOk){
              self.controls.alert.type="success"
              self.controls.alert.message = 'vue.new_account_form.view_detail_text'
              self.controls.alert.link.text = 'vue.new_account_form.show_link'
              self.controls.alert.link.href=this.account_path
              self.clear();
            }else{
              self.controls.alert.type="danger"
            }
          })
          .catch(()=>{
            self.result.isError=true;
            self.controls.alert.type="error"
            self.controls.alert.notice = 'vue.new_account_form.result.error_promise';
          })
          .finally(()=>{
            self.state.sending=false;
            self.controls.alert.display=true
          })
      }
    },
    clear:function(){
      Object
        .values(this.inputs)
        .forEach(v=>v.resetValue());
    }
  },
  components:{...CoreComponents}
}
</script>
