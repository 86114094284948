<template lang="pug">

div
  app-icon-btn(
    @click.native="showModal"
    data-kind="link",
    :data-icon="dataIcon",
    :data-label="dataLabel",
    :data-title="dataTitle"
  )
  // Modal
  app-modal(
    :showing='controls.modal.showing'
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}
    
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        app-form(
          v-if="!state.sending"
          v-bind:grid="form_grid"
          class='tw-py-4'
        )
        div(v-if="!record.present")
          p(class="text-danger") {{$I18n.t('vue.edit_contact_modal.necessary_capture_value')}}
      
    template(v-slot:footer)
      .app-buttons-row( v-if="!state.sending" class='justify-end' )
        app-icon-btn(
          v-if="dataMode=='new'"
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="update",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>
<script>

import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default{
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components:{
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataItem: String,
    dataIcon: String,
    dataLabel: String,
    dataTitle: String,
    dataMode: String,
    dataSendInvoiceOption: String,
  },
  data:function(){
    return {
      record:{
        id: this.dataId,
        model: this.dataModel,
        present: true
      },
      controls:{
        modal:{
          showing: false
        }
      },
      state:{
        sending: false
      },
      values:{
        role: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.edit_contact_modal.role'));
          i.setValue(JSON.parse(this.dataItem).role_name==undefined ? "" : JSON.parse(this.dataItem).role_name);
          i.setInputKind('text');
        }),
        first_name: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.edit_contact_modal.first_name'));
          i.setValue(JSON.parse(this.dataItem).person.first_name==undefined ? "" : JSON.parse(this.dataItem).person.first_name);
          i.setInputKind('text');
        }),
        middle_name: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.edit_contact_modal.middle_name'));
          i.setValue(JSON.parse(this.dataItem).person.middle_name==undefined ? "" : JSON.parse(this.dataItem).person.middle_name);
          i.setInputKind('text');
        }),
        last_name: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.edit_contact_modal.last_name'));
          i.setValue(JSON.parse(this.dataItem).person.last_name==undefined ? "" : JSON.parse(this.dataItem).person.last_name);
          i.setInputKind('text');
        }),
        email: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.edit_contact_modal.email'));
          i.setValue(JSON.parse(this.dataItem).email==undefined ? "" : JSON.parse(this.dataItem).email);
          i.setKind('email');
          i.setInputKind('text');
        }),
        send_invoice: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_contact_modal.send_invoice"));
          i.setValue(JSON.parse(this.dataItem).send_invoice==true ? "true" : "false");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataSendInvoiceOption));
          i.required();
        }),
      },
      labels:{
        modal_title: this.$I18n.t('vue.edit_contact_modal.label_contact'),
        clear_btn: this.$I18n.t('vue.edit_contact_modal.button_clear'),
        cancel_btn: this.$I18n.t('vue.edit_contact_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.edit_contact_modal.button_create')
      }
    }
  },
  computed:{
    form_grid:function(){
      return [
        [this.values.role],
        [this.values.email,      this.values.send_invoice],
        [this.values.first_name, this.values.middle_name,  this.values.last_name],
      ]
    }
  },
  methods:{
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function(){
      this.controls.modal.showing = false;
    },
    clear: function(){
      this.resetActiveValues(this.values);
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.hideModal();
      this.resetActiveValues(this.values);
      if (this.dataMode=='new') {
      }else{
        this.values.role.setValue(JSON.parse(this.dataItem).role_name);
        this.values.email.setValue(JSON.parse(this.dataItem).email);
        this.values.first_name.setValue(JSON.parse(this.dataItem).person.first_name);
        this.values.middle_name.setValue(JSON.parse(this.dataItem).person.middle_name);
        this.values.last_name.setValue(JSON.parse(this.dataItem).person.last_name);
      }
    },
    params:function(){
      return JSON.stringify({
        id: JSON.parse(this.dataItem).id,
        contactable: this.record.model+"#"+this.record.id,
        role_name: this.values.role.input.toString(),
        first_name: this.values.first_name.input,
        middle_name:this.values.middle_name.input,
        last_name:this.values.last_name.input,
        email:this.values.email.input,
        send_invoice:this.values.send_invoice.input,
        phone: {
          kind: "",
          number:"",
        }
      })
    },
    valid: function () {
      let ins = this.values;
      //If some contact values are present all are required otherwise all are optional
      let contact_keys = [
        "role",
        "first_name",
        "middle_name",
        "last_name",
        "email",
      ];
      let contactIsPresent = contact_keys
        .map((k) => ins[k].isPresent())
        .some((v) => v === true);
      if (contactIsPresent) {
        this.record.present = true
      } else {
        this.record.present = false
      }
      return this.record.present
    },
    update:function(){
      let self = this; 
      if(self.valid()){
        self.state.sending=true;
        let params = this.params()
        this.clear();
        //this.hideModal();
        this.$emit("updated", params);        
      }
    }
  }
}
</script>
