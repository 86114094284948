<template lang="pug">

div(class='app-card' style="height: 100%;")
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display",
    v-bind:alert="controls.alert"
  )
  
  // Spinner Loading
  div(v-if="state.loading" class="tw-text-center tw-my-20")
      div(class="spinner-border spinner-border-sm" role="status")
  div(v-else class="")
    table(class="table table-hover")
      thead
        tr
          th(class="tw-text-center ") {{$I18n.t('vue.unbilled_invoices_list.view_header')}}
          th(class="tw-text-center ") {{$I18n.t('vue.unbilled_invoices_list.number_header')}}
          th(class="tw-text-center ") {{$I18n.t('vue.unbilled_invoices_list.restaurant_header')}}
          th(class="tw-text-center ") {{$I18n.t('vue.unbilled_invoices_list.date_header')}}
          th(class="tw-text-center ") {{$I18n.t('vue.unbilled_invoices_list.cod_header')}}
          th(class="tw-text-center ") {{$I18n.t('vue.unbilled_invoices_list.outstanding_balance_header')}}
          th(class="tw-text-center ") {{$I18n.t('vue.unbilled_invoices_list.emails_header')}}
          th(class="tw-text-center ") 
            app-icon-btn(
              v-if="state.all_sending==false"
              data-kind="link",
              @click.native="send_all_mail()",
              data-icon="fa fa-envelope",
              data-label="Send All Emails",
              :data-title="$I18n.t('vue.unbilled_invoices_list.send_all_emails')"
            )
            i(v-else)
              div(class="spinner-border spinner-border-sm" role="status")
      tbody
        tr(v-for="item in items")
          td
            .app-buttons-row.center
              app-icon-link(
                data-kind="link",
                :href="item.value.table.paths.table.show"
                data-icon="fa fa-eye",
                data-target="_blank"
                data-label="",
                :data-title="$I18n.t('vue.unbilled_invoices_list.view_invoice')"
              )
          td {{item.value.table.document_number==''? item.value.table.id : item.value.table.document_number}}
          td {{item.value.table.list_subsidaries_names.join(', ')}}
          td(class="tw-text-center ") {{item.value.table.locale_date}}
          td(class="tw-text-center ") {{item.value.table.cod==0 ? "COD" : item.value.table.cod}}
          td(class="tw-text-center ") {{item.value.table.masked_total_unpaid_amount}}
          td(class="tw-text-left ") {{item.value.table.list_emails_from_billing_notes.join(', ')}}
          td(class="tw-text-center ")
            app-icon-btn(
              v-if="item.value.table.status=='UNPAID'"
              data-kind="link",
              @click.native="send_mail(item.value.table, true)",
              data-icon="fa fa-envelope",
              data-label="",
              :data-title="$I18n.t('vue.unbilled_invoices_list.send_email')"
            )
            app-icon-btn(
              v-if="item.value.table.status=='SENDED'"
              data-kind="link",
              @click.native="send_mail(item.value.table, true)",
              data-icon="fa fa-check",
              data-label="",
              :data-title="$I18n.t('vue.unbilled_invoices_list.email_sended_ok')"
            )
            app-icon-btn(
              v-if="item.value.table.status=='NO_SENDED'"
              data-kind="link",
              @click.native="send_mail(item.value.table, true)",
              data-icon="fa fa-times",
              data-label="",
              :data-title="$I18n.t('vue.unbilled_invoices_list.email_not_sended')"
            )
            i(v-if="item.value.table.status=='SENDING'")
              div(class="spinner-border spinner-border-sm" role="status")

</template>
<script>

import CoreComponents from '../core';
const {ActiveValue}=require('../../core/active_support');
const {BackendService,BackendRoutes} = require('app/services');

export default {
  components:{    
    "app-icon-btn": CoreComponents.AppIconBtn,
    ...CoreComponents,
  },
  props:{
  },
  data: function(){
    return {
      state: {
        loading: true,
        all_sending: false,
      },
      controls: {
        alert: {
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: "",
          },
        },
      },
      items: '',
    }
  },
  computed:{
    emptyItems: function(){
      return this.data_invoices === '';
    },
  },
  methods:{
    getInvoices:function(){
      let params="";
      BackendService
        .get_overdue_invoices(params)
        .then((response) => {
          if (response.isOk) {
            this.state.loading = false;
            this.items = response.data_invoices;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    send_mail:function(item, show_alert){
      let self = this
      item.status = "SENDING"
      let params = JSON.stringify({
        invoice_ids: [item.id],
      });
      const promise = 
        BackendService
          .send_mail_overdue_invoices(params)
          .then((response) => {
            console.log("Response:",response);
            self.controls.alert.notice = "vue.unbilled_invoices_list.result." + response.notice;
            if (response.isOk) {
              if (response.array_invoices[0].emails==""){
                item.status = "NO_SENDED"
                self.controls.alert.type = "error";
                self.controls.alert.notice = "vue.unbilled_invoices_list.email_invoice_not_present";
                self.controls.alert.link.text = 'vue.empty';
              }else{
                item.status = "SENDED"
                self.controls.alert.type = "success";
                self.controls.alert.message = "vue.empty";
                self.controls.alert.link.text = 'vue.empty';
              }
            }else{
              item.status = "NO_SENDED"
              self.controls.alert.type = "error";
              self.controls.alert.notice = "vue.unbilled_invoices_list.result.error_send";
              self.controls.alert.link.text = 'vue.empty';
            }
          })
          .catch(() => {
            item.status = "NO_SENDED"
            self.controls.alert.type = "error";
            self.controls.alert.notice = "vue.unbilled_invoices_list.result.error_promise";
            self.controls.alert.link.text = 'vue.empty';
          })
          .finally(() => {
            if (show_alert==true){
              self.controls.alert.display = true;
            }
          });
      return promise;
    },
    send_all_mail:function(){
      let self = this
      this.state.all_sending = true;
      let num_items = this.items.length;
      let count = 0;
      this.items.forEach(item => {
        item.value.table.status = "SENDING"
      });
      this.items.forEach(function(item, index) {
        setTimeout(() => {
          console.log("Envia_correo:",item, index)
          self.send_mail(item.value.table, false)
            .then((response) => {
              count += 1
              if (count == num_items){
                self.state.all_sending = false;
              }
            });
        }, index * 3000);
      });
    },
  },
  beforeMount() {
    this.getInvoices();
  },
}
</script>
