
const { Ok } = require('app/core');
const Routes = require('./backend_routes.js.erb');

const post = (path, params) => {
  return fetch(path, {
    method: 'post',
    body: params,
    headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
    credentials: 'same-origin'
  })
    .then(response => response.json())
}

const Backend = {
  sum: (a, b) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let r = a + b;
        if (r === 4) {
          resolve(a + b)
        } else {
          reject('Suma diferente a 4')
        }
      }, 2000);
    })
  },
  ajax: () => {
    return fetch(Routes.ajax_sum_path(1, 4), {
      method: 'get'
    })
      .then(response => response.json())
  },
  send_reset_password_email: (email) => {
    return fetch(Routes.send_reset_password_email_path(), {
      method: 'post',
      body: JSON.stringify({ email: email }),
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  send_change_password: (params) => {
    return fetch(Routes.send_change_password_path(), {
      method: 'post',
      body: JSON.stringify(params),
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  create_customer: (params) => {
    return fetch(Routes.create_customer_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  create_vendor: (params) => {
    return fetch(Routes.create_vendor_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  update_vendor: (id, params) => {
    return post(
      Routes.update_vendor_path(id),
      params
    )
  },
  active_vendor: (id, params) => {
    return post(
      Routes.active_vendor_path(id),
      params
    )
  },
  disable_vendor: (id, params) => {
    return post(
      Routes.disable_vendor_path(id),
      params
    )
  },
  get_num_vendors: (params) => {
    return post(
      Routes.get_num_vendors_path(),
      params
    )
  },
  create_account: (params) => {
    return fetch(Routes.create_account_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  update_account: (id, params) => {
    return post(
      Routes.update_account_path(id),
      params
    )
  },
  active_account: (id, params) => {
    return post(
      Routes.active_account_path(id),
      params
    )
  },
  disable_account: (id, params) => {
    return post(
      Routes.disable_account_path(id),
      params
    )
  },
  public_send_reset_password_email: (id, params) => {
    return post(
      Routes.public_send_reset_password_email_path(id),
      params
    )
  },
  create_customer_contact: (params) => {
    return fetch(Routes.create_customer_contact_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  create_hood_maintenance: (params) => {
    return fetch(Routes.create_hood_maintenance_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  retrieve_contacts_by_customer: (id) => {
    return fetch(Routes.customer_contacts_path(id), {
      method: 'get',
    }).then(response => response.json())
  },
  retrieve_maintenances_by_hood: (id) => {
    return fetch(Routes.hood_maintenances_path(id), {
      method: 'get',
    }).then(response => response.json())
  },
  retrieve_maintenances_by_subsidary: (params) => {
    return fetch(Routes.search_maintenances_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  create_customer_subsidary: (params) => {
    return fetch(Routes.create_customer_subsidary_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },

  update_customer: (id, params) => {
    return post(Routes.update_customer_path(id), params);
  },
  active_customer: (id, params) => {
    return post(
      Routes.active_customer_path(id),
      params
    )
  },
  disable_customer: (id, params) => {
    return post(
      Routes.disable_customer_path(id),
      params
    )
  },
  get_num_customers: (params) => {
    return post(
      Routes.get_num_customers_path(),
      params
    )
  },

  create_subsidary_hood: (params) => {
    return fetch(Routes.create_subsidary_hood_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  create_subsidary_note: (params) => {
    return fetch(Routes.create_subsidary_note_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  create_subsidary_service: (params) => {
    return fetch(Routes.create_subsidary_service_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  create_subsidary_hood: (params) => {
    return fetch(Routes.create_subsidary_hood_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  create_item_order: (params) => {
    return fetch(Routes.create_item_order_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  delete_item_order: (id) => {
    return post(
      Routes.delete_item_order_path(id),
      JSON.stringify({})
    )
  },
  delete_note: (id) => {
    return post(
      Routes.delete_note_path(id),
      JSON.stringify({})
    )
  },
  service_delete: (id) => {
    return post(
      Routes.service_delete_path(id),
      JSON.stringify({})
    )
  },
  hood_delete: (id) => {
    return post(
      Routes.hood_delete_path(id),
      JSON.stringify({})
    )
  },
  create_maintenance_kind: (params) => {
    return fetch(Routes.create_maintenance_kind_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  update_maintenance_kind: (params) => {
    return post(Routes.update_maintenance_kind_path(), params);
  },
  active_maintenance_kind: (id, params) => {
    return post(
      Routes.active_maintenance_kind_path(id),
      params
    )
  },
  disable_maintenance_kind: (id, params) => {
    return post(
      Routes.disable_maintenance_kind_path(id),
      params
    )
  },

  create_order: (params) => {
    return fetch(Routes.create_order_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json());
  },
  complete_order: (id, params) => {
    return fetch(Routes.complete_order_path(id), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
    }).then(response => response.json());
  },
  cancel_order: (id, params) => {
    return fetch(Routes.cancel_order_path(id), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
    }).then(response => response.json());
  },
  order_update_item:(params) =>{
    return post(Routes.order_update_item_path(), params);
  },
  order_update_total_price:(params) =>{
    return post(Routes.order_update_total_price_path(), params);
  },
  order_update_units:(params) =>{
    return post(Routes.order_update_units_path(), params);
  },
  order_update_frequency:(params) =>{
    return post(Routes.order_update_frequency_path(), params);
  },
  order_update_schedule_for:(params) =>{
    return post(Routes.order_update_schedule_for_path(), params);
  },
  order_update_due_at:(params) =>{
    return post(Routes.order_update_due_at_path(), params);
  },
  create_order_note: (params) => {
    return fetch(Routes.create_order_note_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  note_update:(params) =>{
    return post(Routes.note_update_path(), params);
  },
  get_jobs_list:(params) =>{
    return post(Routes.get_jobs_list_path(), params);
  },

  service_update:(params) =>{
    return post(Routes.service_update_path(), params);
  },

  hood_update:(params) =>{
    return post(Routes.hood_update_path(), params);
  },

  update_address: (params) => {
    return fetch(Routes.update_address_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  reset_maintenance_due_date: (id, params) => {
    return fetch(
      Routes.reset_maintenance_due_date_path(id),
      {
        method: 'post',
        body: params,
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
        credentials: 'same-origin'
      })
      .then(response => response.json())
  },
  cancel_maintenance: (id, params) => {
    return fetch(
      Routes.cancel_maintenance_path(id),
      {
        method: 'post',
        body: params,
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
        credentials: 'same-origin'
      })
      .then(response => response.json())
  },
  update_maintenance_price: (id, params) => {
    return post(
      Routes.update_maintenance_price_path(id),
      params
    )
  },
  delete_contact: (id) => {
    return post(
      Routes.delete_contact_path(id),
      JSON.stringify({})
    )
  },
  create_contact: (params) => {
    return fetch(
      Routes.create_contact_path(),
      {
        method: 'post',
        body: params,
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
        credentials: 'same-origin'
      })
      .then(response => response.json())
  },
  contact_update:(params) =>{
    return post(Routes.contact_update_path(), params);
  },
  contacts: (params) => {
    return fetch(
      Routes.contacts_path(),
      {
        method: 'post',
        body: params,
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
        credentials: 'same-origin'
      })
      .then(response => response.json())
  },
  /*----------------*/
  create_phone_contact: (params) => {
    return fetch(Routes.create_phone_contact_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  contact_phone_update:(params) =>{
    return post(Routes.contact_phone_update_path(), params);
  },
  delete_phone: (id) => {
    return post(
      Routes.delete_phone_path(id),
      JSON.stringify({})
    )
  },
  /*----------------*/
  active_subsidary: (id, params) => {
    return post(
      Routes.active_subsidary_path(id),
      params
    )
  },
  disable_subsidary: (id, params) => {
    return post(
      Routes.disable_subsidary_path(id),
      params
    )
  },
  update_subsidary: (params) => {
    return post(Routes.update_subsidary_path(), params);
  },
  get_num_subsidaries: (params) => {
    return post(
      Routes.get_num_subsidaries_path(),
      params
    )
  },
  subsidary_hoods: (id)=>{
    return fetch(Routes.subsidary_hoods_path(id), {
      method: 'get',
    }).then(response => response.json())
  },
  subsidary_services: (id)=>{
    return fetch(Routes.subsidary_services_path(id), {
      method: 'get',
    }).then(response => response.json())
  },
  /*----------------*/
  delete_link: (id) => {
    return post(
      Routes.delete_link_path(id),
      JSON.stringify({})
    )
  },
  create_link: (params) => {
    return fetch(
      Routes.create_link_path(),
      {
        method: 'post',
        body: params,
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
        credentials: 'same-origin'
      })
      .then(response => response.json())
  },
  link_update:(params) =>{
    return post(Routes.link_update_path(), params);
  },
  /*-----------------*/
  create_technician: (params) => {
    return fetch(
      Routes.create_technician_path(),
      {
        method: 'post',
        body: params,
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
        credentials: 'same-origin'
      })
      .then(response => response.json())
  },
  technician_update:(params) =>{
    return post(Routes.technician_update_path(), params);
  },
  active_technician: (id, params) => {
    return post(
      Routes.active_technician_path(id),
      params
    )
  },
  disable_technician: (id, params) => {
    return post(
      Routes.disable_technician_path(id),
      params
    )
  },
  /*-------------------*/
  /*----------------*/
  remove_technician_to_order: (id) => {
    return post(
      Routes.remove_technician_to_order_path(id),
      JSON.stringify({})
    )
  },
  add_technician_to_order: (params) => {
    return fetch(
      Routes.add_technician_to_order_path(),
      {
        method: 'post',
        body: params,
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
        credentials: 'same-origin'
      })
      .then(response => response.json())
  },
  /*-----------------------*/
  assign_service_to_order: (params) => {
    return fetch(
      Routes.assign_service_to_order_path(),
      {
        method: 'post',
        body: params,
        headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
        credentials: 'same-origin'
      })
      .then(response => response.json())
  },
  /*-----------------------*/
  /*-----------------*/
  create_new_invoice: (params) => {
    return fetch(Routes.create_new_invoice_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  update_invoice: (params) => {
    return post(Routes.update_invoice_path(), params);
  },
  cancel_invoice: (params) => {
    return post(Routes.cancel_invoice_path(), params);
  },
  send_email_invoice: (params) => {
    return post(Routes.send_email_invoice_path(), params);
  },
  delete_order_invoice: (id) => {
    return post(
      Routes.delete_order_invoice_path(id),
      JSON.stringify({})
    )
  },
  create_invoice_note: (params) => {
    return fetch(Routes.create_invoice_note_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  get_invoices_by_state: (params) => {
    return post(
      Routes.get_invoices_by_state_path(),
      params
    )
  },
  send_mail_invoices_by_state: (params) => {
    return post(
      Routes.send_mail_invoices_by_state_path(),
      params
    )
  },
  get_overdue_invoices: (params) => {
    return post(
      Routes.get_overdue_invoices_path(),
      params
    )
  },
  send_mail_overdue_invoices: (params) => {
    return post(
      Routes.send_mail_overdue_invoices_path(),
      params
    )
  },
  create_invoice_item: (params) => {
    return fetch(Routes.create_invoice_item_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  update_invoice_item: (params) => {
    return post(Routes.update_invoice_item_path(), params);
  },
  delete_invoice_item: (id) => {
    return post(
      Routes.delete_invoice_item_path(id),
      JSON.stringify({})
    )
  },
  /*-----------------*/
  /*-----------------*/
  create_payment: (params) => {
    return fetch(Routes.create_payment_path(), {
      method: 'post',
      body: params,
      headers: { 'Content-Type': 'application/json', 'X-CSRF-Token': Rails.csrfToken() },
      credentials: 'same-origin'
    }).then(response => response.json())
  },
  update_payment: (params) => {
    return post(Routes.update_payment_path(), params);
  },
  cancel_payment: (params) => {
    return post(Routes.cancel_payment_path(), params);
  },
  /*-----------------*/
  
}
module.exports = Backend;

