<template lang="pug">
div
  app-icon-btn(
    :disabled="this.disabled"
    @click.native="showModal",
    data-kind="link",
    data-icon="fa fa-credit-card",
    :data-label="$I18n.t('vue.apply_payment_modal.title')"
    :data-title="$I18n.t('vue.apply_payment_modal.title')"
  )

  // Modal
  app-modal(
    :showing="controls.modal.showing",
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(#header)
      p {{labels.modal_title}}
    template(#body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        app-form.tw-py-4(v-bind:grid="formGrid")
      
      div(v-if="validations!=''")  
          p(class="text-danger") {{validations}}
      
      p(style="display: none;") {{update_amount_with_admin_fee}}
      p(style="display: none;") {{amount_breakdown}}

    template(#footer)
      .app-buttons-row.justify-end(v-if="!state.sending")
        app-icon-btn(
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="send",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
          :disabled="validations != ''"
        )
</template>

<script>
import { ActiveValue } from "../../core/active_support";
import CoreComponents from "../core";
import {BackendService} from '../../services';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components: {
    "app-modal": CoreComponents.Modal,
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-form": CoreComponents.Detailform,
  },
  props:{
    dataId: Number,
    dataInvoice: Object,
    disabled: Boolean,
    dataWayToPay: String,
  },
  data: function () {
    return {
      record: {
        invoice: this.dataInvoice,
        payment: {
          amount_invoice_paid: 0,
          amount_admin_fee_paid: 0,
          amount_total_paid: 0,
        },
      },
      state: {
        sending: false,
      },
      controls: {
        modal: {
          showing: false,
        },
      },
      values: {
        date: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.apply_payment_modal.label_date'));
          i.setValue("");
          i.setInputKind("date");
          i.required();
        }),
        amount_payment: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.apply_payment_modal.label_amount'));
          i.setValue(Number(this.dataInvoice.total_unpaid_amount).toFixed(2));
          i.setInputKind("number");
          i.required();
        }),
        way_to_paid: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.apply_payment_modal.label_way_to_pay'));
          i.setValue(this.dataInvoice.way_to_pay);
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataWayToPay));
          i.required();
        }),
        num_check: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.apply_payment_modal.label_num_check'));
          i.setValue("");
          i.setInputKind("text");
        }),
        credit_card_number: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.apply_payment_modal.label_credit_card_number'));
          i.setValue("");
          i.setInputKind("text");
        }),
        reference: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.apply_payment_modal.label_reference'));
          i.setValue("");
          i.setInputKind("text");
        }),
      },
      labels:{
        clear_btn: this.$I18n.t('vue.apply_payment_modal.button_clear'),
        cancel_btn: this.$I18n.t('vue.apply_payment_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.apply_payment_modal.button_create'),
        confirmation_question: this.$I18n.t('vue.apply_payment_modal.question'),
        confirmation_reject: this.$I18n.t('vue.apply_payment_modal.reject'),
        confirmation_accept: this.$I18n.t('vue.apply_payment_modal.accept'),
        modal_title: this.$I18n.t('vue.apply_payment_modal.title'),
      }
    };
  },
  computed: {
    update_amount_with_admin_fee: function(){
      let amount_unpaid = this.get_amount_unpaid();
      //Si pagan con "Credit Card" entonces aumenta el "Credit Card Admin Fee"
      this.values.amount_payment.setValue(amount_unpaid.toFixed(2));
      return amount_unpaid
    },
    amount_breakdown: function(){
      let amount_total = Number(this.values.amount_payment.input)
      let way_to_paid  =        this.values.way_to_paid.input
      if (way_to_paid == "Credit Card"){
        let admin_fee        = Number(this.record.invoice.porcent)
        let amount_admin_fee = amount_total * admin_fee / (100+admin_fee)
        this.record.payment.amount_invoice_paid    = (amount_total - amount_admin_fee).toFixed(2)
        this.record.payment.amount_admin_fee_paid  = (amount_admin_fee).toFixed(2)
        this.record.payment.amount_total_paid      = (amount_total).toFixed(2)
      }else{
        this.record.payment.amount_invoice_paid    = amount_total.toFixed(2)
        this.record.payment.amount_admin_fee_paid  = (0).toFixed(2)
        this.record.payment.amount_total_paid      = amount_total.toFixed(2)
      }
      return this.record.payment.amount_total_paid
    },
    formGrid: function () {
      //Input num check, num card or reference
      let input_number = this.values.reference
      if (this.values.way_to_paid.input == "Check"){
        input_number = this.values.num_check
      }
      if (this.values.way_to_paid.input == "Credit Card"){
        input_number = this.values.credit_card_number
      }
      return [
        [this.values.date,              this.values.way_to_paid],
        [this.values.amount_payment,    input_number],
      ];
    },
    validations: function(){
      console.log(" ")
      let message = ""
      let amount_payment = Number(this.values.amount_payment.input)
      let amount_unpaid = this.get_amount_unpaid();
      //Importe NO mayor al saldo de la factura
      console.log(amount_payment.toFixed(2))
      console.log(amount_unpaid.toFixed(2))
      if ( Number(amount_unpaid.toFixed(2)) < Number(amount_payment.toFixed(2)) ) {
        message = this.$I18n.t('vue.apply_payment_modal.messages.amount_greater_than_the_invoice')
      }
      return message;
    }
  },
  methods: {
    get_amount_unpaid: function(){
      let amount_unpaid = Number(this.dataInvoice.total_unpaid_amount)
      if (this.values.way_to_paid.input == "Credit Card"){
        let Credit_card_admin_fee = Number(this.record.invoice.porcent)/100
        let amount_admin_fee = amount_unpaid * Credit_card_admin_fee
        let amount_with_admin_fee = amount_unpaid + amount_admin_fee
        amount_unpaid = amount_with_admin_fee
      }
      return amount_unpaid
    },
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    clear: function () {
      this.resetActiveValues(this.values);
      this.values.amount_payment.setValue(Number(this.dataInvoice.total_unpaid_amount).toFixed(2));
      this.values.way_to_paid.setValue(this.dataInvoice.way_to_pay);
    },
    cancel: function () {
      this.hideModal();
      this.clear();
    },
    disabled_inputs: function () {
      
    },
    valid: function () {
      let ins = this.values;
      return Object.values(this.values)
        .map((i) => {
          i.validate();
          return i.hasError();
        })
        .every((e) => e === false);
    },
    stringify: function () {
      let array_invoice_ids = [this.dataId];
      let params = JSON.stringify({
        invoice_ids: array_invoice_ids,
        date: this.values.date.input,
        amount_payment: this.record.payment.amount_invoice_paid,
        amount_admin_fee: this.record.payment.amount_admin_fee_paid,
        way_to_paid: this.values.way_to_paid.input,
        num_check: this.values.num_check.input,
      });
      return params;
    },
    send: function () {
      let self = this;
      if (self.valid()) {
        this.state.sending = true;
        BackendService
          .create_payment(self.stringify())
          .then((response) => {
            if (response.isOk) {
              location.reload();
            }
          })
          .catch(() => {
            self.result.isError = true;
            self.result.notice = "error_promise";
            self.state.sending = false;
          });
      }
    },
  },
};
</script>
