<template lang="pug">
.div
  //Payments
  .tw-flex.tw-flex-row.tw-justify-between
    
  table(v-if='!emptyItems' class="table table-hover")
    thead
      th {{$I18n.t('vue.invoice_payments_table_panel.table_header_id')}}
      th {{$I18n.t('vue.invoice_payments_table_panel.table_header_status')}}
      th {{$I18n.t('vue.invoice_payments_table_panel.table_header_date_payment')}}
      th {{$I18n.t('vue.invoice_payments_table_panel.table_header_amount_paid')}}
      th {{$I18n.t('vue.invoice_payments_table_panel.table_header_way_to_pay')}}
      th {{$I18n.t('vue.invoice_payments_table_panel.table_header_num_check')}}
      th {{$I18n.t('vue.invoice_payments_table_panel.table_header_credit_card_number')}}
      th {{$I18n.t('vue.invoice_payments_table_panel.table_header_reference')}}
      th
    tbody
      tr(v-for="item in items")
        td {{item.id}}
        td(:class="state_style_text(item.status.toUpperCase())") {{$I18n.t('vue.states.'+item.status.toLowerCase())}}
        td {{item.locale_date_payment}}
        td {{item.masked_amount_payment}}
        td {{$I18n.t('vue.payment_types.'+item.way_to_pay.split(' ').join('_').toLowerCase())}}
        td {{item.num_check}}
        td {{item.credit_card_number}}
        td {{item.reference}}
        td
          .app-buttons-row.justify-end
            app-icon-link(
              data-kind="link",
              :href="payment_path(item.id)"
              data-target="_blank"
              data-icon="fa fa-eye",
              data-label="",
              :data-title="$I18n.t('vue.invoice_payments_table_panel.view_payment')"
            )
  div(v-if='!emptyItems')
    .row
      .col-9
      .col-3
        .row
          table(class="table table-bordered table-hover")
            tbody
              tr(v-if="calcularTotal()")
                th.flex(class='justify-end')  {{$I18n.t('vue.invoice_payments_table_panel.total_payments')}}
                th(class="text-dark") {{calcularTotal()}}
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.invoice_payments_table_panel.empty_items')}}

</template>
<script>
import CoreComponents from "../core";
const { BackendRoutes } = require("app/services");
export default {
  components:{
    "app-icon-link": CoreComponents.AppIconLink,
  },
  props:{
    dataItems: String,
  },
  data: function(){
    return {
      record:{
      },
      items: JSON.parse(this.dataItems),
      labels:{
      }
    }
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  },
  methods:{
    payment_path: function (payment_id) {
      return BackendRoutes.payment_path(payment_id);
    },
    calcularTotal:function(){
      // Usamos reduce para iterar sobre el array y sumar los precios
      let total = this.items.reduce((total, item) => {
        // Verificamos si el estado del elemento es diferente de "CANCELED"
        if (item.status !== "CANCELED") {
          // Si es diferente, sumamos su precio al total
          return total + parseFloat(item.amount_paid.replace("$", "").replace(",", "").replace(" ", ""));
        } else {
          // Si es "CANCELED", simplemente retornamos el total sin modificarlo
          return total;
        }
      }, 0); // Inicializamos el total en 0
      return total.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    },
    state_style_text:function(status){
      let style = "text-black"
      if (status == "CANCELED"){
        style = "text-danger"
      }
      if (status == "APPLIED"){
        style = "text-success"
      }
      return style
    },
  }
}
</script>
