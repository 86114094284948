<template lang='pug'>
  div
    //Button link
    app-icon-btn(
      :disabled="this.disabled"
      @click.native="showModal",
      data-kind="link",
      data-icon="fa fa-calendar-times-o",
      :data-label="$I18n.t('vue.order_due_at.title')"
    )
    app-modal(
      :showing="controls.modal.showing",
      @showing="controls.modal.showing = $event"
    )
      template(#header)
        p {{ labels.modal_title }}
      template(#body)
        // Spinner Loading
        div(v-if="state.sending" class="tw-text-center tw-my-20")
          div(class="spinner-border spinner-border-sm" role="status")

        // FORM
        div(v-else)
          app-form.tw-py-4(v-bind:grid="formGrid")

      template(#footer)
        .app-buttons-row.justify-end(v-if="!state.sending")
          app-icon-btn(
            data-kind="link",
            @click.native="clear",
            data-icon="fa fa-eraser",
            :data-label="labels.clear_btn"
          )
          app-icon-btn(
            data-kind="link",
            @click.native="cancel",
            data-icon="fa fa-times",
            :data-label="labels.cancel_btn"
          )
          app-icon-btn(
            data-kind="btn",
            @click.native="send",
            data-icon="fa fa-save",
            :data-label="labels.send_btn"
          )
</template>

<script>
import { ActiveValue } from "../../core/active_support";
import CoreComponents from "../core";
import BackendService from '../../services/backend_service';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components: {
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
  },
  props:['dataOrder', 'disabled'],
  data: function () {
    return {
      record: {
        order: this.dataOrder
      },
      controls: {
        modal: {
          showing: false,
        },
      },
      state:{
        sending: false
      },
      labels: {
        modal_title: this.$I18n.t('vue.order_due_at.title'),
        clear_btn: this.$I18n.t('vue.order_due_at.clear'),
        cancel_btn: this.$I18n.t('vue.order_due_at.cancel'),
        send_btn: this.$I18n.t('vue.order_due_at.send'),
      },
      values: {
        due_at: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.order_due_at.date'));
          i.setValue(this.dataOrder.due_at);
          i.setInputKind("date");
          i.required();
        })
      },
      
    };
  },
  computed: {
    formGrid: function () {
      return [
        [this.values.due_at]
      ];
    },
  },
  methods: {
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    clear: function () {
      this.resetActiveValues(this.values);
    },
    cancel: function () {
      this.hideModal();
      this.resetActiveValues(this.values);
    },
    disabled_inputs: function () {
      
    },
    send_params: function () {
      let params = JSON.stringify({
        id: this.record.order.id,
        due_at: this.values.due_at.input
      });
      return params;
    },
    send: function () {
      let self = this;
      if (this.areValidActiveValues(this.values)) {
        let params = this.send_params()
        self.state.sending = true;
        BackendService
          .order_update_due_at(params)
          .then(_=>{
            this.clear();
            //this.hideModal();
            location.reload();
          })
      }
    },
  },
};
</script>
