<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display" 
    v-bind:alert="controls.alert"
  )

  app-icon-btn(
    @click.native="showModal",
    data-kind="link",
    data-icon="fa fa-envelope",
    :data-label="$I18n.t('vue.send_email_invoice_modal.title')"
    :data-title="$I18n.t('vue.send_email_invoice_modal.title')"
  )

  // Modal
  app-modal(
    :showing="controls.modal.showing",
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(#header)
      p {{labels.modal_title}}
    template(#body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        app-form.tw-py-4(v-bind:grid="formGrid")
        label(class="col col-form-label text-right app-label-color") {{$I18n.t('vue.send_email_invoice_modal.label_message')}}
        quill-editor(
          :content="contentHTML"
          :options="editorOption"
          @change="onEditorChange($event)"
        )
    template(#footer)
      .app-buttons-row.justify-end(v-if="!state.sending")
        app-icon-btn(
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="send",
          data-icon="fa fa-envelope",
          :data-label="labels.send_btn"
        )
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import { ActiveValue } from "../../core/active_support";
import CoreComponents from "../core";
import {BackendService} from '../../services';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components: {
    quillEditor,
    "app-modal": CoreComponents.Modal,
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-form": CoreComponents.Detailform,
    "Notificationalert": CoreComponents.Notificationalert,
  },
  props:{
    dataId: Number,
    dataInvoice: Object,
  },
  data: function () {
    return {
      contentHTML:  "",
      editorOption: {
        // Some Quill options...
      },
      record: {
        invoice: this.dataInvoice,
      },
      state: {
        sending: false,
      },
      controls: {
        modal: {
          showing: false,
        },
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      values: {
        to: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.send_email_invoice_modal.label_to'));
          i.setInputTitle(this.$I18n.t('vue.send_email_invoice_modal.title_to'));
          i.setValue(this.dataInvoice.list_emails_from_billing_notes.join('; '));
          i.setInputKind("text");
          i.required();
        }),
        cc: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.send_email_invoice_modal.label_cc'));
          i.setInputTitle(this.$I18n.t('vue.send_email_invoice_modal.title_cc'));
          i.setValue("");
          i.setInputKind("text");
        }),
        cco: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.send_email_invoice_modal.label_cco'));
          i.setInputTitle(this.$I18n.t('vue.send_email_invoice_modal.title_cco'));
          i.setValue("");
          i.setInputKind("text");
        }),
        subject: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.send_email_invoice_modal.label_subject'));
          i.setInputTitle(this.$I18n.t('vue.send_email_invoice_modal.title_subject'));
          i.setValue("Kanela Invoice: " + this.dataInvoice.list_subsidaries_names.join('; ') + ' - ' + (this.dataInvoice.document_number=='' ? this.dataInvoice.id : this.dataInvoice.document_number) );
          i.setInputKind("text");
          i.required();
        }),
      },
      labels:{
        clear_btn: this.$I18n.t('vue.send_email_invoice_modal.button_clear'),
        cancel_btn: this.$I18n.t('vue.send_email_invoice_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.send_email_invoice_modal.button_create'),
        confirmation_question: this.$I18n.t('vue.send_email_invoice_modal.question'),
        confirmation_reject: this.$I18n.t('vue.send_email_invoice_modal.reject'),
        confirmation_accept: this.$I18n.t('vue.send_email_invoice_modal.accept'),
        modal_title: this.$I18n.t('vue.send_email_invoice_modal.title'),
      }
    };
  },
  computed: {
    formGrid: function () {
      return [
        [this.values.to],
        [this.values.cc,       this.values.cco],
        [this.values.subject],
      ];
    },
  },
  mounted(){
    this.setContentHTML();
  },
  methods: {
    setContentHTML: function(){
      let result = "";
      let contentHTML = '<h3><strong>Good evening,</strong></h3>'+
                        '<h3><br></h3>'+
                        '<h3><strong>Please see the attached invoice for services that was performed on <u>'+this.dataInvoice.american_format_date+'</u>.</strong></h3>'+
                        '<h3><br></h3>'+
                        '<h4><strong>The payment is due when service is rendered, we\'d very much appreciate it if you can place the check in an envelope and <u>seal it</u>.</strong></h4>'+
                        '<h3><br></h3>'+
                        '<h3><strong>You also have the option to pay via credit card as stated on the invoice.</strong></h3>'+
                        '<h3><br></h3>'+
                        '<h3><strong><em><u>Please confirm receipt of this email.</u></em></strong></h3>'+
                        '<h3><br></h3>'+
                        '<h3><strong>Thank you for your business!</strong></h3>'+
                        '<p><br></p>'
      if (this.record.invoice.cod.toUpperCase()=='COD' || this.record.invoice.cod=='0' || this.record.invoice.cod=='' ){
        this.contentHTML = contentHTML;
      }else{
        //Quita la linea especificada
        this.contentHTML = contentHTML.replace('<h3><br></h3><h4><strong>The payment is due when service is rendered, we\'d very much appreciate it if you can place the check in an envelope and <u>seal it</u>.</strong></h4>', '');
      }
    },
    onEditorChange({ quill, html, text }) {
      //console.log('editor change!', quill, html, text)
      this.contentHTML = html
    },
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    clear: function () {
      this.resetActiveValues(this.values);
      this.values.to.setValue(this.dataInvoice.list_emails_from_billing_notes.join('; '))
      this.values.subject.setValue("Kanela Invoice: " + this.dataInvoice.list_subsidaries_names.join('; ') + ' - ' + (this.dataInvoice.document_number=='' ? this.dataInvoice.id : this.dataInvoice.document_number))
      this.setContentHTML();
    },
    cancel: function () {
      this.hideModal();
      this.clear();
    },
    disabled_inputs: function () {
      
    },
    valid: function () {
      let ins = this.values;
      return Object.values(this.values)
        .map((i) => {
          i.validate();
          return i.hasError();
        })
        .every((e) => e === false);
    },
    stringify: function () {
      let array_invoice_ids = [this.dataId];
      let params = JSON.stringify({
        id: this.record.invoice.id,
        to: this.values.to.input,
        cc: this.values.cc.input,
        cco: this.values.cco.input,
        subject: this.values.subject.input,
        message: this.contentHTML,
      });
      return params;
    },
    send: function () {
      let self = this;
      if (self.valid()) {
        this.state.sending = true;
        BackendService
          .send_email_invoice(self.stringify())
          .then((response) => {
            console.log(response);
            self.controls.alert.notice = 'vue.send_email_invoice_modal.result.'+response.notice
            if (response.isOk) {
              self.controls.alert.type="success"
              self.controls.alert.message = 'vue.empty'
              self.controls.alert.link.text = 'vue.empty'
              self.controls.alert.link.href=""
            }else{
              console.log("response.isError", response)
              self.controls.alert.type="danger"
              self.controls.alert.notice = 'vue.send_email_invoice_modal.result.email_not_sent'
              self.controls.alert.message = response.error_message
              self.controls.alert.link.text = 'vue.send_email_invoice_modal.result.email_not_sent'
              self.controls.alert.link.href=""
            }
            this.state.edit = false;
            this.state.show = true;
            this.state.sending = false;
          })
          .catch(() => {
            this.state.edit = false;
            this.state.show = true;
            this.state.sending = false;
            self.controls.alert.type="danger"
            self.controls.alert.notice = 'vue.send_email_invoice_modal.result.email_not_sent'
            self.controls.alert.message = ""
            self.controls.alert.link.text = 'vue.empty'
            self.controls.alert.link.href=""
          })
          .finally(()=>{
            self.cancel();
            self.state.sending=false;
            self.controls.alert.display=true
          });
      }
    },
  },
};
</script>
