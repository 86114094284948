<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display",
    v-bind:alert="controls.alert"
  )

  // Spinner Loading
  .tw-text-center.tw-my-20(v-if="state.sending")
    .spinner-border.spinner-border-sm(role="status")

  // FORM
  div(v-else)
    Detailform(v-bind:grid="grid_customer")

    .h5.pt-4.tw-text-gray-500
      h5.h5.pt-4 {{ $I18n.t('vue.new_customer_form.subtitle_address') }}
      hr
    Detailform(v-bind:grid="grid_address")

    .h5.pt-4.tw-text-gray-500
      h5.h5.pt-4 {{ $I18n.t('vue.new_customer_form.subtitle_contact') }}
      hr
    Detailform(v-bind:grid="grid_contact")

    //BUTTONS
    .tw-text-right.tw-mt-7(v-if="!state.sending")
      .form-group.row
        .row
          .col
            a.app-btn-link-primary(@click="clear") 
              i.fa.fa-eraser.mr-2
              span {{ $I18n.t('vue.new_customer_form.button_clear') }}
            a.app-btn-link-primary(:href="index_customers_path") 
              i.fa.fa-times.mr-2
              span {{ $I18n.t('vue.new_customer_form.button_cancel') }}
            button.app-btn-primary(@click="send") 
              i.fa.fa-save.mr-2
              span {{ $I18n.t('vue.new_customer_form.button_create') }}
</template>
<script>
import CoreComponents from "../core";
const { ActiveValue } = require("../../core/active_support");
const { BackendService, BackendRoutes } = require("app/services");
export default {
  props: {
    dataVendors: String,
  },
  data: function () {
    return {
      controls: {
        alert: {
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: "",
          },
        },
      },
      inputs: {
        //Inputs Customer
        name: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_name"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        aka: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_aka"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        customer_phone_number: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_phone_number"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        vendor_id: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_vendor"));
          i.setValue("");
          i.setKind("text");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataVendors));
          i.required();
        }),
        //Inputs Address
        street_address: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_customer_form.label_address_street")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        secundary_address: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_customer_form.label_address_apartament")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.optional();
        }),
        city: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_address_city"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        state_code: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_address_state"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        zip_code: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_customer_form.label_address_zipCode")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        //Inputs Contact
        role: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_contact_role"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        first_name: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_customer_form.label_contact_fistName")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        middle_name: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_vendor_form.label_contact_middleName")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        last_name: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_customer_form.label_contact_lastName")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        phone_kind: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_customer_form.label_contact_phoneKind")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        phone_number: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_customer_form.label_contact_phoneNumber")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        email: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_contact_email"));
          i.setValue("");
          i.setKind("email");
          i.setInputKind("text");
        }),
      },
      state: {
        compleated: true,
        sending: false,
      },
      result: {
        isError: undefined,
        isOk: undefined,
        key: undefined,
        notice: undefined,
        vendor: {
          id: undefined,
        },
      },
    };
  },
  computed: {
    index_customers_path: function () {
      return BackendRoutes.index_customers_path();
    },
    customer_path: function () {
      return BackendRoutes.customer_path(this.result.customer.id);
    },
    grid_customer: function () {
      return [
        [this.inputs.name, this.inputs.vendor_id],
        [this.inputs.aka, this.inputs.customer_phone_number],
      ];
    },
    grid_address: function () {
      return [
        [
          this.inputs.street_address,
          this.inputs.secundary_address,
          this.inputs.city,
        ],
        [this.inputs.state_code, this.inputs.zip_code, ""],
      ];
    },
    grid_contact: function () {
      return [
        [this.inputs.role, "", ""],
        [
          this.inputs.first_name,
          this.inputs.middle_name,
          this.inputs.last_name,
        ],
        [this.inputs.phone_kind, this.inputs.phone_number, this.inputs.email],
      ];
    },
  },
  methods: {
    valid: function () {
      return Object.values(this.inputs)
        .map((i) => {
          i.validate();
          return i.hasError();
        })
        .every((e) => e === false);
    },
    stringify: function () {
      const params = {
        name: this.inputs.name.input,
        aka: this.inputs.aka.input,
        state: "ACTIVE",
        vendor_id: this.inputs.vendor_id.input.toString(),
        phone_number: this.inputs.customer_phone_number.input,
        address: {
          street_address: this.inputs.street_address.input,
          secundary_address: this.inputs.secundary_address.input,
          city: this.inputs.city.input,
          state_code: this.inputs.state_code.input,
          zip_code: this.inputs.zip_code.input,
        },
      };

      //Agrega contacto, solo si existe algun dato
      let ins = this.inputs;
      let contact_keys = [
        "role",
        "first_name",
        "middle_name",
        "last_name",
        "phone_kind",
        "phone_number",
        "email",
      ];
      let contactIsPresent = contact_keys
        .map((k) => ins[k].isPresent())
        .some((v) => v === true);
      if (contactIsPresent) {
        params["contact"] = {
          role_name: this.inputs.role.input,
          first_name: this.inputs.first_name.input,
          middle_name: this.inputs.middle_name.input,
          last_name: this.inputs.last_name.input,
          phone_kind: this.inputs.phone_kind.input,
          phone_number: this.inputs.phone_number.input,
          email: this.inputs.email.input,
        };
      }

      return JSON.stringify(params);
    },
    send: function () {
      let self = this;
      if (self.valid()) {
        self.state.sending = true;
        BackendService.create_customer(self.stringify())
          .then((response) => {
            self.result = response;
            self.controls.alert.notice =
              "vue.new_customer_form.result." + response.notice;
            if (response.isOk) {
              self.controls.alert.type = "success";
              self.controls.alert.message =
                "vue.new_customer_form.view_detail_text";
              self.controls.alert.link.text = "vue.new_customer_form.show_link";
              self.controls.alert.link.href = this.customer_path;
              self.clear();
            } else {
              self.controls.alert.type = "danger";
            }
          })
          .catch(() => {
            self.result.isError = true;
            self.controls.alert.type = "error";
            self.controls.alert.notice =
              "vue.new_customer_form.result.error_promise";
          })
          .finally(() => {
            self.state.sending = false;
            self.controls.alert.display = true;
          });
      }
    },
    clear: function () {
      Object.values(this.inputs).forEach((v) => v.resetValue());
    },
  },
  components: { ...CoreComponents },
};
</script>
