

module.exports={
  cable_path:()=>{
    return "/cable";
  },
  ajax_sum_path:(a,b)=>{
    return `/ha.json?a=${a}&b=${b}`;
  },
  vendor_path:(id)=>{
    return `/vs.json?id=${id}`;
  },
  send_reset_password_email_path:()=>{
    return '/psrp.json'
  },
  send_change_password_path:()=>{
    return '/pcp.json'
  },
  new_session_path:()=>{
    return '/sessions/new'
  }, 
  index_customers_path:()=>{
    return '/c'
  },
  customer_path:(id)=>{ 
    return `/cs?id=${id}`;
  },
  create_customer_path:()=>{
    return '/cc.json'
  },
  update_customer_path:(id)=>{
    return `/c/:id/u.json`.replace(':id', id);
  },
  active_customer_path:(id)=>{
    return `/c/:id/a.json`.replace(':id', id);
  },
  disable_customer_path:(id)=>{
    return `/c/:id/d.json`.replace(':id', id);
  },
  get_num_customers_path:()=>{
    return '/cg.json'
  },

  subsidary_path:(id)=>{ 
    return `/ss?id=${id}`;
  },
  index_subsidaries_path:()=>{
    return '/s'
  },
  get_num_subsidaries_path:()=>{
    return '/sg.json'
  },

  index_vendors_path:()=>{
    return '/v'
  },
  vendor_path:(id)=>{ 
    return `/vs?id=${id}`;
  },
  update_vendor_path:(id)=>{
    return `/v/:id/u.json`.replace(':id', id);
  },
  active_vendor_path:(id)=>{
    return `/v/:id/a.json`.replace(':id', id);
  },
  disable_vendor_path:(id)=>{
    return `/v/:id/d.json`.replace(':id', id);
  },
  get_num_vendors_path:()=>{
    return '/vg.json'
  },


  customer_contacts_path:(id)=>{
    return `/cci?id=${id}`; 
  },
  hood_maintenances_path:(id)=>{
    return "/h/:id/m.json".replace(':id', id);
  },
  create_vendor_path:()=>{
    return '/vc.json'
  },
  create_customer_contact_path:()=>{
    return '/ccc.json'
  },
  create_hood_maintenance_path:()=>{
    return '/h/m/c.json'
  },
  create_customer_subsidary_path:()=>{
    return '/ccs.json'
  },
  create_subsidary_hood_path:()=>{
    return '/sch.json'
  },
  create_subsidary_note_path:()=>{
    return '/scn.json'
  },
  create_subsidary_service_path:()=>{
    return '/scs.json'
  },
  create_subsidary_hood_path:()=>{
    return '/sch.json'
  },
  index_maintenance_kinds_path:()=>{
    return '/mk'
  },
  maintenance_kind_path:(id)=>{ 
    return `/mks?id=${id}`;
  },
  create_maintenance_kind_path:()=>{
    return '/mkc.json'
  },
  update_maintenance_kind_path:()=>{
    return '/mku.json';
  },
  active_maintenance_kind_path:(id)=>{
    return `/mk/:id/a.json`.replace(':id', id);
  },
  disable_maintenance_kind_path:(id)=>{
    return `/mk/:id/d.json`.replace(':id', id);
  },

  create_order_path:()=>{
    return '/oc.json'
  },
  order_path:(id)=>{
    return "/o/:id".replace(':id', id);
  },
  complete_order_path:(id)=>{
    return "/o/:id/c.json".replace(':id', id);
  },
  cancel_order_path:(id)=>{
    return "/o/:id/cancel.json".replace(':id', id);
  },
  order_update_item_path:()=>{
    return "/oui.json";
  },
  order_update_total_price_path:()=>{
    return "/outp.json";
  },
  order_update_units_path:()=>{
    return "/ouu.json";
  },
  order_update_frequency_path:()=>{
    return "/ouf.json";
  },
  order_update_schedule_for_path:()=>{
    return "/ousf.json";
  },
  order_update_due_at_path:()=>{
    return "/ouda.json";
  },
  create_order_note_path:()=>{
    return '/ocn.json'
  },
  
  search_maintenances_path:()=>{
    return '/ms.json'
  },
  reset_maintenance_due_date_path:(id)=>{
    return "/m/:id/rdd.json".replace(':id', id);
  },
  cancel_maintenance_path:(id)=>{
    return "/m/:id/c.json".replace(':id', id);
  },
  update_maintenance_price_path:(id)=>{
    return "/m/:id/up.json".replace(':id', id);
  },

  /*Orders*/
  create_item_order_path:()=>{
    return '/oci.json'
  },
  delete_item_order_path:(id)=>{
    return  "/o/:id/di.json".replace(':id', id);
  },
  /*------*/

  /*Notes*/
  note_update_path:()=>{
    return "/nu.json";
  },
  delete_note_path:(id)=>{
    return  "/n/:id/dn.json".replace(':id', id);
  },
  /*------*/

  /*Subsidary Service*/
  service_update_path:()=>{
    return "/ssu.json";
  },
  service_delete_path:(id)=>{
    return  "/ss/:id/ds.json".replace(':id', id);
  },
  /*------*/

  /*Subsidary Hood*/
  hood_update_path:()=>{
    return "/hu.json";
  },
  hood_delete_path:(id)=>{
    return  "/h/:id/hd.json".replace(':id', id);
  },
  /*------*/

  /*addresses*/
  update_address_path:()=>{
    return '/adu.json';
  },
  /*--------*/

  /*contacts*/
  delete_contact_path:(id)=>{
    return  "/contact/:id/d.json".replace(':id', id);
  },
  create_contact_path:()=>{
    return '/create_contact.json';
  },
  contact_update_path:()=>{
    return "/update_contact.json";
  },
  contacts_path:()=>{
    return '/contacts.json';
  },
  create_phone_contact_path:()=>{
    return '/ccnp.json';
  },
  contact_phone_update_path:()=>{
    return "/update_phone.json";
  },
  delete_phone_path:(id)=>{
    return  "/phone/:id/d.json".replace(':id', id);
  },
  /*/contacts*/

  /*Subsidaries*/
  update_subsidary_path:()=>{
    return '/su.json';
  },

  subsidary_hoods_path:(id)=>{
    return '/s/:id/h.json'.replace(':id', id);
  },

  subsidary_services_path:(id)=>{
    return '/s/:id/s.json'.replace(':id', id);
  },
  active_subsidary_path:(id)=>{
    return `/s/:id/a.json`.replace(':id', id);
  },
  disable_subsidary_path:(id)=>{
    return `/s/:id/d.json`.replace(':id', id);
  },
  /*/Subsidaries*/

  //accounts
  create_account_path:()=>{
    return '/ac.json'
  },
  index_accounts_path:()=>{
    return '/ai'
  },
  account_path:(id)=>{ 
    return `/as?id=${id}`;
  },
  update_account_path:(id)=>{
    return `/a/:id/u.json`.replace(':id', id);
  },
  active_account_path:(id)=>{
    return `/a/:id/a.json`.replace(':id', id);
  },
  disable_account_path:(id)=>{
    return `/a/:id/d.json`.replace(':id', id);
  },
  public_send_reset_password_email_path:(id)=>{
    return `/psrp.json?id=%3Aid`.replace(':id', id);
  },
  //accounts

  //Links//
  delete_link_path:(id)=>{
    return  "/link/:id/d.json".replace(':id', id);
  },
  create_link_path:()=>{
    return '/create_link.json';
  },
  link_update_path:()=>{
    return "/update_link.json";
  },
  /*/Links*/

  //Technicians//
  index_technicians_path:()=>{
    return '/ti'
  },
  create_technician_path:()=>{
    return '/create_technician.json';
  },
  technician_update_path:()=>{
    return "/update_technician.json";
  },
  active_technician_path:(id)=>{
    return `/t/:id/a.json`.replace(':id', id);
  },
  disable_technician_path:(id)=>{
    return `/t/:id/d.json`.replace(':id', id);
  },
  technician_path:(id)=>{
    return `/ts?id=${id}`;
  },
  remove_technician_to_order_path:(id)=>{
    return  "/o/:id/rto.json".replace(':id', id);
  },
  add_technician_to_order_path:()=>{
    return '/oat.json';
  },
  /*/Technicians*/

  /*/Services In Order*/
  assign_service_to_order_path:()=>{
    return '/oas.json';
  },
  /*/Services In Order*/

  //Invoices//
  create_new_invoice_path:()=>{
    return '/ic.json';
  },
  invoice_path:(id)=>{ 
    return `/is?id=${id}`;
  },
  index_invoices_path:()=>{
    return '/ii'
  },
  update_invoice_path:(id)=>{
    return "/update_invoice.json";
  },
  cancel_invoice_path:(id)=>{
    return "/cancel_invoice.json";
  },
  send_email_invoice_path:()=>{
    return "/send_email_invoice.json";
  },
  delete_order_invoice_path:(id)=>{
    return  "/io/:id/d.json".replace(':id', id);
  },
  create_invoice_note_path:()=>{
    return '/icn.json'
  },
  get_invoices_by_state_path:()=>{
    return '/igbs.json'
  },
  send_mail_invoices_by_state_path:()=>{
    return '/ismibs.json'
  },
  get_overdue_invoices_path:()=>{
    return '/igoi.json'
  },
  send_mail_overdue_invoices_path:()=>{
    return '/ismoi.json'
  },
  unbilled_invoices_list_path:()=>{
    return '/iubi' 
  },
  create_invoice_item_path:()=>{
    return '/icii.json';
  },
  update_invoice_item_path:()=>{
    return '/iuii.json';
  },
  delete_invoice_item_path:(id)=>{
    return  "/i/:id/dii.json".replace(':id', id);
  },
  /*/Invoices*/

  //Payments//
  payment_path:(id)=>{ 
    return `/ps?id=${id}`;
  },
  index_payments_path:()=>{
    return '/pi'
  },
  create_payment_path:()=>{
    return '/pc.json';
  },
  update_payment_path:(id)=>{
    return "/update_payment.json";
  },
  cancel_payment_path:(id)=>{
    return "/cancel_payment.json";
  },
  /*/Payments*/

  //Kanela Jobs List//
  jobs_index_list_path:()=>{
    return '/jil'
  },
  get_jobs_list_path:()=>{ 
    return '/gjl.json'
  },
  /*/Kanela Jobs List*/ 
}
