<template lang="pug">

.app-card.overflow-x-auto
  .flex(class='justify-end')
    edit-hood-modal(
      :data-icon='"fa fa-plus"'
      :data-label="$I18n.t('vue.hoods_detail_table_panel.title')"
      :data-mode='"new"'
      :data-item='JSON.stringify(empty_item)'
      :data-equipment-under-kinds='dataEquipmentUnderKinds'
      @updated='createNewHood'
    )
  table(v-if='!emptyItems' class="table")
    thead
      th {{$I18n.t('vue.hoods_detail_table_panel.table_header_equipment_under')}}
      th {{$I18n.t('vue.hoods_detail_table_panel.table_header_description')}}
      th
    tbody
      tr(v-for="item in items")
        td {{item.equipment_under}}
        td {{item.description}}
        td
          .app-buttons-row(class='justify-end')
            delete-hood-modal(
              :data-id='item.id'
              @deleted='deleteHood'
            )
            edit-hood-modal(
              :data-icon='"fa fa-pencil"'
              :data-label='""'
              :data-mode='"edit"'
              :data-item='JSON.stringify(item)'
              :data-equipment-under-kinds='dataEquipmentUnderKinds'
              @updated='updateHood'
            )
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.hoods_detail_table_panel.empty_items')}}

</template>
<script>

import DeleteHoodModal from './delete-hood-modal.vue'
import EditHoodModal from './edit-hood-modal.vue'
import {BackendService} from '../../services';
import CoreComponents from '../core';

export default {
  components:{
    'delete-hood-modal':DeleteHoodModal,
    'edit-hood-modal':EditHoodModal,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataItems: String,
    dataEquipmentUnderKinds: String
  },
  data: function(){
    return {
      state: {
        confirmed: false,
      },
      empty_item:{
        equipment_under:"",
        description:"",
      },
      record:{
        id: this.dataId,
      },
      items: JSON.parse(this.dataItems),
      equipment_under_kinds_options: JSON.parse(this.dataEquipmentUnderKinds),
    }
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  },
  methods:{
    retrieveHoods:function(){
      location.reload();
    },
    createNewHood:function(item){
      const self = this;
      this.state.sending = true;
      let params = JSON.stringify({
        id: this.dataId,
        hood: JSON.parse(item)
      })
      BackendService
        .create_subsidary_hood(params)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    deleteHood:function(hood_id){
      const self = this;
      this.state.sending = true;
      BackendService
        .hood_delete(hood_id)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    updateHood:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .hood_update(params)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
  }
}
</script>
