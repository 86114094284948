<template lang="pug">
.div
  //Orders
  .flex(class='justify-end')
    CreateOrderModal(
      data-model= 'order'
      :data-subsidaries = 'dataSubsidaries'
      :dataSubsidarySelected = 'dataSubsidarySelected'
      :data-item-kinds= 'dataItemKinds'
      :data-options-maintenance-kinds= 'dataOptionsMaintenanceKinds'
      :data-options-maintenance-complement-kinds= 'dataOptionsMaintenanceComplementKinds'
      :data-options-orderable-kinds= 'dataOptionsOrderableKinds'
    )
    
  table(v-if='!emptyItems' class="table table-hover")
    thead
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_id')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_job_number')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_id_kanela')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_status')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_service')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_frequency')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_price')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_preview_cleaning')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_due_on')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_schedule_for')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_compleated_on')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_next_cleaning')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_invoice_ids')}}
      th {{$I18n.t('vue.orders_detail_table_panel.table_header_payment_status')}}
      th
    tbody
      tr(v-for="item in items")
        td(:class="getTextClassItem(item)") {{item.id}}
        td(:class="getTextClassItem(item)") {{item.job_number}}
        td(:class="getTextClassItem(item)") {{item.id_kanela}}
        td(:class="getTextClassItem(item)") {{item.state.name}}
        td(:class="getTextClassItem(item)") {{item.service_item==null ? "" : item.service_item.service}}
        td(:class="getTextClassItem(item)") {{item.frequency_month=="" || item.frequency_month==null ? item.frequency : $I18n.t('vue.orders_detail_table_panel.months')+": "+item.frequency_month}}
        td(:class="getTextClassItem(item)") ${{(item.price*100/100).toFixed(2)}}
        td(:class="getTextClassItem(item)") {{item.previous_cleaning_date}}
        td(:class="getTextClassItem(item)") {{item.locale_due_at}}
        td(:class="getTextClassItem(item)") {{item.locale_schedule_for}}
        td(:class="getTextClassItem(item)") {{item.locale_completed_at}}
        td(:class="getTextClassItem(item)") {{item.next_cleaning_date}}
        td(:class="getTextClassItem(item)")
          app-icon-link(
            v-if="item.invoices.length > 0",
            v-for="(inv, index) in item.invoices",
            :key="index",
            data-kind="link",
            :href="invoice_path(inv.invoice_id)",
            data-target="_blank",
            data-icon="",
            data-style="display: inline-block;",
            :data-label="inv.invoice_id",
            :data-title="$I18n.t('vue.orders_detail_table_panel.view_invoice') + ' ' + inv.invoice_id"
          )
        td(:class="getTextClassItem(item)") {{ item.invoices.map(function(i) { return i.status }).filter(function(value, index, self) { return self.indexOf(value) === index; }).join(', ') }}
        td
          .app-buttons-row.justify-end
            app-icon-link(
              data-kind="link",
              :href="order_path(item.id)"
              data-target="_blank"
              data-icon="fa fa-eye",
              data-label="",
              :data-title="$I18n.t('vue.orders_detail_table_panel.view_order')"
            )
  div(v-if='!emptyItems')
    .row
      .col-9
      .col-3
        .row
          table(class="table table-bordered table-hover")
            tbody
              tr(v-if="total_amount_orders")
                th.flex(class='justify-end')  {{$I18n.t('vue.orders_detail_table_panel.total_orders')}}
                th(class="text-dark") {{total_amount_orders}}
              tr(v-if="total_amount_billed")
                th.flex(class='justify-end')  {{$I18n.t('vue.orders_detail_table_panel.total_billed')}}
                th(class="text-success") {{total_amount_billed}}
              tr(v-if="total_amount_unbilled")
                th.flex(class='justify-end')  {{$I18n.t('vue.orders_detail_table_panel.total_unbilled')}}
                th(class="text-danger")  {{total_amount_unbilled}}

  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.orders_detail_table_panel.empty_items')}}

</template>
<script>
import CoreComponents from "../core";
import CreateOrderModal from './create-order-modal.vue'
const { BackendRoutes } = require("app/services");
export default {
  components:{
    "app-icon-link": CoreComponents.AppIconLink,
    CreateOrderModal,
  },
  props:{
    dataItems: String,
    dataModel: String,
    dataSubsidaries: String,
    dataSubsidarySelected: String,
    dataItemKinds: String,
    dataOptionsMaintenanceKinds: String,
    dataOptionsMaintenanceComplementKinds: String,
    dataOptionsOrderableKinds: String,
    dataTotales: String,
  },
  data: function(){
    return {
      record:{
      },
      items: JSON.parse(this.dataItems),
      total_amount_orders: (!this.dataTotales || /^\s*$/.test(this.dataTotales)) ? "" : JSON.parse(this.dataTotales).total_orders,
      total_amount_billed: (!this.dataTotales || /^\s*$/.test(this.dataTotales)) ? "" : JSON.parse(this.dataTotales).billed,
      total_amount_unbilled: (!this.dataTotales || /^\s*$/.test(this.dataTotales)) ? "" : JSON.parse(this.dataTotales).unbilled,
      labels:{
      }
    }
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  },
  methods:{
    getTextClassItem: function (item) {
      let status = item.state.name;
      let result = "";
      if (status == "CANCELED"){
        result = "text-danger";
      }
      if (status == "COMPLETED"){
        result = "text-success";
      }
      if (status == "SCHEDULED"){
        result = "text-info";
      }
      return result;
    },
    retrievenotes:function(){
      location.reload();
    },
    order_path: function (order_id) {
      return BackendRoutes.order_path(order_id);
    },
    invoice_path: function (invoice_id) {
      return BackendRoutes.invoice_path(invoice_id);
    }
  }
}
</script>
