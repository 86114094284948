<template lang="pug">
.div
  //Orders
  .tw-flex.tw-flex-row.tw-justify-between
    
  table(v-if='!emptyItems' class="table table-hover")
    thead
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_id')}}
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_job_number')}}
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_id_kanela')}}
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_status')}}
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_frequency')}}
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_price')}}
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_due_on')}}
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_schedule_for')}}
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_compleated_on')}}
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_restaurant')}}
      th {{$I18n.t('vue.invoice_orders_table_panel.table_header_invoice_target')}}
      th
    tbody
      tr(v-for="item in items")
        td(class="tw-text-center") {{item.id}}
        td(class="tw-text-center") {{item.job_number}}
        td(class="tw-text-center") {{item.id_kanela}}
        td(class="tw-text-center") {{$I18n.t('vue.states.'+item.state.name.toLowerCase())}}
        td(class="tw-text-center") {{item.frequency_month=="" || item.frequency_month==null ? item.frequency : $I18n.t('vue.invoice_orders_table_panel.months')+": "+item.frequency_month}}
        td ${{(item.price*100/100).toFixed(2)}}
        td {{item.locale_due_at}}
        td {{item.locale_schedule_for}}
        td {{item.locale_completed_at}}
        td {{item.subsidary.name}}
        td {{$I18n.t('vue.invoice_target.'+(item.subsidary.invoice_target+"").toLowerCase())}}
        td
          .app-buttons-row.justify-end
            //remove-order-from-invoice-modal(
              :data-id='item.id'
              @deleted='retrieveLinks'
             )
            app-icon-link(
              data-kind="link",
              :href="order_path(item.id)"
              data-target="_blank"
              data-icon="fa fa-eye",
              data-label="",
              :data-title="$I18n.t('vue.invoice_orders_table_panel.view_order')"
            )
  div(v-if='!emptyItems')
    .row
      .col-9
      .col-3
        .row
          table(class="table table-bordered table-hover")
            tbody
              tr(v-if="calcularTotal()")
                th.flex(class='justify-end')  {{$I18n.t('vue.invoice_orders_table_panel.total_orders')}}
                th(class="text-dark") {{calcularTotal()}}

  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.invoice_orders_table_panel.empty_items')}}

</template>
<script>
import CoreComponents from "../core";
import RemoveOrderFromInvoice from "./remove-order-from-invoice-modal.vue"
const { BackendRoutes } = require("app/services");
export default {
  components:{
    "app-icon-link": CoreComponents.AppIconLink,
    "remove-order-from-invoice-modal": RemoveOrderFromInvoice,
  },
  props:{
    dataItems: String,
  },
  data: function(){
    return {
      record:{
      },
      items: JSON.parse(this.dataItems),
      labels:{
      }
    }
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  },
  methods:{
    retrieveLinks:function(){
      
    },
    order_path: function (order_id) {
      return BackendRoutes.order_path(order_id);
    },
    calcularTotal:function(){
      // Usamos reduce para iterar sobre el array y sumar los precios
      let total = this.items.reduce((total, item) => {
        // Verificamos si el estado del elemento es diferente de "CANCELED"
        if (item.state.name !== "CANCELED") {
          // Si es diferente, sumamos su precio al total
          return total + parseFloat(item.price.replace("$", "").replace(",", "").replace(" ", ""));
        } else {
          // Si es "CANCELED", simplemente retornamos el total sin modificarlo
          return total;
        }
      }, 0); // Inicializamos el total en 0
      return total.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    }
  }
}
</script>
