<template lang='pug'>
  div  
    // Alert (success/error)
    div(class="app-alert-background")
    div(class="app-alert")
      svg(xmlns="http://www.w3.org/2000/svg" style="display: none;")
        symbol(id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16")
          path(d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z")
        symbol(id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16")
          path(d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z")
      div(:class="alert.type==='success' ? 'border-success' : 'border-danger'" class="alert alert-light border-0 border-start border-5 alert-dismissible" role="alert")
        div(:class="alert.type==='success' ? 'app-text-ok' : 'app-text-error'" class="d-flex align-items-center")
          svg(class="bi flex-shrink-0 me-2" width="24" height="24" role="img")
            use(:xlink:href="alert.type==='success' ? '#check-circle-fill' : '#exclamation-triangle-fill'")
          p(class="tw-text-2xl") {{$I18n.t(alert.notice)}}
        div(v-if="alert.type==='success'" class="tw-mt-5")
          p {{$I18n.t(alert.message)}}
            a(class="app-btn-link app-btn-link-primary" :href="alert.link.href") 
              span {{$I18n.t(alert.link.text)}}
        div(v-else class="tw-mt-5")
          p {{alert.message}}
        button(@click="close_alert" type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close")

</template>



<script>
export default{
  props:['alert'],
  mounted(){
    setTimeout(()=>{this.close_alert()},10000);
  },
  computed:{},
  methods:{
    reload_page:function(){
      let options = this.alert.options
      if (options != undefined){
        if (options.update_page == true){
          location.reload();
        }
      }
    },
    close_alert:function(){
      this.reload_page();
      this.alert.display = false;
    },
  }
}
</script>