<template lang="pug">

.app-card.overflow-x-auto
  table(v-if='!emptyItems' class="table")
    thead
      th ID
      th Status
      th Service Kind
      th Hood ID
      th Hood Model
      th Hood Equipment Under
      th Frequency (days)
      th Price (USD)
      th Due on
      th Schedule for
    tbody
      tr(v-for="item in items")
        td
          a(class="app-btn-link" :href="item.paths.show")
            span {{item.id}}
        td {{item.state.name}}
        td {{item.kind.name}}
        td {{item.hood.id}}
        td {{item.hood.kind}}
        td {{item.hood.equipment_under}}
        td {{item.kind.frequency}}
        td
          div
            maintenance-price-value(
              :data-record='item'
              :data-value='item.price'
            )
        td {{item.due_at}}
        td {{item.schedule_for}}

</template>

<script>
import MaintenancePriceValue from './maintenance-price-value.vue'
import { BackendService } from "../../services";

export default {
  components:{
    'maintenance-price-value': MaintenancePriceValue
  },
  props: {
    dataId: String,
    dataModel: String,
    dataItems: String,
  },
  data: function () {
    return {
      record: {
        id: this.dataId,
        model: this.dataModel,
      },
      items: JSON.parse(this.dataItems),
    };
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  },
};
</script>