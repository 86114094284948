<template lang="pug">

div
  app-icon-btn(
    @click.native="showModal"
    data-kind="link",
    :data-icon="dataIcon",
    :data-label="dataLabel",
  )
  // Modal
  app-modal(
    :showing='controls.modal.showing'
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}
    
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        app-form(
          v-if="!state.processing"
          v-bind:grid="form_grid"
          class='tw-py-4'
        )
        p(v-if="verify_hood_selected()")
    
    template(v-slot:footer)
      .app-buttons-row( v-if="!state.sending" class='justify-end' )
        app-icon-btn(
          v-if="dataMode=='new'"
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="update",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>
<script>

import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default{
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components:{
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataItem: String,
    dataIcon: String,
    dataLabel: String,
    dataMode: String,
    dataEquipmentUnderKinds: String,
  },
  data:function(){
    return {
      state:{
        sending: false
      },
      controls:{
        modal:{
          showing: false
        }
      },
      values:{
        equipment_under: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_subsidary_hood.label_hood_equipment_under"));
          i.setValue(JSON.parse(this.dataItem).equipment_under);
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataEquipmentUnderKinds));
          i.required();
        }),
        or_select: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_subsidary_hood.label_or"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("label_center");
        }),
        other_equipment_under: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_subsidary_hood.label_hood_equipment_under_write"));
          i.setValue(JSON.parse(this.dataItem).equipment_under);
          i.setKind("string");
          i.setInputKind("text");
        }),
        description: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_subsidary_hood.label_hood_description"));
          i.setValue(JSON.parse(this.dataItem).description);
          i.setKind("string");
          i.setInputKind("textArea");
          i.optional();
        }),
      },
      labels:{
        modal_title: this.$I18n.t('vue.edit_subsidary_hood.modal_title'),
        clear_btn: this.$I18n.t('vue.edit_subsidary_hood.button_clear'),
        cancel_btn: this.$I18n.t('vue.edit_subsidary_hood.button_cancel'),
        send_btn: this.$I18n.t('vue.edit_subsidary_hood.button_save')
      }
    }
  },
  computed:{
    form_grid:function(){
      return [
        [this.values.equipment_under, this.values.or_select, this.values.other_equipment_under],
        [this.values.description]
      ]
    }
  },
  methods:{
    verify_hood_selected: function () {
      let hood = this.values.equipment_under.input
      let list = JSON.parse(this.dataEquipmentUnderKinds)
      if (list.some(item => item.value === hood)){
        console.log("Esta")
        this.values.other_equipment_under.setValue("");
        this.values.other_equipment_under.notEditable()
      }else{
        console.log("NO Esta")
        if(hood==""){
          hood = JSON.parse(this.dataItem).equipment_under
        }
        this.values.equipment_under.setValue("");
        this.values.other_equipment_under.setValue(hood);
        this.values.other_equipment_under.isEditable()
      }
    },
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function(){
      this.controls.modal.showing = false;
    },
    clear: function(){
      this.resetActiveValues(this.values);
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.hideModal();
      this.resetActiveValues(this.values);
      if (this.dataMode=='new') {
      }else{
        this.values.equipment_under.setValue(JSON.parse(this.dataItem).equipment_under);
        this.values.description.setValue(JSON.parse(this.dataItem).description);
      }
    },
    params:function(){
      return JSON.stringify({
        id: JSON.parse(this.dataItem).id,
        equipment_under: this.values.equipment_under.input,
        description: this.values.description.input,
        state: "ACTIVE"
      })
    },
    update:function(){
      let hood_select = this.values.equipment_under.input.trim()
      let hood_other = this.values.other_equipment_under.input.trim()
      if (hood_select=="" && hood_other!=""){
        this.values.equipment_under.setValue(hood_other)
      }
      let self = this; 
      if(self.areValidActiveValues(this.values)){
        self.state.sending=true;
        let params = this.params()
        this.clear();
        //this.hideModal();
        this.$emit("updated", params);        
      }
    }
  }
}
</script>
