<template lang="pug">

div
  app-icon-btn(
    @click.native="showModal"
    data-kind="link",
    data-icon="fa fa-plus",
    :data-label="$I18n.t('vue.create_order_modal.btn_add_order')"
  )
  // Modal
  app-modal-90(
    :showing='controls.modal.showing'
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}
    
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        Neworder(
          :data-model= 'dataModel'
          :data-subsidaries = 'dataSubsidaries'
          :dataSubsidarySelected = 'dataSubsidarySelected'
          :data-item-kinds= 'dataItemKinds'
          :data-options-maintenance-kinds= 'dataOptionsMaintenanceKinds'
          :data-options-maintenance-complement-kinds= 'dataOptionsMaintenanceComplementKinds'
          :data-options-orderable-kinds='dataOptionsOrderableKinds'
        )
    
    template(v-slot:footer)
      
</template>
<script>
import Neworder from './new_order.vue'
import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
import {BackendService} from '../../services';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default{
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components:{
    "app-modal-90": CoreComponents.Modal90,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
    Neworder,
  },
  props:{
    dataModel: String,
    dataSubsidaries: String,
    dataSubsidarySelected: String,
    dataItemKinds: String,
    dataOptionsMaintenanceKinds: String,
    dataOptionsMaintenanceComplementKinds: String,
    dataOptionsOrderableKinds: String
  },
  data:function(){
    return {
      record:{
        id: this.dataId,
        kind: this.dataKindNote
      },
      controls:{
        modal:{
          showing: false
        }
      },
      state:{
        sending: false
      },
      labels:{
        modal_title: this.$I18n.t('vue.create_order_modal.label_add_note_'+this.dataKindNote),
        clear_btn: this.$I18n.t('vue.create_order_modal.button_clear'),
        cancel_btn: this.$I18n.t('vue.create_order_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.create_order_modal.button_create')
      }
    }
  },
  computed:{
    form_grid:function(){
      return []
    }
  },
  methods:{
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function(){
      this.controls.modal.showing = false;
    },
    clear: function(){
      this.resetActiveValues(this.values);
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.hideModal();
      this.resetActiveValues(this.values);
    },
    params:function(){
      return JSON.stringify({
        id: this.record.id,
        note: {
          kind: this.record.kind,
          description: this.values.description.input.toString(),
          visibility: this.values.visibility.input
        }
      })
    },
    create:function(){
      let self = this; 
      if(self.areValidActiveValues(this.values)){
        if (this.dataModel=='subsidary'){
          this.create_subsidary_note();
        }
        if (this.dataModel=='order'){
          this.create_order_note();
        }
        if (this.dataModel=='invoice'){
          this.create_invoice_note();
        }
      }
    },
    create_subsidary_note:function(){
      let self = this; 
      this.process();
      BackendService
        .create_subsidary_note(self.params())
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
        })
        .finally(()=>{
          //self.processed();
        })
    },
    create_order_note:function(){
      let self = this; 
      this.process();
      BackendService
        .create_order_note(self.params())
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
        })
        .finally(()=>{
          //self.processed();
        })
    },
    create_invoice_note:function(){
      let self = this; 
      this.process();
      BackendService
        .create_invoice_note(self.params())
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
        })
        .finally(()=>{
          //self.processed();
        })
    },
    process:function(){
      this.state.sending = true;
    },
    processed:function(){
      this.state.sending = false;
    }
  }
}
</script>
