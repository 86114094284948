<template lang='pug'>
  div
    //Notification Alert
    Notificationalert(
      v-if="controls.alert.display",
      v-bind:alert="controls.alert",
    )

    //Button link
    app-icon-btn(
      :disabled="this.disabled"
      @click.native="showModal",
      data-kind="link",
      data-icon="fa fa-usd",
      :data-label="$I18n.t('vue.order_actions.invoice_order')"
    )
    app-modal(
      :showing="controls.modal.showing",
      @showing="controls.modal.showing = $event"
    )
      template(#header)
        p {{ labels.modal_title }}
      template(#body)
        // Spinner Loading
        div(v-if="state.sending" class="tw-text-center tw-my-20")
          div(class="spinner-border spinner-border-sm" role="status")

        // FORM
        div(v-else)
          app-form.tw-py-4(v-bind:grid="formGrid")
          //Preguntar si genera todo dentro de 1 factura
          div(v-if="calc_total_other_orders")
          div(v-if="other_orders.length>1" class="text-right")
            input(
              class="form-check-input"
              type="checkbox"
              id="checkbox"
              :checked="controls.checkbox.combine_orders_in_invoice.checked"
              @click="combine_orders_in_invoice()"
            )
            label(
              class="form-check-label tw-ml-2"
              for="checkbox"
            ) {{$I18n.t('vue.new_invoice.combine_orders_in_invoice')}}

        div(v-if="validations!=''")  
          p(class="text-danger") {{validations}}

      template(#footer)
        p(style="display: none") {{get_price_with_credit_card}}
        .app-buttons-row.justify-end(v-if="!state.sending")
          app-icon-btn(
            data-kind="link",
            @click.native="clear",
            data-icon="fa fa-eraser",
            :data-label="labels.clear_btn"
          )
          app-icon-btn(
            data-kind="link",
            @click.native="cancel",
            data-icon="fa fa-times",
            :data-label="labels.cancel_btn"
          )
          //app-icon-btn(
            data-kind="btn",
            @click.native="send",
            data-icon="fa fa-save",
            :data-label="labels.send_btn"
            :disabled="validations != ''"
          //)
          app-icon-btn(
            data-kind="btn",
            @click.native="send",
            data-icon="fa fa-save",
            :data-label="labels.send_btn"
          )
          div(v-if="validations != ''")

</template>

<script>
import { ActiveValue } from "../../core/active_support";
import CoreComponents from "../core";
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");
const { BackendService, BackendRoutes } = require("app/services");

export default {
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components: {
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "Notificationalert": CoreComponents.Notificationalert,
  },
  props:{
    dataOrder: Object, 
    otherOrders: Array,
    disabled: Boolean,
    dataCreditCardPaymentPercentageValue: String,
  },
  data: function () {
    return {
      porcent_default: Number(JSON.parse(this.dataCreditCardPaymentPercentageValue)),
      record: {
        order: this.dataOrder
      },
      other_orders: this.otherOrders.filter(order => order.status !== 'CANCELED'),
      total_other_orders: this.otherOrders.filter(order => order.status !== 'CANCELED').reduce((total, order) => total + Number(order.unbilled_amount), 0),
      controls: {
        modal: {
          showing: false,
        },
        alert: {
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: "",
          },
          options: {
            update_page: true,
          }
        },
        checkbox: {
          combine_orders_in_invoice: {
            checked: true
          }
        },
      },
      state:{
        sending: false
      },
      options: {},
      labels: {
        modal_title: this.$I18n.t('vue.new_invoice.title'),
        clear_btn: this.$I18n.t('vue.new_invoice.clear'),
        cancel_btn: this.$I18n.t('vue.new_invoice.cancel'),
        send_btn: this.$I18n.t('vue.new_invoice.send'),
      },
      values: {
        document_number: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.document_number'));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        date_invoice: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.date'));
          i.setValue(this.dataOrder.schedule_for);
          i.setInputKind("date");
          i.required();
        }),
        porcent_credit_card: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.porcent_credit_card'));
          i.setValue(Number(this.dataCreditCardPaymentPercentageValue).toFixed(2));
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        price: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.price'));
          i.setValue(Number(this.dataOrder.unbilled_amount).toFixed(2));
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        price_with_credit_card: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.price_with_credit_card'));
          i.setValue(Number((this.dataOrder.unbilled_amount + this.total_other_orders) * (1 + (Number(this.dataCreditCardPaymentPercentageValue)/100))).toFixed(2));
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        cod: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.cod'));
          i.setValue(this.dataOrder.subsidary.turn_around_time=='' ? 0 : this.dataOrder.subsidary.turn_around_time);
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
      },
      
    };
  },
  computed: {
    formGrid: function () {
      return [
        [this.values.document_number,  this.values.price],
        [this.values.date_invoice,     this.values.porcent_credit_card],
        [this.values.cod,              this.values.price_with_credit_card],
      ];
    },
    invoice_path: function(){
      return BackendRoutes.invoice_path(this.result.invoice.id);
    },
    get_price_with_credit_card: function(){
      let num_ids = 1
      let price = 0
      let price_with_cr = 0
      let percentage = 0
      price = Number(this.values.price.input)
      percentage = Number(this.values.porcent_credit_card.input)
      price_with_cr = price * (1 + (percentage/100))
      //this.values.price.setValue(price.toFixed(2));
      //this.values.porcent_credit_card.setValue(percentage.toFixed(2));
      this.values.price_with_credit_card.setValue(price_with_cr.toFixed(2));
      return price_with_cr;
    },
    validations: function(){
      let message = ""
      //Importe NO mayor al de la orden
      let price_invoice = Number(this.dataOrder.price)
      if(this.controls.checkbox.combine_orders_in_invoice.checked == true){
        price_invoice = this.otherOrders.filter(order => order.status !== 'CANCELED').reduce((total, order) => total + Number(order.price), 0)
      }
      //console.log("Validacion 1", Number(this.values.price.input), price_invoice);
      if (parseFloat(Number(this.values.price.input).toFixed(2)) > parseFloat(price_invoice.toFixed(2))) {
        message = this.$I18n.t('vue.new_invoice.messages.amount_greater_than_the_order')
      }
      //Importe facturado no mayor al capturado
      let billed_total = Number(this.dataOrder.billed_amount);
      if(this.controls.checkbox.combine_orders_in_invoice.checked == true){
        billed_total = this.otherOrders.filter(order => order.status !== 'CANCELED').reduce((total, order) => total + Number(order.billed_amount), 0)
      }
      //console.log("Validacion 2", Number(this.dataOrder.billed_amount), billed_total, price_invoice);
      //console.log( ((billed_total+Number(this.values.price.input)) > price_invoice) );
      if (billed_total != 0 && (parseFloat((billed_total + Number(this.values.price.input)).toFixed(2)) > parseFloat(price_invoice.toFixed(2)))) {
        message = this.$I18n.t('vue.new_invoice.messages.total_charged_greater_than_the_order')
        //  sera mejor con "unbilled_total"???????
      }
      //No es posible aplicar pagos con "Faltante por facturar" igual a 0.0
      let unbilled_total = Number(this.dataOrder.unbilled_amount);
      if(this.controls.checkbox.combine_orders_in_invoice.checked == true){
        unbilled_total = this.otherOrders.filter(order => order.status !== 'CANCELED').reduce((total, order) => total + Number(order.unbilled_amount), 0)
      }
      //console.log("Validacion 3", Number(this.dataOrder.unbilled_amount), unbilled_total);
      if (parseFloat(unbilled_total.toFixed(2)) === 0) {
        message = this.$I18n.t('vue.new_invoice.messages.unbilled_total_zero')
      }
      return message;
    },
    calc_total_other_orders: function(){
      this.calc_totales();
    },
  },
  methods: {
    calc_totales: function () {
      let price = Number(this.dataOrder.unbilled_amount);
      let price_other_orders = this.total_other_orders
      if(this.controls.checkbox.combine_orders_in_invoice.checked == true){
        price = price_other_orders
      }
      price = price.toFixed(2)
      this.values.price.setValue(price)
      this.values.price_with_credit_card.setValue(price * (1 + (Number(this.dataCreditCardPaymentPercentageValue)/100)))
    },
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    clear: function () {
      this.resetActiveValues(this.values);
      this.values.date_invoice.setValue(this.dataOrder.schedule_for);
      this.values.porcent_credit_card.setValue(Number(this.dataCreditCardPaymentPercentageValue).toFixed(2));
      this.values.price.setValue(Number(this.dataOrder.unbilled_amount).toFixed(2));
      this.values.price_with_credit_card.setValue(Number(this.dataOrder.unbilled_amount * (1 + (Number(this.dataCreditCardPaymentPercentageValue)/100))).toFixed(2));
      this.values.cod.setValue(this.dataOrder.subsidary.turn_around_time);
      this.calc_totales();
    },
    cancel: function () {
      this.hideModal();
      this.clear();
    },
    disabled_inputs: function () {
      
    },
    combine_orders_in_invoice: function () {
      this.controls.checkbox.combine_orders_in_invoice.checked = !this.controls.checkbox.combine_orders_in_invoice.checked
    },
    send_params: function () {
      let array_order_ids = [this.record.order.id];
      if(this.controls.checkbox.combine_orders_in_invoice.checked == true){
        array_order_ids = this.otherOrders.filter(order => order.status !== 'CANCELED').map((order) => order.id)
      }
      let params = JSON.stringify({
        order_ids: array_order_ids,
        date_create: '',
        date_invoice: this.values.date_invoice.input,
        porcent_credit_card: this.values.porcent_credit_card.input,
        price: this.values.price.input,
        price_with_credit_card: this.values.price_with_credit_card.input+"",
        document_number: this.values.document_number.input,
        status: '',
        amount_paid: '',
        outstanding_balance: '',
        cod: this.values.cod.input+"",
      });
      return params;
    },
    send: function () {
      let self = this;
      if (this.areValidActiveValues(this.values)) {
        let params = this.send_params()
        self.state.sending = true;
        BackendService
          .create_new_invoice(params)
          .then((response) => {
            self.result = response;
            self.controls.alert.notice =
              "vue.new_invoice.result." + response.notice;
            if (response.isOk) {
              self.controls.alert.type = "success";
              self.controls.alert.message =
                "vue.new_invoice.view_detail_text";
              self.controls.alert.link.text = "vue.new_invoice.show_link";
              self.controls.alert.link.href = this.invoice_path;
              self.hideModal();
              self.clear();
            } else {
              self.controls.alert.type = "danger";
            }
          })
          .finally(() => {
            self.state.sending = false;
            self.controls.alert.display = true;
          });
      }
    },
  },
};
</script>
