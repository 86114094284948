<template lang='pug'>
div
  div(class="tw-flex tw-flex-row tw-justify-between")
    div
      h5(class="h5 tw-text-gray-500") {{$I18n.t('vue.address_detail.title')}}
    div(class="tw-flex tw-flex-row")
      button(v-if='state.show' @click='edit()'   class="app-btn-link-primary") 
        i(class="fa fa-pencil tw-mx-1") 
        span {{$I18n.t('vue.address_detail.edit')}}
      button(v-if='state.edit' @click='cancel()' class="app-btn-link-primary")
        i(class="fa fa-times tw-mx-2")
        span {{$I18n.t('vue.address_detail.cancel')}}
      button(v-if='state.edit' @click='update()' class="app-btn-link-primary")
        i(class="fa fa-save tw-mx-2")
        span {{$I18n.t('vue.address_detail.save')}}
  hr
  div(class="container-fluid")
    Detailgrid(v-if='state.show' v-bind:grid="grid")
    Detailform(v-if='state.edit' v-bind:grid="grid")
  div(v-if="state.sending" class="tw-text-center tw-my-20")
      div(class="spinner-border spinner-border-sm" role="status")
</template>

<script>

import CoreComponents from '../core';
const {ActiveValue}=require('../../core/active_support');
import {BackendService} from '../../services';

export default{
  props:['dataRecord'],
  components:{...CoreComponents},
  data:function(){
    this.record= JSON.parse(this.dataRecord);
    let self=this;
    return {
      state:{
        show:true,
        edit:false,
        sending:false,
      },
      values:{
        street_address: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.customer_address_detail.label_address_street'));
          i.setValue(self.record.address.street_address);
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        }),
        secundary_address: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.customer_address_detail.label_address_apartament'));
          i.setValue(self.record.address.secundary_address);
          i.setKind('string');
          i.setInputKind('text');
          i.optional();
        }),
        city: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.customer_address_detail.label_address_city'));
          i.setValue(self.record.address.city);
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        }),
        state_code: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.customer_address_detail.label_address_state'));
          i.setValue(self.record.address.state_code);
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        }),
        zip_code: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.customer_address_detail.label_address_zipCode'));
          i.setValue(self.record.address.zip_code);
          i.setKind('string');
          i.setInputKind('text');
          i.required();
        })
      }
    }
  },
  computed:{
    grid:function(){
      return [
        [this.values.street_address, this.values.secundary_address, this.values.city],
        [this.values.state_code,     this.values.zip_code, '']
      ]
    }
  },
  methods:{
    valid:function(){
      return Object
        .values(this.values)
        .map(v=>v.validate())
        .map(v=>v.hasError())
        .every(e=>e===false)
    },
    edit:function(){
      this.state.edit=true;
      this.state.show=false;
      this.state.sending=false;
    },
    cancel:function(){
      this.state.show=true;
      this.state.edit=false;
      this.state.sending=false;
      Object
        .values(this.values)
        .map(v=>v.valueToInput());
    },
    sending:function(){
      this.state.show=false;
      this.state.edit=false;
      this.state.sending=true;
    },
    stringify:function(){
      return JSON.stringify({
        id: this.record.id,
        address:{
          street_address: this.values.street_address.input,
          secundary_address:  this.values.secundary_address.input,
          state_code: this.values.state_code.input,
          city: this.values.city.input,
          zip_code: this.values.zip_code.input
        }
      })
    },
    update:function(){
      const self=this;
      if(this.valid()){
        self.sending();
        BackendService
        .update_address(this.stringify())
        .then((r)=>{
          self.cancel();
          self.values.street_address.value=r.address.street_address;
          self.values.secundary_address.value=r.address.secundary_address;
          self.values.state_code.value=r.address.state_code;
          self.values.city.value=r.address.city;
          self.values.zip_code.value=r.address.zip_code;
        })
      }
    }
  }
}
</script>