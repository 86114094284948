export default {
  data: function () {
    return {
      state: {
        processing: false
      }
    }
  },
  methods: {
    process: function () { this.state.processing = true; },
    processed: function () { this.state.processing = false; }
  },
  computed: {
    processing: function () { return this.state.processing; }
  }
}