<template lang='pug'>
  div(class="app-notifications")
    div(class="app-notification" v-for='notification in notifications')
      div(class='app-notification-icon') 
         div(style='margin:auto') {{notification.kind}}
      div(class='app-notification-msg')
        div(style='margin:auto') {{notification.msg}}
        div(v-if='notification.link')
          a(
            :href='notification.link.href'
            target='_blank' 
          ) {{notification.link.text}}
      div(
        class='app-notification-close' 
        @click='_=>remove(notification.id)'
      ) 
         div(style='margin:auto') close
</template>

<script>
export default {
  data: function(){
    return {
      notifications:[],
      time: 8000
    };
  },
  methods:{
    push: function(notification){
      let self = this;
      notification.id = Date.now() + Math.floor(Math.random() * 10000);
      this.notifications.push(notification);
      setTimeout(_=>{
        self.remove(notification.id)
      }, self.time);
    },
    remove: function(id){
      console.log(id)
      this.notifications = this.notifications.filter(n=>n.id!=id);
    }
  },
  mounted: function(){
    let self=this;
    window.addEventListener('show-notification', (e)=>{
      self.push(e.detail)
    })
  }
}
</script>