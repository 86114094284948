<template lang='pug'>
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display" 
    v-bind:alert="controls.alert"
  )

  .tw-flex.tw-flex-row.tw-justify-between
    div
      h5.h5.tw-text-gray-500 {{$I18n.t('vue.vendor_detail.title')}}

    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center")
      div(class="spinner-border spinner-border-sm" role="status")

    //Barra de botones  
    div(v-else)
      .tw-flex.tw-flex-row
        delete-vendor-modal(
          v-if="record.state!='INACTIVE' && state.show"
          :data-id='record.id'
          :data-label="$I18n.t('vue.vendor_detail.inactive_vendor')"
          @deleted='disable_vendor'
        )
        button.app-btn-link-primary(v-if="record.state=='INACTIVE' && state.show", @click="active_vendor()") 
          i.fa.fa-check-circle.tw-mx-1 
          span {{$I18n.t('vue.vendor_detail.active_vendor')}}
        button.app-btn-link-primary(v-if="state.show", @click="edit()") 
          i.fa.fa-pencil.tw-mx-1 
          span {{$I18n.t('vue.vendor_detail.edit')}}
        button.app-btn-link-primary(v-if="state.edit", @click="cancel()")
          i.fa.fa-times.tw-mx-2
          span {{$I18n.t('vue.vendor_detail.cancel')}}
        button.app-btn-link-primary(v-if="state.edit", @click="update()")
          i.fa.fa-save.tw-mx-2
          span {{$I18n.t('vue.vendor_detail.save')}}
  hr
  Detailgrid(:data-grid="grid" :data-editing='state.edit')

</template>

<script>
import DeleteVendorModal from './delete-vendor-modal.vue'
import CoreComponents from "../core";
import { BackendService } from "../../services";
const { ActiveValue } = require("../../core/active_support");

export default {
  props: ["dataRecord", "dataStates"],
  components: { 
    'delete-vendor-modal':DeleteVendorModal,
    ...CoreComponents 
  },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    let self = this;
    return {
      state: {
        show: true,
        edit: false,
        sending: false,
      },
      controls:{
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      values: {
        id: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.vendor_detail.id"));
          i.setValue(self.record.id);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        name: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.vendor_detail.name"));
          i.setValue(self.record.name);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        aka: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.vendor_detail.aka"));
          i.setValue(self.record.aka);
          i.setKind("string");
          i.setInputKind("text");
        }),
        state: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.vendor_detail.state"));
          i.setValue(self.$I18n.t("vue.states."+self.record.state.toLowerCase()));
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataStates));
          i.required();
          i.notEditable();
        }),
        phone_number: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.vendor_detail.phone_number"));
          i.setValue(self.record.phone.number);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        })
      },
    };
  },
  methods: {
    disable_vendor: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
      }
    },
    active_vendor: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .active_vendor(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    valid: function () {
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    edit: function () {
      this.state.edit = true;
      this.state.show = false;
      this.state.sending = false;
    },
    cancel: function () {
      this.state.show = true;
      this.state.edit = false;
      this.state.sending = false;
      Object.values(this.values).map((v) => v.valueToInput());
    },
    sending: function () {
      this.state.show = false;
      this.state.edit = false;
      this.state.sending = true;
    },
    stringify: function () {
      return JSON.stringify({
        atts: {
          name: this.values.name.input,
          aka: this.values.aka.input,
          state: this.values.state.input,
          phone_number: this.values.phone_number.input,
        },
      });
    },
    update: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .update_vendor(
            self.record.id,
            this.stringify()
          )
          .then((r) => {
            if (r.isOk){
              //self.controls.alert.type="success"
              //self.controls.alert.message = 'vue.empty'
              //self.controls.alert.link.text = 'vue.empty'
              //self.controls.alert.link.href=''
            }else{
              self.controls.alert.notice = 'vue.vendor_detail.result.'+r.notice
              self.controls.alert.type="danger"
              self.controls.alert.display=true
            }
            self.cancel();
            self.values.name.setValue(r.vendor.name);
            self.values.aka.setValue(r.vendor.aka);
            self.values.state.setValue(r.vendor.state);
            self.values.phone_number.setValue(r.vendor.phone.number);
          })
          .finally(()=>{
            self.state.sending=false;
          });
      }
    },
  },
  computed: {
    grid: function () {
      return [
        [this.values.id,    this.values.state         ],
        [this.values.name,  this.values.phone_number  ],
        [this.values.aka,   ''                        ]
      ];
    }
  },
};
</script>
