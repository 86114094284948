<template lang="pug">
div
  app-icon-btn(
    @click.native="showModal",
    data-kind="link",
    data-icon="fa fa-plus",
    :data-label="$I18n.t('vue.create_customer_modal.modal_title')"
  )
  app-modal(
    :showing="controls.modal.showing",
    @showing="controls.modal.showing = $event"
  )
    template(#header)
      p {{ labels.modal_title }}

    template(#body)
      // Spinner Loading
      div(v-if="state.processing" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")
      app-form.tw-py-4(v-else, v-bind:grid="formGrid")
    template(#footer)
      .app-buttons-row.justify-end(v-if="!state.processing")
        app-icon-btn(
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="create",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>

<script>
import { ActiveValue } from "../../core/active_support";
import { BackendService } from "../../services";
import CoreComponents from "../core";
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components: {
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
  },
  props: {
    dataId: String,
    dataModel: String,
  },
  data: function () {
    return {
      record: {
        id: this.dataId,
        model: this.dataModel,
      },
      controls: {
        modal: {
          showing: false,
        },
      },
      state:{
        processing: false,
      },
      labels: {
        modal_title: this.$I18n.t("vue.create_customer_modal.modal_title"),
        clear_btn: this.$I18n.t("vue.create_customer_modal.button_clear"),
        cancel_btn: this.$I18n.t("vue.create_customer_modal.button_cancel"),
        send_btn: this.$I18n.t("vue.create_customer_modal.button_create"),
      },
      values: {
        name: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_name"));
          i.setValue("");
          i.setInputKind("text");
          i.required();
        }),
        aka: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_aka"));
          i.setValue("");
          i.setInputKind("text");
        }),
        phone_number: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_phone_number"));
          i.setInputKind("text");
          i.required();
        }),
        street_address: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_customer_form.label_address_street")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        secundary_address: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_customer_form.label_address_apartament")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.optional();
        }),
        city: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_address_city"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        state_code: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_customer_form.label_address_state"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        zip_code: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_customer_form.label_address_zipCode")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
      },
    };
  },
  computed: {
    formGrid: function () {
      return [
        [this.values.name],
        [this.values.aka, this.values.phone_number],
        [
          this.values.street_address,
          this.values.secundary_address,
          this.values.city,
        ],
        [this.values.state_code, this.values.zip_code, ""],
      ];
    },
  },
  methods: {
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    clear: function () {
      this.resetActiveValues(this.values);
    },
    cancel: function () {
      this.hideModal();
      this.resetActiveValues(this.values);
    },
    create_params: function () {
      return JSON.stringify({
        name: this.values.name.input,
        aka: this.values.aka.input,
        state: "ACTIVE",
        vendor_id: this.record.id,
        phone_number: this.values.phone_number.input,
        address: {
          street_address: this.values.street_address.input,
          secundary_address: this.values.secundary_address.input,
          city: this.values.city.input,
          state_code: this.values.state_code.input,
          zip_code: this.values.zip_code.input,
        },
      });
    },
    create: function () {
      let self = this;
      if (this.areValidActiveValues(this.values)) {
        self.state.processing=true;
        BackendService.create_customer(this.create_params())
        .then(
          (response) => {
            if (response.isOk) {
              location.reload();
            }
          }
        );
      }
    },
  },
};
</script>
