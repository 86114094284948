<template lang="pug">
  //En desuso??????
  div
    div(v-if="!editing || !value.editable")
      p(v-if="value.kind !== 'link'") {{value.value}}
      a(
        v-if="value.kind === 'link'"
        class='app-btn-link' 
        :href='value.link.href'
      ) {{value.value}}

    div(v-if='editing && value.editable')
      // Type Text
      input(
        v-if='value.inputKind==="text"'
        v-model="value.input",
        :name="value.inputName"
        :disabled="!value.editable"
        type="text",
        class='form-control'
        :title="value.inputTitle"
      )
      // Type Number
      input(
        class="form-control"
        v-if="value.inputKind==='number'"
        type="number"
        v-model="value.input",
        :disabled="!value.editable"
      )
      // Type Decimal
      input(
        class="form-control"
        v-if="value.inputKind==='decimal'"
        type="number"
        step="0.01"
        v-model="value.input",
        :disabled="!value.editable"
      )
      // Type Date
      input(
        class="form-control"
        v-if="value.inputKind==='date'"
        type="date"
        step="0.01"
        v-model="value.input",
        :disabled="!value.editable"
      )
      // Type Textarea
      textarea(
        class="form-control"
        v-if="value.inputKind==='textArea'"
        rows="3"
        v-model="value.input",
        :disabled="!value.editable"
      )
      // Type Hidden
      input(
        class="form-control"
        v-if="value.inputKind==='hidden'"
        type="hidden"
        v-model="value.input",
        :disabled="!value.editable"
      )
      // Type Select
      select(
        class="form-select" 
        v-if="value.inputKind==='select'"
        v-model="value.input",
        :disabled="!value.editable"
      )
        option(class="app-label-color" value="" selected) {{$I18n.t('vue.general_messages.choose_option')}}
        option(v-for="option in value.getInputOption('options')" :value="option.value") {{option.label}}
      p.app-text-error(v-if="value.error") {{ $I18n.t('vue.general_messages.' + value.error) }}
</template>

<script>
export default {
  props:['value', 'editing']
}
</script>
