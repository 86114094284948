<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display",
    v-bind:alert="controls.alert"
  )

  // Spinner Loading
  .tw-text-center.tw-my-20(v-if="state.sending")
    .spinner-border.spinner-border-sm(role="status")

  // FORM
  div(v-else)
    //Data Invoice
    .flex(class='justify-between items-center')
      h5(class="h5 pt-4 tw-text-gray-500") {{$I18n.t('vue.new_invoice_form.subtitle_invoice_detail')}}
    hr
    app-form.tw-py-4(v-bind:grid="formGrid")

    p(style="display: none;") {{set_values_amount}}

    //List Orders
    .flex(class='justify-between items-center')
      h5(class="h5 pt-4 tw-text-gray-500") {{$I18n.t('vue.new_invoice_form.list_orders_detail')}}
    hr
    div(v-if='emptyItems')
      p(class='message-search') {{$I18n.t('vue.new_invoice_form.no_orders')}}
    div(v-else)
      div(class="app-table-scrollable-500")
        table.table
          thead
            tr
              th
                input(
                  class="form-check-input"
                  type="checkbox"
                  :checked='controls.checkbox.checked_masive.checked'
                  @click="checked_masive()"
                )
              th {{$I18n.t('vue.new_invoice_form.table_header_number')}}
              th {{$I18n.t('vue.new_invoice_form.table_header_status')}}
              th {{$I18n.t('vue.new_invoice_form.table_header_vendor')}}
              th {{$I18n.t('vue.new_invoice_form.table_header_customer')}}
              th {{$I18n.t('vue.new_invoice_form.table_header_subsidary')}}
              th {{$I18n.t('vue.new_invoice_form.table_header_frecuency')}}
              th {{$I18n.t('vue.new_invoice_form.table_header_schedule_for')}}
              th {{$I18n.t('vue.new_invoice_form.table_header_price')}}
              th {{$I18n.t('vue.new_invoice_form.table_header_billed')}}
              th {{$I18n.t('vue.new_invoice_form.table_header_unbilled')}}
          tbody
            tr(v-for="(order, index) in items")
              td 
                input(
                  class="form-check-input"
                  type="checkbox"
                  v-model='order.checked'
                ) 
              td {{ order.job_number==null ? order.id : order.job_number}}
              td {{ $I18n.t('vue.states.'+order.status.toLowerCase()) }}
              td 
                a(:href="order.vendor.show_path" 
                  target="_blank"
                  class="app-btn-link-primary"
                ) {{order.vendor.name}}
              td 
                a(:href="order.customer.show_path" 
                  target="_blank"
                  class="app-btn-link-primary"
                ) {{order.customer.name}}
              td 
                a(:href="order.subsidary.show_path" 
                  target="_blank"
                  class="app-btn-link-primary"
                ) {{order.subsidary.name}}
              td {{ order.frecuency }}
              td {{ order.locale_schedule_for }}
              td {{ order.price }}
              td {{ order.billed }}
              td {{ order.unbilled }}
              td 
                app-icon-link(
                  data-kind="link",
                  :href="order.paths.show"
                  data-icon="fa fa-eye",
                  data-label="",
                  data-target="_blank",
                  :data-title="$I18n.t('vue.new_invoice_form.view_order')"
                )

    app-form.tw-py-4(v-bind:grid="formGridAmounts")

    //BUTTONS
    .tw-text-right.tw-mt-7(v-if="!state.sending")
      .form-group.row
        .row
          .col
            a.app-btn-link-primary(@click="clear") 
              i.fa.fa-eraser.mr-2
              span {{ $I18n.t('vue.new_invoice_form.button_clear') }}
            a.app-btn-link-primary(:href="index_invoices_path") 
              i.fa.fa-times.mr-2
              span {{ $I18n.t('vue.new_invoice_form.button_cancel') }}
            button.app-btn-primary(@click="send" :disabled='count_order_ids == 0') 
              i.fa.fa-save.mr-2
              span {{ $I18n.t('vue.new_invoice_form.button_create') }}
</template>
<script>
import { ActiveValue } from "../../core/active_support";
import CoreComponents from "../core";
const {BackendService,BackendRoutes} = require('app/services');
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  props: {
    dataItems: String,
    dataCreditCardPaymentPercentageValue: String,
  },
  data: function () {
    this.orders = JSON.parse(this.dataItems);
    return {
      porcent_default: Number(JSON.parse(this.dataCreditCardPaymentPercentageValue)),
      controls: {
        checkbox: {
          checked_masive: {
            checked: false
          }
        },
        alert: {
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: "",
          },
          options: {
            update_page: true,
          }
        },
      },
      items: [],
      values: {
        document_number: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.document_number'));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        date_invoice: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.date'));
          i.setValue("");
          i.setInputKind("date");
          i.required();
        }),
        porcent_credit_card: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.porcent_credit_card'));
          i.setValue(Number(this.dataCreditCardPaymentPercentageValue).toFixed(2));
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        price: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.price'));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        price_with_credit_card: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.price_with_credit_card'));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        cod: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.new_invoice.cod'));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
      },
      state: {
        compleated: true,
        sending: false,
      },
      result: {
        isError: undefined,
        isOk: undefined,
        key: undefined,
        notice: undefined,
        vendor: {
          id: undefined,
        },
      },
    };
  },
  computed: {
    set_values_amount:function(){
      let self = this
      let amount = self.values.price.input
      let percentage = self.values.porcent_credit_card.input
      let amount_with_admin_fee = Number(amount) * (1 + (Number(percentage) / 100))
      self.values.price_with_credit_card.setValue(amount_with_admin_fee.toFixed(2));
      return amount_with_admin_fee
    },
    count_order_ids: function(){
      let num_ids = 0
      let amount_total = 0
      let amount_with_cr_total = 0
      let self = this
      this.items.forEach( function(item, indice, array) {
        if (item.checked==true){
          num_ids = num_ids + 1
          amount_total = amount_total + Number(item.unbilled_amount)
          if (self.values.date_invoice.input==""){
            self.values.date_invoice.setValue(item.schedule_for);
          }
          if (self.values.cod.input==""){
            self.values.cod.setValue(item.subsidary.cod);
          }
        }
      });
      if (num_ids==0){
        self.values.date_invoice.setValue("");
        self.values.cod.setValue("");
      }
      amount_with_cr_total = amount_total * (1 + (this.values.porcent_credit_card.input/100))
      this.values.price.setValue(amount_total.toFixed(2));
      this.values.price_with_credit_card.setValue(amount_with_cr_total.toFixed(2));
      return num_ids;
    },
    emptyItems: function(){
      return this.orders.length === 0;
    },
    index_invoices_path: function () {
      return BackendRoutes.index_invoices_path();
    },
    invoice_path: function () {
      return BackendRoutes.invoice_path(this.result.invoice.id);
    },
    formGrid: function () {
      return [
        [this.values.document_number,  this.values.date_invoice],
        [this.values.cod,              ''],
      ];
    },
    formGridAmounts: function () {
      return [
        [this.values.price,   this.values.porcent_credit_card,  this.values.price_with_credit_card],
      ];
    },
  },
  methods: {
    vendor_path: function (vendor_id) {
      return BackendRoutes.vendor_path(vendor_id);
    },
    checked_masive: function (){
      let self = this; 
      self.controls.checkbox.checked_masive.checked = !self.controls.checkbox.checked_masive.checked
      self.items.forEach( function(item, indice, array) {
        item.checked = self.controls.checkbox.checked_masive.checked
      });
    },
    orders_to_table: function(){
      let self = this; 
      self.orders.forEach( function(order, indice, array) {
        if (order.masked_billed_amount != order.masked_price){
          self.items.push({
            checked: false,
            id: order.id,
            status: order.state.name,
            vendor: {
              name: order.vendor.name,
              show_path: order.vendor.paths.show,
            },
            customer: {
              name: order.customer.name,
              show_path: order.customer.paths.show,
            },
            subsidary: {
              name: order.subsidary.name,
              cod: order.subsidary.turn_around_time,
              show_path: order.subsidary.paths.show,
            },
            frecuency: order.frequency,
            locale_schedule_for: order.locale_schedule_for,
            schedule_for: order.schedule_for,
            price: order.masked_price,
            amount: order.price,
            billed: order.masked_billed_amount,
            unbilled: order.masked_unbilled_amount,
            billed_amount: order.billed_amount,
            unbilled_amount: order.unbilled_amount,
            paths: {
              show: order.paths.show
            },
          })
        }
      });
    },
    valid: function () {
      return Object.values(this.values)
        .map((i) => {
          i.validate();
          return i.hasError();
        })
        .every((e) => e === false);
    },
    stringify: function () {
      let self = this;
      let array_order_ids = [];
      this.items.forEach( function(item, indice, array) {
        if (item.checked==true){
          array_order_ids.push(item.id)
        }
      });
      let params = JSON.stringify({
        order_ids: array_order_ids,
        date_create: '',
        date_invoice: this.values.date_invoice.input,
        porcent_credit_card: this.values.porcent_credit_card.input,
        price: this.values.price.input,
        price_with_credit_card: this.values.price_with_credit_card.input+"",
        document_number: this.values.document_number.input,
        status: '',
        amount_paid: '',
        outstanding_balance: '',
        cod: this.values.cod.input,
      });
      return params;
    },
    send: function () {
      let self = this;
      if (self.valid()) {
        self.state.sending = true;
        console.log("params:",self.stringify())
        BackendService
          .create_new_invoice(self.stringify())
            .then((response) => {
              self.result = response;
              self.controls.alert.notice =
                "vue.new_invoice_form.result." + response.notice;
              if (response.isOk) {
                self.controls.alert.type = "success";
                self.controls.alert.message =
                  "vue.new_invoice_form.view_detail_text";
                self.controls.alert.link.text = "vue.new_invoice_form.show_link";
                self.controls.alert.link.href = this.invoice_path;
                self.clear();
              } else {
                self.controls.alert.type = "danger";
              }
            })
            .finally(() => {
              self.state.sending = false;
              self.controls.alert.display = true;
            });
      }
    },
    clear: function () {
      Object.values(this.values).forEach((v) => v.resetValue());
      this.values.porcent_credit_card.setValue(Number(this.dataCreditCardPaymentPercentageValue).toFixed(2));
      this.items.forEach((item) => item.checked=false);
      this.controls.checkbox.checked_masive.checked = false;
    },
  },
  mounted: function(){
    this.orders_to_table();
  },
  components: { 
    "app-modal": CoreComponents.Modal,
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-form": CoreComponents.Detailform,
    ...CoreComponents,
  },
};
</script>
