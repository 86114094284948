import Vue        from 'vue/dist/vue.esm';
import Components from './components'
import I18n       from './i18n';

Vue.prototype.$I18n=I18n;
const A={
  UI:{
    render:(selector)=>{
      return new Vue({
        el: selector,
        components: Components,
        beforeMount:function(){
          this.$I18n.locale(this.$el.getAttribute("locale"));
        }
      });
    }
  }
}
export default A;