// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//= require moment
//= require bootstrap

import '../app/assets/stylesheets'

import 'bootstrap';

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import App from 'app';

Rails.start()
window.Rails=Rails;
ActiveStorage.start()

window.onload=function(){
  window.app= App.UI.render('#app');
}