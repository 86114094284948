<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display",
    v-bind:alert="controls.alert"
  )

  // Spinner Loading
  .tw-text-center.tw-my-20(v-if="state.sending")
    .spinner-border.spinner-border-sm(role="status")

  // FORM
  div(v-else)
    Detailform(v-bind:grid="grid_subsidary")
    
    .h5.pt-4.tw-text-gray-500.tw-text-left
      h5.h5.pt-4 {{ $I18n.t('vue.new_subsidary_form.subtitle_services') }}
      hr
    .app-buttons-row( class='justify-end' )
      edit-subsidary-service(
        :data-icon='"fa fa-plus"'
        :data-label="$I18n.t('vue.new_subsidary_form.add_service')"
        :data-mode='"new"'
        :data-item='JSON.stringify([])'
        :data-hide-modal='"true"'
        @updated='addService'
      )
    div(v-if='emptyServiceItems')
      p(class='message-search') {{ $I18n.t('vue.new_subsidary_form.no_services') }}
    div(v-else)
      table.table
        thead
          tr
            th {{ $I18n.t('vue.new_subsidary_form.table_services_service') }}
            th {{ $I18n.t('vue.new_subsidary_form.table_services_frecuency') }}
            th {{ $I18n.t('vue.new_subsidary_form.table_services_base_price') }}
            th {{ $I18n.t('vue.new_subsidary_form.table_services_breakdown') }}
            th {{ $I18n.t('vue.new_subsidary_form.table_services_total') }}
        tbody
          tr(v-for="item in array_service_items_by_subsidary")
            td {{ item.service }}
            td(v-if="item.frequency != ''") {{ item.frequency }}
            td(v-else) {{ $I18n.t('vue.new_subsidary_form.label_service_months') }} {{ item.frequency_month }}
            td {{ item.base_price }}
            td {{ item.breakdown }}
            td {{ item.total }}
            td
              app-icon-btn(
                data-kind="link",
                @click.native="removeService(item)",
                data-icon="fa fa-window-close",
                data-label="",
                :data-title="$I18n.t('vue.new_subsidary_form.table_services_delete_service')"
              )

    .h5.pt-4.tw-text-gray-500.tw-text-left
      h5.h5.pt-4 {{ $I18n.t('vue.new_subsidary_form.subtitle_address') }}
      hr
    Detailform(v-bind:grid="grid_address")

    .h5.pt-4.tw-text-gray-500.tw-text-left
      h5.h5.pt-4 {{ $I18n.t('vue.new_subsidary_form.subtitle_contact') }}
      hr
    div(class="text-right")
      input(
        class="form-check-input"
        type="checkbox"
        id="checkbox"
        @click="check_copy_contacts_from_vendor()"
      )
      label(
        class="form-check-label tw-ml-2"
        for="checkbox"
      ) {{$I18n.t('vue.create_subsidary_modal.contacts_from_vendor')}}
    div(v-if="!controls.checkbox.copy_contacts_from_vendor.checked")
      Detailform(v-bind:grid="grid_contact") 

    .h5.pt-4.tw-text-gray-500.tw-text-left
      h5.h5.pt-4 {{ $I18n.t('vue.new_subsidary_form.subtitle_notes') }}
      hr
    Detailform(v-bind:grid="grid_notes") 

    //BUTTONS
    .tw-text-right.tw-mt-7(v-if="!state.sending")
      .form-group.row
        .row
          .col
            a.app-btn-link-primary(@click="clear") 
              i.fa.fa-eraser.mr-2
              span {{ $I18n.t('vue.new_subsidary_form.button_clear') }}
            a.app-btn-link-primary(:href="index_subsidaries_path") 
              i.fa.fa-times.mr-2
              span {{ $I18n.t('vue.new_subsidary_form.button_cancel') }}
            button.app-btn-primary(@click="send") 
              i.fa.fa-save.mr-2
              span {{ $I18n.t('vue.new_subsidary_form.button_create') }}
</template>
<script>
import CoreComponents from "../core";
import Editsubsidaryservice from "../services/edit-service-modal.vue";
const { ActiveValue } = require("../../core/active_support");
const { BackendService, BackendRoutes } = require("app/services");
export default {
  props: {
    dataCustomers: String,
    dataInvoiceTarget: String,
    dataNoteKindVisible: String,
  },
  data: function () {
    return {
      controls: {
        alert: {
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: "",
          },
        },
        checkbox: {
          copy_contacts_from_vendor: {
            checked: false
          }
        },
      },
      inputs: {
        //Inputs Subsidary
        customer: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.customer"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataCustomers));
          i.required();
        }),
        name: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_name"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        aka: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_aka"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        subsidary_phone: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_phone"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        turn_around_time: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_turn_around_time"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("number");
        }),
        fax_number: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_fax"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        keys: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_keys"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        alarm: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_alarm"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        hours_of_operation: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_hours_of_operation"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        invoice_target: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_invoice_tarjet"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataInvoiceTarget));
          i.required();
        }),
        //Inputs Notes
        service_note_description: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_service_note_description"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("textArea");
        }),
        service_note_visibility: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_service_note_visibility"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataNoteKindVisible));
        }),
        billing_note_description: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_billing_note_description"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("textArea");
        }),
        billing_note_visibility: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_billing_note_visibility"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataNoteKindVisible));
        }),
        work_note_description: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_work_note_description"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("textArea");
        }),
        work_note_visibility: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_subsidary_form.label_work_note_visibility"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataNoteKindVisible));
        }),
        //Inputs Address
        street_address: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_address_street")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        secundary_address: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_address_apartament")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.optional();
        }),
        city: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_address_city")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        state_code: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_address_state")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        zip_code: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_address_zipCode")
          );
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        //Inputs Contact
        role: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_contact_role")
          );
          i.setValue("");
          i.setInputKind("text");
        }),
        first_name: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_contact_first_name")
          );
          i.setInputKind("text");
        }),
        middle_name: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_contact_middle_name")
          );
          i.setInputKind("text");
        }),
        last_name: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_contact_last_name")
          );
          i.setInputKind("text");
        }),
        email: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_contact_email")
          );
          i.setKind("email");
          i.setInputKind("text");
        }),
        phone_kind: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t("vue.new_subsidary_form.label_contact_phone_kind")
          );
          i.setValue("");
          i.setInputKind("text");
        }),
        phone_number: ActiveValue().setup((i) => {
          i.setLabel(
            this.$I18n.t(
              "vue.new_subsidary_form.label_contact_phone_number"
            )
          );
          i.setInputKind("text");
        }),
      },
      state: {
        compleated: true,
        sending: false,
      },
      result: {
        isError: undefined,
        isOk: undefined,
        key: undefined,
        notice: undefined,
        vendor: {
          id: undefined,
        },
      },
      array_service_items_by_subsidary: [],
    };
  },
  computed: {
    index_subsidaries_path: function () {
      return BackendRoutes.index_subsidaries_path();
    },
    subsidary_path: function () {
      return BackendRoutes.subsidary_path(this.result.subsidary.id);
    },
    grid_subsidary: function () {
      return [
        [this.inputs.name,                this.inputs.aka,                this.inputs.customer],
        [this.inputs.subsidary_phone,     this.inputs.fax_number,         this.inputs.turn_around_time],
        [this.inputs.keys,                this.inputs.alarm,              this.inputs.hours_of_operation],
        [this.inputs.invoice_target, "", ""],
      ];
    },
    grid_address: function () {
      return [
        [
          this.inputs.street_address,
          this.inputs.secundary_address,
          this.inputs.city,
        ],
        [this.inputs.state_code, this.inputs.zip_code],
      ];
    },
    grid_contact: function () {
      return [
        [this.inputs.role, "", ""],
        [
          this.inputs.first_name,
          this.inputs.middle_name,
          this.inputs.last_name,
        ],
        [this.inputs.phone_kind, this.inputs.phone_number, this.inputs.email],
      ];
    },
    grid_notes: function () {
      return [
        [this.inputs.service_note_description, this.inputs.service_note_visibility],
        [this.inputs.billing_note_description, this.inputs.billing_note_visibility],
        [this.inputs.work_note_description, this.inputs.work_note_visibility],
      ];
    },
    emptyServiceItems: function(){
      return this.array_service_items_by_subsidary.length === 0;
    }
  },
  methods: {
    valid: function () {
      let ins = this.inputs;
      //If notes is blank, option is required
      //   Note Service
      if (ins['service_note_description'].isPresent()){
        ins['service_note_visibility'].required();
      }else{
        ins['service_note_visibility'].optional();
      }
      if (ins['service_note_visibility'].isPresent()){
        ins['service_note_description'].required();
      }else{
        ins['service_note_description'].optional();
      }
      //   Note Billing
      if (ins['billing_note_description'].isPresent()){
        ins['billing_note_visibility'].required();
      }else{
        ins['billing_note_visibility'].optional();
      }
      if (ins['billing_note_visibility'].isPresent()){
        ins['billing_note_description'].required();
      }else{
        ins['billing_note_description'].optional();
      }
      //   Note Work
      if (ins['work_note_description'].isPresent()){
        ins['work_note_visibility'].required();
      }else{
        ins['work_note_visibility'].optional();
      }
      if (ins['work_note_visibility'].isPresent()){
        ins['work_note_description'].required();
      }else{
        ins['work_note_description'].optional();
      }

      return Object.values(this.inputs)
        .map((i) => {
          i.validate();
          return i.hasError();
        })
        .every((e) => e === false);
    },
    stringify: function () {
      const params = {
        id: this.inputs.customer.input.toString(),
        subsidary: {
          name: this.inputs.name.input,
          aka: this.inputs.aka.input,
          state: "ACTIVE",
          phone_number: this.inputs.subsidary_phone.input,
          //
         	turn_around_time: this.inputs.turn_around_time.input,
          fax_number: this.inputs.fax_number.input,
          keys: this.inputs.keys.input,
          alarm: this.inputs.alarm.input,
          hours_of_operation: this.inputs.hours_of_operation.input,
          invoice_target: this.inputs.invoice_target.input,
          //
          copy_contacts_from_vendor: this.controls.checkbox.copy_contacts_from_vendor.checked+"",
          //
          address: {
            street_address: this.inputs.street_address.input,
            secundary_address: this.inputs.secundary_address.input,
            city: this.inputs.city.input,
            state_code: this.inputs.state_code.input,
            zip_code: this.inputs.zip_code.input,
          },
          notes: [],
          services: this.array_service_items_by_subsidary,
        },
      };
      //Agrega contacto, solo si existe algun dato
      let ins = this.inputs;
      let contact_keys = [
        "role",
        "first_name",
        "middle_name",
        "last_name",
        "phone_kind",
        "phone_number",
        "email",
      ];
      let contactIsPresent = contact_keys
        .map((k) => ins[k].isPresent())
        .some((v) => v === true);
      if (contactIsPresent) {
        params.subsidary.contact = {
          role_name: this.inputs.role.input.toString(),
          first_name: this.inputs.first_name.input,
          middle_name: this.inputs.middle_name.input,
          last_name: this.inputs.last_name.input,
          email: this.inputs.email.input,
          phone_kind: this.inputs.phone_kind.input.toString(),
          phone_number: this.inputs.phone_number.input,
        };
      }
      
      //Si hay nota de service, la agrega
      if (this.inputs.service_note_description.isPresent()) {
        params.subsidary.notes.push({
          kind: 'service',
          description: this.inputs.service_note_description.input,
          visibility: this.inputs.service_note_visibility.input,
        })
      }
      //Si hay nota de billing, la agrega
      if (this.inputs.billing_note_description.isPresent()) {
        params.subsidary.notes.push({
          kind: 'billing',
          description: this.inputs.billing_note_description.input,
          visibility: this.inputs.billing_note_visibility.input,
        })
      }
      //Si hay nota de work, la agrega
      if (this.inputs.work_note_description.isPresent()) {
        params.subsidary.notes.push({
          kind: 'work',
          description: this.inputs.work_note_description.input,
          visibility: this.inputs.work_note_visibility.input,
        })
      }

      return JSON.stringify(params);
    },
    send: function () {
      let self = this;
      if (self.valid()) {
        self.state.sending = true;
        BackendService.create_customer_subsidary(self.stringify())
          .then((response) => {
            self.result = response;
            self.controls.alert.notice =
              "vue.new_subsidary_form.result." + response.notice;
            if (response.isOk) {
              self.controls.alert.type = "success";
              self.controls.alert.message =
                "vue.new_subsidary_form.view_detail_text";
              self.controls.alert.link.text = "vue.new_subsidary_form.show_link";
              self.controls.alert.link.href = this.subsidary_path;
              self.clear();
            } else {
              self.controls.alert.type = "danger";
            }
          })
          .catch(() => {
            self.result.isError = true;
            self.controls.alert.type = "error";
            self.controls.alert.notice =
              "vue.new_subsidary_form.result.error_promise";
          })
          .finally(() => {
            self.state.sending = false;
            self.controls.alert.display = true;
          });
      }
    },
    clear: function () {
      Object.values(this.inputs).forEach((v) => v.resetValue());
    },
    addService: function (params) {
      let item = JSON.parse(params)
      let new_item = item
      this.array_service_items_by_subsidary.push(new_item);
    },
    removeService: function (item) {
      this.array_service_items_by_subsidary.splice(this.array_service_items_by_subsidary.indexOf(item), 1);
    },
    check_copy_contacts_from_vendor:function (){
      this.controls.checkbox.copy_contacts_from_vendor.checked = !this.controls.checkbox.copy_contacts_from_vendor.checked
      if (this.controls.checkbox.copy_contacts_from_vendor.checked){
        let ins = this.inputs;
        let contact_keys = [
          "role",
          "first_name",
          "middle_name",
          "last_name",
          "phone_kind",
          "phone_number",
          "email",
        ];
        let some_contact_inputs_present = contact_keys
          .map((k) => ins[k].setValue(""))
      }
      
    },
  },
  components: { 
    ...CoreComponents,
    "edit-subsidary-service": Editsubsidaryservice
  },
};
</script>
