<template lang="pug">
.div
  //Orders
  .tw-flex.tw-flex-row.tw-justify-between
  table(v-if='!emptyItems' class="table")
    thead
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_id')}}
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_number')}}
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_status')}}
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_vendor')}}
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_customer')}}
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_subsidary')}}
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_date_invoice')}}
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_amount')}}
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_porcent')}}
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_amount_with_credit_card')}}
      th {{$I18n.t('vue.payment_invoices_table_panel.table_header_cod')}}
      th
    tbody
      tr(v-for="item in items")
        td {{item.id}}
        td {{item.document_number}}
        td {{$I18n.t('vue.states.'+item.status.toLowerCase())}}
        td(v-html="item.list_vendors_in_links")
        td(v-html="item.list_customers_in_links")
        td(v-html="item.list_subsidaries_in_links")
        td {{item.locale_date}}
        td {{item.masked_amount}}
        td {{item.masked_porcent}}
        td {{item.masked_amount_with_credit_card}}
        td {{item.cod}}
        td
          .app-buttons-row.justify-end
            app-icon-link(
              data-kind="link",
              :href="order_path(item.id)"
              data-target="_blank"
              data-icon="fa fa-eye",
              data-label="",
              :data-title="$I18n.t('vue.payment_invoices_table_panel.view_order')"
            )
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.payment_invoices_table_panel.empty_items')}}

</template>
<script>
import CoreComponents from "../core";
const { BackendRoutes } = require("app/services");
export default {
  components:{
    "app-icon-link": CoreComponents.AppIconLink,
  },
  props:{
    dataItems: String,
  },
  data: function(){
    return {
      record:{
      },
      items: JSON.parse(this.dataItems),
      labels:{
      }
    }
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  },
  methods:{
    retrieveLinks:function(){
      
    },
    order_path: function (invoice_id) {
      return BackendRoutes.invoice_path(invoice_id);
    }
  }
}
</script>
