<template lang="pug">
  div
    div(class="row")
      div(class="col")
        div(class="app-card tw-mb-5")
          div(class="tw-pl-10 tw-pr-20")
            //Spinner Loading
            div(v-if="state.loading" class="tw-text-center tw-my-20")
              div(class="spinner-border spinner-border-sm" role="status")
            div(v-else)
              div(class="row")
                div(class="col-9")
                div(class="col-3 tw-text-right")
                  //Button Add
                  ul 
                    li.nav-link.app-btn-link 
                      i.fa.fa-plus.tw.mx-1
                      a(id='addMaintenanceModalTrigger') {{$I18n.t('vue.hood_maintenances.label_add_maintenance')}}
                    Hoodaddmaintenance(data-trigger-id='addMaintenanceModalTrigger'
                                      :data-hood-id='hood_id'
                                      :data-kinds='dataKinds'
                                      v-on:update="retrieveMaintenances")

              //Table Maintenances
              table(class="table")
                thead
                  tr
                    th {{$I18n.t('vue.hood_maintenances.table_header_id')}}
                    th {{$I18n.t('vue.hood_maintenances.table_header_status')}}
                    th {{$I18n.t('vue.hood_maintenances.table_header_maintenance_kind')}}
                    th {{$I18n.t('vue.hood_maintenances.table_header_maintenance_frequency')}}
                    th {{$I18n.t('vue.hood_maintenances.table_header_maintenance_price')}}
                    th {{$I18n.t('vue.hood_maintenances.table_header_restaurant')}}
                    th {{$I18n.t('vue.hood_maintenances.table_header_due_at')}}
                    th {{$I18n.t('vue.hood_maintenances.table_header_completed_at')}}
                tbody
                  tr(v-for="item in items")
                    td(class="app-btn-link")
                      a(:href="item.paths.show")
                        span {{item.id}}
                    td {{item.state.name}}
                    td(class="app-btn-link")
                      a(:href="item.kind.paths.show") 
                        span {{item.kind.name}}
                    td {{item.kind.frequency}}
                    td {{item.kind.price}}
                    td(class="app-btn-link")
                      a(:href="item.subsidary.paths.show")
                        span {{item.subsidary.name}}
                    td {{item.due_at}}
                    td {{item.completed_at}}                 
</template>
<script>
  import Hoodaddmaintenance from './hood_add_maintenance.vue';
  const {BackendService,BackendRoutes} = require('app/services');
  export default{
    props:{
      dataItems: String,
      dataKinds: String,
      dataHoodId: String
    },
    data:function(){
      this.hood_id  = this.dataHoodId;
      this.addMaintenanceModalTrigger = "addMaintenanceModalTrigger"
      return {
        items: JSON.parse(this.dataItems),
        state:{
          loading:false,
          compleated:true
        },
        result:{
          isError: undefined,
          isOk: undefined,
          key: undefined,
          notice: undefined,
        },
        values:{

        }
      }
    },
    computed:{},
    methods:{
      retrieveMaintenances:function(){
        let self = this; 
        self.state.loading=true;
        BackendService.retrieve_maintenances_by_hood(self.hood_id)
          .then((response)=>{
            self.result = response;
            if (response.isOk){
              self.items = response.maintenances;
            }
          })
          .catch(()=>{
            self.result.isError=true;
            self.result.notice='error_promise';
          })
          .finally(()=>{
            self.state.loading=false;
          })
      }
    },
    components:{Hoodaddmaintenance}
  }
</script>