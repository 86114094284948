import LinksDetailTablePanel from './links-detail-table-panel.vue';
import EditLinkModal  from './edit-link-modal.vue';
import DeleteLinkModal  from './delete-link-modal.vue';

export default{
  'links-detail-table-panel': LinksDetailTablePanel,
  'edit-link-modal': EditLinkModal,
  'delete-link-modal': DeleteLinkModal
}

