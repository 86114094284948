<template lang="pug">

div
  app-icon-btn(
    @click.native="showModal"
    data-kind="link",
    :data-icon="dataIcon",
    :data-label="dataLabel",
    :data-title="dataTitle"
  )
  // Modal
  app-modal(
    :showing='controls.modal.showing'
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}
    
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        app-form(
          v-if="!state.sending"
          v-bind:grid="form_grid"
          class='tw-py-4'
        )
        div(v-if="!record.present")
          p(class="text-danger") {{$I18n.t('vue.edit_phone_contact_modal.necessary_capture_value')}}
      
    template(v-slot:footer)
      .app-buttons-row( v-if="!state.sending" class='justify-end' )
        app-icon-btn(
          v-if="dataMode=='new'"
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="update",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>
<script>

import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default{
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components:{
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataId: String,
    dataContactId: String,
    dataModel: String,
    dataItem: String,
    dataIcon: String,
    dataLabel: String,
    dataTitle: String,
    dataMode: String,
  },
  data:function(){
    return {
      record:{
        id: this.dataId,
        contact_id: this.dataContactId,
        model: this.dataModel,
        present: true
      },
      controls:{
        modal:{
          showing: false
        }
      },
      state:{
        sending: false
      },
      values:{
        phone_kind: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.edit_phone_contact_modal.phone_kind'));
          i.setValue(JSON.parse(this.dataItem).kind);
          i.setKind("string");
          i.setInputKind('text');
          i.required();
        }),
        phone_number: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.edit_phone_contact_modal.phone_number'));
          i.setValue(JSON.parse(this.dataItem).number);
          i.setKind("string");
          i.setInputKind('text');
          i.required();
        })
      },
      labels:{
        modal_title: this.$I18n.t('vue.edit_phone_contact_modal.label_phone'),
        clear_btn: this.$I18n.t('vue.edit_phone_contact_modal.button_clear'),
        cancel_btn: this.$I18n.t('vue.edit_phone_contact_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.edit_phone_contact_modal.button_create')
      }
    }
  },
  computed:{
    form_grid:function(){
      return [
        [this.values.phone_kind, this.values.phone_number]
      ]
    }
  },
  methods:{
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function(){
      this.controls.modal.showing = false;
    },
    clear: function(){
      this.resetActiveValues(this.values);
      this.reset_values();
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.hideModal();
      this.clear();
    },
    reset_values:function(){
      this.values.phone_kind.setValue(JSON.parse(this.dataItem).kind);
      this.values.phone_number.setValue(JSON.parse(this.dataItem).number);
    },
    params:function(){
      return JSON.stringify({
        id: JSON.parse(this.dataItem).id,
        contact_id: this.record.contact_id,
        contactable: this.record.model+"#"+this.record.contact_id,
        phone: {
          kind: this.values.phone_kind.input.toString(),
          number:this.values.phone_number.input
        }
      })
    },
    valid: function () {
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    update:function(){
      let self = this; 
      if(self.valid()){
        self.state.sending=true;
        let params = this.params()
        this.clear();
        //this.hideModal();
        this.$emit("updated", params);        
      }
    }
  }
}
</script>
