<template lang="pug">
.div
  //Notes
  //.tw-flex.tw-flex-row.tw-justify-between
  div.row
    div.col-8
      h5.h5.tw-text-gray-500 {{$I18n.t('vue.notes_detail_table_panel.subtitle_'+dataKindNote)}}
    div.col-2
      p.h5.tw-text-gray-500 {{$I18n.t('vue.notes_detail_table_panel.visibility')}}
    div.col-2
      .tw-flex.tw-flex-row
        create-note-modal(
          :data-id='record.id' 
          :data-kind-note='record.kind'
          :data-model='dataModel'
          :data-note-kind-visible='dataNoteKindVisible'
          @created='retrievenotes'
        )
  hr
  div.tw-px-5
    ul( style="list-style-type:circle;")
      li(v-for="item in items")
        div.row
          div.col-8 {{item.description}}
          div.col-2
            //p {{$I18n.t('vue.notes_detail_table_panel.visible')}}: {{$I18n.t('vue.notes_detail_table_panel.visible_'+item.visibility)}}
            p(:class="item.visibility=='employee' || item.visibility=='everyone' ? 'text-success' : 'text-danger'") {{$I18n.t('vue.notes_detail_table_panel.visible_employee')}}
              i(:class="item.visibility=='employee' || item.visibility=='everyone' ? 'fa fa-eye' : 'fa fa-eye-slash'")
            p(:class="item.visibility=='customer' || item.visibility=='everyone' ? 'text-success' : 'text-danger'") {{$I18n.t('vue.notes_detail_table_panel.visible_customer')}}
              i(:class="item.visibility=='customer' || item.visibility=='everyone' ? 'fa fa-eye' : 'fa fa-eye-slash'")
            br
          div.col-2
            .app-buttons-row( class='justify-end' )
              delete-note-modal(
                :data-id='item.id'
                @deleted='retrievenotes'
              )
              edit-note-modal(
                :data-item='JSON.stringify(item)'
                :data-note-kind-visible='dataNoteKindVisible'
                @updated='retrievenotes'
              )
          
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.notes_detail_table_panel.empty_items')}}

</template>
<script>

import CreateNoteModal from './create-note-modal.vue'
import DeleteNoteModal from './delete-note-modal.vue'
import EditNoteModal from './edit-note-modal.vue'
import {BackendService} from '../../services';
import CoreComponents from '../core';

export default {
  components:{
    'create-note-modal':CreateNoteModal,
    'delete-note-modal':DeleteNoteModal,
    'edit-note-modal':EditNoteModal,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataKindNote: String,
    dataItems: String,
    dataNoteKindVisible: String
  },
  data: function(){
    return {
      record:{
        id: this.dataId,
        kind: this.dataKindNote
      },
      items: JSON.parse(this.dataItems),
      note_kinds_visible_option: JSON.parse(this.dataNoteKindVisible),
      labels:{
        modal_title: this.$I18n.t('vue.create_contact_modal.label_add_contact'),
        clear_btn: this.$I18n.t('vue.create_contact_modal.button_clear'),
        cancel_btn: this.$I18n.t('vue.create_contact_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.create_contact_modal.button_create')
      }
    }
  },
  computed:{
    emptyItems: function(){
      return this.items.length === 0;
    }
  },
  methods:{
    retrievenotes:function(){
      location.reload();
    }
  }
}
</script>
