<template lang="pug">

.app-card.overflow-x-auto
  
  div.container
    .row
      .col-md-3(v-for="(column, columnIndex) in columns" :key="columnIndex")
        div(v-for="checkbox in column" :key="checkbox.value")
          .form-check
            input.form-check-input(type="checkbox" :id='"checkbox"+checkbox.value' :value='checkbox.value' v-model="checkbox.check" :disabled='checkbox.disabled')
            label.form-check-label(:for='"checkbox"+checkbox.value') {{checkbox.value}}

  div.container
    hr
    div.row
      div.col-6
      div.col-6
        
        table(class="table table-bordered table-hover")
          tbody
            tr
              th.flex(class='justify-end')  {{$I18n.t('vue.units_detail_table_panel.total_units')}}
              th(class="text-dark") {{calcularTotal[0]}}
            tr
              th.flex(class='justify-end')  {{$I18n.t('vue.units_detail_table_panel.total_units_price')}}
              th(class="text-dark") {{calcularTotal[1]}}

  div.container(v-if="getSelectedUnits()")
    div(v-if="units_active != units_active_original")
      .flex.justify-end
        div(v-if="state.sending" class="tw-text-center tw-mx-10 tw-my-10")
          div(class="spinner-border spinner-border-sm" role="status")
        div(v-else)  
          button.app-btn-primary(
            @click="save_units"
          ) {{$I18n.t('vue.units_detail_table_panel.save_units')}}
    
</template>
<script>

import {BackendService} from '../../services';
import CoreComponents from "../core";

export default {
  components:{
    "app-icon-link": CoreComponents.AppIconLink,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataOrder: String,
    dataTotales: String,
  },
  data: function(){
    return {
      state: {
        sending: false,
      },
      record:{
        id: this.dataId,
        model: this.dataModel
      },
      data_order: JSON.parse(this.dataOrder),
      units_active: JSON.parse(this.dataOrder).units+"",
      units_active_original: JSON.parse(this.dataOrder).units,
      total_units_price: 0,
      checkboxes: []  // Inicializamos como un array vacío
    }
  },
  created() {
    const activeUnits = this.units_active.split(',').map(Number);
    this.checkboxes = Array.from({ length: 40 }, (_, i) => ({
      value: (i + 1)+"",
      check: activeUnits.includes(i + 1),
      disabled: this.data_order.state.name=="COMPLETED" || this.data_order.state.name=="CANCELED" ? true : false
    }));
  },
  computed:{
    columns() {
      const cols = [[], [], [], []];
      this.checkboxes.forEach((checkbox, index) => {
        cols[Math.floor(index / 10)].push(checkbox);
      });
      return cols;
    },
    calcularTotal:function(){
      let result = [0,0];
      // Usamos reduce para iterar sobre el array y sumar los precios
      let total_units = 0;
      this.checkboxes.forEach((item) => {
        // Verificamos si el estado del elemento es diferente de "CANCELED"
        if (item.check == true) {
          // Si esta seleccionado lo suma
          total_units += 1;
        }
      });
      this.total_units_price = total_units * 162.50;
      result[0] = total_units
      result[1] = this.total_units_price.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
      console.log(result)
      return result
    }
  },
  methods:{
    getSelectedUnits() {
      let units_selected = this.checkboxes
                            .filter(checkbox => checkbox.check)
                            .map(checkbox => checkbox.value)
                            .join(',');
      this.units_active = units_selected;
      console.log(this.units_active)
      return true;
    },
    params: function () {
      const params = {
        id: this.record.id,
        units: this.units_active+"",
        units_price: this.total_units_price+"",
      };
      return JSON.stringify(params);
    },
    save_units: function () {
      //Guarda los units seleccionados en un  string
      //Guarda el total $ de units 
      //Actualiza Precio de orden
      //Recarga la pagina
      let self = this;
      self.state.sending = true;
      BackendService.order_update_units(this.params())
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
        })
        .finally(()=>{
          //self.processed();
        })
    },
  }
}
</script>
