<template lang='pug'>

  .tw-inline-flex
    //Cancelar Factura
    cancel-invoice-modal(
      :disabled= 'invoice.status == "CANCELED"' 
      :data-id='invoice.id'
      :data-payments='payments'
    )

    //Pagar Factura
    apply-payment-modal(
      :disabled= 'invoice.status == "CANCELED" || Number(invoice.total_unpaid_amount)==0' 
      :data-invoice='invoice'
      :data-id='invoice.id'
      :data-way-to-pay='dataWayToPay'
    )

    //Enviar Factura por correo
    send-email-invoice-modal(
      :data-invoice='invoice'
      :data-id='invoice.id'
    )
    
    //Imprimir factura
    a(
      class="app-btn-link-primary" 
      :href='invoice.paths.paper_invoice+"?type=C"'
      target='_blank'
      role="button" 
    )
      i(class="fa fa-file mr-2")
      span {{$I18n.t('vue.invoice_actions.paper_invoice')}}

    //Descargar Factura
    a(
      class="app-btn-link-primary" 
      :href='invoice.paths.download_invoice+"?type=C"'
      target='_blank'
      role="button" 
    )
      i(class="fa fa-download mr-2")
      span {{$I18n.t('vue.invoice_actions.download_invoice')}}

    
</template>

<script>

import CancelInvoiceModal from './cancel-invoice-modal.vue';
import ApplyPaymentModal from './apply-payment-modal.vue';
import SendEmailInvoiceModal from './send-email-invoice-modal.vue';

export default {
  components:{
    'cancel-invoice-modal':CancelInvoiceModal,
    'apply-payment-modal': ApplyPaymentModal,
    'send-email-invoice-modal': SendEmailInvoiceModal,
  },
  props:['dataRecord','dataPayments','dataWayToPay'],
  data: function(){
    return {
      invoice: JSON.parse(this.dataRecord),
      payments: JSON.parse(this.dataPayments),
    }
  }
}
</script>
