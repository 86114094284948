import Neworder from './new_order.vue'
import CreateOrderModal from './create-order-modal.vue'
import Orderactions from './order_actions.vue'
import OrderableSelector from './orderable_selector.vue'
import Orderschedule from './order_schedule.vue'
import Orderdueat from './order_due_at.vue'
import Ordercancel from './order_cancel.vue'
import OrderDetail from './order_detail.vue'
import OrdersDetailTablePanel from './orders_detail_table_panel.vue'
import UnitsDetailTablePanel from './units-detail-table-panel.vue'
export default{
    Neworder,
    CreateOrderModal,
    Orderactions,
    'orderable-selector': OrderableSelector,
    'order-detail': OrderDetail,
    Orderschedule,
    Orderdueat,
    Ordercancel,
    'orders-detail-table-panel': OrdersDetailTablePanel,
    'units-detail-table-panel': UnitsDetailTablePanel
}