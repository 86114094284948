<template lang="pug">

.app-card.overflow-x-auto
  .flex(class='justify-end')
    edit-link-modal(
      :data-icon='"fa fa-plus"'
      :data-label="$I18n.t('vue.links_detail_panel.add_link')"
      :data-mode='"new"'
      :data-item='JSON.stringify(empty_item)'
      :data-id='record.id' 
      :data-model='record.model'
      @updated='createNewLink'
    )
  table(v-if='!emptyItems' class="table")
    thead
      th {{$I18n.t('vue.links_detail_panel.table_header_link')}}
      th {{$I18n.t('vue.links_detail_panel.table_header_description')}}
      th
    tbody
      tr(v-for="item in all_links")
        td 
          a(:href="item.link" 
            target="_blank"
            class="app-btn-link-primary"
          ) {{item.link}}
        td {{item.description}}
        td
          .app-buttons-row(class='justify-end')
            delete-link-modal(
              :data-id='item.id'
              @deleted='retrieveLinks'
            )
            edit-link-modal(
              :data-icon='"fa fa-pencil"'
              :data-label='""'
              :data-mode='"edit"'
              :data-item='JSON.stringify(item)'
              @updated='updateLink'
            )
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.links_detail_panel.empty_items')}}

</template>
<script>

import DeleteLinkModal from './delete-link-modal.vue'
import EditLinkModal from './edit-link-modal.vue'
import {BackendService} from '../../services';

export default {
  components:{
    'delete-link-modal':DeleteLinkModal,
    'edit-link-modal':EditLinkModal
  },
  props:{
    dataId: String,
    dataModel: String,
    dataOrder: String,
    dataItems: String,
    dataOtherOrders: String,
  },
  data: function(){
    return {
      state: {
        confirmed: false,
      },
      record:{
        id: this.dataId,
        model: this.dataModel
      },
      items: JSON.parse(this.dataItems),
      other_orders: JSON.parse(this.dataOtherOrders),
      data_order: JSON.parse(this.dataOrder),
      empty_item: {
        link:"",
        description:""
      }
    }
  },
  computed:{
    all_links: function(){
      var items = []
      if (this.other_orders.length != 0 && this.data_order.state.name != 'CANCELED'){
        this.other_orders.forEach(order => {
          if(order.state.name != "CANCELED"){
            order.links.forEach(link => {
              if(link.deleted_at === null){
                items.push(link)
              }
            })
          }
        })
      }else{
        items = this.items
      }
      return items
    },
    emptyItems: function(){
      return this.all_links.length === 0;
    },
  },
  methods:{
    showLink:function(path){
    },
    retrieveLinks:function(){
      let self = this; 
      BackendService
        .links(JSON.stringify({
          query:{
            linkable_type: self.record.model,
            linkable_id: self.record.id
          }
        }))
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            self.items = response.links;
          }
        })
        .finally(()=>{
        })
    },
    updateLink:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .link_update(params)
        .then((response) => {
          if (response.isOk) {
            location.reload();
          }
        })
        .catch(() => {
          self.result.isError = true;
          self.result.notice = "error_promise";
          self.state.sending = false;
        });
    },
    createNewLink:function(params){
      const self = this;
      this.state.sending = true;
      BackendService
        .create_link(params)
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
          self.state.sending = false;
        })
        .finally(()=>{
        })
    },
  }
}
</script>
