export default {
  methods: {
    emitNotification: function (notification) {
      window.dispatchEvent(
        new CustomEvent('show-notification', {
          detail: notification
        })
      )
    },
    emitOkNotification: function ({ msg, link }) {
      this.emitNotification({
        kind: 'ok',
        msg: msg,
        link: link
      })
    }
  }
}