<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display" 
    v-bind:alert="controls.alert"
  )

  .tw-flex.tw-flex-row.tw-justify-between
    div
      h5.h5.tw-text-gray-500 {{ $I18n.t('vue.customer_detail.title') }}

    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center")
      div(class="spinner-border spinner-border-sm" role="status")

    //Barra de botones  
    div(v-else)
      .tw-flex.tw-flex-row
        delete-customer-modal(
          v-if="record.state!='INACTIVE' && state.show"
          :data-id='record.id'
          :data-label="$I18n.t('vue.customer_detail.inactive_customer')"
          @deleted='disable_customer'
        )
        button.app-btn-link-primary(v-if="record.state=='INACTIVE' && state.show", @click="active_customer()") 
          i.fa.fa-check-circle.tw-mx-1 
          span {{$I18n.t('vue.customer_detail.active_customer')}}
        button.app-btn-link-primary(v-if="state.show", @click="edit()") 
          i.fa.fa-pencil.tw-mx-1 
          span {{$I18n.t('vue.customer_detail.edit')}}
        button.app-btn-link-primary(v-if="state.edit", @click="cancel()")
          i.fa.fa-times.tw-mx-2
          span {{$I18n.t('vue.customer_detail.cancel')}}
        button.app-btn-link-primary(v-if="state.edit", @click="update()")
          i.fa.fa-save.tw-mx-2
          span {{$I18n.t('vue.customer_detail.save')}}
  hr
  Detailgrid(:data-grid="grid", :data-editing="state.edit")
</template>

<script>
import DeleteCustomerModal from './delete-customer-modal.vue'
import CoreComponents from "../core";
import { BackendService } from "../../services";
const { ActiveValue } = require("../../core/active_support");

export default {
  props: ["dataRecord"],
  components: { 
    'delete-customer-modal':DeleteCustomerModal,
    ...CoreComponents 
  },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    let self = this;
    return {
      state: {
        show: true,
        edit: false,
        sending: false,
      },
      controls:{
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      values: {
        id: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.customer_detail.id"));
          i.setValue(self.record.id);
          i.setKind("string");
          i.setInputKind("text");
          i.notEditable();
        }),
        name: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.customer_detail.name"));
          i.setValue(self.record.name);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        aka: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.customer_detail.aka"));
          i.setValue(self.record.aka);
          i.setKind("string");
          i.setInputKind("text");
        }),
        state: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.vendor_detail.state"));
          i.setValue(self.$I18n.t("vue.states."+self.record.state.toLowerCase()));
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        vendor: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.customer_detail.vendor"));
          i.setValue(self.record.vendor.name);
          i.setKind("link");
          i.setLinkOptions({ href: self.record.vendor.paths.show });
          i.notEditable();
        }),
        phone_number: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.customer_detail.phone_number"));
          i.setValue(self.record.phone.number);
          i.setKind("string");
          i.setInputKind("text");
        })
      },
    };
  },
  methods: {
    disable_customer: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
      }
    },
    active_customer: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .active_customer(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    valid: function () {
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    edit: function () {
      this.state.edit = true;
      this.state.show = false;
      this.state.sending = false;
    },
    cancel: function () {
      this.state.show = true;
      this.state.edit = false;
      this.state.sending = false;
      Object.values(this.values).map((v) => v.valueToInput());
    },
    sending: function () {
      this.state.show = false;
      this.state.edit = false;
      this.state.sending = true;
    },
    params: function () {
      return JSON.stringify({
        atts: {
          name: this.values.name.input,
          aka: this.values.aka.input,
          phone_number: this.values.phone_number.input,
        },
      });
    },
    update: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService.update_customer(this.record.id, this.params()).then((r) => {
          self.cancel();
          self.values.name.setValue(r.customer.name);
          self.values.aka.setValue(r.customer.aka);
          self.values.phone_number.setValue(r.customer.phone.number);
        });
      }
    },
  },
  computed: {
    grid: function () {
      return [
        [this.values.id,    this.values.state],
        [this.values.name,  this.values.vendor],
        [this.values.aka,   this.values.phone_number]
      ];
    },
  },
};
</script>
