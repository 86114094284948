<template lang="pug">
div
  .tw-flex.tw-flex-row.tw-justify-between
    div
      h5.h5.tw-text-gray-500 {{$I18n.t('vue.order_detail.title')}}
    .tw-flex.tw-flex-row
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center")
        div(class="spinner-border spinner-border-sm" role="status")
      div(v-else)
        button.app-btn-link-primary(v-if="state.show && record.state.name != 'COMPLETED' && record.state.name != 'CANCELED'", @click="edit()") 
          i.fa.fa-pencil.tw-mx-1 
          span {{$I18n.t('vue.order_detail.btn_edit')}}
        button.app-btn-link-primary(v-if="state.edit", @click="cancel()")
          i.fa.fa-times.tw-mx-2
          span {{$I18n.t('vue.order_detail.btn_cancel')}}
        button.app-btn-link-primary(v-if="state.edit", @click="update()")
          i.fa.fa-save.tw-mx-2
          span {{$I18n.t('vue.order_detail.btn_save')}}
  hr
  Detailgrid(:data-grid="grid", :data-editing="state.edit")
</template>

<script>
import CoreComponents from "../core";
import { BackendService } from "../../services";
const { ActiveValue } = require("../../core/active_support");

export default {
  props:{ dataRecord: String,
          dataOtherOrders: String
  },
  components: { ...CoreComponents },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    let self = this;
    return {
      state: {
        show: true,
        edit: false,
        sending: false,
      },
      other_orders: JSON.parse(this.dataOtherOrders),
      values: {
        id: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.id"));
          i.setValue(self.record.id);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        job_number: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.job_number"));
          i.setValue('');
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        id_kanela: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.id_kanela"));
          i.setValue(self.record.id_kanela);
          i.setKind("string");
          i.setInputKind("number");
        }),
        vendor: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.vendor"));
          i.setValue(self.record.vendor.name);
          i.setKind("link");
          i.setLinkOptions({ href: self.record.vendor.paths.show });
          i.notEditable();
        }),
        customer: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.customer"));
          i.setValue(self.record.customer.name);
          i.setKind("link");
          i.setLinkOptions({ href: self.record.customer.paths.show });
          i.notEditable();
        }),
        subsidary: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.subsidary"));
          i.setValue(self.record.subsidary.name);
          i.setKind("link");
          i.setLinkOptions({ href: self.record.subsidary.paths.show });
          i.notEditable();
        }),
        state: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.state"));
          i.setValue(self.$I18n.t("vue.states."+self.record.state.name.toLowerCase()));
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        cancelation_reason: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.cancelation_reason"));
          i.setValue(self.record.cancelation_reason);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        frequency: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.frequency"));
          i.setValue(self.record.frequency_month=="" ? self.record.frequency : self.$I18n.t("vue.order_detail.months")+":"+self.record.frequency_month);
          i.setKind("string");
          i.setInputKind("number");
          i.required();
          i.notEditable();
        }),
        due_on: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.due_on"));
          i.setValue(self.record.locale_due_at);
          i.setKind("string");
          i.setInputKind("date");
          i.required();
          i.notEditable();
        }),
        schedule_for: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.schedule_for"));
          i.setValue(self.record.locale_schedule_for);
          i.setKind("string");
          i.setInputKind("date");
          i.required();
          i.notEditable();
        }),
        price: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.price"));
          i.setValue(self.record.masked_price);
          i.setKind("string");
          i.setInputKind("number");
          i.required();
          i.notEditable();
        }),
        billed_amount: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.billed_amount"));
          i.setValue(self.record.masked_billed_amount);
          i.setKind("string");
          i.setInputKind("number");
          i.required();
          i.notEditable();
        }),
        unbilled_amount: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.order_detail.unbilled_amount"));
          i.setValue(self.record.masked_unbilled_amount);
          i.setKind("string");
          i.setInputKind("number");
          i.required();
          i.notEditable();
        }),
      },
    };
  },
  methods: {
    valid: function () {
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    edit: function () {
      this.state.edit = true;
      this.state.show = false;
      this.state.sending = false;
    },
    cancel: function () {
      this.state.show = true;
      this.state.edit = false;
      this.state.sending = false;
      Object.values(this.values).map((v) => v.valueToInput());
    },
    sending: function () {
      this.state.show = false;
      this.state.edit = false;
      this.state.sending = true;
    },
    params: function () {
      return JSON.stringify({
        id: this.record.id,
        job_number: this.values.job_number.input,
        id_kanela: this.values.id_kanela.input,
        frequency: this.values.frequency.input
      });
    },
    update: function () {
      const self = this;
      if (self.values.frequency.isPresent()) {
        self.sending();
        BackendService.order_update_frequency(this.params()).then((r) => {
          //self.cancel();
          self.values.frequency.setValue(r.order.table.frequency);
          self.values.job_number.setValue(r.order.table.job_number);
          self.values.id_kanela.setValue(r.order.table.id_kanela);
          location.reload();
        });
      }
    },
    get_number_of_job: function(){
      var items = []
      var result = ''
      if (this.other_orders.length != 0 && this.record.state.name != 'CANCELED'){
        this.other_orders.forEach(order => {
          if(order.state.name != "CANCELED"){
            if(order.job_number !== null){
              items.push(order.job_number)
            }
          }
        })
      }else{
        items.push(this.record.job_number)
      }
      items = Array.from(new Set(items.filter((a) => a)))
      if (items==[]){
        items.push(this.record.id)
      }
      result = items.join(',')
      this.values.job_number.setValue(result);
      return result
    },
  },
  computed: {
    grid: function () {
      this.get_number_of_job()
      let cancelation_reason
      if (this.record.cancelation_reason != null){
        cancelation_reason = [this.values.cancelation_reason,   ""]
      } else{
        cancelation_reason=[]
      }
      return [
        [this.values.id,              this.values.job_number],
        [''            ,              this.values.id_kanela],
        [this.values.customer,        this.values.vendor    ],
        [this.values.state,           this.values.subsidary ],
        [this.values.due_on,          this.values.frequency ],
        [this.values.schedule_for,    this.values.price     ],
        ['',                          this.values.billed_amount],
        ['',                          this.values.unbilled_amount],
        cancelation_reason
      ];
    },
  },
};
</script>