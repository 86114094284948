<template lang="pug">
div(v-if="state.showing")
  .app-modal(@click.self="reject()")
    .dialog
      .header
        p {{ $I18n.t('vue.subsidary_add_hood.label_add_hood') }}
      .body(v-if="!state.sending")
        //FORM
        Detailform(v-bind:grid="grid")
      .body(v-else)
        .tw-my-20.tw-text-center 
          .spinner-border.spinner-border-sm(role="status")
      .footer
        //Error
        p.app-text-error.tw-my-3(v-if="result.isError") {{ $I18n.t('vue.subsidary_add_hood.result.' + result.notice) }}
        p.app-text-ok(v-if="result.isOk") {{ $I18n.t('vue.subsidary_add_hood.result.' + result.notice) }}
        //BUTTONS
        a.app-btn-link-primary(@click="clear") 
          i.fa.fa-eraser.mr-2
          span {{ $I18n.t('vue.subsidary_add_hood.button_clear') }}
        a.app-btn-link-primary(@click="cancel") 
          i.fa.fa-times.mr-2
          span {{ $I18n.t('vue.subsidary_add_hood.button_cancel') }}
        button.app-btn-primary(@click="create") 
          i.fa.fa-save.mr-2
          span {{ $I18n.t('vue.subsidary_add_hood.button_create') }}
</template>
<script>
import { ActiveValue } from "../../core/active_support";
import CoreComponents from "../core";
import { BackendService } from "../../services";

export default {
  props: { dataTriggerId: String, dataRecord: String },
  components: { ...CoreComponents },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    return {
      state: {
        showing: false,
        sending: false,
        compleated: true,
      },
      result: {
        isError: undefined,
        isOk: undefined,
        key: undefined,
        notice: undefined,
      },
      inputs: {
        equipment_under: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.subsidary_add_hood.label_equipment_under"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        description: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.subsidary_add_hood.label_description"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("textArea");
          i.optional();
        }),
      },
    };
  },
  computed: {
    grid: function () {
      return [
        [this.inputs.equipment_under],
        [this.inputs.description]
      ];
    },
  },
  methods: {
    valid: function () {
      return Object.values(this.inputs)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    show: function () {
      this.state.showing = true;
    },
    cancel: function () {
      this.state.sending = false;
      this.hide();
      this.clear();
      this.result.isError = undefined;
      this.result.isOk = undefined;
      this.result.key = undefined;
      this.result.notice = undefined;
    },
    clear: function () {
      Object.values(this.inputs).forEach((v) => v.resetValue());
    },
    hide: function () {
      this.state.showing = false;
    },
    stringify: function () {
      return JSON.stringify({
        id: this.record.id.toString(),
        hood: {
          equipment_under: this.inputs.equipment_under.input,
          description: this.inputs.description.input,
        },
      });
    },
    create: function () {
      let self = this;
      if (self.valid()) {
        this.state.sending = true;
        BackendService.create_subsidary_hood(self.stringify())
          .then((response) => {
            self.result = response;
            if (response.isOk) {
              self.hide();
              self.clear();
              location.reload();
            }
          })
          .catch(() => {
            self.result.isError = true;
            self.result.notice = "error_promise";
          })
          .finally(() => {
            self.state.sending = false;
          });
      }
    },
  },
  mounted: function () {
    let _self = this;
    let _btn = document.getElementById(this.dataTriggerId);
    _btn.onclick = function () {
      console.log(_self.show());
    };
  },
};
</script>
