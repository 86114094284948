import Sidebar from './sidebar/sidebar.vue'
import Sidebaritem from './sidebar/sidebar_item.vue'
import Forgotpasswordform from './forgot_password_form.vue'
import Changepasswordeditform from './change_password_edit_form.vue'
import DashboardComponents from './dashboard'
import AccountComponents from './account'
import CustomerComponents from './customer'
import VendorComponents from './vendor'
import SubsidaryComponents from './subsidary'
import MaintenanceComponents from './maintenance'
import MaintenancekindsComponents from './maintenance_kinds'
import HoodComponents from './hood'
import OrderComponents from './order'
import AddressComponents from './address'
import OrderableComponents from './orderable'
import TechnicianComponents from './technician'
import InvoiceComponents from './invoice'
import PaymentComponents from './payment'
import ContactsComponents from './contacts'
import NotesComponents from './notes'
import LinksComponents from './links'
import InvoiceableComponents from './invoiceable'
import ServicesComponents from './services'
import CoreComponents from './core'
import CableTimer from './cable-timer.vue'


export default {
  ...CoreComponents,
  Sidebar,
  Sidebaritem,
  Forgotpasswordform, 
  Changepasswordeditform,
  ...DashboardComponents,
  ...AccountComponents,
  ...CustomerComponents,
  ...VendorComponents,
  ...SubsidaryComponents,
  ...MaintenanceComponents,
  ...MaintenancekindsComponents,
  ...HoodComponents,
  ...OrderComponents,
  ...ContactsComponents,
  ...NotesComponents,
  ...LinksComponents,
  ...ServicesComponents,
  ...AddressComponents,
  ...OrderableComponents,
  ...TechnicianComponents,
  ...InvoiceComponents,
  ...PaymentComponents,
  ...InvoiceableComponents,
  'app-cable-timer': CableTimer
}
