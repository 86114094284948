<template lang="pug">
  div
    // FORM
    Detailform(v-bind:grid="grid")

    //BUTTONS
    div(class="tw-text-right tw-mt-7")
      div(class="form-group row")
        div(class="row")
          div(class="col")
            button(@click="addFilters" class="app-btn-primary") 
              i(class="fa fa-save mr-2")
              span {{$I18n.t('vue.maintenance_search_form.add_filters')}}

</template>
<script>
  import CoreComponents from '../core';
  const {ActiveValue}=require('../../core/active_support');
  const {BackendService,BackendRoutes} = require('app/services');
  export default {
    components:{...CoreComponents},
    data: function(){
      return {
        controls:{
          filters:{}
        },
        inputs:{
          subsidary: ActiveValue().setup((i)=>{
            i.setLabel(this.$I18n.t('vue.maintenance_search_form.label_subsidary'));
            i.setValue('');
            i.setKind('string');
            i.setInputKind('text');
            i.required();
          })
        }
      }
    },
    computed:{
      grid:function(){
        return [
          [this.inputs.subsidary,                '']
        ]
      },
    },
    methods:{
      addFilters:function(){
        this.filters =  Object
                          .values(this.inputs)
                          .map((i)=>{
                            if (i.isPresent()){
                              return i;
                            }
                        })
      }
    },
  }
</script>
