<template lang="pug">

div
  app-icon-btn(
    @click.native="showModal"
    data-kind="link",
    data-icon="fa fa-plus",
    :data-label="$I18n.t('vue.create_note_modal.btn_add_note_'+dataKindNote)"
  )
  // Modal
  app-modal(
    :showing='controls.modal.showing'
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}
    
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        app-form(
          v-bind:grid="form_grid"
          class='tw-py-4'
        )
    
    template(v-slot:footer)
      //p(v-if="result.isError" class="app-text-error tw-my-3") {{$I18n.t('vue.create_note_modal.result.'+result.notice)}}
      //p(v-if="result.isOk" class="app-text-ok") {{$I18n.t('vue.create_note_modal.result.'+result.notice)}}
      .app-buttons-row( v-if="!state.sending" class='justify-end' )
        app-icon-btn(
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="create",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>
<script>

import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
import {BackendService} from '../../services';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default{
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components:{
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataId: String,
    dataModel: String,
    dataKindNote: String,
    dataNoteKindVisible: String,
    dataOtherOrders: String,
  },
  data:function(){
    return {
      record:{
        id: this.dataId,
        kind: this.dataKindNote
      },
      controls:{
        modal:{
          showing: false
        }
      },
      state:{
        sending: false
      },
      other_orders: this.dataOtherOrders==undefined ? '' : JSON.parse(this.dataOtherOrders),
      values:{
        description: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.create_note_modal.description'));
          i.setValue('');
          i.setInputKind('textArea');
          i.required();
        }),
        visibility: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.create_note_modal.visibility"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataNoteKindVisible));
          i.required();
        }),
      },
      labels:{
        modal_title: this.$I18n.t('vue.create_note_modal.label_add_note_'+this.dataKindNote),
        clear_btn: this.$I18n.t('vue.create_note_modal.button_clear'),
        cancel_btn: this.$I18n.t('vue.create_note_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.create_note_modal.button_create')
      }
    }
  },
  computed:{
    form_grid:function(){
      return [
        [this.values.description, this.values.visibility]
      ]
    }
  },
  methods:{
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function(){
      this.controls.modal.showing = false;
    },
    clear: function(){
      this.resetActiveValues(this.values);
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.hideModal();
      this.resetActiveValues(this.values);
    },
    params:function(){
      return JSON.stringify({
        id: this.record.id,
        note: {
          kind: this.record.kind,
          description: this.values.description.input.toString(),
          visibility: this.values.visibility.input
        }
      })
    },
    create:function(){
      let self = this; 
      if(self.areValidActiveValues(this.values)){
        if (this.dataModel=='subsidary'){
          this.create_subsidary_note();
        }
        if (this.dataModel=='order'){
          this.create_order_note();
        }
        if (this.dataModel=='invoice'){
          this.create_invoice_note();
        }
      }
    },
    create_subsidary_note:function(){
      let self = this; 
      this.process();
      BackendService
        .create_subsidary_note(self.params())
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
        })
        .finally(()=>{
          //self.processed();
        })
    },
    create_order_note:function(){
      let self = this; 
      this.process();
      BackendService
        .create_order_note(self.params())
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
        })
        .finally(()=>{
          //self.processed();
        })
    },
    create_invoice_note:function(){
      let self = this; 
      this.process();
      BackendService
        .create_invoice_note(self.params())
        .then((response)=>{
          self.result = response;
          if (response.isOk){
            location.reload();
          }
        })
        .catch(()=>{
          self.result.isError=true;
          self.result.notice='error_promise';
        })
        .finally(()=>{
          //self.processed();
        })
    },
    process:function(){
      this.state.sending = true;
    },
    processed:function(){
      this.state.sending = false;
    }
  }
}
</script>
