<template lang='pug'>

  .tw-inline-flex
    //Cancelar Factura
    cancel-payment-modal(
      :disabled= 'payment.status == "CANCELED"' 
      :data-id='payment.id'
    )


</template>

<script>

import CancelPaymentModal from './cancel-payment-modal.vue';

export default {
  components:{
    'cancel-payment-modal':CancelPaymentModal,
  },
  props:['dataRecord'],
  data: function(){
    return {
      payment: JSON.parse(this.dataRecord),
    }
  }
}
</script>
