<template lang="pug">

div(class='app-card' style="height: 100%;")
  div(class='text-center')
    h5(class="h5 py-4 tw-text-gray-500") 
      i(class="app-title-ico-xl fa fa-file-text-o")
      span {{$I18n.t('vue.invoices_by_state.subtitle_invoices_by_state')}}
  
  // Spinner Loading
  div(v-if="state.loading" class="tw-text-center tw-my-20")
      div(class="spinner-border spinner-border-sm" role="status")
  div(v-else class="")
    table(class="table table-hover")
      thead
        tr
          th State
          th(class="tw-text-center ") Num
          //th
      tbody
        tr(v-for="item in items")
          td {{$I18n.t('vue.invoices_by_state.invoice_'+item.state)}}
          td(class="tw-text-center ") {{item.num}}
          //td(class="tw-text-center ")
            app-icon-btn(
              v-if="item.other==''"
              data-kind="link",
              @click.native="send_mail(item)",
              data-icon="fa fa-envelope",
              data-label="",
              :data-title="$I18n.t('vue.invoices_by_state.title')"
            )
            i(v-else)
              div(class="spinner-border spinner-border-sm" role="status")
    div
      .flex(class='justify-content-end')
        a(class="app-btn-link-primary" :href="index_invoices_path") 
          span {{$I18n.t('vue.invoices_by_state.view_invoices')}}
          i(class="fa fa-arrow-right")
</template>
<script>

import CoreComponents from '../core';
const {ActiveValue}=require('../../core/active_support');
const {BackendService,BackendRoutes} = require('app/services');

export default {
  components:{    
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
  },
  data: function(){
    return {
      state: {
        loading: true,
      },
      items: '',
    }
  },
  computed:{
    emptyItems: function(){
      return this.data_invoices === '';
    },
    index_invoices_path:function(){
      return  BackendRoutes.index_invoices_path();
    },
  },
  methods:{
    getInvoicesByState:function(){
      let params="";
      BackendService
        .get_invoices_by_state(params)
        .then((response) => {
          if (response.isOk) {
            this.state.loading = false;
            this.items = response.data_invoices;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
    send_mail:function(item){
      item.other = "loading"
      let params = JSON.stringify({
        query: {
          status: item.state,
        }
      });
      BackendService
        .send_mail_invoices_by_state(params)
        .then((response) => {
          if (response.isOk) {
            item.other = ""
            //alert
          }
        })
        .catch(() => {
          item.other = ""
        });
    },
  },
  beforeMount() {
    this.getInvoicesByState();
  },
}
</script>
