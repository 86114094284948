import en from './locales/en';
import es from './locales/es';

export default {
  locale:function(locale){
    this.locale=locale;
  },
  t:function(translation){
    let _t= translation.split('.')
          .reduce((l,k)=>l === undefined ? undefined:l[k], this.locales[this.locale]);
    return _t === undefined ? `translation missing: ${this.locale}.${translation}` : _t;
  },
  locales:{
    'en': en,
    'es': es
  }
}