<template lang='pug'>
  div
    div(v-if="changed" )
      h3(v-if="result.kind !== undefined" :class="result.kind==='error' ? 'app-text-error' : 'app-text-ok'" class="tw-text-2xl tw-mb-5") {{result.message}}
      div(class="tw-text-right") 
      a(class="app-btn-link app-btn-link-primary" :href="new_session_path") 
          span {{$I18n.t('vue.change_password_edit_form.login_link')}}
    div(v-else)
      h3(class="tw-text-2xl tw-mb-5") {{$I18n.t('vue.change_password_edit_form.change_password')}}
      div(v-if="changing" class="spinner-border spinner-border-sm" role="status")  
      div(v-else)
        //Inputs del formulario
        input(v-model="token" type="hidden")
        
        div(class="input-group mb-3")
          input(class="form-control tw-mb-3" 
                    v-model="password" 
                    :type="controls.input_password.type" 
                    :placeholder="$I18n.t('vue.change_password_edit_form.password_placeholder')" )
          button(class="btn tw-mb-3" 
                 type="button"
                 @click="change_input_password_type()")
            i(:class="controls.input_password.eye_icon" aria-hidden="true")
        
        div(class="input-group mb-3")
          input(class="form-control tw-mb-3" 
                v-model="passwordConfirmation" 
                :type="controls.input_password_confirmation.type" 
                :placeholder="$I18n.t('vue.change_password_edit_form.confirm_password_placeholder')" )
          button(class="btn tw-mb-3" 
                 type="button"
                 @click="change_input_password_confirmation_type()")
            i(:class="controls.input_password_confirmation.eye_icon" aria-hidden="true")

        //Nivel de seguridad de la contraseña
        div(class="tw-text-left tw-my-5 tw-mx-2")
          div(class="tw-inline" v-if="password_validate.level_security!=0")
            div(class="tw-inline progress tw-mt-5 tw-mr-30")
              div(v-if="password_validate.level_security!=0 && password_validate.level_security<2"
                  class="progress-bar bg-danger" 
                  role="progressbar" 
                  style="width: 33%" 
                  aria-valuenow="25" 
                  aria-valuemin="0" 
                  aria-valuemax="100") {{$I18n.t('vue.change_password_edit_form.password_strenght')}} <b>{{$I18n.t('vue.change_password_edit_form.weak')}}</b>
              div(v-if="password_validate.level_security>=2 && password_validate.level_security<=4"
                  class="progress-bar bg-warning" 
                  role="progressbar" 
                  style="width: 67%" 
                  aria-valuenow="25" 
                  aria-valuemin="0" 
                  aria-valuemax="100") {{$I18n.t('vue.change_password_edit_form.password_strenght')}} <b>{{$I18n.t('vue.change_password_edit_form.medium')}}</b>
              div(v-if="password_validate.level_security>=5"
                  class="progress-bar bg-success" 
                  role="progressbar" 
                  style="width: 100%" 
                  aria-valuenow="25" 
                  aria-valuemin="0" 
                  aria-valuemax="100") {{$I18n.t('vue.change_password_edit_form.password_strenght')}} <b>{{$I18n.t('vue.change_password_edit_form.strong')}}</b>
        //Seccion para mostrar mensajes de seguridad de la contraseña
        div(v-if="valid_password")
        div(v-if="password_validate.level_security!=0" class="tw-mb-5 tw-text-left app-div-validate-password")
          i(class="fa fa-lock tw-text-4xl app-text-ok tw-mx-5 tw-mt-5")
          span(class="app-text-ok tw-text-2xl") {{$I18n.t('vue.change_password_edit_form.password_need_to')}}
          div(class="tw-ml-16")
            div(:class="password_validate.length ? 'app-text-ok' : 'app-text-error'")
              i( aria-hidden="true" :class="password_validate.length ? 'fa fa-check-circle' : 'fa fa-times-circle'" class="tw-mr-1")
              span(class="tw-text-sm") {{$I18n.t('vue.change_password_edit_form.number_characters')}}
            div(:class="password_validate.number ? 'app-text-ok' : 'app-text-error'")
              i( aria-hidden="true" :class="password_validate.number ? 'fa fa-check-circle' : 'fa fa-times-circle'" class="tw-mr-1")
              span(class="tw-text-sm") {{$I18n.t('vue.change_password_edit_form.one_number')}}
            div(:class="password_validate.lower ? 'app-text-ok' : 'app-text-error'")
              i( aria-hidden="true" :class="password_validate.lower ? 'fa fa-check-circle' : 'fa fa-times-circle'" class="tw-mr-1")
              span(class="tw-text-sm") {{$I18n.t('vue.change_password_edit_form.lowercase_letter')}}
            div(:class="password_validate.upper ? 'app-text-ok' : 'app-text-error'")
              i( aria-hidden="true" :class="password_validate.upper ? 'fa fa-check-circle' : 'fa fa-times-circle'" class="tw-mr-1")
              span(class="tw-text-sm") {{$I18n.t('vue.change_password_edit_form.uppercase_letter')}}
            div(:class="password_validate.character ? 'app-text-ok' : 'app-text-error'")
              i( aria-hidden="true" :class="password_validate.character ? 'fa fa-check-circle' : 'fa fa-times-circle'" class="tw-mr-1")
              span(class="tw-text-sm") {{$I18n.t('vue.change_password_edit_form.special_character')}}
            div(:class="password_validate.passwords_equals ? 'app-text-ok' : 'app-text-error'")
              i( aria-hidden="true" :class="password_validate.passwords_equals ? 'fa fa-check-circle' : 'fa fa-times-circle'" class="tw-mr-1")
              span(class="tw-text-sm") {{$I18n.t('vue.change_password_edit_form.passwords_match')}}
        //Errores al registrar la contraseña en backend
        div(class="tw-text-left")
          span(v-if="result.kind !== undefined" :class="result.kind==='error' ? 'app-text-error' : 'app-text-ok' ") {{result.message}}
        //Seccion de botones
        div(class="tw-text-right")
          a(class="app-btn-link-primary" @click="cancel") 
            span {{$I18n.t('vue.change_password_edit_form.cancel_button')}}
          button(class="app-btn-primary" @click="change" :disabled="button.disabled") 
            span {{$I18n.t('vue.change_password_edit_form.change_button')}}
</template>

<script>
  const {BackendService,BackendRoutes} = require('app/services');
  export default {
    data:function(){
      return {
        controls:{
          input_password:{
            type: 'password',
            eye_icon: 'fa fa-eye',
          },
          input_password_confirmation:{
            type: 'password',
            eye_icon: 'fa fa-eye',
          }
        },
        password:"",
        passwordConfirmation:"",
        changing:false,
        changed:false,
        button:{
          disabled:false
        },
        result:{
          message:undefined,
          kind: undefined
        },
        password_validate:{
          level_security: undefined,
          length: false,
          number: false,
          lower: false,
          upper: false,
          character: false,
          passwords_equals: false,
        }
      }
    },
    props:{
      token: String
    },
    computed:{
      new_session_path:function(){
        return  BackendRoutes.new_session_path();
      },
      valid_password:function(){
        let password = this.password
        let password_confirmation = this.passwordConfirmation
        let level = 0

        //Valida Longitud
        if (password.length >= 8){
          this.password_validate.length = true
          level = level + 1
        }else{
          this.password_validate.length = false
        }

        //Valida que tenga minusculas
        if (password.match(/[a-z]/)){
          this.password_validate.lower = true
          level = level + 1
        }else{
          this.password_validate.lower = false
        }

        //Valida que tenga Mayusculas
        if (password.match(/[A-Z]/)){
          this.password_validate.upper = true
          level = level + 1
        }else{
          this.password_validate.upper = false
        }

        //Valida que tenga numero
        if (password.match(/\d/)){
          this.password_validate.number = true
          level = level + 1
        }else{
          this.password_validate.number = false
        }

        //Valida que tenga simbolo
        if (password.match(/[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/)){
          this.password_validate.character = true
          level = level + 1
        }else{
          this.password_validate.character = false
        }
        //Resultados de validacion
        this.password_validate.level_security = level

        //Valida que las 2 contraseñas sean iguales. Solo cuando captura la confirmacion de contraseña
        if (password_confirmation != ""){
          if (password == password_confirmation){
            this.password_validate.passwords_equals = true
          }else{
            this.password_validate.passwords_equals = false
          }
        }

        //Cambia status del boton
        if (this.password_validate.level_security == 5 && this.password_validate.passwords_equals==true){
          this.button.disabled = false
        }else{
          this.button.disabled = true
        }

      }
    },
    methods:{
      change:function(){
        let self = this; 
        this.clear()
        if (this.validate()){
          this.sending(true);
          let params = {
            token: this.token,
            password: this.password,
            password_confirmation: this.passwordConfirmation
          }
          BackendService.send_change_password(params)
          .then((response)=>{
            if (response.isOk){
              self.ok(this.$I18n.t(`vue.change_password_edit_form.results.${response.notice}`));
              this.changed=true;
            }else{
              self.error(this.$I18n.t(`vue.change_password_edit_form.results.${response.notice}`));
            }
          })
          .catch(()=>{
            this.error(this.$I18n.t(`vue.change_password_edit_form.error_promise`))
          })
          .finally(()=>{
            this.sending(false);
          })
        }else{
          
        }
      },
      cancel:function(){
        this.password="";
        this.passwordConfirmation=""
        this.changing=false;
        this.button.disabled=false;
        this.result.message=undefined;
        this.result.kind=undefined;
      },
      validate:function(){
        let result_validation=true;
        //equal password
        if (this.password !== this.passwordConfirmation){
          result_validation=false;
          this.error(this.$I18n.t(`vue.change_password_edit_form.different_passwords`))
        }
        //not blank passwords
        if (this.password==='' || this.passwordConfirmation===''){
          result_validation=false;
          this.error(this.$I18n.t(`vue.change_password_edit_form.password_empty`))
        }
        return result_validation;
      },
      error:function(message){
        this.result.kind="error"
        this.result.message=message
      },
      ok:function(message){
        this.result.kind="ok"
        this.result.message=message
      },
      clear:function(){
        this.result.kind=undefined
        this.result.message=undefined
      },
      sending:function(status){
        this.changing=status
        this.button.disabled = status
      },
      change_input_password_type:function(){
        if (this.controls.input_password.type=="password"){
          this.controls.input_password.type="text"
          this.controls.input_password.eye_icon="fa fa-eye-slash"
        }else{
          this.controls.input_password.type="password"
          this.controls.input_password.eye_icon="fa fa-eye"
        }
      },
      change_input_password_confirmation_type:function(){
        if (this.controls.input_password_confirmation.type=="password"){
          this.controls.input_password_confirmation.type="text"
          this.controls.input_password_confirmation.eye_icon="fa fa-eye-slash"
        }else{
          this.controls.input_password_confirmation.type="password"
          this.controls.input_password_confirmation.eye_icon="fa fa-eye"
        }
      },
    }
  }
</script>