<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display" 
    v-bind:alert="controls.alert"
  )

  .tw-flex.tw-flex-row.tw-justify-between
    div
      h5.h5.tw-text-gray-500 {{$I18n.t('vue.invoice_detail.title')}}
    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center")
      div(class="spinner-border spinner-border-sm" role="status")

    //Barra de botones  
    div(v-else)
      .tw-flex.tw-flex-row
        button.app-btn-link-primary(
          v-if="state.show", 
          :disabled="record.status == 'CANCELED'",
          @click="edit()"
        ) 
          i.fa.fa-pencil.tw-mx-1 
          span {{$I18n.t('vue.invoice_detail.actions.edit')}}
        button.app-btn-link-primary(v-if="state.edit", @click="cancel()")
          i.fa.fa-times.tw-mx-2
          span {{$I18n.t('vue.invoice_detail.actions.cancel')}}
        button.app-btn-link-primary(v-if="state.edit", @click="update()")
          i.fa.fa-save.tw-mx-2
          span {{$I18n.t('vue.invoice_detail.actions.save')}}
  hr
  Detailgrid(:data-grid="grid", :data-editing="state.edit")
  p(style="display: none;") {{update_amount}}
</template>

<script>
import CoreComponents from "../core";
import { BackendService } from "../../services";
const { ActiveValue } = require("../../core/active_support");

export default {
  props: {
    dataRecord: String,
    dataWayToPay: String
  },
  components: { ...CoreComponents },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    let self = this;
    return {
      state: {
        show: true,
        edit: false,
        sending: false,
      },
      controls:{
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      values: {
        id: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.id"));
          i.setValue(self.record.id);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        document_number: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.document_number"));
          i.setValue(self.record.document_number);
          i.setKind("string");
          i.setInputKind("text");
        }),
        date_create: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.date_created"));
          i.setValue(self.record.locale_date_create);
          i.setKind("string");
          i.setInputKind("date");
          i.notEditable();
        }),
        date_invoice: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.date_invoice"));
          i.setValue(self.record.locale_date);
          i.setKind("string");
          i.setInputKind("date");
          i.required();
        }),
        amount: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.amount"));
          i.setValue(self.record.masked_amount);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        porcent: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.porcent"));
          i.setValue(self.record.masked_porcent);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        amount_with_credit_card: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.amount_with_credit_card"));
          i.setValue(self.record.masked_amount_with_credit_card);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        status: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.status"));
          i.setValue(self.$I18n.t("vue.states."+self.record.status.toLowerCase()));
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        cancelation_reason: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.cancelation_reason"));
          i.setValue(self.record.cancelation_reason);
          i.setKind("string");
          i.setInputKind("text");
        }),
        cod: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.cod"));
          i.setValue(self.record.cod=='' || self.record.cod=='0' ? 'COD' : self.record.cod);
          i.setKind("string");
          i.setInputKind("number");
        }),
        total_paid_amount: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.total_paid_amount"));
          i.setValue(self.record.masked_total_paid_amount);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        total_unpaid_amount: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.invoice_detail.masked_total_unpaid_amount"));
          i.setValue(self.record.masked_total_unpaid_amount);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
      },
    };
  },
  methods: {
    disable_invoice: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .disable_invoice(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    active_invoice: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .active_invoice(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    valid: function () {
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    edit: function () {
      this.values.date_invoice.input = this.record.date
      this.values.amount.input = this.record.amount
      this.values.porcent.input = this.record.porcent
      this.values.amount_with_credit_card.input = this.record.amount_with_credit_card
      this.state.edit = true;
      this.state.show = false;
      this.state.sending = false;
    },
    cancel: function () {
      this.state.show = true;
      this.state.edit = false;
      this.state.sending = false;
      Object.values(this.values).map((v) => v.valueToInput());
      this.values.porcent.setValue(this.record.masked_porcent)
      this.values.amount_with_credit_card.setValue(this.record.masked_amount_with_credit_card)
      this.values.total_paid_amount.setValue(this.record.masked_total_paid_amount)
      this.values.total_unpaid_amount.setValue(this.record.masked_total_unpaid_amount)
      this.values.cod=='' || this.values.cod=='0' ? 'COD' : this.values.cod
    },
    sending: function () {
      this.state.show = false;
      //this.state.edit = false;
      this.state.sending = true;
    },
    params: function () {
      return JSON.stringify({
        id: this.record.id.toString(),
        document_number: this.values.document_number.input,
        date_invoice: this.values.date_invoice.input,
        cod: this.values.cod.input,
        price: this.values.amount.input,
        porcent_credit_card: this.values.porcent.input,
        price_with_credit_card: this.values.amount_with_credit_card.input,
      });
    },
    update: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService.update_invoice(this.params()).then((r) => {
          location.reload();
        });
      }
    },
  },
  computed: {
    grid: function () {
      let cancelation_reason
      if (this.record.cancelation_reason != null){
        cancelation_reason = [this.values.cancelation_reason,   ""]
      } else{
        cancelation_reason=[]
      }
      return [
        [this.values.id,                    this.values.status ],
        [this.values.document_number,       this.values.amount ],
        [this.values.date_create,           this.values.porcent ],
        [this.values.date_invoice,          this.values.amount_with_credit_card ],
        [this.values.cod,                   this.values.total_paid_amount ],
        ['',                                this.values.total_unpaid_amount ],
        cancelation_reason,
      ];
    },
    update_amount: function(){
      if (this.state.edit === true){
        let amount_total = Number(this.values.amount.input)
        let percentage = Number(this.values.porcent.input)
        let amount_with_cr_total = 0
        let unpaid_amount = 0
        amount_with_cr_total = amount_total * (1 + (percentage/100))
        //if (this.values.way_to_pay.input == "Credit Card"){
        //  unpaid_amount = amount_with_cr_total - Number(this.record.total_paid_amount)
        //}else{
            unpaid_amount = amount_total - Number(this.record.total_paid_amount)
        //}
        this.values.amount_with_credit_card.setValue(amount_with_cr_total.toFixed(2));
        this.values.total_paid_amount.setValue(this.record.masked_total_paid_amount);
        this.values.total_unpaid_amount.setValue('$'+unpaid_amount.toFixed(2));
        return amount_with_cr_total;
      }
    },
  },
};
</script>