<template lang="pug">
  div
    //Notification Alert
    Notificationalert(
      v-if="controls.alert.display" 
      v-bind:alert="controls.alert"
    )

    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center tw-my-20")
      div(class="spinner-border spinner-border-sm" role="status")

    // FORM
    div(v-else)
      Detailform(v-bind:grid="grid")

      //BUTTONS
      div(v-if="!state.sending" class="tw-text-right tw-mt-7")
        div(class="form-group row")
          div(class="row")
            div(class="col")
              a(@click="clear" class="app-btn-link-primary") 
                i(class="fa fa-eraser mr-2")
                span {{$I18n.t('vue.new_maintenance_kind_form.button_clear')}}
              a(class="app-btn-link-primary" :href="index_maintenance_kinds") 
                i(class="fa fa-times mr-2")
                span {{$I18n.t('vue.new_maintenance_kind_form.button_cancel')}}
              button(@click="send" class="app-btn-primary") 
                i(class="fa fa-save mr-2")
                span {{$I18n.t('vue.new_maintenance_kind_form.button_create')}}

</template>
<script>
  import CoreComponents from '../core';
  const {ActiveValue}=require('../../core/active_support');
  const {BackendService,BackendRoutes} = require('app/services');
  
  export default {
    props:{

    },
    data: function(){
      return {
        controls:{
          alert:{
            display: false,
            type: "",
            notice: "",
            message: "",
            link: {
              text: "",
              href: ""
            }
          },
        },
        inputs:{
          name: ActiveValue().setup((i)=>{
            i.setLabel(this.$I18n.t('vue.new_maintenance_kind_form.label_name'));
            i.setValue('');
            i.setKind('string');
            i.setInputKind('text');
            i.required();
          }),
          abbreviation: ActiveValue().setup((i)=>{
            i.setLabel(this.$I18n.t('vue.new_maintenance_kind_form.label_abbreviation'));
            i.setValue('');
            i.setKind('string');
            i.setInputKind('text');
            i.required();
          }),
          description: ActiveValue().setup((i)=>{
            i.setLabel(this.$I18n.t('vue.new_maintenance_kind_form.label_description'));
            i.setValue('');
            i.setKind('text');
            i.setInputKind('textArea');
          }),
          periodicity: ActiveValue().setup((i)=>{
            i.setLabel(this.$I18n.t('vue.new_maintenance_kind_form.label_periodicity'));
            i.setValue('');
            i.setKind('string');
            i.setInputKind('number');
            i.required();
          })
        },
        state:{
          compleated: true,
          sending: false
        },
        result:{
          isError: undefined,
          isOk: undefined,
          key: undefined,
          notice: undefined,
          vendor: {
            id: undefined
          }
        }
      }
    },
    computed:{
      index_maintenance_kinds:function(){
        return BackendRoutes.index_maintenance_kinds_path();
      },
      maintenance_kind_path:function(){
        return BackendRoutes.maintenance_kind_path(this.result.maintenance_kind.id);
      },
      grid:function(){
        return [
          [this.inputs.name, this.inputs.abbreviation, this.inputs.periodicity],
          [this.inputs.description]
        ]
      }
    },
    methods:{
      valid:function(){
        return Object
              .values(this.inputs)
              .map((i)=>{
                i.validate();
                return i.hasError();
              })
              .every(e=>e===false)
      },
      stringify:function(){
        return JSON.stringify({
          name: this.inputs.name.input,
          abbreviation: this.inputs.abbreviation.input,
          description: this.inputs.description.input,
          price: 0.00,
          periodicity: this.inputs.periodicity.input,
          state: "ACTIVE",
        })
      },
      send:function(){
        let self = this; 
        if(self.valid()){
          self.state.sending=true;
          BackendService.create_maintenance_kind(self.stringify())
            .then((response)=>{
              self.result = response;
              self.controls.alert.notice = 'vue.new_maintenance_kind_form.result.'+response.notice
              if (response.isOk){
                self.controls.alert.type="success"
                self.controls.alert.message = 'vue.new_maintenance_kind_form.view_detail_text'
                self.controls.alert.link.text = 'vue.new_maintenance_kind_form.show_link'
                self.controls.alert.link.href=this.maintenance_kind_path
                self.clear();
              }else{
                self.controls.alert.type="danger"
              }
            })
            .catch(()=>{
              self.result.isError=true;
              self.controls.alert.type="error"
              self.controls.alert.notice = 'vue.new_maintenance_kind_form.result.error_promise';
            })
            .finally(()=>{
              self.state.sending=false;
              self.controls.alert.display=true
            })
        }
      },
      clear:function(){
      Object
        .values(this.inputs)
        .forEach(v=>v.resetValue());
      }
    },
    components:{...CoreComponents}
  }
</script>
