<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display" 
    v-bind:alert="controls.alert"
  )

  // Spinner Loading
  div(v-if="state.sending")
    .app-card.mb-5
      div(class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

  // FORM
  div(v-else)
    .app-card.mb-5
      Detailform(:grid="mainGrid")
      
      //Mensajes, en caso de no tener hoods ni service
      .app-card.mt-3.mx-5.border-danger(v-if='options.hoods.length == 0 && values.subsidary.input != ""')
        p(
          class="app-text-error"
        ) {{$I18n.t('vue.new_order.restaurant_no_hoods')}}
        a.app-btn-link-primary(:href='subsidary_path' target='_blank')
          i.fa.fa-edit.tw-mx-2
          span {{$I18n.t('vue.new_order.restaurant_register_hood')}}

      .app-card.mt-3.mx-5.border-danger(v-if='options.services.length == 0 && values.subsidary.input != ""')
        p(
          class="app-text-error"
        ) {{$I18n.t('vue.new_order.restaurant_no_services')}}
        a.app-btn-link-primary(:href='subsidary_path' target='_blank')
          i.fa.fa-edit.tw-mx-2
          span {{$I18n.t('vue.new_order.restaurant_register_service')}}
    
    .flex(class='justify-between items-center')
      h5(class="h5 pt-4 tw-text-gray-500") {{$I18n.t('vue.new_order.subtitle_order_items')}}
    .app-card.mb-2.overflow-x-auto
      .flex(class='justify-end')
        create-orderable-modal(
          :data-model='dataModel'
          :data-maintenance-kinds='dataOptionsMaintenanceKinds'
          :data-maintenance-complement-kinds='dataOptionsMaintenanceComplementKinds'
          :data-subsidary-hoods='array_subsidary_hoods'
          :data-orderable-kinds='dataOptionsOrderableKinds'
          :data-hide-modal='"true"'
          @created='addItem'
        )

      div(v-if='emptyItems')
        p(class='message-search') {{$I18n.t('vue.new_order.no_order_items')}}
      div(v-else)
        table.table
          thead
            tr
              th {{$I18n.t('vue.new_order.table_order_items_header_kind')}}
              th {{$I18n.t('vue.new_order.table_order_items_header_service_kind')}}
              th {{$I18n.t('vue.new_order.table_order_items_header_under_hood')}}
              th {{$I18n.t('vue.new_order.table_order_items_header_quantity')}}
              th {{$I18n.t('vue.new_order.table_order_items_header_price')}}
              th {{$I18n.t('vue.new_order.table_order_items_header_total')}}
          tbody
            tr(v-for="item in array_items_by_order")
              td {{ item.hood.id=='' ? "Extra" : "Service" }}
              td {{ item.service_kind.label }}
              td {{ item.hood.id !== '' ? `${item.hood.fan_type == null ? '' : ''} ${item.hood.equipment_under}` : '' }}
              td {{ item.quantity }}
              td {{ item.price != '0.0' && item.price != '0.00' && item.price != '' ? '$'+`${((item.price)*100/100).toFixed(2)}` : '$0.00' }}
              td {{ item.quantity*item.price != 0 ? '$'+`${((item.quantity*item.price)*100/100).toFixed(2)}` : '-' }}
              td
                app-icon-btn(
                  data-kind="link",
                  @click.native="removeItem(item)",
                  data-icon="fa fa-window-close",
                  data-label="",
                  data-title="$I18n.t('vue.new_order.data_title_delete_item')",
                )
            tr
              td(colspan="7")
            tr
              td(colspan="4")
              th {{$I18n.t('vue.new_order.total_order_price')}}
              td $
                input(type="number", v-model="values.price.input")
              td
    .flex.justify-end
      button.app-btn-primary(
        @click="create"
        :disabled= 'emptyItems'
      ) {{$I18n.t('vue.new_order.create')}}

  div(v-if="error_en_fechas" class="tw-pt-5 alert alert-danger col-md-6 offset-md-6")
    span {{$I18n.t('vue.new_order.messages.error_dates')}}

</template>
<script>
import CoreComponents from "../core";
import OrderableSelector from "./orderable_selector.vue";
import OrderableComponents from "../orderable";
const { ActiveValue } = require("../../core/active_support");
const { BackendService, BackendRoutes } = require("app/services");
const {
  ActiveValuesMixin,
  NotificationsMixin,
  ProcessingMixin,
} = require("../../mixins");

export default {
  props: {
    dataModel: String,
    dataSubsidaries: String,
    dataSubsidarySelected: String,
    dataItemKinds: String,
    dataOptionsMaintenanceKinds: String,
    dataOptionsMaintenanceComplementKinds: String,
    dataOptionsOrderableKinds: String
  },
  mixins: [ActiveValuesMixin, NotificationsMixin, ProcessingMixin],
  components: {
    ...CoreComponents,
    "orderable-selector": OrderableSelector,
    "create-orderable-modal": OrderableComponents.CreateOrderableModal,
  },
  data: function () {
    let self = this;
    return {
      error_en_fechas: false,
      maintenances: [],
      controls: {
        button_new_order: {
          isDisabled: true,
        },
        alert: {
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: "",
          },
        },
        addItemButton: {
          disabled: true,
        },
      },
      state: {
        loading: false,
        sending: false,
      },
      subsidary_hoods: [],
      options: {
        subsidaries: JSON.parse(this.dataSubsidaries),
        orderables: JSON.parse(this.dataOptionsOrderableKinds),
        maintenances_kinds: JSON.parse(this.dataOptionsMaintenanceKinds),
        maintenance_complements_kinds: JSON.parse(this.dataOptionsMaintenanceComplementKinds),
        hoods: [],
        services: [],
      },
      array_subsidary_hoods: JSON.stringify(""),
      array_subsidary_services: [],
      values: {
        subsidary: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_order.label_subsidary"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataSubsidaries));
          i.required();
          i.onChange(() => {
            self.setSubsidary();
          });
        }),
        service: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_order.label_service"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", []);
          i.onChange(() => {
            self.setService();
          });
        }),
        frequency: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_order.frequency"));
          i.setValue("0");
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        frequency_month: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_order.frequency_month"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("text");
        }),
        due_date: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_order.due_date"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("date");
        }),
        schedule_date: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_order.schedule_date"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("date");
        }),
        price: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_order.price"));
          i.setValue("0.00");
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        })
      },
      array_items_by_order: [],
      array_maintenances_by_order: [],
      inputs: {
        subsidary: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_order.label_subsidary"));
          i.setValue("");
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataSubsidaries));
          i.required();
        }),
        date: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.new_order.label_schedule_date"));
          i.setValue("");
          i.setInputKind("date");
          i.required();
        }),
      },
      labels: {
        order_created_ok: this.$I18n.t("vue.new_order.result.order_created_ok"),
      },
    };
  },
  computed: {
    mainGrid: function () {
      return [
        [this.values.subsidary, this.values.service],
        [this.values.frequency, this.values.price],
        [this.values.due_date, this.values.schedule_date]
      ];
    },
    orderable_items: function () {
      return this.options[this.values.item.kind.input + "_kinds"];
    },
    order_path: function () {
      return BackendRoutes.order_path(this.result.order.id);
    },
    subsidary_path:function(){
        return BackendRoutes.subsidary_path(this.values.subsidary.input);
      },
    grid: function () {
      return [[this.inputs.subsidary, this.inputs.date]];
    },
    subsidary_selected: function () {
      this.retrieveMaintenances();
    },
    emptyItems: function(){
      return this.array_items_by_order.length === 0;
    },
  },
  methods: {
    init: function () {
      this.maintenances = [];
      this.array_maintenances_by_order = [];
      this.subsidary_id = undefined;
      this.controls.button_new_order.isDisabled = true;
      this.setActiveValues(this.inputs, "");
    },
    initialData: function () {
      return {
        options: {
          hoods: [],
        },
        values: {
          item: {
            kind: null,
            price: null,
            quantity: null,
            orderable: {},
          },
          items: [],
        },
      };
    },
    setHoodsOptions: function (hoods) {
      let array_hoods = [];
      hoods.forEach( function(valor, indice, array) {
        if (valor.state=="ACTIVE"){
          array_hoods.push({
            value: valor.id+"",
            label: valor.equipment_under+""
          })
        }
      });
      this.options.hoods = array_hoods
      this.array_subsidary_hoods = JSON.stringify(array_hoods)
      this.subsidary_hoods = hoods
    },
    setServicesOptions: function (services) {
      let array_services = [];
      services.forEach( function(valor, indice, array) {
        if (valor.deleted_at == null){
          array_services.push({
            value: valor.id+"",
            label: valor.service+""
          })
        }
      });
      this.options.services = array_services
      this.values.service.setInputOption("options", this.options.services);
      this.array_subsidary_services = services
    },
    setSubsidary: function () {
      let self = this;
      //Obtiene el listado de hoods
      BackendService.subsidary_hoods(this.values.subsidary.input)
        .then((data) => self.setHoodsOptions(data.hoods))
        .then((_) => {
          self.array_items_by_order = [];
        });
      //Obtiene el listado de servicios
      BackendService.subsidary_services(this.values.subsidary.input)
        .then((data) => self.setServicesOptions(data.services))
        .then((_) => {
          self.array_items_by_order = [];
        });
      //Limpia los otros campos de Frequency y Price
      this.values.service.setValue("");
      this.values.frequency.setValue("0");
      this.values.price.setValue("");
    },
    setDueDate: function () {
      //Si DueDate lo dejaron vacio, pero si hay schedule date, entonces le asigna el mismo valor
      if (this.values.due_date.input=="" && this.values.schedule_date.input!=""){
        this.values.due_date.setValue(this.values.schedule_date.input);
      }
    },
    validDueDate: function () {
      this.error_en_fechas = false;
      //Si DueDate lo dejaron vacio y schedule date tambien es vacio, es error.
      if (this.values.due_date.input=="" && this.values.schedule_date.input==""){
        this.error_en_fechas = true;
      }
    },
    setService: function () {
      let self = this;
      this.values.price.setValue("0.00");
      this.values.frequency.setValue("0");
      const res = this.array_subsidary_services.find(service => service.id == this.values.service.input)
      if (res != undefined){
        this.values.price.setValue(res.total);
        this.values.frequency_month.setValue("");
        if(res.frequency==null || res.frequency==undefined || res.frequency==""){
          //Si no tiene periodicidad en numero, entonces es por mes, y se coloca "0"
          res.frequency = 0
          this.values.frequency_month.setValue(res.frequency_month);
        }
        this.values.frequency.setValue(res.frequency);
      }
    },
    setOrderable: function () {
      this.values.item.price.setValue(this.values.item.orderable.input.price);
      this.values.item.quantity.setValue(1);
    },
    isItemMaintenance: function () {
      return this.values.item.kind.input == "maintenances";
    },
    isItemComplement: function () {
      return this.values.item.kind.input == "maintenances_complement";
    },
    validateItem: function () {
      let values = [];

      if (this.isItemMaintenance()) {
        values = [
          this.values.item.kind,
          this.values.item.orderable,
          this.values.item.hood,
          this.values.item.quantity,
          this.values.item.price,
        ];
      } else if (this.isItemComplement()) {
        values = [
          this.values.item.kind,
          this.values.item.orderable,
          this.values.item.quantity,
          this.values.item.price,
        ];
      }

      let isValid = values
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);

      if (isValid) {
        this.controls.addItemButton.disabled = false;
      }
    },
    addItem: function (params) {
      this.setDueDate();
      let items = JSON.parse(params).array_hoods
      let new_item = {}
      items.forEach(item => {
        new_item = {
          order_item_kind: '',
          service_kind: {
            kind: item.orderable.kind,
            label: this.getDataMaintenance(item.orderable.id, item.orderable.kind).label,
            id: item.orderable.id,
          },
          hood: {
            id: item.orderable.hood.id,
            fan_type: this.getDataHood(item.orderable.hood.id).fan_type,
            equipment_under: this.getDataHood(item.orderable.hood.id).equipment_under,
          },
          quantity: item.quantity,
          price: item.price,
        }
        this.array_items_by_order.push(new_item);
        this.values.price.value = Number(this.values.price.value)+(Number(item.quantity)*Number(item.price))
      })
    },
    getDataMaintenance: function (id_maintenance, type_maintenance) {
      let data_maintenance={
        label: null,
        value: null
      }
      if (type_maintenance=="maintenances"){
        data_maintenance = this.options.maintenances_kinds.find(element => element.value == id_maintenance)
      }
      if (type_maintenance=="maintenance_complements"){
        data_maintenance = this.options.maintenance_complements_kinds.find(element => element.value == id_maintenance)
      }
      return data_maintenance;
    },
    getDataHood: function (id_hood) {
      let data_hood={
        description: null,
        equipment_under: null,
        fan_type: null,
        id: null,
        subsidary_id: null,
      }
      if (id_hood==''){
      }else{
        data_hood = this.subsidary_hoods.find(element => element.id == id_hood)
      }
      return data_hood
    },
    removeItem: function (item) {
      this.array_items_by_order.splice(this.array_items_by_order.indexOf(item), 1);
      this.values.price.value = Number(this.values.price.value)-(Number(item.quantity)*Number(item.price))
    },
    stringifyRetrieveMaintenances: function () {
      return JSON.stringify({
        query: {
          subsidary_id: this.inputs.subsidary.input,
          state_name: "REGISTERED",
        },
      });
    },
    retrieveMaintenances: function () {
      let self = this;
      self.maintenances = [];
      if (this.inputs.subsidary.isPresent()) {
        self.state.loading = true;
        BackendService.retrieve_maintenances_by_subsidary(
          self.stringifyRetrieveMaintenances()
        )
          .then((response) => {
            self.result = response;
            if (response.isOk) {
              self.maintenances = response.maintenances;
            }
          })
          .catch(() => {
            self.result.isError = true;
            self.result.notice = "error_promise";
          })
          .finally(() => {
            self.state.loading = false;
          });
      }
    },
    checkedMaintenance: function (id_maintenance) {
      const index = this.array_maintenances_by_order.indexOf(id_maintenance);
      if (index === -1) {
        //Add ID Maintenance
        this.array_maintenances_by_order.push(id_maintenance);
      } else {
        //Remove ID Maintenance
        this.array_maintenances_by_order.splice(index, 1);
      }
      this.state_disabled_button_new_order();
    },
    state_disabled_button_new_order: function () {
      if (this.array_maintenances_by_order.length === 0) {
        return (this.controls.button_new_order.isDisabled = true);
      } else {
        return (this.controls.button_new_order.isDisabled = false);
      }
    },
    valid_order_params: function () {
      let ins = this.values;
      return Object.values(this.values)
        .map((i) => {
          i.validate();
          return i.hasError();
        })
        .every((e) => e === false);
    },
    createOrderParams: function () {
      const params = {
        subsidary_id: this.values.subsidary.input,
        service_id: this.values.service.input+"",
        frequency: this.values.frequency.input+"",
        frequency_month: this.values.frequency_month.input+"",
        price: this.values.price.input+"",
        due_date: this.values.due_date.input,
        schedule_date: this.values.schedule_date.input,
        items: this.itemsOrderParams(),
      };
      return JSON.stringify(params);
    },
    itemsOrderParams: function () {
      let array_items = []
      this.array_items_by_order.forEach( function(valor, indice, array) {
        array_items.push({
          price: valor.price,
          quantity: valor.quantity,
          orderable: {
            kind: valor.service_kind.kind,
            id: valor.service_kind.id,
            hood: {
              id: valor.hood.id
            }
          }
        })
      });
      return array_items;
    },
    create: function () {
      this.setDueDate();
      this.validDueDate();
      let self = this;
      if (this.areValidActiveValues(this.values) && !this.error_en_fechas) {
        self.state.sending = true;
        BackendService.create_order(this.createOrderParams())
          .then((data) => {
            // Obtener la URL actual
            const urlActual = window.location.href;
            // Validar si la URL contiene "ss" o "on"
            if (urlActual.includes("ss")) {
              location.href = urlActual;
            } else {
              location.href = data.order.paths.show;
            }
          });
      }
    },
    autoSelectSubsidaryAndService: function () {
      if (this.dataSubsidarySelected){
        this.values.subsidary.setValue(this.dataSubsidarySelected);
      }
    },
  },
  mounted() {
    // Código que deseas ejecutar después de que el componente se haya cargado
    //   Se asigna automaticamente al Restaurant
    this.autoSelectSubsidaryAndService();
    //   Carga los datos del Restaurant
    this.setSubsidary();
  },
};
</script>
