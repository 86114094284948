import NotesDetailTablePanel from './notes-detail-table-panel.vue';
import CreateNoteModal  from './create-note-modal.vue';
import DeleteNoteModal  from './delete-note-modal.vue';
import EditNoteModal  from './edit-note-modal.vue';

export default{
  'notes-detail-table-panel': NotesDetailTablePanel,
  'create-note-modal': CreateNoteModal,
  'delete-note-modal': DeleteNoteModal,
  'edit-note-modal': EditNoteModal,
}