<template lang="pug">

div
  app-icon-btn(
    @click.native="showModal"
    data-kind="link",
    :data-icon="dataIcon",
    :data-label="dataLabel",
  )
  // Modal
  app-modal(
    :showing='controls.modal.showing'
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}
    
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        app-form(
          v-if="!state.processing"
          v-bind:grid="form_grid"
          class='tw-py-4'
        )
      // Calculate Total
      div
        p(style="visibility: hidden;") {{calculate_total}}

    template(v-slot:footer)
      .app-buttons-row( v-if="!state.sending" class='justify-end' )
        app-icon-btn(
          v-if="dataMode=='new'"
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="update",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>
<script>

import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default{
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components:{
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataItem: String,
    dataIcon: String,
    dataLabel: String,
    dataMode: String,
    dataPercentageDefault: String,
  },
  data:function(){
    return {
      state:{
        sending: false
      },
      controls:{
        modal:{
          showing: false
        }
      },
      values:{
        concept: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_invoice_item.label_invoice_item_concept"));
          i.setValue(JSON.parse(this.dataItem).concept);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        description: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_invoice_item.label_invoice_item_description"));
          i.setValue(JSON.parse(this.dataItem).description);
          i.setKind("string");
          i.setInputKind("textArea");
          i.optional();
        }),
        price: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_invoice_item.label_invoice_item_price"));
          i.setValue(JSON.parse(this.dataItem).price);
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        quantity: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_invoice_item.label_invoice_item_quantity"));
          i.setValue( JSON.parse(this.dataItem).quantity=="" ? 1 : JSON.parse(this.dataItem).quantity );
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        percentage: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_invoice_item.label_invoice_item_percentage"));
          i.setValue( JSON.parse(this.dataItem).percentage=="" ? JSON.parse(JSON.parse(this.dataPercentageDefault)) : JSON.parse(this.dataItem).percentage );
          i.setKind("string");
          i.setInputKind("number");
          i.required();
        }),
        total: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_invoice_item.label_invoice_item_total"));
          i.setValue(0);
          i.setKind("string");
          i.setInputKind("number");
          i.required();
          i.notEditable();
        }),
      },
      labels:{
        modal_title: this.$I18n.t('vue.edit_invoice_item.modal_title'),
        clear_btn: this.$I18n.t('vue.edit_invoice_item.button_clear'),
        cancel_btn: this.$I18n.t('vue.edit_invoice_item.button_cancel'),
        send_btn: this.$I18n.t('vue.edit_invoice_item.button_save')
      }
    }
  },
  computed:{
    form_grid:function(){
      return [
        [this.values.concept],
        [this.values.description],
        [this.values.price,    this.values.quantity,  this.values.percentage, this.values.total]
      ]
    },
    calculate_total:function(){
      let price = this.values.price.input;
      let quantity = this.values.quantity.input;
      let percentage = this.values.percentage.input;
      let subtotal = price * quantity;
      let total = (   subtotal + ( (subtotal*percentage)/100 )   );
      total = (Math.round(total * 100) / 100).toFixed(2)
      this.values.total.setValue( total )
      return total;
    },
  },
  methods:{
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function(){
      this.controls.modal.showing = false;
    },
    clear: function(){
      this.resetActiveValues(this.values);
      if (this.dataMode=='new') {
        this.values.percentage.setValue( JSON.parse(JSON.parse(this.dataPercentageDefault)) )
        this.values.quantity.setValue( JSON.parse(this.dataItem).quantity=="" ? 1 : JSON.parse(this.dataItem).quantity )
      }else{
        this.values.concept.setValue( JSON.parse(this.dataItem).concept )
        this.values.description.setValue( JSON.parse(this.dataItem).description )
        this.values.price.setValue( JSON.parse(this.dataItem).price )
        this.values.quantity.setValue( JSON.parse(this.dataItem).quantity=="" ? 1 : JSON.parse(this.dataItem).quantity )
        this.values.percentage.setValue( JSON.parse(this.dataItem).percentage=="" ? JSON.parse(JSON.parse(this.dataPercentageDefault)) : JSON.parse(this.dataItem).percentage )
        this.values.total.setValue( JSON.parse(this.dataItem).total=="" ? 0 : JSON.parse(this.dataItem).total )
      }
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.clear();      
      this.hideModal();
    },
    params:function(){
      return JSON.stringify({
        id: JSON.parse(this.dataItem).id,
        concept: this.values.concept.input,
        description: this.values.description.input,
        price: this.values.price.input+"",
        quantity: this.values.quantity.input+"",
        percentage: this.values.percentage.input+"",
      })
    },
    update:function(){
      let self = this; 
      if(self.areValidActiveValues(this.values)){
        self.state.sending=true;
        let params = this.params()
        this.clear();
        //this.hideModal();
        this.$emit("updated", params);        
      }
    }
  }
}
</script>
