<template lang="pug">
div
 div(
   v-if='!state.editing'
   class='tw-flex tw-flex-row'
  )
  p {{price.value}}
  a(class="app-btn-link" @click='edit')
    i(class="fa fa-pencil ml-2") 

 div(v-else)
  input(class='form-control' v-model='price.input')
  a(class="app-btn-link mr-2" @click='cancel')
    i(class="fa fa-times")
    span(class='ml-2') {{$I18n.t('vue.maintenance_price_value.cancel')}}
  a(class="app-btn-link" @click='update')
    i(class="fa fa-save")
    span(class='ml-2') {{$I18n.t('vue.maintenance_price_value.save')}}
</template>
<script>
import { BackendService } from '../../services';
const {ActiveValue} = require('../../core/active_support');

export default {
  props:['dataValue', 'dataRecord'],
  data: function(){
    return {
      state:{
        editing: false,
        loading: false
      },
      price: ActiveValue().setup(i=>{
        i.setValue(this.dataValue)
      }),
      record: this.dataRecord
    }
  },
  methods:{
    edit: function(){
      this.state.editing = true;
    },
    cancel: function(){
      this.state.editing = false;
    },
    params: function(){
      return JSON.stringify({
        price: this.price.input
      })
    },
    update: function(){
      let self = this;
      BackendService
        .update_maintenance_price(
          this.record.id,
          this.params()
        )
        .then(r =>{
          if(r.isOk){
            self.price.setValue(r.maintenance.price);
            self.state.editing = false;
          }
        })
    }
  }
}
</script>