export default{
  methods:{
    areValidActiveValues: function(activeValues){
      return Object
        .values(activeValues)
        .map(v=>v.validate())
        .map(v=>v.hasError())
        .every(e=>e===false)
    },
    setActiveValues: function(activeValues, value){
      Object
        .values(activeValues)
        .map(v=>v.setValue(value))
    },
    resetActiveValues: function(activeValues){
      Object
        .values(activeValues)
        .forEach(v=>v.resetValue())
    }
  }
}