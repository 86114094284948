<template lang="pug">

.app-card.overflow-x-auto
  .flex(class='justify-end')
    create-subsidary-modal(
      :data-id='record.id' 
      :data-customer='dataCustomer'
      :data-model='record.model'
      :data-note-kind-visible='dataNoteKindVisible'
      :data-invoice-target='dataInvoiceTarget'
    )
  table.table(v-if="!emptyItems")
    thead
      th {{$I18n.t('vue.subsidaries_detail_table_panel.table_name')}}
      th {{$I18n.t('vue.subsidaries_detail_table_panel.table_address')}}
      th(class="tw-w-28")
    tbody
      tr(v-for="item in items")
        td
          .tw-flex.tw-flex-col
            div {{ item.aka }} 
            div {{ item.name }}
        td {{ item.address.full }}
        td
          .app-buttons-row.justify-end
            delete-subsidary-modal(
              :data-id='item.id'
              @deleted='retrieveCustomers'
            )
            app-icon-link(
              data-kind="link",
              :href="item.paths.show"
              data-icon="fa fa-eye",
              data-label="",
              :data-title="$I18n.t('vue.subsidaries_detail_table_panel.view_restaurant')"
            )
  div
    p(v-if='emptyItems' class='message-search') {{$I18n.t('vue.subsidaries_detail_table_panel.empty_items')}}
</template>

<script>
import CreateSubsidaryModal from './create-subsidary-modal.vue';
import DeleteSubsidaryModal from './delete-subsidary-modal.vue'
import CoreComponents from "../core";
import { BackendService } from "../../services";

export default {
  props: {
    dataId: String,
    dataCustomer: String,
    dataModel: String,
    dataItems: String,
    dataNoteKindVisible: String,
    dataInvoiceTarget: String,
  },
  components:{
    'create-subsidary-modal': CreateSubsidaryModal,
    'delete-subsidary-modal': DeleteSubsidaryModal,
    "app-icon-link": CoreComponents.AppIconLink,
  },
  data: function () {
    return {
      record: {
        id: this.dataId,
        model: this.dataModel,
      },
      items: JSON.parse(this.dataItems),
    };
  },
  computed: {
    emptyItems: function () {
      return this.items.length === 0;
    }
  },
  methods: {
    retrieveCustomers: function () {
    }
  }
};
</script>