<template lang="pug">
.flex.justify-end.mb-2
  create-orderable-modal(
    :data-model='dataModel'
    :data-maintenance-kinds='dataMaintenanceKinds'
    :data-maintenance-complement-kinds='dataMaintenanceComplementKinds'
    :data-subsidary-hoods='dataSubsidaryHoods'
    :data-orderable-kinds='dataOrderableKinds'
    @created='CreateItem'
  )
  .row
    .col
      select.form-select(v-model="values.kind.input")
        option(selected, disabled, :value="null") Please Select Kind
        option(v-for="item in html.kindsOptions", :value="item.value") {{ item.label }}
    .col
      select.form-select(v-model="values.orderable.input")
        option(selected, disabled, :value="null") Please Select Item
        option(v-for="item in orderables", :value="item") {{ item.name }}
    .col
      select.form-select(
        v-model="values.item.hood.input",
        :disabled="values.item.kind.input !== 'maintenances'"
      )
        option(selected, disabled, :value="null") Please Select Hood
        option(v-for="hood in html.hoodsOptions", :value="hood") {{ hood.fan_type }} / {{ hood.equipment_under }}
    .col
      input.form-control(
        type="number",
        placeholder="Quantity",
        v-model="values.quantity.input"
      )
    .col
      input.form-control(
        type="number",
        placeholder="Price",
        v-model="values.price.input"
      )
    .col
      button.app-btn-link(
        :disabled="controls.addItemButton.disabled",
        @click="addItem()"
      ) Add
</template>

<script>
const { ActiveValue } = require("../../core/active_support");

export default {
  props: {
    dataModel: String,
    dataKindsOptions: String,
    dataOrderablesOptions: Object,
    dataHoodsOptions: Array
  },
  data: function () {
    let self = this;
    return {
      html: {
        kindsOptions: this.dataKindsOptions,
        orderablesOptions: this.dataOrderablesOptions,
        hoodsOptions: this.dataHoodsOptions
      },
      values: {
        kind: ActiveValue().setup((i) => {
          i.setValue(null);
          i.required();
          i.onInput((_) => {
            self.validate();
          });
        }),
        orderable: ActiveValue().setup((i) => {
          i.setValue(null);
          i.required();
          i.onInput((_) => {
            self.setPriceAndQuantity();
            self.validate();
          });
        }),
        price: ActiveValue().setup((i) => {
          i.setValue(null);
          i.required();
          i.onInput((_) => {
            self.validate();
          });
        }),
        quantity: ActiveValue().setup((i) => {
          i.setValue(null);
          i.required();
          i.onInput((_) => {
            self.validate();
          });
        }),
        hood: ActiveValue().setup((i) => {
          i.setValue(null);
          i.required();
          i.onInput((_) => {
            self.validate();
          });
        }),
      },
    };
  },
  computed: {
    orderables: function () {
      return this.html.orderablesOptions[this.values.kind.input];
    },
  },
  methods: {
    isMaintenance: function () {
      return this.values.kind.input == "maintenances";
    },
    isComplement: function () {
      return this.values.kind.input == "complements";
    },
    validate: function () {
      let values = [];

      if (this.isMaintenance()) {
        values = [
          this.values.kind,
          this.values.orderable,
          this.values.hood,
          this.values.quantity,
          this.values.price,
        ];
      } else if (this.isComplement()) {
        values = [
          this.values.kind,
          this.values.orderable,
          this.values.quantity,
          this.values.price,
        ];
      }

      let isValid = values
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);

      if (isValid) {
        //this.controls.addItemButton.disabled = false;
      }
    },
    setPriceAndQuantity: function () {
      this.values.price.setValue(this.values.orderable.input.price);
      this.values.quantity.setValue(1);
    },
  },
};
</script>