<template lang="pug">
div
  app-icon-btn(
    data-kind="link",
    @click.native="showModal",
    data-icon="fa fa-window-close",
    :data-label='dataLabel',
    :data-title="$I18n.t('vue.delete_subsidary_modal.title')"
  )

  // Modal
  app-modal(
    :showing="controls.modal.showing",
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}

    // Body
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")
      div(v-else)
        div(v-if="!state.confirmed")
          .tw-mt-3.tw-text-center.tw-mb-8
            p.tw-mb-5.tw-text-xl {{ labels.confirmation_question }}
            p {{labels.comments}}
          .app-buttons-row.justify-center
            button.app-btn-link-primary(@click="reject") 
              i.fa.fa-times.mr-2
              span {{ labels.confirmation_reject }}
            button.app-btn-primary(@click="send") 
              i.fa.fa-check.mr-2
              span {{ labels.confirmation_accept }}
</template>

<script>
import CoreComponents from "../core";
import {BackendService} from '../../services';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components: {
    "app-modal": CoreComponents.Modal,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:['dataId','dataLabel'],
  data: function () {
    return {
      state: {
        confirmed: false,
        sending: false,
      },
      controls: {
        modal: {
          showing: false,
        },
      },
      labels: {
        confirmation_question: this.$I18n.t('vue.delete_subsidary_modal.confirmation_question'),
        comments: this.$I18n.t('vue.delete_subsidary_modal.comments'),
        confirmation_reject: this.$I18n.t('vue.delete_subsidary_modal.reject'),
        confirmation_accept: this.$I18n.t('vue.delete_subsidary_modal.accept'),
        modal_title: this.$I18n.t('vue.delete_subsidary_modal.title'),
      },
    };
  },
  methods: {
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    reject: function () {
      this.state.confirmed = false;
      this.hideModal();
    },
    send: function (id_customer) {
      const self = this;
      this.state.sending = true
      BackendService
        .disable_subsidary(
          this.dataId,
          JSON.stringify({atts: {}})
        )
        .then((r) => {
          location.reload();
        });
    },
  },
};
</script>
