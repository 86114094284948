<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display" 
    v-bind:alert="controls.alert"
  )

  .tw-flex.tw-flex-row.tw-justify-between
    div
      h5.h5.tw-text-gray-500 {{$I18n.t('vue.maintenance_kind_detail.title')}}
    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center")
      div(class="spinner-border spinner-border-sm" role="status")

    //Barra de botones  
    div(v-else)
      .tw-flex.tw-flex-row
        button.app-btn-link-primary(v-if="record.state!='INACTIVE' && state.show", @click="disable_maintenance_kind()") 
          i.fa.fa-window-close.tw-mx-1 
          span {{$I18n.t('vue.maintenance_kind_detail.inactive_service_kind')}}
        button.app-btn-link-primary(v-if="record.state=='INACTIVE' && state.show", @click="active_maintenance_kind()") 
          i.fa.fa-check-circle.tw-mx-1 
          span {{$I18n.t('vue.maintenance_kind_detail.active_service_kind')}}
        button.app-btn-link-primary(v-if="state.show", @click="edit()") 
          i.fa.fa-pencil.tw-mx-1 
          span {{$I18n.t('vue.maintenance_kind_detail.edit')}}
        button.app-btn-link-primary(v-if="state.edit", @click="cancel()")
          i.fa.fa-times.tw-mx-2
          span {{$I18n.t('vue.maintenance_kind_detail.cancel')}}
        button.app-btn-link-primary(v-if="state.edit", @click="update()")
          i.fa.fa-save.tw-mx-2
          span {{$I18n.t('vue.maintenance_kind_detail.save')}}
  hr
  Detailgrid(:data-grid="grid", :data-editing="state.edit")
</template>

<script>
import CoreComponents from "../core";
import { BackendService } from "../../services";
const { ActiveValue } = require("../../core/active_support");

export default {
  props: ["dataRecord"],
  components: { ...CoreComponents },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    let self = this;
    return {
      state: {
        show: true,
        edit: false,
        sending: false,
      },
      controls:{
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      values: {
        id: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.maintenance_kind_detail.id"));
          i.setValue(self.record.id);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        state: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.maintenance_kind_detail.state"));
          i.setValue(self.$I18n.t("vue.states."+self.record.state.toLowerCase()));
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        name: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.maintenance_kind_detail.name"));
          i.setValue(self.record.name);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        abbreviation: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.maintenance_kind_detail.abbreviation"));
          i.setValue(self.record.abbreviation);
          i.setKind("string");
          i.setInputKind("text");
        }),
        periodicity: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.maintenance_kind_detail.periodicity"));
          i.setValue(self.record.periodicity);
          i.setKind("string");
          i.setInputKind("number");
        }),
        description: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.maintenance_kind_detail.description"));
          i.setValue(self.record.description);
          i.setKind("string");
          i.setInputKind("textArea");
        })
      },
    };
  },
  methods: {
    disable_maintenance_kind: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .disable_maintenance_kind(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    active_maintenance_kind: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .active_maintenance_kind(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    valid: function () {
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    edit: function () {
      this.state.edit = true;
      this.state.show = false;
      this.state.sending = false;
    },
    cancel: function () {
      this.state.show = true;
      this.state.edit = false;
      this.state.sending = false;
      Object.values(this.values).map((v) => v.valueToInput());
    },
    sending: function () {
      this.state.show = false;
      this.state.edit = false;
      this.state.sending = true;
    },
    params: function () {
      return JSON.stringify({
        id: this.record.id,
        atts: {
          name: this.values.name.input,
          abbreviation: this.values.abbreviation.input,
          frequency: this.values.periodicity.input,
          description: this.values.description.input
        },
      });
    },
    update: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService.update_maintenance_kind(this.params()).then((r) => {
          self.cancel();
          self.values.name.setValue(r.maintenance_kind.name);
          self.values.abbreviation.setValue(r.maintenance_kind.abbreviation);
          self.values.periodicity.setValue(r.maintenance_kind.periodicity);
          self.values.description.setValue(r.maintenance_kind.description);
        });
      }
    },
  },
  computed: {
    grid: function () {
      return [
        [this.values.id,                    this.values.state ],
        [this.values.name,                  this.values.abbreviation],
        [this.values.description,           this.values.periodicity ]
      ];
    },
  },
};
</script>