<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display" 
    v-bind:alert="controls.alert"
  )

  .tw-flex.tw-flex-row.tw-justify-between
    div
      h5.h5.tw-text-gray-500 {{$I18n.t('vue.payment_detail.title')}}
    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center")
      div(class="spinner-border spinner-border-sm" role="status")

    //Barra de botones  
    div(v-else)
      .tw-flex.tw-flex-row
        button.app-btn-link-primary(
          v-if="state.show", 
          @click="edit()",
          :disabled= 'record.status == "CANCELED"' 
        ) 
          i.fa.fa-pencil.tw-mx-1 
          span {{$I18n.t('vue.payment_detail.edit')}}
        button.app-btn-link-primary(v-if="state.edit", @click="cancel()")
          i.fa.fa-times.tw-mx-2
          span {{$I18n.t('vue.payment_detail.cancel')}}
        button.app-btn-link-primary(v-if="state.edit", @click="update()")
          i.fa.fa-save.tw-mx-2
          span {{$I18n.t('vue.payment_detail.save')}}
  hr
  Detailgrid(:data-grid="grid", :data-editing="state.edit")
</template>

<script>
import CoreComponents from "../core";
import { BackendService } from "../../services";
const { ActiveValue } = require("../../core/active_support");

export default {
  props: {
    dataRecord: String,
    dataWayToPay: String
  },
  components: { ...CoreComponents },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    let self = this;
    return {
      state: {
        show: true,
        edit: false,
        sending: false,
      },
      controls:{
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      values: {
        id: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.id"));
          i.setValue(self.record.id);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        status: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.status"));
          i.setValue(self.$I18n.t("vue.states."+self.record.status.toLowerCase()));
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        date_payment: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.date_payment"));
          i.setValue(self.record.locale_date_payment);
          i.setKind("string");
          i.setInputKind("date");
          i.required();
        }),
        amount_paid: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.amount_paid"));
          i.setValue(self.record.masked_amount_paid);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        amount_admin_fee: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.amount_admin_fee"));
          i.setValue(self.record.masked_amount_admin_fee_paid);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        amount_total: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.amount_total"));
          i.setValue(self.record.masked_amount_total);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        way_to_pay: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.way_to_pay"));
          i.setValue(self.$I18n.t("vue.payment_types."+self.record.way_to_pay.split(' ').join('_').toLowerCase()));
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataWayToPay));
          i.required();
          i.notEditable();
        }),
        num_check: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.num_check"));
          i.setValue(self.record.num_check);
          i.setKind("string");
          i.setInputKind("text");
        }),
        credit_card_number: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.credit_card_number"));
          i.setValue(self.record.credit_card_number);
          i.setKind("string");
          i.setInputKind("text");
        }),
        reference: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.reference"));
          i.setValue(self.record.reference);
          i.setKind("string");
          i.setInputKind("text");
        }),
        cancelation_reason: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.payment_detail.cancelation_reason"));
          i.setValue(self.record.cancelation_reason);
          i.setKind("string");
          i.setInputKind("text");
        }),
      },
    };
  },
  methods: {
    disable_payment: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .disable_payment(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    active_payment: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .active_payment(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    valid: function () {
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    edit: function () {
      this.values.date_payment.input = this.record.date_payment;
      this.values.amount_paid.input  = this.record.amount_paid;
      this.state.edit = true;
      this.state.show = false;
      this.state.sending = false;
    },
    cancel: function () {
      this.state.show = true;
      this.state.edit = false;
      this.state.sending = false;
      Object.values(this.values).map((v) => v.valueToInput());
    },
    sending: function () {
      this.state.show = false;
      this.state.edit = false;
      this.state.sending = true;
    },
    params: function () {
      return JSON.stringify({
        id: this.record.id,
        date_payment: this.values.date_payment.input,
        amount_paid: this.values.amount_paid.input,
        way_to_pay: this.values.way_to_pay.input,
        num_check: this.values.num_check.input,
        credit_card_number: this.values.credit_card_number.input,
        reference: this.values.reference.input,
      });
    },
    update: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService.update_payment(this.params()).then((r) => {
          self.cancel();
          self.values.date_payment.setValue(r.payment.table.item.date_payment);
          self.values.amount_paid.setValue(r.payment.table.item.amount_paid);
          self.values.way_to_pay.setValue(r.payment.table.item.way_to_pay);
          self.values.num_check.setValue(r.payment.table.item.num_check);
          self.values.credit_card_number.setValue(r.payment.table.item.credit_card_number);
          self.values.reference.setValue(r.payment.table.item.reference);
          location.reload();
        });
      }
    },
  },
  computed: {
    grid: function () {
      //Cancelation_reason
      let cancelation_reason
      if (this.record.cancelation_reason != ""){
        cancelation_reason = [this.values.cancelation_reason,   ""]
      } else{
        cancelation_reason=[]
      }
      return [
        [this.values.id,                    this.values.status ],
        [this.values.way_to_pay,            this.values.amount_paid ],
        [this.values.date_payment,          this.values.amount_admin_fee ],
        [this.values.num_check,             this.values.amount_total ],
        [this.values.credit_card_number,    '' ],
        [this.values.reference,             '' ],
        cancelation_reason,
      ];
    },
  },
};
</script>