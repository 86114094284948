<template lang="pug">
  div(class="tw-inline-flex")
    Maintenancerescheduleduedate(
      v-bind:dataMaintenanceRecord="maintenance"
    )
    Maintenancecancel(
      v-bind:dataMaintenanceRecord="maintenance"
    )

</template>

<script>
  import Maintenancerescheduleduedate from './maintenance_reschedule_due_date.vue';
  import Maintenancecancel from './maintenance_cancel.vue'
  
  export default{
    props:{
      dataMaintenanceRecord: String
    },
    components:{Maintenancerescheduleduedate, Maintenancecancel},
    data:function(){
      this.maintenance = JSON.parse(this.dataMaintenanceRecord);
      this.maintenance_state = this.maintenance.state.name.value
      return {
      }
    },
    computed:{
      buttonRescheduleDueDateEnabled:function(){ 
        let status_keys=['SCHEDULED']
        let present_status=status_keys.map(s=>this.maintenance_state===s).some(v=>v===true);
        if (present_status){
          return true;
        }else{
          return false;
        }
      },
      buttonCancelMaintenanceEnabled:function(){ 
        let status_keys=['SCHEDULED']
        let present_status=status_keys.map(s=>this.maintenance_state===s).some(v=>v===true);
        if (present_status){
          return true;
        }else{
          return false;
        }
      }
    },
    methods:{
    }
  }
</script>
 