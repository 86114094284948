<template lang="pug">
  a(:class="kind" :title='dataTitle' :href="path" :target="dataTarget" :style="dataStyle")
    i(:class='icon')
    span(class='ml-2') {{label}}
</template>

<script>
const kinds = {
  link: 'app-btn-link-primary',
  btn:  'app-btn-primary'
}
export default {
  props: ['dataKind', 'dataIcon', 'dataLabel', 'dataTitle', 'dataPath', 'dataTarget', 'dataStyle'],
  data: function(){
    return {
      icon: this.dataIcon,
      label: this.dataLabel,
      kind: kinds[this.dataKind],
      path: this.dataPath,
    }
  }
}
</script>
