const { save } = require("autoprefixer/lib/value");

module.exports={
  vue:{
    empty: '',
    states: {
      active: 'ACTIVO',
      inactive: 'INACTIVO',
      registered: 'REGISTRADO',
      scheduled: 'PROGRAMADO',
      completed: 'COMPLETADO',
      canceled: 'CANCELADO',
      unpaid: "NO PAGADO",
      semi_paid: "PARCIALMENTE PAGADO",
      paid: "PAGADO",
      applied: "APLICADO"
    },
    payment_types: {
      check: "CHEQUE",
      credit_card: "TARJETA DE CREDITO",
      direct_deposit: "DEPOSITO DIRECTO",
      money_order: "GIRO POSTAL",
      zelle: "ZELLE",
    },
    invoice_target: {
      restaurant: "RESTAURANTE",
      customer: "CLIENTE",
      vendor: "PROVEEDOR",
    },
    forgot_password_form:{
      send_button: 'Enviar',
      cancel_button: 'Cancelar',
      login_link: 'Volver a la página de inicio de sesión',
      sending: 'Enviando...',
      follow_instructions: 'Siga las instrucciones del correo electrónico para cambiar su contraseña',
      forget_password: '¿Olvidaste tu contraseña?',
      instructions_reset: `No te preocupes, cambiar tu contraseña es fácil, solo tienes que decirnos tu dirección de correo electrónico.`,
      error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      results:{
        reset_password_sended_if_account_exist_ok: 'Se ha enviado un correo electrónico si su cuenta existe',
        reset_password_email_sended_if_account_exist_ok: 'Se ha enviado un correo electrónico si su cuenta existe',
        invalid_email: 'Correo Electrónico Invalido',
        invalid_account_error: 'Cuenta no válida'
      },
    },
    change_password_edit_form:{
      change_button: 'Cambiar',
      cancel_button: 'Cancelar',
      login_link: 'Volver a la página de inicio de sesión',
      sending: 'Enviando...',
      password_placeholder: 'Contraseña',
      confirm_password_placeholder: 'Confirmar contraseña',
      change_password: 'Cambiar contraseña',
      different_passwords: 'La contraseña y la confirmación de contraseña deben coincidir',
      password_empty: 'No se permite contraseña vacía',
      error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      password_strenght: 'Seguridad de la contraseña:',
      weak: 'DÉBIL',
      medium: 'MEDIO',
      strong: 'FUERTE',
      password_need_to: 'Tu contraseña necesita:',
      number_characters: 'Tener al menos 8 caracteres.',
      one_number: 'Incluir al menos un número.',
      lowercase_letter: 'Incluir al menos una letra minúscula.',
      uppercase_letter: 'Incluir al menos una letra mayúscula.',
      special_character: 'Incluir al menos un carácter especial.',
      passwords_match: 'La contraseña y la confirmación de la contraseña deben ser las mismas.',
      results:{
        invalid_token_error: 'Token Invalido',
        InvaliPasswordError: 'Contraseña Invalida',
        InvaliPasswordConfirmationError: 'Confirmación de contraseña invalida',
        UserNotFoundError: 'Usuario no encontrado',
        user_update_error: 'Ocurrió un error al actualizar',
        user_updated_ok: 'La actualización fue exitosa'
      },
    },
    new_account_form:{
      label_contact_role:'Perfil:',
      label_contact_fistName:'Nombre:',
      label_contact_middleName:'Apellido Paterno:',
      label_contact_lastName:'Apellido Materno:',
      label_contact_phoneKind:'Tipo de Teléfono',
      label_contact_phoneNumber:'Número de Teléfono',
      label_contact_email: 'Correo Electrónico:',
      show_link: 'Ver Cuenta',
      button_create:'Crear',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
      view_detail_text:'Para ver la nueva cuenta creada, haga clic en el siguiente enlace:',
      RequiredInputError:'Este campo es obligatorio',
      InvalidEmailError: 'Correo Electrónico Invalido',
      error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      label_phone_kind: 'Tipo de Teléfono',
      phone_number: 'Número de Teléfono',
      result:{
        account_update_error: 'Ocurrió un error al actualizar la cuenta',
        account_updated_ok: 'Cuenta actualizada correctamente',
        account_created_ok: 'Cuenta creada con éxito',
        create_account_ok: 'Cuenta creada con éxito',
        create_account_user_exist: 'Ya existe una cuenta con este correo electrónico',
        account_not_found_error: 'Cuenta no encontrada',
        account_create_error: 'Ocurrió un error al crear la cuenta',
        address_create_error: 'Ocurrió un error al guardar la dirección',
        person_create_error: 'Ocurrió un error al guardar el contacto (persona)',
        contact_create_error: 'Ocurrió un error al guardar el contacto',
        phone_create_error: 'Ocurrió un error al guardar el contacto (número de teléfono)',
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      }      
    },
    new_customer_form:{
      subtitle_address:'Dirección',
      subtitle_contact:'Contacto',
      label_name:'Nombre:',
      label_aka: 'A.K.A.:',
      label_vendor:'Proveedor:',
      label_address_street:'Calle:',
      label_address_apartament:'Dep / Suite / Otro:',
      label_address_city:'Ciudad:',
      label_address_state:'Estado:',
      label_address_zipCode: 'Codigo Postal:',
      label_contact_role: 'Puesto:',
      label_contact_fistName:'Nombre:',
      label_contact_middleName:'Apellido Paterno:',
      label_contact_lastName:'Apellido Materno:',
      label_contact_phoneKind:'Tipo de Teléfono',
      label_contact_phoneNumber:'Número de Teléfono',
      label_contact_email: 'Correo Electrónico:',
      label_phone_number: 'Número de Teléfono',
      show_link: 'Ver Cliente',
      button_create:'Crear',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
      view_detail_text:'Para ver el nuevo cliente creado, haga clic en el siguiente enlace:',
      RequiredInputError:'Este campo es obligatorio',
      InvalidEmailError: 'Correo Electrónico Inválido',
      error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      result:{
        customer_update_error: 'Ocurrió un error al actualizar el cliente',
        customer_updated_ok: 'Cliente actualizado correctamente',
        customer_created_ok: 'Cliente creado con éxito',
        vendor_not_found_error: 'Proveedor no encontrado',
        customer_create_error: 'Ocurrió un error al crear el cliente',
        address_create_error: 'Ocurrió un error al guardar la dirección',
        person_create_error: 'Ocurrió un error al guardar el contacto (persona)',
        contact_create_error: 'Ocurrió un error al guardar el contacto',
        phone_create_error: 'Ocurrió un error al guardar el contacto (número de teléfono)',
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      }      
    },
    new_vendor_form:{
      subtitle_address:'Dirección',
      subtitle_contact:'Contacto',
      label_name:'Nombre',
      label_aka: 'A.K.A.:',
      label_invoice_target: 'Destino de la factura:', 
      label_address_street:'Calle:',
      label_address_apartament:'Dep / Suite / Otro:',
      label_address_city:'Ciudad:',
      label_address_state:'Estado:',
      label_address_zipCode: 'Codigo Postal:',
      label_contact_role: 'Puesto:',
      label_contact_fistName:'Nombre:',
      label_contact_middleName:'Apellido Paterno:',
      label_contact_lastName:'Apellido Materno:',
      label_contact_phoneKind:'Tipo de Teléfono',
      label_contact_phoneNumber:'Número de Teléfono',
      label_contact_email: 'Correo Electrónico:',
      show_link: 'Ver Proveedor',
      button_create:'Crear',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
      view_detail_text:'Para ver el nuevo proveedor creado, haga clic en el siguiente enlace:',
      RequiredInputError:'Este campo es obligatorio',
      InvalidEmailError: 'Correo Electrónico Inválido',
      error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      label_phone_kind: 'Tipo de Teléfono',
      phone_number: 'Número de Teléfono',
      result:{
        vendor_update_error: 'Ocurrió un error al actualizar el proveedor',
        vendor_updated_ok: 'Proveedor actualizado con éxito',
        vendor_created_ok: 'Proveedor creado con éxito',
        vendor_not_found_error: 'Proveedor no encontrado',
        vendor_create_error: 'Ocurrió un error al crear el proveedor',
        address_create_error: 'Ocurrió un error al guardar la dirección',
        person_create_error: 'Ocurrió un error al guardar el contacto (persona)',
        contact_create_error: 'Ocurrió un error al guardar el contacto',
        phone_create_error: 'Ocurrió un error al guardar el contacto (número de teléfono)',
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      }      
    },
    vendor_detail:{
      title: 'Proveedor',
      inactive_vendor: 'Desactivar Proveedor',
      active_vendor: 'Activar Proveedor',
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Guardar',
      id: 'ID',
      name: 'Nombre',
      aka: 'A.K.A.',
      state: 'Estado',
      vendor: 'Proveedor',
      customer: 'Cliente',
      phone_number: 'Número de Teléfono',
      invoice_target: 'Destino de la factura',
      result: {
        vendor_updated_ok: 'Proveedor actualizado con éxito',
      }
    },
    account_detail:{
      title: 'Cuenta',
      id: 'ID',
      status: 'Estado',
      first_name: 'Nombre',
      middle_name: 'Apellido Paterno',
      last_name: 'Apellido Materno',
      email: 'Correo Electrónico',
      role: 'Puesto',
      inactive_account: 'Desactivar Cuenta',
      active_account: 'Activar Cuenta',
      sending_email: 'Enviando Correo Electrónico ...',
      send_email_recover_password: 'Enviar correo electrónico para recuperar contraseña',
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Guardar',
      result: {
        reset_password_email_sended_if_account_exist_ok: 'Correo Electrónico Enviado Correctamente',
        account_updated_ok: 'Cuenta actualizada correctamente',
        email_not_sent: 'No Fue Posible Enviar el Correo Electrónico',
      }
    },
    subsidaries_detail_table_panel:{
      empty_items: "No hay restaurantes todavía",
      table_name: 'Nombre',
      table_address: 'Dirección',
      view_restaurant: 'Ver Restaurante',
    },
    create_subsidary_modal:{
      label_add_subsidary:'Agregar Restaurante',
      subtitle_address:'Dirección',
      subtitle_contact:'Contacto',
      subtitle_notes: 'Notas',
      label_name:'Nombre',
      label_aka: 'A.K.A.',
      label_phone: 'Número de Teléfono',
      label_address_street:'Calle:',
      label_address_apartament:'Dep / Suite / Otro:',
      label_address_city:'Ciudad:',
      label_address_state:'Estado:',
      label_address_zipCode: 'Codigo Postal:',
      label_contact_role:'Puesto:',
      label_contact_first_name:'Nombre:',
      label_contact_middle_name:'Apellido Paterno:',
      label_contact_last_name:'Apellido Materno:',
      label_contact_phone_kind:'Tipo de Teléfono',
      label_contact_phone_number:'Número de Teléfono',
      label_contact_email: 'Correo Electrónico:',
      label_service_note_description:'Notas del Servicio',
      label_service_note_visibility:'Visible para',
      label_billing_note_description:'Notas de Facturación',
      label_billing_note_visibility:'Visible para',
      label_work_note_description:'Notas del Trabajo',
      label_work_note_visibility:'Visible para',
      contacts_from_vendor:'¿Copiar los contactos del cliente a este nuevo restaurante?',
      data_from_customer:'¿Copiar los datos del Cliente a este nuevo restaurante?',
      button_create:'Crear',
      button_clear:'Limpiar',
      button_cancel:'Cancelar',
      RequiredInputError:'Este campo es obligatorio',
      InvalidEmailError: 'Correo Electrónico Inválido',
      error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      result:{
        record_created_ok: 'Restaurante creado con éxito',
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      }      
    },
    customer_address_detail:{
      label_address_street:'Calle:',
      label_address_apartament:'Dep / Suite / Otro:',
      label_address_city:'Ciudad:',
      label_address_state:'Estado:',
      label_address_zipCode: 'Codigo Postal:',
    },
    address_detail:{
      title: 'Dirección',
      label_address_street:'Calle:',
      label_address_apartament:'Dep / Suite / Otro:',
      label_address_city:'Ciudad:',
      label_address_state:'Estado:',
      label_address_zipCode: 'Codigo Postal:',
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Guardar',
      result: {
        address_updated_ok: 'Dirección actualizada con éxito'
      }
    },
    maintenance_kind_detail:{
      title: 'Tipo de Servicio',
      inactive_service_kind: 'Desactivar Tipo de Servicio',
      active_service_kind: 'Activar Tipo de Servicio',
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Guardar',
      id: 'ID',
      periodicity: 'Frecuencia (días)',
      name: 'Nombre',
      state: 'Estado',
      abbreviation: 'Abreviatura',
      description: 'Notas'
    },
    order_detail:{
      id: 'ID',
      job_number: 'WO-PO',
      id_kanela: 'ID Programado',
      customer: 'Cliente',
      state: 'Estado',
      due_on: 'Vencimiento',
      vendor: 'Proveedor',
      subsidary: 'Restaurante',
      frequency: 'Frecuencia (días)',
      schedule_for: 'Programado',
      price: 'Precio',
      billed_amount: 'Importe Facturado',
      unbilled_amount: 'Importe Sin Facturar',
      cancelation_reason: 'Motivo de cancelación',
      title: 'Orden de Trabajo',
      btn_edit: 'Editar',
      btn_cancel: 'Cancelar',
      btn_save: 'Guardar',
      months: 'Meses',
    },
    contacts_detail_panel:{
      title: 'Agregar Contacto',
      new_contact: 'Nuevo Contacto',
      edit_contact: 'Editar Contacto',
      table_header_id: "ID",
      table_header_role_name: "Puesto",
      table_header_name: "Nombre",
      table_header_phone_numbers: "Número de Teléfono",
      table_header_email: "Email",
      table_header_send_invoice: "Enviar Factura",
      table_header_phone_kind: "Tipo de Teléfono",
      table_header_phone_number: "Número de Teléfono",
      empty_items: 'No hay contactos todavía',
      phone_add: 'Agregar Teléfono',
      new_phone_contact: 'Nuevo Teléfono',
      edit_phone_contact: 'Editar Telefono',
    },
    edit_contact_modal:{
      btn_add: 'Agregar Contacto',
      label_contact: 'Contacto',
      role:'Puesto:',
      first_name:'Nombre:',
      middle_name:'Apellido Paterno:',
      last_name:'Apellido Materno:',
      email: 'Correo Electrónico',
      send_invoice: 'Enviar Factura',
      phone_kind:'Tipo de Teléfono',
      phone_number:'Número de Teléfono',
      button_create:'Guardar',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
      necessary_capture_value: 'Es necesario capturar al menos 1 valor',
      result:{
        contact_created_ok: 'Contacto creado con éxito',
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      }
    },
    new_subsidary_form:{
      label_add_subsidary:'Agregar Restaurante',
      subtitle_address:'Dirección',
      subtitle_contact:'Contacto',
      subtitle_notes: 'Notas',
      subtitle_services:'Servicios',
      no_services: 'No hay servicios todavía',
      add_service: 'Agregar Servicio',
      table_services_service: 'Servicio',
      table_services_frecuency: 'Frecuencia (días)',
      table_services_base_price: 'Precio Base',
      table_services_breakdown: 'Desglose',
      table_services_total: 'Total',
      table_services_delete_service: 'Quitar Servicio',
      customer:'Cliente',
      label_name:'Nombre',
      label_aka: 'A.K.A.',
      label_phone: 'Número de Teléfono',
      label_fax: 'Número de fax',
      label_turn_around_time: 'Tiempo de Entrega (Dias)',
      label_keys: 'Llaves',
      label_alarm: 'Alarma',
      label_hours_of_operation: 'Horas de Operación',
      label_invoice_tarjet: 'Destino de la factura',
      label_service_periodicity: 'Servicio',
      label_service_base_price: 'Precio Base',
      label_service_breakdown: 'Desglose',
      label_service_total: 'Total',
      label_service_months: 'Meses:',
      label_address_street:'Calle:',
      label_address_apartament:'Dep / Suite / Otro:',
      label_address_city:'Ciudad:',
      label_address_state:'Estado:',
      label_address_zipCode: 'Código Postal:',
      label_contact_role: 'Puesto:',
      label_contact_first_name:'Nombre:',
      label_contact_middle_name:'Apellido Paterno:',
      label_contact_last_name:'Apellido Materno:',
      label_contact_phone_kind:'Tipo de Teléfono',
      label_contact_phone_number:'Número de Teléfono',
      label_contact_email: 'Correo Electrónico:',
      label_service_note_description:'Notas del Servicio',
      label_service_note_visibility:'Visible para',
      label_billing_note_description:'Notas de Facturación',
      label_billing_note_visibility:'Visible para',
      label_work_note_description:'Notas del Trabajo',
      label_work_note_visibility:'Visible para',
      button_create:'Crear',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
      view_detail_text:'Mostrar Restaurante:',
      show_link: 'Ver Restaurante',
      RequiredInputError:'Este campo es obligatorio',
      InvalidEmailError: 'Correo Electrónico Inválido',
      error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      result:{
        record_created_ok: 'Restaurante creado con éxito',
        subsidary_created_ok: 'Restaurante creado con éxito',
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
        invalid_params_error: 'Parámetros no válidos',
      }      
    },
    subsidary_add_hood:{
      label_add_hood: 'Agregar Campana',
      label_kind: 'Tipo de ventilador',
      label_equipment_under: 'Equipo debajo de la campana',
      label_maintenance_kind: 'Tipo de Servicio',
      label_description: 'Descripción',
      button_create:'Crear',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
      result:{
        hood_created_ok: 'Campana creada con éxito',
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      }
    },
    new_maintenance_kind_form:{
      label_name:'Nombre',
      label_abbreviation:'Abreviatura:',
      label_description:'Notas:',
      label_price: 'Precio (USD):',
      label_periodicity: 'Frecuencia (días):',
      show_link: 'Ver tipo de servicio',
      button_create:'Crear',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
      view_detail_text:'Mostrar tipo de servicio:',
      RequiredInputError:'Este campo es obligatorio',
      error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      result:{
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
        maintenance_kind_created_ok: 'Tipo de servicio creado con éxito'
      }      
    },
    hood_maintenances: {
      label_add_maintenance: 'Agregar mantenimiento',
      table_header_id: 'ID',
      table_header_restaurant: 'Restaurante',
      table_header_maintenance_kind: 'Tipo de servicio',
      table_header_hood_id: 'Campana ID',
      table_header_due_at: 'Vencimiento',
      table_header_completed_at: 'Completado',
      table_header_status: 'Estado',
      table_header_maintenance_frequency: 'Frecuencia (días)',
      table_header_maintenance_price: 'Precio (USD)'
    },
    hood_add_maintenance: {
      label_add_maintenance: 'Agregar Mantenimiento',
      maintenance_kind: 'Tipo de servicio',
      due_at: 'Vencimiento',
      button_create:'Crear',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
    },
    create_order_modal: {
      btn_add_order:'Agregar Orden',
      label_add_note_undefined:'Agregar Orden',
    },
    new_order:{
      label_subsidary:'Restaurante',
      label_service:'Servicio',
      frequency: 'Frecuencia (días)',
      due_date: 'Fecha de Vencimiento',
      schedule_date: 'Fecha de Programación',
      price: 'Precio total de la orden',
      subtitle_maintenances: 'Mantenimientos Registrados',
      restaurant_no_hoods: 'Este restaurante no tiene campanas.',
      restaurant_register_hood: 'Registre nuevas campanas desde aquí',
      restaurant_no_services: 'Este restaurante no tiene servicios.',
      restaurant_register_service: 'Registra nuevos servicios desde aquí',
      subtitle_order_items: 'Elementos de la Orden',
      no_order_items: 'Aún no hay elementos de la orden',
      table_order_items_header_kind: 'Tipo',
      table_order_items_header_service_kind: 'Servicio',
      table_order_items_header_under_hood: 'Equipo Debajo de la Campana',
      table_order_items_header_quantity: 'Cantidad',
      table_order_items_header_price: 'Precio',
      table_order_items_header_total: 'Total',
      total_order_price: 'Precio Total de la Orden',
      create: 'Crear',
      table_header_id:'ID',
      table_header_customer:'Cliente',
      table_header_subsidary:'Restaurante',
      table_header_due_date: 'Fecha de Vencimiento',
      table_header_status: 'Estado',
      button_create:'Crear nueva orden de trabajo',
      view_detail_text:'Para ver el nuevo pedido creado, haga clic en el siguiente enlace:',
      show_link: 'Ver orden',
      result:{
        order_created_ok: 'Orden creada con éxito',
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      },
      messages:{
        error_dates: 'Es imprescindible introducir la "Fecha de Vencimiento" o la "Fecha de Programación"'
      },
      data_title_delete_item: 'Quitar Elemento de la Orden'
    },
    maintenance_actions: {
      button_cancel: "Cancelar mantenimiento",
      button_reschedul_due_date: "Reprogramar fecha de vencimiento"
    },
    maintenance_reschedule_due_date: {
      label_reschedul_due_date: "Reprogramar fecha de vencimiento",
      button_reschedul_due_date: "Reprogramar fecha de vencimiento",
      due_date: "Fecha de Vencimiento:",
      button_clear: "Limpiar",
      button_cancel: "Cancelar",
      button_reschedule: "Reprogramar fecha de vencimiento",
      result:{
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      }
    },
    maintenance_cancel: {
      label_cancel_maintenance: "Cancelar mantenimiento",
      button_clear: "Limpiar",
      button_close: "Cerrar",
      cancellation_reason: "Motivo de Cancelación",
      button: "Cancelar mantenimiento",
      send: 'Cancelar mantenimiento',
      cancellation_reason_question: '¿Cuál es el motivo de la cancelación?',
      confimation_question: "¿Está seguro de que deseas cancelar este mantenimiento?",
      confirmation_reject: "No",
      confirmation_accept: "Si",
      result:{
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo.',
      }
    },
    general_messages: {
      RequiredValueError: 'Este valor es obligatorio',
      InvalidEmailError: 'Correo Electrónico Inválido',
      choose_option: 'Por favor, elija una opción',
      InvalidLinkFormatError: 'Formato de enlace no válido'
    },
    customers_detail_table_panel:{
      table_header_id: 'ID',
      table_header_name: 'Nombre',
      table_header_address: 'Dirección',
      empty_items: 'No hay clientes todavía',
      view_customer: 'Ver Cliente',
    },
    create_customer_modal:{
      modal_title: 'Agregar Cliente',
      button_clear: 'Limpiar',
      button_cancel: 'Cancelar',
      button_create: 'Crear'
    },
    customer_detail:{
      title: 'Cliente',
      id: 'ID',
      name: 'Nombre',
      aka: 'A.K.A.',
      vendor: 'Proveedor',
      phone_number: 'Número de Teléfono',
      active_customer: 'Activar Cliente',
      inactive_customer: 'Desactivar Cliente',
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Guardar',
    },
    subsidary_detail:{
      title: 'Restaurante',
      active_restaurant: 'Activar Restaurante',
      inactive_restaurant: 'Desactivar Restaurante',
      bt_edit: 'Editar',
      bt_cancel: 'Cancelar',
      bt_save: 'Guardar',
      id: 'ID',
      name: 'Nombre',
      aka: 'A.K.A.',
      state: 'Estado',
      vendor: 'Proveedor',
      customer: 'Cliente',
      turn_around_time: 'Tiempo de Entrega (dias)',
      invoice_target: 'Destino de la factura',
      phone_number: 'Número de Teléfono',
      fax_number: 'Número de Fax',
      keys: 'Llaves',
      alarm: 'Alarma',
      hours_of_operation: 'Horas de Operación',
      service_periodicity: 'Servicio',
      service_base_price: 'Precio Base',
      service_breakdown: 'Desglose',
      service_total: 'Total',
    },
    notes_detail_table_panel:{
      subtitle_service: 'Notas del Servicio',
      subtitle_billing: 'Notas de Facturación',
      subtitle_work: 'Notas del Trabajo',
      empty_items: 'No hay notas todavía',
      visible: 'Visible',
      visibility: 'Visibilidad',
      visible_system: 'Sistema',
      visible_employee: 'Empleados',
      visible_customer: 'Clientes',
      visible_everyone: 'Todos',
    },
    create_note_modal:{
      btn_add_note_service: 'Agregar Nota de Servicio',
      btn_add_note_billing: 'Agregar Nota de Facturación',
      btn_add_note_work: 'Agregar Nota de Trabajo',
      btn_add_note_invoice: 'Agregar Nota a Factura',
      label_add_note_service: 'Nueva Nota de Servicio',
      label_add_note_billing: 'Nueva Nota de Facturación',
      label_add_note_work: 'Nueva Nota de Trabajo',
      label_add_note_invoice: 'Nueva Nota de Factura',
      description: 'Descripción',
      visibility: 'Visible para',
      button_clear: 'Limpiar',
      button_cancel: 'Cancelar',
      button_create: 'Crear'
    },
    edit_note_modal:{
      label_edit_note_service: 'Editar Nota de Servicio',
      label_edit_note_billing: 'Editar Nota de Facturación',
      label_edit_note_work: 'Editar Nota de Trabajo',
      label_edit_note_invoice: 'Editar Nota de Factura',
      description: 'Descripción',
      visibility: 'Visible para',
      button_clear: 'Limpiar',
      button_cancel: 'Cancelar',
      button_save: 'Guardar'
    },
    services_detail_table_panel:{
      title: 'Agregar Servicio',
      months: 'Meses',
      empty_items: 'No hay servicios todavía',
      table_header_service: 'Tipo',
      table_header_frequency: 'Frecuencia (días)',
      table_header_base_price: 'Precio Base',
      table_header_breakdown: 'Desglose',
      table_header_total: 'Total',
    },
    hoods_detail_table_panel:{
      title: 'Agregar Campana',
      empty_items: 'No hay campanas todavía',
      table_header_hood: 'Campana',
      table_header_equipment_under: 'Equipo debajo de la campana',
      table_header_description: 'Descripción',
    },
    edit_subsidary_hood:{
      modal_title: 'Campana',
      label_hood_equipment_under: 'Seleccione un Equipo debajo de la campana',
      label_or: 'O',
      label_hood_equipment_under_write: 'Escriba un Equipo debajo de la campana',
      label_hood_description: 'Descripción',
      button_clear: 'Limpiar',
      button_cancel: 'Cancelar',
      button_save: 'Guardar'
    },
    links_detail_panel:{
      table_header_link: 'Enlace',
      table_header_description: 'Descripción',
      empty_items: "Aún no hay enlaces",
      add_link: 'Agregar Enlace',
    },
    edit_link_modal: {
      link: 'Enlace',
      description: 'Descripción',
      label_link: 'Enlace',
      button_create:'Guardar',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
    },
    new_technician_form: {
      role: 'Puesto',
      email: 'Correo Electrónico',
      first_name: 'Nombre',
      middle_name: 'Apellido Paterno',
      last_name: 'Apellido Materno',
      phone_kind: 'Tipo de Teléfono',
      phone_number: 'Número de Teléfono',
      button_create:'Crear',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
      view_detail_text: 'Para ver el nuevo técnico creado, haga clic en el siguiente enlace:',
      necessary_capture_value: 'Es necesario capturar al menos 1 valor',
      show_link: 'Ver técnico',
      result: {
        technician_created_ok: 'Técnico creado con éxito',
      }
    },
    technician_detail:{
      title: 'Técnico',
      inactive_technician: 'Desactivar Técnico',
      active_tecnician: 'Activar Técnico',
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Guardar',
      id: 'ID',
      state: 'Estado',
      first_name: 'Nombre',
      middle_name: 'Apellido Paterno',
      last_name: 'Apellido Materno',
      email: 'Correo Electrónico',
      role: 'Puesto',
      phone_kind: 'Tipo de Teléfono',
      phone_number: 'Número de Teléfono',
    },
    technicians_detail_panel:{
      add_technician: 'Agregar Técnico',
      table_header_name: 'Nombre',
      table_header_role_name: 'Puesto',
      table_header_email: 'Correo Electrónico',
      table_header_phone: 'Teléfono',
      empty_items: 'Aún no hay técnicos'
    },
    add_technician_to_order_modal:{
      label_technician: 'Agregar Técnico',
      technician: 'Técnico',
      button_create:'Agregar',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
    },
    orders_detail_table_panel:{
      table_header_id: 'ID',
      table_header_job_number: 'WO-PO',
      table_header_id_kanela: 'ID Programación',
      table_header_status: 'Estado',
      table_header_service: 'Servicio',
      table_header_frequency: 'Frecuencia(días)',
      table_header_price: 'Precio',
      table_header_due_on: 'Vencimiento',
      table_header_schedule_for: 'Programado',
      table_header_compleated_on: 'Completado',
      table_header_preview_cleaning: 'Limpieza Previa',
      table_header_next_cleaning: 'Limpieza Siguiente',
      table_header_invoice_ids: 'Factura',
      table_header_payment_status: 'Estado de Pago',
      total_orders: 'Total Ordenes',
      total_billed: 'Total Facturado',
      total_unbilled: 'Total No Facturado',
      months: 'Meses',
      view_order: 'Ver Orden',
      view_invoice: 'Ver Factura',
      empty_items: 'No hay ordenes todavía',
    },
    delete_contact_modal:{
      delete_contact: "Borrar Contacto",
      question: "¿Está seguro de eliminar este contacto?",
      reject: 'No',
      accept: 'Si',
      title: 'Borrar Contacto',
      delete_phone_contact: 'Borrar Teléfono',
    },
    delete_customer_modal:{
      inactive_customer: 'Desactivar Cliente',
      comments: 'Desactivar el cliente también desactivará sus restaurantes.',
      question: "¿Está seguro de desactivar a este cliente?",
      reject: 'No',
      accept: 'Si',
      title: 'Desactivar Cliente',
    },
    delete_hood_modal: {
      title: 'Borrar Campana',
      question: "¿Está seguro de eliminar esta campana?",
      reject: 'No',
      accept: 'Si',
    },
    delete_link_modal:{
      title: 'Borrar Enlace',
      question: '¿Está seguro de eliminar este enlace?',
      reject: 'No',
      accept: 'Si',
    },
    delete_vendor_modal:{
      inactive_vendor: "Desactivar Proveedor",
      question: "¿Está seguro de desactivar este proveedor?",
      comments: 'Deshabilitar al proveedor también desactivará sus clientes.',
      reject: 'No',
      accept: 'Yes',
      title: 'Inactive Vendor',
    },
    maintenance_price_value: {
      cancel: 'Cancelar',
      save: 'Guardar',
    },
    delete_note_modal: {
      title: "Borrar Nota",
      question: '¿Está seguro de eliminar esta nota?',
      reject: 'No',
      accept: 'Si',
    },
    order_actions: {
      invoice_order: 'Factura de la Orden',
      paper_work: 'Papel de Trabajo',
      for_technician: 'Para Técnico',
      for_customer: 'Para Cliente',
    },
    order_cancel: {
      title: 'Cancelar Orden de Trabajo',
      btn_clear: 'Limpiar',
      btn_cancel: 'Cancelar',
      btn_send: 'Enviar',
      label_reason_cancellation: 'Motivo de la cancelación',
      warning_order_cancel: '¡Advertencia! Esta orden de trabajo tiene facturas asociadas. Por favor, ten en cuenta que al cancelar esta orden, también se cancelarán todas las facturas relacionadas. Te recomendamos revisar cuidadosamente antes de proceder con esta acción.',
    },
    order_complete: {
      title: 'Completar Orden de Trabajo',
      confirmation_question: '¿Está seguro de completar esta orden de trabajo?',
      cancel: 'Cancelar',
      send: 'Completar',
    },
    order_due_at: {
      title: 'Vencimiento en',
      date: 'Fecha',
      clear: 'Limpiar',
      cancel: 'Cancelar',
      send: 'Guardar',
    },
    order_schedule: {
      title: 'Programado para',
      date: 'Fecha',
      clear: 'Limpiar',
      cancel: 'Cancelar',
      send: 'Guardar',
    },
    create_orderable_modal: {
      title: 'Agregar Elemento',
      clear: 'Limpiar',
      cancel: 'Cancelar',
      send: 'Guardar',
      orderable_kind: 'Tipo de Elemento',
      service_kind: 'Tipo de Servicio',
      hood: 'Campana',
      quantity: 'Cantidad',
      price: 'Precio',
    },
    delete_orderable_modal: {
      title: 'Quitar Elemento',
      confirmation_question: '¿Está seguro de eliminar este elemento de la orden?',
      reject: 'No',
      accept: 'Si',
    },
    orderables_list_panel: {
      no_order_items: 'Aún no hay elementos de la orden',
      table_header_item_kind: 'Tipo',
      table_header_kind: 'Servicio',
      table_header_under_hood: 'Equipo Debajo de la Campana',
      table_header_quantity: 'Cantidad',
      table_header_price: 'Precio',
      table_header_total: 'Total',
      extra: 'Extra',
      service: 'Servicio',
      update_order_item: 'Actualizar elemento de la orden',
      total_order_price: 'Precio Total de la Orden:',
      update_total_order_price: 'Actualizar precio total de la orden',
    },
    delete_service_modal: {
      title: 'Quitar Servicio',
      confirmation_question: '¿Está seguro de eliminar este servicio?',
      message1: 'Si eliminas este servicio, la frecuencia asociada a las siguientes órdenes (IDs: ',
      message2: ' ) también será eliminada. ¿Estás seguro de que deseas continuar?',
      reject: 'No',
      accept: 'Si',
    },
    edit_service_modal: {
      modal_title: 'Servicio',
      label_service_periodicity: 'Tipo',
      label_service_frequency: 'Frecuencia (días)',
      label_service_base_price: 'Precio Base',
      label_service_breakdown: 'Desglose',
      label_service_total: 'Total',
      button_clear: 'Limpiar',
      button_cancel: 'Cancelar',
      button_save: 'Guardar',
      other_frecuency: 'Otra Frecuencia',
      select_months: 'Por favor, seleccione los meses:',
      january: 'Enero',
      february: 'Febrero',
      march: 'Marzo',
      april: 'Abril',
      may: 'Mayo',
      june: 'Junio',
      july: 'Julio',
      august: 'Agosto',
      september: 'Septiembre',
      october: 'Octubre',
      november: 'Noviembre',
      december: 'Diciembre',
    },
    delete_subsidary_modal: {
      title: 'Desactivar Restaurante',
      comments: 'Desactivar el restaurante también cancelará las órdenes de trabajo incompletas.',
      confirmation_question: '¿Está seguro de desactivar este restaurante?',
      reject: 'No',
      accept: 'Si',
    },
    remove_technician_to_order_modal: {
      title: 'Quitar Técnico',
      confirmation_question: '¿Estás seguro de quitar a este técnico de la orden?',
      reject: 'No',
      accept: 'Si',
    },
    new_invoice: {
      title: 'Nueva Factura',
      clear: 'Limpiar',
      cancel: 'Cancelar',
      send: 'Crear',
      document_number: 'PO# (Opcional)',
      date: 'Fecha',
      porcent_credit_card: 'Porcentaje de administración de tarjeta de crédito',
      price: 'Importe',
      price_with_credit_card: 'Importe con tarjeta de credito',
      way_to_pay: 'Tipo de pago',
      cod: 'Plazo de pago (dias)',
      view_detail_text:'Para ver la nueva factura creada, haga clic en el siguiente enlace:',
      show_link: 'Ver Factura',
      combine_orders_in_invoice: '¿Generar 1 factura incluyendo todas las ordenes?',
      messages: {
        amount_greater_than_the_order: 'Importe superior al de la orden',
        total_charged_greater_than_the_order: 'Importe superior al ya facturado',
        unbilled_total_zero: 'Cantidad no facturada es cero',
      },
      result: {
        invoice_created_ok: 'Factura creada con éxito',
      }
    },
    new_invoice_form: {
      subtitle_invoice_detail: 'Detalle de la nueva factura',
      list_orders_detail: 'Listado de Ordenes',
      table_header_number: 'Num.',
      table_header_status: 'Estado',
      table_header_vendor: 'Proveedor',
      table_header_customer: 'Cliente',
      table_header_subsidary: 'Restaurante',
      table_header_frecuency: 'Frecuencia',
      table_header_schedule_for: 'Programado',
      table_header_price: 'Precio',
      table_header_billed: 'Facturado',
      table_header_unbilled: 'Sin Facturar',
      button_clear: 'Limpiar',
      button_cancel: 'Cancelar',
      button_create: 'Crear',
      no_orders: 'No hay ordenes',
      view_detail_text:'Para ver la nueva factura creada, haga clic en el siguiente enlace:',
      show_link: 'Ver Factura',
      result: {
        invoice_created_ok: 'Factura creada con éxito',
      }
    },
    invoice_detail: {
      title: 'Factura',
      id: 'ID',
      document_number: 'PO#',
      date_created: 'Fecha de Creación',
      date_invoice: 'Fecha de Facturación',
      way_to_pay: 'Tipo de pago',
      cod: 'Plazo de pago (dias)',
      cancelation_reason: 'Motivo de cancelación',
      status: 'Estado',
      amount: 'Importe',
      porcent: 'Tarifa de administración de tarjeta de crédito',
      amount_with_credit_card: 'Importe con tarjeta de crédito',
      total_paid_amount: 'Importe pagado',
      masked_total_unpaid_amount: 'Saldo pendiente',
      cancel_invoice: 'Cancelar Factura',
      pay_invoice: 'Pagar Factura',
      edit: 'Editar',
      cancel: 'Cancelar',
      save: 'Guardar',
      actions: {
        edit: "Editar",
        cancel: "Cancelar",
        save: "Guardar",
      },
    },
    invoices_detail_panel: {
      empty_items: 'No hay facturas todavía',
      table_header_document_number: 'Num.',
      table_header_date_invoice: 'Fecha',
      table_header_price: 'Importe',
      table_header_porcent_credit_card: 'Tarifa de administración de tarjeta de crédito',
      table_header_price_with_credit_card: 'Importe con Tarjeta de Crédito',
      table_header_cod: 'Plazo de pago (dias)',
      table_header_way_to_pay: 'Tipo de pago',
      table_header_amount_paid: 'Pagado',
      table_header_outstanding_balance: 'No Pagado',
      table_header_status: 'Estado',
      total_invoices: 'Total Facturas',
      total_paid: 'Total Pagado',
      total_unpaid: 'Total No Pagado',
    },
    invoice_orders_table_panel: {
      view_order: 'Ver Orden',
      table_header_id: 'ID',
      table_header_job_number: 'WO-PO',
      table_header_id_kanela: 'ID Programación',
      table_header_status: 'Estado',
      table_header_frequency: '	Frecuencia(dias)',
      table_header_price: 'Precio',
      table_header_due_on: 'Vencimiento',
      table_header_schedule_for: 'Programado',
      table_header_compleated_on: 'Completado',
      table_header_restaurant: 'Restaurante',
      table_header_invoice_target: 'Destino de la factura',
      months: 'Meses',
      total_orders: 'Total Ordenes',
    },
    remove_order_from_invoice_modal: {
      title: 'Quitar orden de la factura',
      question: '¿Está seguro de que deseas eliminar la orden de la factura?',
      reject: 'No',
      accept: 'Si',
    },
    invoice_payments_table_panel: {
      empty_items: "No hay pagos todavía",
      view_payment: 'Ver Pago',
      table_header_id: 'ID',
      table_header_status: 'Estado',
      table_header_user_create: 'Usuario',
      table_header_date_payment: 'Fecha',
      table_header_amount_paid: 'Importe',
      table_header_way_to_pay: 'Tipo de pago',
      table_header_num_check: 'Num. Cheque',
      table_header_credit_card_number: 'Num. Tarjeta de Credito',
      table_header_reference: 'Referencia',
      total_payments: 'Total Pagos',
    },
    cancel_invoice_modal: {
      title: 'Cancelar Factura',
      question: '¿Está seguro de que deseas cancelar la factura?',
      label_reason_cancellation: 'Motivo de cancelación',
      label_exist_payments_applied: 'No se puede cancelar debido a que existen pagos aplicados. Cancele primero los pagos.',
      reject: 'No',
      accept: 'Si',
    },
    invoice_actions: {
      paper_invoice: 'Imprimir Factura',
      download_invoice: 'Descargar Factura',
      for_technician: 'Para Técnico',
      for_customer: 'Para Cliente',
    },
    apply_payment_modal: {
      title: 'Aplicar Pago',
      label_date: 'Fecha',
      label_amount: 'Importe',
      label_way_to_pay: 'Tipo de pago',
      label_num_check: 'Num. Cheque',
      label_credit_card_number: 'Num. Tarjeta de Credito',
      label_reference: 'Referencia',
      label_num_check: 'Num. Cheque',
      button_create:'Aplicar',
      button_clear:'Limpiar',
      button_cancel:'Cancelar',
      messages: {
        amount_greater_than_the_invoice:'Importe superior al saldo de la factura',
      },
    },
    payment_detail: {
      title: 'Pago',
      id: "ID",
      status: "Estado",
      date_payment: "Fecha",
      amount_paid: "Importe",
      amount_admin_fee: "Importe de la tarifa administrativa",
      amount_total: "Importe total",
      way_to_pay: "Tipo de pago",
      num_check: "Num. Cheque",
      credit_card_number: "Num. Tarjeta de Credito",
      reference: "Referencia",
      cancelation_reason: 'Motivo de cancelación',
      edit: "Editar",
      cancel: "Cancelar",
      save: "Guardar",
    },
    payment_invoices_table_panel: {
      table_header_id: 'ID',
      table_header_number: 'PO#',
      table_header_status: 'Estado',
      table_header_vendor: 'Proveedor',
      table_header_customer: 'Cliente',
      table_header_subsidary: 'Restaurante',
      table_header_date_invoice: 'Fecha Facturación',
      table_header_amount: 'Importe',
      table_header_porcent: 'Tarifa de administración de tarjeta de crédito',
      table_header_amount_with_credit_card: 'Importe con tarjeta de crédito',
      table_header_way_to_pay: 'Tipo de pago',
      table_header_cod: 'Plazo de pago (dias)',
    },
    cancel_payment_modal: {
      title: "Cancelar Pago",
      question: '¿Está seguro de que deseas cancelar el pago?',
      label_reason_cancellation: 'Motivo de cancelación',
      reject: 'No',
      accept: 'Si',
    },
    new_payment_form: {
      subtitle_invoice_detail: 'Detalle del nuevo pago',
      date: 'Fecha del Pago',
      amount: 'Importe',
      way_to_pay: 'Tipo de pago',
      num_check: 'Num. Cheque',
      credit_card_number: 'Num. Tarjeta de Credito',
      reference: 'Referencia',
      list_invoices_detail: 'Seleccionar facturas para el pago',
      table_header_number: 'Num.',
      table_header_status: 'Estado',
      table_header_vendor: 'Proveedor',
      table_header_customer: 'Cliente',
      table_header_subsidary: 'Restaurante',
      table_header_invoice_target: 'Destino de la factura',
      table_header_date: 'Fecha',
      table_header_amount: 'Importe',
      table_header_percentage: 'Porcentaje de administración de tarjeta de crédito',
      table_header_amount_cr: 'Importe con Tarjeta de Crédito',
      table_header_way_to_pay: 'Tipo del pago',
      table_header_amount_pay: 'Pagado',
      table_header_outstanding_balance: 'No Pagado',
      button_clear: 'Limpiar',
      button_cancel: 'Cancelar',
      button_create: 'Crear',
      no_invoices: 'No hay facturas todavía',
      view_detail_text:'Para ver el nuevo pago, haga clic en el siguiente enlace:',
      show_link: 'Ver Pago',
      result: {
        payment_created_ok: 'Pago creado con éxito',
      }
    },
    send_email_invoice_modal:{
      title: 'Enviar Factura',
      label_to: 'Para',
      label_cc: 'CC',
      label_cco: 'CCO',
      label_subject: 'Asunto',
      label_message: 'Mensaje',
      title_to: 'Ingresa las direcciones de correo electrónico de las personas a las que deseas enviar el correo. Si envías el correo a más de una persona, sepáralas utilizando comas (,) o punto y coma (;).',
      title_cc: 'Ingresa las direcciones de correo electrónico de las personas que también recibirán una copia del correo. Esto es útil para mantener a otros informados o para compartir información con múltiples destinatarios. Separa las direcciones con comas (,) o punto y coma (;).',
      title_cco: 'Similar al campo "CC", pero las direcciones ingresadas aquí no serán visibles para los otros destinatarios del correo. Esto te permite enviar copias del correo sin revelar las direcciones de los destinatarios. Separa las direcciones con comas (,) o punto y coma (;).',
      title_subject: 'Escribe un breve resumen o título que describa el contenido del correo electrónico. Esto ayuda a los destinatarios a comprender de qué trata el correo y puede aumentar la probabilidad de que lo abran.',
      button_clear: 'Limpiar',
      button_cancel: 'Cancelar',
      button_create: 'Enviar',
      result: {
        email_not_sent: 'No Fue Posible Enviar el Correo Electrónico',
        invoice_email_sended_ok: 'Email enviado correctamente',
      }
    },
    edit_phone_contact_modal:{
      necessary_capture_value: 'Es necesario capturar al menos 1 valor',
      phone_kind: 'Tipo de Teléfono',
      phone_number: 'Número de Teléfono',
      label_phone: 'Teléfono',
      button_clear: 'Limpiar',
      button_cancel: 'Cancelar',
      button_create: 'Guardar',
    },
    delete_phone_contact_modal:{
      delete_contact: "Borrar Teléfono",
      question: "¿Está seguro de eliminar este teléfono?",
      reject: 'No',
      accept: 'Si',
      title: 'Borrar Telefono',
    },
    vendors_active:{
      subtitle_vendors_active: 'Proveedores Activos',
      view_vendors: 'Ver Proveedores ',
    },
    customers_active:{
      subtitle_customers_active: 'Clientes Activos',
      view_customers: 'Ver Clientes ',
    },
    subsidaries_active:{
      subtitle_subsidaries_active: 'Restaurantes Activos',
      view_subsidaries: 'Ver Restaurantes ',
    },
    invoices_by_state: {
      title: 'Enviar por Correo',
      subtitle_invoices_by_state: 'Resumen de Facturas por Estado',
      invoice_UNPAID: 'Facturas No Pagadas',
      invoice_SEMI_PAID: 'Facturas Pagadas Parcialmente',
      invoice_PAID: 'Facturas Pagadas',
      invoice_CANCELED: 'Facturas Canceladas',
      view_invoices: 'Ver Facturas',
    },
    invoices_unbilled: {
      subtitle_invoices_unbilled: 'Facturas Vencidas',
      view_invoices: 'Ver Facturas',
    },
    unbilled_invoices_list: {
      subtitle_invoices_unbilled: 'Facturas Vencidas',
      restaurant_header: 'Restaurante',
      date_header: 'Fecha',
      outstanding_balance_header: 'No Pagado',
      emails_header: 'Email',
      view_header: '',
      number_header: 'Número',
      cod_header: 'Plazo de pago (dias)',
      send_all_emails: 'Enviar Todos los Emails',
      send_email: 'Enviar Email',
      view_invoices: 'Ver Facturas ',
      result: {
        error_send: 'Error al enviar el correo electrónico, inténtalo de nuevo',
        error_promise: 'Ha ocurrido un error. Por favor intente de nuevo',
        invalid_params_error: 'Parámetros no válidos',
        invoice_not_found: 'Factura no encontrada',
        invoice_email_sended_ok: 'Email enviado con éxito',
      },
      email_invoice_not_present: 'No se encontró Email para enviar la factura',
      email_sended_ok_to: 'El Email fue enviado a',
      email_sended_ok: 'Email enviado con éxito',
      email_not_sended: 'No se pudo enviar el Email',
    },
    jobs_list: {
      subtitle_jobs_list: 'Lista de trabajos',
      headers: {
        restaurant: 'Restaurante',
        word_order_id: 'ID Orden de Trabajo',
        num_service: 'WO-PO',
        id_kanela: 'ID Programación',
        job_status: 'Estado',
        previous_cleaning: 'Limpieza Anterior',
        schedule_date: 'Fecha Programada',
        next_cleaning: 'Siguiente Limpieza',
        service: 'Servicio',
        frequency: 'Frecuencia (Días)',
        invoice_id: 'ID Factura',
        invoice_document_number: 'PO#',
        total_amount: 'Importe total',
        percentage: '%',
        credit_card: 'Tarjeta de Crédito',
        date_paid: 'Fecha de Pago	',
        check: 'Cheque',
        way_to_pay: 'Metodo de pago',
      },
      months_label: 'Meses',
      months: {
        1: 'Enero',
        2: 'Febrero',
        3: 'Marzo',
        4: 'Abril',
        5: 'Mayo',
        6: 'Junio',
        7: 'Julio',
        8: 'Agosto',
        9: 'Septiembre',
        10: 'Octubre',
        11: 'Noviembre',
        12: 'Diciembre',
      },
      total_jobs: 'Total de Trabajos',
      view_jobs: 'Listado Completa de Trabajos ',
    },
    invoiceables_detail_table_panel: {
      create_new_invoice_item: 'Crear Nuevo Concepto',
      empty_items: "No hay conceptos adicionales todavía",
      table_header_concept: 'Concepto',
      table_header_description: 'Descripcion',
      table_header_price: 'Precio',
      table_header_quantity: 'Cantidad',
      table_header_percentage: 'Porcentaje',
      table_header_total: 'Total',
      total_invoice_items: 'Total Conceptos',
    },
    edit_invoice_item: {
      modal_title: 'Conceptos de Factura',
      label_invoice_item_concept: 'Concepto',
      label_invoice_item_description: 'Descripción (opcional)',
      label_invoice_item_price: 'Precio',
      label_invoice_item_quantity: 'Cantidad',
      label_invoice_item_percentage: 'Porcentaje (%)',
      label_invoice_item_total: 'Total',
      button_save:'Crear',
      button_clear:'Limpiar',
      button_cancel: 'Cancelar',
    },
    delete_invoiceable_modal: {
      title: 'Eliminar ítem de factura',
      question: '¿Estás seguro de eliminar este ítem de factura?',
      reject: 'No',
      accept: 'Si',
    },
    service_detail:{
      title: 'Servicio del Restaurante Asignado a esta Orden',
      title_list_services: 'Lista de Servicios Disponibles en el Restaurante',
      label_delete_service: 'Quitar Servicio de la Orden',
      label_assign_service: 'Asignar otro servicio a la orden',
      label_cancel_assignment: 'Cancelar asignación',
      label_create_service: 'Crear un nuevo servicio para el restaurante',
      table_services_header_service: 'Servicio',
      table_services_header_frequency: 'Frecuencia (días)',
      table_services_header_frequency_month: 'Frecuencia (meses)',
      table_services_header_base_price: 'Precio Base',
      table_services_header_breakdown: 'Desglose',
      table_services_header_total: 'Total',
      edit: 'Editar',
      assign_service: 'Asignar Servicio',
      select: 'Seleccionar',
      messages:{
        no_services_yet: 'Actualmente, el restaurante no cuenta con ningún servicio registrado y activo.',
        no_service_assigned: 'No se ha asignado ningún servicio. Es esencial asignar un servicio para garantizar la programación adecuada de las siguientes órdenes',
        this_service_is_selected: 'Este servicio está actualmente seleccionado',
        modifying_affect_restaurant: '¡Atención! Modificar estos datos afectará la configuración del servicio registrado en el restaurante.',
        remove_affect_order: '¡Cuidado! Si eliminas el servicio de la orden, no será posible asignar automáticamente las órdenes siguientes. Es crucial que todas las órdenes tengan un servicio asignado.',
      }
    },
    units_detail_table_panel: {
      total_units: 'Total de Unidades',
      total_units_price: 'Precio Total',
      save_units: 'Guardar'
    }
  }
}