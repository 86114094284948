import Detailgrid from './detail_grid.vue'
import Detailform from './detail_form.vue'
import Notificationalert from './notification_alert.vue'
import Notifications from './notifications.vue'
import Modal from './modal.vue'
import Modal90 from './modal_90.vue'
import AppIconBtn from './app-icon-btn.vue'
import AppIconLink from './app-icon-link.vue'
import ActiveValue from './active-value.vue'

export default{
  Detailgrid,
  Detailform,
  Notificationalert,
  Notifications,
  Modal,
  Modal90,
  AppIconBtn,
  AppIconLink,
  'active-value': ActiveValue
}