<template lang='pug'>
  div
    //Button link
    app-icon-btn(
      :disabled="this.disabled"
      @click.native="showModal",
      data-kind="link",
      data-icon="fa fa-check-circle",
      :data-label="$I18n.t('vue.order_complete.title')"
    )
    app-modal(
      :showing="controls.modal.showing",
      @showing="controls.modal.showing = $event"
    )
      template(#header)
        p {{ labels.modal_title }}
      template(#body)
        // Spinner Loading
        div(v-if="state.sending" class="tw-text-center tw-my-20")
          div(class="spinner-border spinner-border-sm" role="status")

        // FORM
        div(v-else)
          .tw-mt-3.tw-text-center.tw-mb-8
            p.tw-text-xl {{ labels.confirmation_question }}
      template(#footer)
        .app-buttons-row.justify-end(v-if="!state.sending")
          app-icon-btn(
            data-kind="link",
            @click.native="cancel",
            data-icon="fa fa-times",
            :data-label="labels.cancel_btn"
          )
          app-icon-btn(
            data-kind="btn",
            @click.native="send",
            data-icon="fa fa-check",
            :data-label="labels.send_btn"
          )
</template>

<script>

import BackendService from '../../services/backend_service';
import CoreComponents from "../core";
export default {
  components: {
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
  },
  props:['dataOrder', 'otherOrders', 'disabled'],
  data: function(){
    return {
      order: this.dataOrder,
      other_orders: this.otherOrders,
      controls: {
        modal: {
          showing: false,
        },
      },
      state: {
        sending: false,
      },
      labels: {
        confirmation_question: this.$I18n.t('vue.order_complete.confirmation_question'),
        modal_title: this.$I18n.t('vue.order_complete.title'),
        cancel_btn: this.$I18n.t('vue.order_complete.cancel'),
        send_btn: this.$I18n.t('vue.order_complete.send'),
      },
    }
  },
  methods: {
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    cancel: function () {
      this.hideModal();
      this.resetActiveValues(this.values);
    },
    send: function(){
      let self = this;
      self.state.sending = true;

      if (this.other_orders.length != 0 && this.order.state.name != 'CANCELED'){
        var cont = 0
        this.other_orders.forEach(order => {
          if(order.state.name != "CANCELED"){
            console.log(order.id)
            this.complete_order(order.id).then((response)=>{
              cont += 1
              if (this.other_orders.length == cont){
                console.log("Ya termino todos, reinicia")
                location.reload();
              }
            })
          }
        })
      }else{
        console.log(this.order.id)
        this.complete_order(this.order.id).then((response)=>{
          console.log("Ya termino todos, reinicia")
          location.reload();
        })
      }

      //BackendService
      //  .complete_order(this.order.id,JSON.stringify({}))
      //  .then(_=>{
      //    location.reload();
      //  })
    },
    complete_order: function(id_order){
      return BackendService
        .complete_order(id_order,JSON.stringify({})).then((response)=>{
          console.log("Ya termino el ID:"+id_order)
        })
    }
  }
}
</script>