
// import Customeraddsubsidary from './customer_add_subsidary.vue';
import Customeraddressdetail from './customer_address_detail.vue'
import Newcustomerform from './new_customer_form.vue'
import CustomersDetailTablePanel from './customers-detail-table-panel.vue';
import CustomerDetail from './customer-detail.vue';

export default{
  // Customeraddsubsidary,
  Customeraddressdetail,
  Newcustomerform,
  CustomersDetailTablePanel,
  'customer-detail': CustomerDetail
}