let Validator = {
  patterns:{
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  },
  isEmail: function(value){
    return this.patterns.email.test(value+'')
  }
}

let ActiveValue=function(kind){
  let Value=function(kind){
    this._value=null;
    this._input={
      kind: null,
      value: null,
      options:{},
      name: null,
      title: null
    }
    this._link={}
    
    Object.defineProperty(this,'value',{
      get:()=>{return this._value;},
      set:(v)=>{
        this._value=v;
        this._input.value=v;
      }
    });

    Object.defineProperty(this,'input',{
      get:()=>{return this._input.value;},
      set:(v)=>{
        this._input.value=v;
        if (this.onInputCb){
          this.onInputCb();
        }
      }
    });

    Object.defineProperty(this,'inputKind',{
      get:()=>{return this._input.kind;}
    });

    Object.defineProperty(this,'inputTitle',{
      get:()=>{return this._input.title;}
    });

    Object.defineProperty(this,'inputName',{
      get:()=>{return this._input.name;}
    });

    Object.defineProperty(this,'editable',{
      get:()=>{return this._editable;}
    });

    Object.defineProperty(this,'link',{
      get:()=>{return this._link;}
    });

    Object.defineProperty(this,'kind',{
      get:()=>{return this._kind;}
    });


    this._kind=kind;
    this._minLen=null;
    this._maxLen=null;
    
    this.label='';
    this.error=null;
    this._editable = true;

    this.setInputKind=function(value){
      this._input.kind=value;
      return this;
    }
    this.setInputTitle=function(value){
      this._input.title=value;
      return this;
    }
    this.setInputName=function(value){
      this._input.name=value;
    }
    this.setInputOption=function(key,value){
      this._input.options[key]=value;
      return this;
    }
    this.getInputOption=function(key){
      return this._input.options[key];
    }

  }
  Value.prototype.required=function(){
    this._required=true;
    return this;
  }
  Value.prototype.optional=function(){
    this._required=false;
    return this;
  }
  Value.prototype.setKind=function(value){
    this._kind=value;
    return this;
  }
  Value.prototype.validate=function(){
    this.error=null;
    if(this._required && !this.isPresent()){
      this.error="RequiredValueError";
      return this;
    }
    if(this.isPresent()){
      if(this._kind === 'email' && !Validator.isEmail(this.input)){
        this.error="InvalidEmailError";
        return this;
      }
    }
    return this;
  }
  Value.prototype.isPresent=function(){
    return (this.input !== undefined && this.input!== null && this.input.toString().trim() !== '')
  }
  Value.prototype.isBlank=function(){
    return (this.value === undefined || this.value === null || this.value.toString().trim() === '')
  }
  Value.prototype.isInputBlank=function(){
    return (
      this.input === undefined || 
      this.input === null || 
      this.input.toString().trim() === ''
    )
  }
  Value.prototype.minLen=function(value){
    this._minLen=value;
    return this;
  }
  Value.prototype.maxLen=function(value){
    this._maxLen=value;
    return this;
  }
  Value.prototype.hasError=function(){
    return this.error!==null;
  }
  Value.prototype.setup=function(callback){
    callback(this);
    return this;
  }
  Value.prototype.setValue=function(value){
    this.value=value;
    return this;
  }
  Value.prototype.setLabel=function(label){
    this.label=label;
    return this;
  }
  Value.prototype.self=function(){
    return this;
  }
  Value.prototype.resetValue=function(){
    this.value="";
    this.error=null;
    return this;
  }
  Value.prototype.valueToInput=function(){
    this.input=this.value;
    return this;
  }
  Value.prototype.notEditable=function(){
    this._editable = false;
    return this;
  }
  Value.prototype.isEditable=function(){
    this._editable = true;
    return this;
  }

  Value.prototype.setLinkOptions=function(options){
    this._link = options;
  }

  Value.prototype.onChange=function(cb){
    this.onChangeCb = cb;
  }

  Value.prototype.onInput=function(cb){
    this.onInputCb = cb;
  }
  return new Value(kind);
}
module.exports= ActiveValue;