<template lang='pug'>
  div
    div(v-if="sended" )
      h3(v-if="result.kind !== undefined" :class="result.kind==='error' ? 'app-text-error' : 'app-text-ok'" class="tw-text-2xl tw-mb-5") {{result.message}}
      p(class="tw-mb-5") {{$I18n.t('vue.forgot_password_form.follow_instructions')}}
      div(class="tw-text-right") 
        a(class="app-btn-link app-btn-link-primary" :href="new_session_path") 
            span {{$I18n.t('vue.forgot_password_form.login_link')}}
    div(v-else)
      h3(class="tw-text-2xl tw-mb-5") {{$I18n.t('vue.forgot_password_form.forget_password')}}
      p(class="tw-mb-5") {{$I18n.t('vue.forgot_password_form.instructions_reset')}}
      div(v-if="loading" class="spinner-border spinner-border-sm" role="status")  
      div(v-else)
        input(class="form-control tw-mb-3" v-model="email" type="email" placeholder="Email")
        div(class="tw-text-left")
          span(v-if="result.kind !== undefined" :class="result.kind==='error' ? 'app-text-error' : 'app-text-ok' ") {{result.message}}
        div(class="tw-text-right")
          a(class="app-btn-link-primary" :href="new_session_path") 
            span {{$I18n.t('vue.forgot_password_form.cancel_button')}}
          button(class="app-btn-primary" @click="send" :disabled="button.disabled") 
            span {{$I18n.t('vue.forgot_password_form.send_button')}}
</template>

<script>
  const {BackendService,BackendRoutes} = require('app/services');
  export default {
    data:function(){
      return {
        email:"",
        loading:false,
        sended:false,
        button:{
          disabled:false
        },
        result:{
          message:undefined,
          kind: undefined
        }
      }
    },
    computed:{
      new_session_path:function(){
        return  BackendRoutes.new_session_path();
      }
    },
    methods:{
      send:function(){
        let self = this; 
        this.clear()
        if (this.validate()){
          this.sending(true);
          BackendService.send_reset_password_email(this.email)
          .then((response)=>{
            //if (response.isOk){
              self.ok(this.$I18n.t(`vue.forgot_password_form.results.reset_password_sended_if_account_exist_ok`));
              this.sended=true;
            //}else{
            //  self.error(this.$I18n.t(`vue.forgot_password_form.results.${response.notice}`));
            //  console.log("2");
            //}
          }).catch(()=>{
            this.error(this.$I18n.t(`vue.forgot_password_form.error_promise`))
          })
          .finally(()=>{
            this.sending(false);
          })
        }else{
          this.error(this.$I18n.t(`vue.forgot_password_form.results.invalid_email`))
        }
      },
      cancel:function(){
        this.email="";
        this.loading=false;
        this.button.disabled=false;
        this.result.message=undefined;
        this.result.kind=undefined;
      },
      validate:function(){
        return String(this.email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      },
      error:function(message){
        this.result.kind="error"
        this.result.message=message
      },
      ok:function(message){
        this.result.kind="ok"
        this.result.message=message
      },
      clear:function(){
        this.result.kind=undefined
        this.result.message=undefined
      },
      sending:function(status){
        this.loading=status
        this.button.disabled = status
      }
    }
  }
</script>