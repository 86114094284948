<template lang='pug'>
div
  .tw-flex.tw-flex-row.tw-justify-between
    div
      h5.h5.tw-text-gray-500 {{$I18n.t('vue.technician_detail.title')}}
    
    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center")
      div(class="spinner-border spinner-border-sm" role="status")

    //Barra de botones  
    div(v-else)
      .tw-flex.tw-flex-row
        button.app-btn-link-primary(v-if="record.state!='INACTIVE' && state.show", @click="disable_technician()") 
          i.fa.fa-window-close.tw-mx-1 
          span {{$I18n.t('vue.technician_detail.inactive_technician')}}
        button.app-btn-link-primary(v-if="record.state=='INACTIVE' && state.show", @click="active_technician()") 
          i.fa.fa-check-circle.tw-mx-1 
          span {{$I18n.t('vue.technician_detail.active_tecnician')}}
        button.app-btn-link-primary(v-if="state.show", @click="edit()") 
          i.fa.fa-pencil.tw-mx-1 
          span {{$I18n.t('vue.technician_detail.edit')}}
        button.app-btn-link-primary(v-if="state.edit", @click="cancel()")
          i.fa.fa-times.tw-mx-2
          span {{$I18n.t('vue.technician_detail.cancel')}}
        button.app-btn-link-primary(v-if="state.edit", @click="update()")
          i.fa.fa-save.tw-mx-2
          span {{$I18n.t('vue.technician_detail.save')}}
  hr
  Detailgrid(:data-grid="grid" :data-editing='state.edit')

</template>

<script>
import CoreComponents from "../core";
import { BackendService } from "../../services";
const { ActiveValue } = require("../../core/active_support");

export default {
  props: ["dataRecord", "dataStates"],
  components: { ...CoreComponents },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    let self = this;
    return {
      state: {
        show: true,
        edit: false,
        sending: false,
      },
      values: {
        id: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.technician_detail.id"));
          i.setValue(self.record.id);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        state: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.technician_detail.state"));
          i.setValue(self.$I18n.t("vue.states."+self.record.state.toLowerCase()));
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        role: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.technician_detail.role'));
          i.setValue(self.record.role_name);
          i.setInputKind('text');
        }),
        first_name: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.technician_detail.first_name'));
          i.setValue(self.record.person.first_name);
          i.setInputKind('text');
        }),
        middle_name: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.technician_detail.middle_name'));
          i.setValue(self.record.person.middle_name);
          i.setInputKind('text');
        }),
        last_name: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.technician_detail.last_name'));
          i.setValue(self.record.person.last_name);
          i.setInputKind('text');
        }),
        email: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.technician_detail.email'));
          i.setValue(self.record.email);
          i.setKind('email');
          i.setInputKind('text');
        }),
        phone_kind: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.technician_detail.phone_kind'));
          i.setValue(self.record.phone.kind);
          i.setInputKind('text');
        }),
        phone_number: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.technician_detail.phone_number'));
          i.setValue(self.record.phone.number);
          i.setInputKind('text');
        })
      },
    };
  },
  methods: {
    disable_technician: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .disable_technician(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    active_technician: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .active_technician(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    valid: function () {
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    edit: function () {
      this.state.edit = true;
      this.state.show = false;
      this.state.sending = false;
    },
    cancel: function () {
      this.state.show = true;
      this.state.edit = false;
      this.state.sending = false;
      Object.values(this.values).map((v) => v.valueToInput());
    },
    sending: function () {
      this.state.show = false;
      this.state.edit = false;
      this.state.sending = true;
    },
    stringify: function () {
      return JSON.stringify({
        id: this.values.id.input.toString(),
        first_name: this.values.first_name.input,
        middle_name:this.values.middle_name.input,
        last_name:this.values.last_name.input,
        role_name: this.values.role.input.toString(),
        email:this.values.email.input,
        phone: {
          kind: this.values.phone_kind.input.toString(),
          number:this.values.phone_number.input
        }
      })
    },
    update: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .technician_update(
            this.stringify()
          )
          .then((response) => {
            if (response.isOk) {
              location.reload();
            }
          });
      }
    },
  },
  computed: {
    grid: function () {
      return [
        [this.values.id,         this.values.state,        ''],
        [this.values.first_name, this.values.middle_name,  this.values.last_name],
        [this.values.email,      this.values.role,         ''],
        [this.values.phone_kind, this.values.phone_number, '']
      ];
    }
  },
};
</script>
