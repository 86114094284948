<template lang="pug">

div(class='app-card' )
  div(class='text-center')
    h5(class="h5 py-4 tw-text-gray-500") 
      i(class="app-title-ico-xl fa fa-file-text-o")
      span {{$I18n.t('vue.invoices_unbilled.subtitle_invoices_unbilled')}}
  
  // Spinner Loading
  div(v-if="state.loading" class="tw-text-center tw-my-20")
      div(class="spinner-border spinner-border-sm" role="status")
  div(v-else )
    div(class="text-center")
      p(class='h2') {{items.length}}

    div(style="height: 100%" class="text-right")
      div(v-if="items.length>0")
        div(class="absolute inset-x-0 bottom-0  tw-pb-5 tw-pr-3")
          a(class="app-btn-link-primary" :href="unbilled_invoices_list_path") 
            span {{$I18n.t('vue.invoices_unbilled.view_invoices')}}
            i(class="fa fa-arrow-right")
</template>
<script>

import CoreComponents from '../core';
const {ActiveValue}=require('../../core/active_support');
const {BackendService,BackendRoutes} = require('app/services');

export default {
  components:{    
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
  },
  data: function(){
    return {
      state: {
        loading: true,
      },
      items: '',
    }
  },
  computed:{
    emptyItems: function(){
      return this.data_invoices === '';
    },
    unbilled_invoices_list_path:function(){
      return  BackendRoutes.unbilled_invoices_list_path();
    },
  },
  methods:{
    getOverdueInvoices:function(){
      let params="";
      BackendService
        .get_overdue_invoices(params)
        .then((response) => {
          if (response.isOk) {
            this.state.loading = false;
            this.items = response.data_invoices;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
  },
  beforeMount() {
    this.getOverdueInvoices();
  },
}
</script>
