<template lang="pug">

div
  app-icon-btn(
    @click.native="showModal"
    data-kind="link",
    data-icon="fa fa-pencil",
    data-label="",
  )
  // Modal
  app-modal(
    :showing='controls.modal.showing'
    @showing="controls.modal.showing = $event"
  )
    // Header
    template(v-slot:header)
      p {{ labels.modal_title }}
    
    template(v-slot:body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center tw-my-20")
        div(class="spinner-border spinner-border-sm" role="status")

      // FORM
      div(v-else)
        app-form(
          v-if="!state.processing"
          v-bind:grid="form_grid"
          class='tw-py-4'
        )
    
    template(v-slot:footer)
      //p(v-if="result.isError" class="app-text-error tw-my-3") {{$I18n.t('vue.edit_note_modal.result.'+result.notice)}}
      //p(v-if="result.isOk" class="app-text-ok") {{$I18n.t('vue.edit_note_modal.result.'+result.notice)}}
      .app-buttons-row( v-if="!state.sending" class='justify-end' )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="update",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>
<script>

import { ActiveValue } from '../../core/active_support';
import CoreComponents from '../core';
import {BackendService} from '../../services';
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default{
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components:{
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
    "app-icon-btn": CoreComponents.AppIconBtn,
  },
  props:{
    dataItem: String,
    dataNoteKindVisible: String,
  },
  data:function(){
    return {
      controls:{
        modal:{
          showing: false
        }
      },
      state:{
        sending: false
      },
      values:{
        description: ActiveValue().setup(i=>{
          i.setLabel(this.$I18n.t('vue.edit_note_modal.description'));
          i.setValue(JSON.parse(this.dataItem).description);
          i.setInputKind('textArea');
          i.required();
        }),
        visibility: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t("vue.edit_note_modal.visibility"));
          i.setValue(JSON.parse(this.dataItem).visibility);
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataNoteKindVisible));
          i.required();
        }),
      },
      labels:{
        modal_title: this.$I18n.t('vue.edit_note_modal.label_edit_note_'+JSON.parse(this.dataItem).kind),
        cancel_btn: this.$I18n.t('vue.edit_note_modal.button_cancel'),
        send_btn: this.$I18n.t('vue.edit_note_modal.button_save')
      }
    }
  },
  computed:{
    form_grid:function(){
      return [
        [this.values.description, this.values.visibility]
      ]
    }
  },
  methods:{
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function(){
      this.controls.modal.showing = false;
    },
    show:function(){
      this.state.showing=true;
    },
    cancel:function(){
      this.hideModal();
      this.values.description.setValue(JSON.parse(this.dataItem).description);
      this.values.visibility.setValue(JSON.parse(this.dataItem).visibility);
    },
    params:function(){
      return JSON.stringify({
        id: JSON.parse(this.dataItem).id,
        description: this.values.description.input.toString(),
        visibility: this.values.visibility.input
      })
    },
    update:function(){
      let self = this; 
      if(self.areValidActiveValues(this.values)){
        this.process();
        BackendService
          .note_update(self.params())
          .then((response)=>{
            self.result = response;
            if (response.isOk){
              location.reload();
              //self.hideModal();
              //self.clear(); 
              //this.$emit("updated", "ok");
            }
          })
          .catch(()=>{
            self.result.isError=true;
            self.result.notice='error_promise';
          })
          .finally(()=>{
            //self.processed();
          })
      }
    },
    process:function(){
      this.state.sending = true;
    },
    processed:function(){
      this.state.sending = false;
    }
  }
}
</script>
