<template lang="pug">

div(class='app-card overflow-x-auto' style="height: 100%;")
  div(class='text-center')
    h5(class="h5 py-4 tw-text-gray-500") 
      i(class="app-title-ico-xl fa fa-file")
      span {{$I18n.t('vue.jobs_list.subtitle_jobs_list')}} 
      span(v-if="!state.loading") {{ "( "+$I18n.t('vue.jobs_list.months.'+month) + "-" + year+" )"}}
  
  // Spinner Loading
  div(v-if="state.loading" class="tw-text-center tw-my-20")
      div(class="spinner-border spinner-border-sm" role="status")
  div(v-else class="")
    table(class="table table-hover tw-text-xs")
      thead
        tr
          th {{$I18n.t('vue.jobs_list.headers.restaurant')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.word_order_id')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.num_service')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.id_kanela')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.job_status')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.previous_cleaning')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.schedule_date')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.next_cleaning')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.service')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.frequency')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.invoice_id')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.invoice_document_number')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.total_amount')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.percentage')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.credit_card')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.date_paid')}}
          th(class="tw-text-center ") {{$I18n.t('vue.jobs_list.headers.way_to_pay')}}
          th(class="tw-text-center ") 
      tbody
        tr(v-for="item in items")
          td(:class="getTextClassItem(item)") {{item.subsidary}}
          td(:class="getTextClassItem(item)") {{item.id}}
          td(:class="getTextClassItem(item)") {{item.job_number}}
          td(:class="getTextClassItem(item)") {{item.id_kanela}}
          td(:class="getTextClassItem(item)") {{$I18n.t('vue.states.'+item.job_status.toLowerCase())}}
          td(:class="getTextClassItem(item)") {{item.previous_cleaning}}
          td(:class="getTextClassItem(item)") {{item.schedule_date}}
          td(:class="getTextClassItem(item)") {{item.next_cleaning}}
          td(:class="getTextClassItem(item)") {{item.service_item==null ? "" : item.service_item.service}}
          td(:class="getTextClassItem(item)") {{item.frequency_month=="" ? item.frequency : $I18n.t('vue.jobs_list.months_label')+": "+item.frequency_month}}
          td(:class="getTextClassItem(item)") {{item.invoices_ids}}
          td(:class="getTextClassItem(item)") {{item.invoices_document_number}}
          td(:class="getTextClassItem(item)") {{item.total_amount}}
          td(:class="getTextClassItem(item)") {{item.percentage}}
          td(:class="getTextClassItem(item)") {{item.price_with_credit_card}}
          td(:class="getTextClassItem(item)") {{item.date_paid}}
          td(:class="getTextClassItem(item)") {{item.way_to_pay}}
          td(class="tw-text-center ") 
            app-icon-link(
              data-kind="link",
              :href="item.paths.show"
              data-icon="fa fa-eye",
              data-target="_blank"
              data-label="",
              :data-title="$I18n.t('vue.jobs_list.view_order')"
            )
    div
      h6(class="h6 py-4 tw-text-gray-500") 
        span {{$I18n.t('vue.jobs_list.total_jobs')}}: {{items.length}}
    div(v-if="dataShowLink=='true'")
      .flex(class='justify-content-end')
        a(class="app-btn-link-primary" :href="index_jobs_list_path") 
          span {{$I18n.t('vue.jobs_list.view_jobs')}}
          i(class="fa fa-arrow-right")
</template>
<script>

import CoreComponents from '../core';
const {ActiveValue}=require('../../core/active_support');
const {BackendService,BackendRoutes} = require('app/services');

export default {
  components:{    
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-icon-link": CoreComponents.AppIconLink,
  },
  props:{
    dataMonth: String,
    dataYear: String,
    dataShowLink: String,
  },
  data: function(){
    return {
      state: {
        loading: true,
      },
      items: '',
      month: new Date().getMonth()+1,
      year: new Date().getFullYear(),
    }
  },
  computed:{
    emptyItems: function(){
      return this.data_invoices === '';
    },
    index_jobs_list_path:function(){
      return  BackendRoutes.jobs_index_list_path();
    },
  },
  methods:{
    getTextClassItem: function (item) {
      let status = item.job_status;
      let result = "";
      if (status == "CANCELED"){
        result = "text-danger";
      }
      if (status == "COMPLETED"){
        result = "text-success";
      }
      if (status == "SCHEDULED"){
        result = "text-info";
      }
      return result;
    },
    params: function () {
      return JSON.stringify({
        month: this.dataMonth,
        year: this.dataYear,
      });
    },
    getJobsList:function(){
      let params=""; 
      BackendService
        .get_jobs_list(this.params())
        .then((response) => {
          if (response.isOk) {
            this.state.loading = false;
            this.items = response.data_jobs.jobs;
            this.month = response.data_jobs.month;
            this.year = response.data_jobs.year;
          }
        })
        .catch(() => {
          this.state.loading = false;
        });
    },
  },
  beforeMount() {
    this.getJobsList();
  },
}
</script>
