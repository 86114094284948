import VendorsActive from './vendors_active.vue';
import CustomersActive from './customers_active.vue';
import SubsidariesActive from './subsidaries_active.vue';
import InvoicesByState from './invoices_by_state.vue';
import UnbilledInvoices from './unbilled-invoices.vue';
import UnbilledInvoicesList from './unbilled-invoices-list.vue';
import JobsList from './jobs-list.vue';
export default{
  'vendors-active-card': VendorsActive,
  'customers-active-card': CustomersActive,
  'subsidaries-active-card': SubsidariesActive,
  'invoices-by-state': InvoicesByState,
  'unbilled-invoices': UnbilledInvoices,
  'unbilled-invoices-list': UnbilledInvoicesList,
  'jobs-list': JobsList,
}