<template lang='pug'>
  div(id="main_sidebar" data-button-show-id="show_main_sidebar_btn")
    div(class="sidebar")
      div(class="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0")
        //img(class="icon-sidebar" :src="image")
        span(style="color: white")
          i(style="color: white;font-size: 30px;" class="fa fa-star")
                
      slot

      div(class="text-center")
        br
        button(
          id="hide_main_sidebar_btn"
          class="btn rounded-circle border-0"
          style="border-radius: 50% !important;background-color: #ffffff78;"
        )
          i(style="color: white;" class="fa fa-arrow-left")
      
      

      
      



    
</template>

<script>
export default {
  data:function(){
    return {
    }
  },
  mounted(){
    this.initSidebar()
  },
  props: ["image"],
  methods:{
    displaySidebar:function(elementB,selectorB){
      let self=this;
      this.elB=document.getElementById(elementB);
      this.showButton=document.getElementById(this.elB.getAttribute("data-button-show-id"));
      this.hideButton=document.getElementById(selectorB);
      
      this.handlerClick=function(showA,showB){
        this.showButton.hidden=showA;
        this.elB.hidden=showB;
      }

      this.showButton.addEventListener('click', ()=>{self.handlerClick(true,false)});
      this.hideButton.addEventListener('click', ()=>{self.handlerClick(false,true)});
    },
    initSidebar:function(){
      let display = this.displaySidebar('main_sidebar','hide_main_sidebar_btn')
    }
  }
}
</script>
