<template lang="pug">
div
  app-icon-btn(
    @click.native="showModal",
    :disabled='JSON.parse(dataSubsidaryHoods)==""'
    data-kind="link",
    data-icon="fa fa-plus",
    :data-label="$I18n.t('vue.create_orderable_modal.title')"
  )
  app-modal(
    :showing="controls.modal.showing",
    @showing="controls.modal.showing = $event"
  )
    template(#header)
      p {{ labels.modal_title }}
    template(#body)
      // Spinner Loading
      div(v-if="state.sending" class="tw-text-center")
        div(class="spinner-border spinner-border-sm tw-my-5" role="status")
      div(v-else)
        div(v-if="input_hood_disabled")
        div(v-if="change_service_kinds")
        div(v-if="load_subsidary_hoods")
        app-form.tw-py-4(v-bind:grid="formGrid")
        div(class="form-group row" v-if="!input_hood_disabled")
          div(class="col" )
            label(class="col col-form-label text-right app-label-color") Select Hoods
            div.app-card(style="box-shadow: 0 0rem 0rem rgba(0, 0, 0, 0.15);")
              div(v-for="(hood, key) in subsidary_items_hoods")
                input(
                  class="form-check-input"
                  type="checkbox"
                  v-model='hood.checked'
                  :id='key'
                ) 
                label(:for='key') {{ hood.label }}
            div(v-if="show_message_select_hood")
              p(class="app-text-error") Please select at least 1 hood.
    template(#footer)
      .app-buttons-row.justify-end(v-if="!state.sending")
        app-icon-btn(
          data-kind="link",
          @click.native="clear",
          data-icon="fa fa-eraser",
          :data-label="labels.clear_btn"
        )
        app-icon-btn(
          data-kind="link",
          @click.native="cancel",
          data-icon="fa fa-times",
          :data-label="labels.cancel_btn"
        )
        app-icon-btn(
          data-kind="btn",
          @click.native="create",
          data-icon="fa fa-save",
          :data-label="labels.send_btn"
        )
</template>

<script>
import { ActiveValue } from "../../core/active_support";
import CoreComponents from "../core";
const { ActiveValuesMixin, ProcessingMixin } = require("../../mixins");

export default {
  mixins: [ActiveValuesMixin, ProcessingMixin],
  components: {
    "app-icon-btn": CoreComponents.AppIconBtn,
    "app-modal": CoreComponents.Modal,
    "app-form": CoreComponents.Detailform,
  },
  props: {
    dataId: String,
    dataModel: String,
    dataMaintenanceKinds: String,
    dataMaintenanceComplementKinds: String,
    dataOrderableKinds: String,
    dataSubsidaryHoods: String,
    dataHideModal: String,
  },
  data: function () {
    return {
      subsidary_items_hoods: [],
      show_message_select_hood: false,
      record: {
        id: this.dataId,
        model: this.dataModel,
      },
      controls: {
        modal: {
          showing: false,
        },
      },
      state: {
        sending: false,
      },
      labels: {
        modal_title: this.$I18n.t('vue.create_orderable_modal.title'),
        clear_btn: this.$I18n.t('vue.create_orderable_modal.clear'),
        cancel_btn: this.$I18n.t('vue.create_orderable_modal.cancel'),
        send_btn: this.$I18n.t('vue.create_orderable_modal.send'),
      },
      values: {
        orderable_kind: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.create_orderable_modal.orderable_kind'));
          i.setValue('');
          i.setKind('string');
          i.setInputKind('select');
          i.setInputOption('options',JSON.parse(this.dataOrderableKinds));
          i.required();
        }),
        service_kind: ActiveValue().setup((i)=>{
          i.setLabel(this.$I18n.t('vue.create_orderable_modal.service_kind'));
          i.setValue('');
          i.setKind('string');
          i.setInputKind('select');
          i.setInputOption('options',JSON.parse(this.dataMaintenanceKinds));
          i.required();
        }),
        quantity: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.create_orderable_modal.quantity'));
          i.setValue("1.00");
          i.setInputKind("number");
        }),
        price: ActiveValue().setup((i) => {
          i.setLabel(this.$I18n.t('vue.create_orderable_modal.price'));
          i.setValue("0.00");
          i.setInputKind("number");
        }),
      },
      
    };
  },
  computed: {
    formGrid: function () {
      return [
        [this.values.orderable_kind, this.values.service_kind],
        [this.values.quantity, this.values.price],
      ];
    },
    input_hood_disabled: function(){
      if (this.values.orderable_kind.input==="maintenances"){
        return false
      } else {
        return true
      }
    },
    load_subsidary_hoods: function(){
      this.subsidary_items_hoods = JSON.parse(this.dataSubsidaryHoods).map(item => ({
        ...item,
        checked: false  // Puedes establecer el valor inicial que desees
      }));
    },
    change_service_kinds: function(){
      let ins = this.values;
      if (this.values.orderable_kind.input==="maintenances"){
        this.values.service_kind.setInputOption('options',JSON.parse(this.dataMaintenanceKinds));
        this.values.service_kind.setValue("1");
      } else {
        this.values.service_kind.setInputOption('options',JSON.parse(this.dataMaintenanceComplementKinds));
        this.values.service_kind.setValue("");
      }
    },
  },
  methods: {
    showModal: function () {
      this.controls.modal.showing = true;
    },
    hideModal: function () {
      this.controls.modal.showing = false;
    },
    clear: function () {
      this.resetActiveValues(this.values);
      this.values.quantity.setValue("1.00");
      this.values.price.setValue("0.00");
      this.values.orderable_kind.setValue('maintenances');
      this.subsidary_items_hoods.forEach(h => {
        h.checked=false
      })
    },
    cancel: function () {
      this.hideModal();
      this.clear();
    },
    disabled_inputs: function () {
      
    },
    create_params: function () {
      var hoods = []
      let order_item = {}
      //En caso de un Service
      this.subsidary_items_hoods.forEach(h => {
        if (h.checked === true) {
          order_item = {
            quantity: this.values.quantity.input,
            price: this.values.price.input,
            orderable: {
              kind: this.values.orderable_kind.input,
              id: this.values.service_kind.input,
              hood: {
                id: h.value,
              }
            }
          }
          hoods.push(order_item)
        }
      });
      //En caso de los Extras
      if (this.values.orderable_kind.input==="maintenance_complements"){
        hoods = []
        order_item = {
          quantity: this.values.quantity.input,
          price: this.values.price.input,
          orderable: {
            kind: this.values.orderable_kind.input,
            id: this.values.service_kind.input,
            hood: {
              id: "",
            }
          }
        }
        hoods.push(order_item)
      }
      let params = JSON.stringify({
        id: this.record.id,
        array_hoods: hoods,
      });
      return params;
    },
    create: function () {
      let self = this;
      //Validar que seleccionaron 1 hood minimo
      let num_selected = 0
      this.show_message_select_hood = false
      if (this.values.orderable_kind.input==="maintenances"){
        this.subsidary_items_hoods.forEach(h => {
          if (h.checked === true) {
            num_selected += 1
          }
        })
        if (num_selected==0){
          this.show_message_select_hood = true
          return
        }
      }
      if (this.areValidActiveValues(this.values)) {
        this.state.sending = true;
        let params = this.create_params()
        this.clear();
        if (this.dataHideModal){
          this.hideModal();
          this.state.sending = false;
        }
        this.$emit("created", params);
        this.resetValues();
      }
    },
    resetValues: function(){
      this.clear();
    },
  },
  mounted(){
    this.resetValues();
  },
};
</script>
