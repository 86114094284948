<template lang="pug">
div
  //Notification Alert
  Notificationalert(
    v-if="controls.alert.display" 
    v-bind:alert="controls.alert"
  )

  .tw-flex.tw-flex-row.tw-justify-between
    div
      h5.h5.tw-text-gray-500 {{$I18n.t('vue.subsidary_detail.title')}}
    
    // Spinner Loading
    div(v-if="state.sending" class="tw-text-center")
      div(class="spinner-border spinner-border-sm" role="status")
    
    //Barra de botones  
    div(v-else)
      .tw-flex.tw-flex-row
        delete-subsidary-modal(
          v-if="record.state!='INACTIVE' && state.show"
          :data-id='record.id'
          :data-label="$I18n.t('vue.subsidary_detail.inactive_restaurant')"
          @deleted='disable_subsidary'
        )
        button.app-btn-link-primary(v-if="record.state=='INACTIVE' && state.show", @click="active_subsidary()") 
          i.fa.fa-check-circle.tw-mx-1 
          span {{$I18n.t('vue.subsidary_detail.active_restaurant')}}
        button.app-btn-link-primary(v-if="state.show", @click="edit()") 
          i.fa.fa-pencil.tw-mx-1 
          span {{$I18n.t('vue.subsidary_detail.bt_edit')}}
        button.app-btn-link-primary(v-if="state.edit", @click="cancel()")
          i.fa.fa-times.tw-mx-2
          span {{$I18n.t('vue.subsidary_detail.bt_cancel')}}
        button.app-btn-link-primary(v-if="state.edit", @click="update()")
          i.fa.fa-save.tw-mx-2
          span {{$I18n.t('vue.subsidary_detail.bt_save')}}
  hr
  Detailgrid(:data-grid="grid", :data-editing="state.edit")
</template>

<script>
import DeleteSubsidaryModal from './delete-subsidary-modal.vue'
import CoreComponents from "../core";
import { BackendService } from "../../services";
const { ActiveValue } = require("../../core/active_support");

export default {
  props: ["dataRecord", "dataInvoiceTarget"],
  components: { 
    'delete-subsidary-modal':DeleteSubsidaryModal,
    ...CoreComponents 
  },
  data: function () {
    this.record = JSON.parse(this.dataRecord);
    let self = this;
    return {
      state: {
        show: true,
        edit: false,
        sending: false,
      },
      controls:{
        alert:{
          display: false,
          type: "",
          notice: "",
          message: "",
          link: {
            text: "",
            href: ""
          }
        },
      },
      values: {
        id: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.id"));
          i.setValue(self.record.id);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        name: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.name"));
          i.setValue(self.record.name);
          i.setKind("string");
          i.setInputKind("text");
          i.required();
        }),
        aka: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.aka"));
          i.setValue(self.record.aka);
          i.setKind("string");
          i.setInputKind("text");
        }),
        state: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.state"));
          i.setValue(self.$I18n.t("vue.states."+self.record.state.toLowerCase()));
          i.setKind("string");
          i.setInputKind("text");
          i.required();
          i.notEditable();
        }),
        phone_number: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.phone_number"));
          i.setValue(self.record.phone.number);
          i.setKind("string");
          i.setInputKind("text");
        }),
        vendor: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.vendor"));
          i.setValue(self.record.vendor.name);
          i.setKind("link");
          i.setLinkOptions({ href: self.record.vendor.paths.show });
          i.notEditable();
        }),
        customer: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.customer"));
          i.setValue(self.record.customer.name);
          i.setKind("link");
          i.setLinkOptions({ href: self.record.customer.paths.show });
          i.notEditable();
        }),
        //new
        turn_around_time: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.turn_around_time"));
          i.setValue(self.record.turn_around_time==0 || self.record.turn_around_time=='' ? "COD" : self.record.turn_around_time );
          i.setKind("string");
          i.setInputKind("number");
        }),
        fax_number: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.fax_number"));
          i.setValue(self.record.fax_number);
          i.setKind("string");
          i.setInputKind("text");
        }),
        keys: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.keys"));
          i.setValue(self.record.keys);
          i.setKind("string");
          i.setInputKind("text");
        }),
        alarm: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.alarm"));
          i.setValue(self.record.alarm);
          i.setKind("string");
          i.setInputKind("text");
        }),
        hours_of_operation: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.hours_of_operation"));
          i.setValue(self.record.hours_of_operation);
          i.setKind("string");
          i.setInputKind("text");
        }),
        invoice_target: ActiveValue().setup((i) => {
          i.setLabel(self.$I18n.t("vue.subsidary_detail.invoice_target"));
          i.setValue(self.$I18n.t("vue.invoice_target."+self.record.invoice_target.toLowerCase()));
          i.setKind("string");
          i.setInputKind("select");
          i.setInputOption("options", JSON.parse(this.dataInvoiceTarget));
          i.required();
        }),
      },
    };
  },
  methods: {
    disable_subsidary: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        
      }
    },
    active_subsidary: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService
          .active_subsidary(
            self.record.id,
            JSON.stringify({atts: {}})
          )
          .then((r) => {
            location.reload();
          });
      }
    },
    valid: function () {
      return Object.values(this.values)
        .map((v) => v.validate())
        .map((v) => v.hasError())
        .every((e) => e === false);
    },
    edit: function () {
      this.values.invoice_target.setValue(this.record.invoice_target)
      this.state.edit = true;
      this.state.show = false;
      this.state.sending = false;
    },
    cancel: function () {
      this.values.invoice_target.setValue(this.$I18n.t("vue.invoice_target."+this.record.invoice_target.toLowerCase()))
      this.state.show = true;
      this.state.edit = false;
      this.state.sending = false;
      Object.values(this.values).map((v) => v.valueToInput());
    },
    sending: function () {
      this.state.show = false;
      this.state.edit = false;
      this.state.sending = true;
    },
    params: function () {
      return JSON.stringify({
        id: this.record.id,
        atts: {
          name: this.values.name.input,
          aka: this.values.aka.input,
          phone_number: this.values.phone_number.input,
          keys: this.values.keys.input,
          hours_of_operation: this.values.hours_of_operation.input,
          turn_around_time: this.values.turn_around_time.input,
          fax_number: this.values.fax_number.input,
          alarm: this.values.alarm.input,
          invoice_target: this.values.invoice_target.input,
        },
      });
    },
    update: function () {
      const self = this;
      if (this.valid()) {
        self.sending();
        BackendService.update_subsidary(this.params()).then((r) => {
          self.cancel();
          self.values.name.setValue(r.subsidary.name);
          self.values.aka.setValue(r.subsidary.aka);
          self.values.phone_number.setValue(r.subsidary.phone.number);
          self.values.keys.setValue(r.subsidary.keys);
          self.values.hours_of_operation.setValue(r.subsidary.hours_of_operation);
          self.values.turn_around_time.setValue(r.subsidary.turn_around_time==0 || r.subsidary.turn_around_time=='' ? "COD" : r.subsidary.turn_around_time);
          self.values.fax_number.setValue(r.subsidary.fax_number);
          self.values.alarm.setValue(r.subsidary.alarm);
          self.values.invoice_target.setValue(r.subsidary.invoice_target);
        });
      }
    },
  },
  computed: {
    grid: function () {
      return [
        [this.values.id,                    this.values.state],
        [this.values.name,                  this.values.vendor],
        [this.values.aka,                   this.values.customer],
        [this.values.phone_number,          this.values.turn_around_time],
        [this.values.keys,                  this.values.fax_number],
        [this.values.hours_of_operation,    this.values.alarm],
        [this.values.invoice_target,        ""]
      ];
    },
  },
};
</script>