<template lang='pug'>
  div
    div(v-for='(row, key) in grid' class='row')
      div(v-for='(col, k) in row' class="col")
        div(class="form-group row tw-mt-3")
          //Inputs "TEXT"
          div(v-if="col.inputKind==='text'" class='tw-text-left')
            label(class="col col-form-label text-right app-label-color") {{col.label}} 
            span(class="text-danger") {{col._required ? " * " : ""}}
            div(class="col")
              input(
                v-model="col.input"
                :name="col.inputName" 
                :disabled='!col.editable'
                type="text" 
                class="form-control"
                :title="col.inputTitle"
              )
              p(v-if="col.error" class="app-text-error") {{$I18n.t('vue.general_messages.'+col.error)}}
          //Inputs "NUMBER"
          div(v-if="col.inputKind==='number'")
            label(class="col col-form-label text-right app-label-color") {{col.label}}
            span(class="text-danger") {{col._required ? " * " : ""}}
            div(class="col")
              input(
                type="number" 
                class="form-control" 
                v-model="col.input" 
                :disabled="!col.editable"
                :title="col.inputTitle"
              )
              p(v-if="col.error" class="app-text-error") {{$I18n.t('vue.general_messages.'+col.error)}}
          //Inputs "DECIMAL"
          div(v-if="col.inputKind==='decimal'" class="row")
            label(class="col-4 col-form-label text-right app-label-color") {{col.label}}
            span(class="text-danger") {{col._required ? " * " : ""}}
            div(class="col-8")
              input(
                type="number" 
                step="0.01" 
                class="form-control" 
                v-model="col.input" 
                :disabled="!col.editable"
                :title="col.inputTitle"
              )
              p(v-if="col.error" class="app-text-error") {{$I18n.t('vue.general_messages.'+col.error)}}
          //Inputs "DATE"
          div(v-if="col.inputKind==='date'" class="tw-text-left")
            label(class="col col-form-label text-right app-label-color") {{col.label}}
            span(class="text-danger") {{col._required ? " * " : ""}}
            div(class="col")
              input(
                type="date" 
                class="form-control" 
                v-model="col.input" 
                :disabled="!col.editable"
                :title="col.inputTitle"
              )
              p(v-if="col.error" class="app-text-error") {{$I18n.t('vue.general_messages.'+col.error)}}
          //Inputs "TEXTAREA"
          div(v-if="col.inputKind==='textArea'" class='tw-text-left')
            label(class="col col-form-label text-right app-label-color") {{col.label}}
            span(class="text-danger") {{col._required ? " * " : ""}}
            div(class="col")
              textarea(
                class="form-control" 
                v-model="col.input" 
                rows="3" 
                :disabled="!col.editable"
                :title="col.inputTitle"
              )
              p(v-if="col.error" class="app-text-error") {{$I18n.t('vue.general_messages.'+col.error)}}
          //Inputs "HIDDEN"
          div(v-if="col.inputKind==='hidden'")
            div(class="col")
              input(
                type="hidden" 
                v-model="col.input"
              )
          //Inputs "LABEL_LEFT"
          div(v-if="col.inputKind==='label'" class="tw-text-left")
            label(class="col col-form-label text-right app-label-color") {{col.label}}
            div(class="col")
              input(
                type="hidden" 
                v-model="col.input"
              )
          //Inputs "LABEL_CENTER"
          div(v-if="col.inputKind==='label_center'" class="tw-text-center")
            label(class="col col-form-label text-right app-label-color") {{col.label}}
            div(class="col")
              input(
                type="hidden" 
                v-model="col.input"
              )
          //Inputs "SELECT"
          div(
            v-if="col.inputKind==='select'" 
            class='tw-text-left'
            @change='col.onChangeCb == undefined ? null : col.onChangeCb()'
          )
            label(class="col col-form-label text-left app-label-color") {{col.label}}
            span(class="text-danger") {{col._required ? " * " : ""}}
            div(class="col")
              select(
                class="col form-select" 
                v-model="col.input"
                :disabled="!col.editable"
                :title="col.inputTitle"
              )
                option(class="app-label-color" value="" selected) {{$I18n.t('vue.general_messages.choose_option')}}
                option(v-for="option in col.getInputOption('options')" :value="option.value") {{option.label}}
              p(v-if="col.error" class="app-text-error") {{$I18n.t('vue.general_messages.'+col.error)}}
                
</template>

<script>
export default{
  props:['grid']
}
</script>